import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeperator',
})
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: number, decimals: boolean = true): string {
    if (value === null || value === undefined) {
      return '';
    }

    const thousandsSeparator = "'";
    const fractionSize = decimals ? 2 : 0;

    // round value and fix decimals to fractionSize
    const transformedValueString = value.toFixed(fractionSize);

    // split transformed value string and add thousands separator
    const [integer, fractionRaw] = transformedValueString.split('.');
    const transformedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    if (!fractionRaw || fractionRaw === '00') {
      return transformedInteger;
    } else {
      return `${transformedInteger}.${fractionRaw}`;
    }
  }
}
