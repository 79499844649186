import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  IAdvertSearchFilter,
  ImageFormat,
  ListingDetail,
  ListingDetailFeautres,
  OfferType,
  PriceType,
  PropertySubType,
  PropertyType,
  SearchListingRequest,
  SearchLocation,
  SearchPropertyType,
  SeoInformation,
  UtilityAvailability,
} from 'newhome.dtos';
import { firstCharToUppercase } from '../utilities';
import { PriceService } from './price.service';

@Injectable({
  providedIn: 'root',
})
export class MetatagService {
  constructor(
    @Inject(DOCUMENT) private dom,
    private meta: Meta,
    private translate: TranslateService,
    private priceService: PriceService,
  ) {}

  public setCopyright() {
    const copyright = this.translate.instant('APP.COPYRIGHT', {
      year: new Date().getFullYear(),
    });

    this.meta.updateTag({
      name: 'copyright',
      content: copyright,
    });
  }

  public setLandingDescription(searchFilter: IAdvertSearchFilter) {
    const description = this.getLandingDescription(searchFilter);

    this.setDescription(description);
  }

  public setResultDescription(
    searchFilter: SearchListingRequest,
    searchLocations: SearchLocation[],
    seoInformation: SeoInformation,
  ) {
    const metaDescription = seoInformation?.metaDescription?.trim() ?? '';
    if (metaDescription.length > 0) {
      this.setDescription(metaDescription);
    } else {
      const description = this.getResultDescription(searchFilter, searchLocations);
      this.setDescription(description);
    }
  }

  public setDetailDescription(detail: ListingDetail) {
    const description = this.getDetailDescription(detail);

    this.setDescription(description);
  }

  public removeDescription() {
    this.meta.removeTag("property='description'");
    this.meta.removeTag("property='og:description'");
  }

  private setDescription(description: string) {
    if (description) {
      this.meta.updateTag({
        name: 'description',
        content: description,
      });
      this.meta.updateTag({
        property: 'og:description',
        content: description,
      });
    }
  }

  private getLandingDescription(searchFilter: IAdvertSearchFilter): string {
    if (searchFilter.offerType) {
      const offerTypeText = this.translate.instant('ENUM.OFFERTYPE.' + OfferType[searchFilter.offerType]);

      if (searchFilter.propertySubtypes && searchFilter.propertySubtypes.length === 1) {
        const propertyTypeText = this.translate.instant(
          'ENUM.PROPERTYSUBTYPE.' + PropertySubType[searchFilter.propertySubtypes[0]],
        );

        return this.translate.instant('DISCOVER.LANDING.SEO_DESCRIPTION', {
          offerTypeText,
          propertyTypeText,
        });
      } else if (searchFilter.propertyType) {
        const propertyTypeText = this.translate.instant(
          'ENUM.PROPERTYTYPE.' + SearchPropertyType[searchFilter.propertyType],
        );

        return this.translate.instant('DISCOVER.LANDING.SEO_DESCRIPTION', {
          offerTypeText,
          propertyTypeText,
        });
      }
    }

    return this.translate.instant('DISCOVER.LANDING.SEO_DESCRIPTION_DEFAULT');
  }

  private getResultDescription(searchFilter: SearchListingRequest, searchLocations: SearchLocation[]): string {
    if (searchFilter.offerType && (searchFilter.location || searchFilter.radiusCenterCoordinate)) {
      let propertyTypeText = this.translate.instant(
        'DISCOVER.RESULT.SEO_DESCRIPTION.PROPERTYTYPE_TEXT.' + SearchPropertyType[SearchPropertyType.All],
      );

      let availablePropertyTypeText = this.translate.instant(
        'DISCOVER.RESULT.SEO_DESCRIPTION.AVAILABLE_PROPERTYTYPE_TEXT.' + SearchPropertyType[SearchPropertyType.All],
      );

      if (searchFilter.propertyType) {
        propertyTypeText = this.translate.instant(
          'DISCOVER.RESULT.SEO_DESCRIPTION.PROPERTYTYPE_TEXT.' + SearchPropertyType[searchFilter.propertyType],
        );
        availablePropertyTypeText = this.translate.instant(
          'DISCOVER.RESULT.SEO_DESCRIPTION.AVAILABLE_PROPERTYTYPE_TEXT.' +
            SearchPropertyType[searchFilter.propertyType],
        );
      }

      const offerTypeText = this.translate.instant(
        'DISCOVER.RESULT.SEO_DESCRIPTION.OFFERTYPE_TEXT.' + OfferType[searchFilter.offerType],
      );

      // location search
      if (searchFilter?.location?.length) {
        const location = searchLocations.find(p => p.identifier === searchFilter.location[0]);

        if (!location) {
          return undefined;
        }

        return this.translate.instant('DISCOVER.RESULT.SEO_DESCRIPTION.TEMPLATE', {
          propertyTypeText,
          availablePropertyTypeText,
          offerTypeText,
          location: location.displayName,
        });
      }

      // custom perimeter search
      if (searchFilter.radiusCenterCoordinate) {
        return this.translate.instant('DISCOVER.RESULT.SEO_DESCRIPTION.TEMPLATE_CUSTOM_PERIMETER', {
          propertyTypeText,
          availablePropertyTypeText,
          offerTypeText,
        });
      }
    }

    return undefined;
  }

  private getDetailDescription(detail: ListingDetail): string {
    if (detail.propertyType && detail.location) {
      const propertySubType =
        detail.detail.propertySubType &&
        this.translate.instant(`ENUM.PROPERTYSUBTYPE.${PropertySubType[detail.detail.propertySubType]}`);
      const offerType = this.translate.instant('ENUM.OFFERTYPE.' + OfferType[detail.offerType]).toLowerCase();
      const location = `${detail.postalCode} ${detail.city}`.trim();
      const rooms = detail.detail.rooms?.toString().replace('.5', ' 1/2');
      const price = this.getPriceStringInDetailText(detail);
      const plotArea = detail.detail.plotArea && `${detail.detail.plotArea} m²`;
      const livingArea = detail.detail.livingArea && `${detail.detail.livingArea} m²`;
      const usableArea = detail.detail.usableArea && `${detail.detail.usableArea} m²`;
      const equipment = this.getEquipmentsString(detail.feature);
      const subtitle = detail.title;
      const constructionYear = detail.detail.constructionYear;

      let parts: string[];

      switch (detail.propertyType) {
        case PropertyType.House:
          parts = this.getDetailHouseDescription(
            offerType,
            propertySubType,
            location,
            rooms,
            livingArea,
            constructionYear,
            equipment,
          );
          break;
        case PropertyType.Apartment:
          parts = this.getDetailApartmentDescription(
            offerType,
            propertySubType,
            location,
            rooms,
            livingArea,
            constructionYear,
            equipment,
          );
          break;

        case PropertyType.Apartmenthouse:
          parts = this.getDetailApartmenthouseDescription(offerType, location, subtitle, constructionYear, price);
          break;

        case PropertyType.Business:
          parts = this.getDetailBusinessDescription(
            propertySubType,
            offerType,
            location,
            subtitle,
            usableArea,
            constructionYear,
            price,
          );
          break;

        case PropertyType.Buildingland:
          parts = this.getDetailBuildinglandDescription(
            propertySubType,
            offerType,
            location,
            subtitle,
            plotArea,
            price,
          );
          break;

        case PropertyType.Parkingspace:
          parts = this.getDetailParkingspaceDescription(propertySubType, offerType, location, subtitle, price);
          break;
      }

      parts.push(this.translate.instant('DETAIL.SEO.DESCRIPTION_END'));

      return firstCharToUppercase(parts.join(' '));
    }

    return undefined;
  }

  public setRobots(value: string) {
    this.meta.updateTag({
      name: 'robots',
      content: value,
    });
  }

  public setOpenGraphUrl() {
    const url = new URL(this.dom.URL);

    this.meta.updateTag({
      property: 'og:url',
      content: `https://${url.host}${url.pathname}`,
    });
  }

  public removeOpenGraphUrl() {
    this.meta.removeTag("property='og:url'");
  }

  public setOpenGraphImageDetail(detail: ListingDetail) {
    detail.images?.length
      ? this.setOpenGraphImage(detail.images[0].imageFormats.find(x => x.format === ImageFormat.Detail).url)
      : this.setOpenGraphImage();
  }

  public setOpenGraphImage(image?: string) {
    const url = new URL(this.dom.URL);

    this.meta.updateTag({
      property: 'og:image',
      content: image
        ? image
        : `https://${url.host}/assets/img/social/newhome-logo-claim-${this.translate.currentLang}.png`,
    });
  }

  public removeOpenGraphImage() {
    this.meta.removeTag("property='og:image'");
  }

  private getPriceStringInDetailText(detail: ListingDetail) {
    var price = this.priceService.getPriceString(detail);

    if (!price && detail.price.priceType === PriceType.PriceOnRequest) {
      price = this.translate.instant('DETAIL.SEO.PRICE_ON_REQUEST');
    }

    return price;
  }

  private getEquipmentsString(feature: ListingDetailFeautres): string[] {
    if (Object.values(feature).some(equipment => equipment === true)) {
      return Object.keys(feature)
        .filter(equipment => feature[equipment])
        .map(item => {
          return item === 'showerAvailability' || item === 'toiletAvailability'
            ? this.translate.instant(`DETAIL.EQUIPMENT.${item}.${UtilityAvailability[feature[item]].toString()}`)
            : this.translate.instant(`DETAIL.EQUIPMENT.${item}`);
        })
        .sort((a, b) => a.localeCompare(b));
    }

    return [];
  }

  private getDetailHouseDescription(
    offerType: OfferType,
    propertySubType: PropertySubType,
    location: string,
    rooms: string,
    livingArea: string,
    constructionYear: number,
    equipments: string[],
  ): string[] {
    let propertyTypeName = PropertyType[PropertyType.House];
    if (!rooms) {
      propertyTypeName = propertyTypeName + '_without_rooms';
    }

    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.DESCRIPTION_START.' + propertyTypeName, {
        offerType,
        propertySubType,
        location,
        rooms,
      }),
    ];

    const subParts: string[] = [];

    if (livingArea) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.LIVING_AREA', {
          livingArea,
        }),
      );
    }

    if (constructionYear) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.CONSTRUCTION_YEAR', {
          constructionYear,
        }),
      );
    }

    if (equipments && equipments.length) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.EQUIPMENTS', {
          equipments: equipments.join(', '),
        }),
      );
    }

    if (subParts.length) {
      parts.push(subParts.join(', '));
    }

    return parts;
  }

  private getDetailApartmentDescription(
    offerType: OfferType,
    propertySubType: PropertySubType,
    location: string,
    rooms: string,
    livingArea: string,
    constructionYear: number,
    equipments: string[],
  ): string[] {
    let propertyTypeName = PropertyType[PropertyType.Apartment];
    if (!rooms) {
      propertyTypeName = propertyTypeName + '_without_rooms';
    }

    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.DESCRIPTION_START.' + propertyTypeName, {
        offerType,
        propertySubType,
        location,
        rooms,
      }),
    ];

    const subParts: string[] = [];

    if (livingArea) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.LIVING_AREA', {
          livingArea,
        }),
      );
    }

    if (constructionYear) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.CONSTRUCTION_YEAR', {
          constructionYear,
        }),
      );
    }

    if (equipments && equipments.length) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.EQUIPMENTS', {
          equipments: equipments.join(', '),
        }),
      );
    }

    if (subParts.length) {
      parts.push(subParts.join(', '));
    }

    return parts;
  }

  private getDetailApartmenthouseDescription(
    offerType: string,
    location: string,
    subtitle: string,
    constructionYear: number,
    price: string,
  ): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.DESCRIPTION_START.' + PropertyType[PropertyType.Apartmenthouse], {
        offerType,
        location,
      }),
    ];

    if (subtitle) {
      parts.push(`${subtitle} -`);
    }

    const subParts: string[] = [];

    if (constructionYear) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.CONSTRUCTION_YEAR', {
          constructionYear,
        }),
      );
    }

    if (price) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.PRICE', {
          price,
        }),
      );
    }

    if (subParts.length) {
      parts.push(subParts.join(', '));
    }

    return parts;
  }

  private getDetailBusinessDescription(
    propertySubType: string,
    offerType: string,
    location: string,
    subtitle: string,
    usableArea: string,
    constructionYear: number,
    price: string,
  ): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.DESCRIPTION_START.' + PropertyType[PropertyType.Business], {
        propertySubType,
        offerType,
        location,
      }),
    ];

    if (subtitle) {
      parts.push(`${subtitle} -`);
    }

    const subParts: string[] = [];

    if (usableArea) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.USABLE_AREA', {
          usableArea,
        }),
      );
    }

    if (constructionYear) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.CONSTRUCTION_YEAR', {
          constructionYear,
        }),
      );
    }

    if (price) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.PRICE', {
          price,
        }),
      );
    }

    if (subParts.length) {
      parts.push(subParts.join(', '));
    }

    return parts;
  }

  private getDetailBuildinglandDescription(
    propertySubType: string,
    offerType: string,
    location: string,
    subtitle: string,
    plotArea: string,
    price: string,
  ): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.DESCRIPTION_START.' + PropertyType[PropertyType.Buildingland], {
        propertySubType,
        offerType,
        location,
      }),
    ];

    if (subtitle) {
      parts.push(`${subtitle} -`);
    }

    const subParts: string[] = [];

    if (plotArea) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.PLOT_AREA', {
          plotArea,
        }),
      );
    }

    if (price) {
      subParts.push(
        this.translate.instant('DETAIL.SEO.PRICE', {
          price,
        }),
      );
    }

    if (subParts.length) {
      parts.push(subParts.join(', '));
    }

    return parts;
  }

  private getDetailParkingspaceDescription(
    propertySubType: string,
    offerType: string,
    location: string,
    subtitle: string,
    price: string,
  ): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.DESCRIPTION_START.' + PropertyType[PropertyType.Parkingspace], {
        propertySubType,
        offerType,
        location,
      }),
    ];

    if (subtitle) {
      parts.push(`${subtitle} -`);
    }

    if (price) {
      parts.push(
        this.translate.instant('DETAIL.SEO.PRICE', {
          price,
        }),
      );
    }

    return parts;
  }
}
