import { SearchListingRequest, SearchLocation, SearchPropertyType } from 'newhome.dtos';
import { resolveOfferType, resolvePropertySubType, resolvePropertyType } from '../map.resolver';
import { listPrefixMap, listSuffixMap } from '../mappings';
import { buildSearchLocationSeoString } from './search-location';
import { buildUrl } from './shared';

export const buildResultsRouteUrl = (
  language: string,
  filter: Partial<SearchListingRequest>,
  locations: SearchLocation[],
): string => {
  const { offerType, wildcard, listSuffix } = {
    offerType: resolveOfferType(language, filter.offerType),
    wildcard: listPrefixMap[language],
    listSuffix: listSuffixMap[language],
  };

  const propertyType = resolvePropertyType(language, filter.propertyType || SearchPropertyType.All);

  if (!filter.location || filter.location.length !== 1) {
    return buildUrl([offerType, wildcard, propertyType, listSuffix]);
  }

  const locationIdentifier = locations.find(x => x.identifier === filter.location[0]);

  const location =
    locationIdentifier?.displayName && locationIdentifier?.locationType
      ? buildSearchLocationSeoString(language, locationIdentifier)
      : '';

  const propertySubType =
    filter.propertySubtypes &&
    filter.propertySubtypes.length === 1 &&
    resolvePropertySubType(language, filter.propertySubtypes[0])
      ? resolvePropertySubType(language, filter.propertySubtypes[0])
      : '';

  if (!location) return buildUrl([offerType, propertySubType || propertyType] as string[]);

  return buildUrl([offerType, wildcard, propertyType, propertySubType, location, listSuffix]);
};
