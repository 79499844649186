import { Directive, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BasicControlValueAccessor<TValue> implements ControlValueAccessor, OnDestroy {
  protected readonly control: FormControl<TValue | null | undefined>;

  private _onTouched: () => unknown = () => {};
  private _onChange: (_: TValue | null | undefined) => unknown = _ => {};

  private readonly subscriptions: Subscription[];

  constructor(defaultValue?: TValue | null | undefined) {
    this.control = new FormControl(defaultValue);

    this.subscriptions = [this.control.valueChanges.subscribe(value => this.onValueChanged(value))];
  }

  protected onValueChanged(value: TValue | null | undefined): void {
    this.markAsTouched();

    if (!this.control.disabled) {
      this.writeValue(value);
      this._onChange(value);
    }
  }

  writeValue(value: TValue | null | undefined): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (_: TValue | null | undefined) => unknown): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disabled || this.control.disable();
    } else {
      this.control.enabled || this.control.enable();
    }
  }

  markAsTouched(): void {
    if (!this.control.touched) {
      this._onTouched();
      this.control.markAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
