import { createAction, props } from '@ngrx/store';
import { StatisticType } from 'newhome.dtos';

export const sendStatisticData = createAction(
  '[Statistic] Send Statistic Data',
  props<{
    statistic: StatisticType;
    immocode?: number;
    contentBoxIds?: number[];
    advertId?: number;
    mandantCode?: string;
  }>(),
);
