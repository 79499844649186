import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'nh-labeled-checkbox',
  templateUrl: './labeled-checkbox.component.html',
  styleUrls: ['./labeled-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabeledCheckboxComponent {
  @Input() control: FormControl = new FormControl();
  @Input() required: boolean = false;
  @Input() errorMessage: string;
  @Input() checkboxId: string;
}
