import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { offerTypeMap, propertySubTypeMap, propertyTypeMap } from 'app/shared/seo/mappings';
import { availableLanguages } from 'app/shared/utilities';
import { staticRoutes } from '../static-routes';

export const redirectToLandingRouteMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
  const [languageSegment] = segments;
  if (!availableLanguages.some(p => p === languageSegment?.path)) {
    if (segments.length === 0) {
      // route like /
      return {
        consumed: [],
        posParams: {},
      };
    }

    // invalid language
    return null;
  }

  // route like /lang
  return {
    consumed: [languageSegment],
    posParams: {
      lang: languageSegment,
    },
  };
};

export const landingRouteMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  if (segments.length === 1) {
    const [homeSegment] = segments;

    if (homeSegment.path === staticRoutes.home) {
      return {
        consumed: [homeSegment],
        posParams: {},
      };
    }
  }

  if (segments.length === 2) {
    const [offerTypeSegment, propertyTypeSegment] = segments;
    const lang = groups.segments[0]?.path;

    if (offerTypeMap[lang][offerTypeSegment?.path]) {
      if (propertyTypeMap[lang][propertyTypeSegment?.path]) {
        // route like /lang/offerType/propertyType
        return {
          consumed: [offerTypeSegment, propertyTypeSegment],
          posParams: {
            offerType: offerTypeSegment,
            propertyType: propertyTypeSegment,
          },
        };
      } else if (propertySubTypeMap[lang][propertyTypeSegment?.path]) {
        // route like /lang/offerType/propertySubType
        return {
          consumed: [offerTypeSegment, propertyTypeSegment],
          posParams: {
            offerType: offerTypeSegment,
            propertySubType: propertyTypeSegment,
          },
        };
      }
    }
  }

  return null;
};
