import { Action, createReducer } from '@ngrx/store';

export const statisticFeatureKey = 'statistic';

export interface State {}

export const initialState: State = {};

const statisticReducer = createReducer(initialState);

export function reducer(state: State | undefined, action: Action) {
  return statisticReducer(state, action);
}
