// Sentry list of ignore errors
export const SENTRY_IGNORE_ERRORS = [
  'Module.p',
  'Uncaught (in promise)',
  'Non-Error exception captured with keys',
  'Failed to register a ServiceWorker',
  'NS_ERROR_NOT_INITIALIZED',
  'NS_ERROR_FILE_NO_DEVICE_SPACE',
  'NS_ERROR_UNEXPECTED',
  'NS_ERROR_FILE_NOT_FOUND',
  'NS_ERROR_FAILURE',
  'NS_ERROR_STORAGE_CONSTRAINT',
  'NS_ERROR_FILE_CORRUPTED',
  'ChunkLoadError',
  // Promise rejection
  'promise rejection',
  // Ignore initialize WebGL errors
  'Failed to initialize WebGL',
  // Ignore all serviceworker errors
  'serviceworker',
  'navigator.serviceWorker.register',
  // Ignore failed to fetch errors
  'AbortError: Fetch is aborted',
  'Failed to fetch',
  // NetworkError when attempting to fetch resource errors
  'NetworkError when attempting to fetch resource',
  // Google analytics error
  "TypeError: undefined is not an object (evaluating 'inputs[1].addEventListener')",
  // ad exception
  'inputs[1] is undefined',
  "reading 'addEventListener'",
  'Rejected',
  'Handled unknown error',
];
