import { ConsentCategories } from '../categories';

export const windowEventName = 'ucEvent';
export const initializedWindowEventName = 'UC_UI_INITIALIZED';

export interface UserCentricsEvent {
  detail: Record<string, boolean> & {
    type: 'implicit' | 'explicit';
    ucCategory: ConsentCategories;
  };
}
