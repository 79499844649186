import { Action, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import * as SearchLocationActions from './search-location.actions';
import { SearchLocation } from 'newhome.dtos';

export const searchLocationsFeatureKey = 'searchLocations';

export interface State extends EntityState<SearchLocation> {
  // additional entities state properties
}

export function selectSearchLocationIdentifier(searchLocation: SearchLocation): string {
  return searchLocation?.identifier;
}

export const adapter: EntityAdapter<SearchLocation> = createEntityAdapter<SearchLocation>({
  selectId: selectSearchLocationIdentifier,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const searchLocationReducer = createReducer(
  initialState,
  on(SearchLocationActions.addSearchLocation, (state, action) => adapter.addOne(action.searchLocation, state)),
  on(SearchLocationActions.upsertSearchLocation, (state, action) => adapter.upsertOne(action.searchLocation, state)),
  on(SearchLocationActions.addSearchLocations, (state, action) => adapter.addMany(action.searchLocations, state)),
  on(SearchLocationActions.upsertSearchLocations, (state, action) => adapter.upsertMany(action.searchLocations, state)),
  on(SearchLocationActions.updateSearchLocation, (state, action) => adapter.updateOne(action.searchLocation, state)),
  on(SearchLocationActions.updateSearchLocations, (state, action) => adapter.updateMany(action.searchLocations, state)),
  on(SearchLocationActions.deleteSearchLocation, (state, action) => adapter.removeOne(action.id, state)),
  on(SearchLocationActions.deleteSearchLocations, (state, action) => adapter.removeMany(action.ids, state)),
  on(SearchLocationActions.loadSearchLocations, (state, action) => adapter.setAll(action.searchLocations, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return searchLocationReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectSearchLocations = createFeatureSelector<State>(searchLocationsFeatureKey);

export const selectAllEntities = createSelector(selectSearchLocations, selectEntities);

export const selectAllAsArray = createSelector(selectSearchLocations, selectAll);
