import { createAction, props } from '@ngrx/store';
import { ListingDetailResponse } from 'newhome.dtos';

export const beginLoadDetail = createAction('[Detail] Begin Load Detail', props<{ id: number }>());

export const loadDetailSuccess = createAction(
  '[Detail] Load Detail Success',
  props<{ immocode: number; response: ListingDetailResponse }>(),
);

export const loadDetailFailure = createAction(
  '[Detail] Load Detail Failure',
  props<{ error: any; immocode: number }>(),
);

export const sendDetailViewStatistic = createAction(
  '[Detail] Sends Detail View Statistic',
  props<{
    financeInfoAdvertisementId?: number;
    detailAdvertisementId?: number;
    immoCode: number;
    languageIso: string;
  }>(),
);

export const sendCountAdvertMortgageLinkClick = createAction(
  '[Detail] Send Count Advert Mortgage Link Click',
  props<{ mandantCode: string }>(),
);

export const sendCountAdvertAdvertismentClick = createAction(
  '[Detail] Send Count Advert Advertisment Click',
  props<{ advertId: number }>(),
);

export const setBackUrl = createAction('[Detail] Set Back Url', props<{ backUrl: string }>());

export const removeBackUrl = createAction('[Detail] Remove Back Url');
