import { Action, createReducer, on } from '@ngrx/store';
import { SearchSubscriptionDetail } from 'newhome.dtos';
import * as SearchSubscriptionActions from './search-subscription.actions';

export const searchSubscriptionFeatureKey = 'searchSubscription';

export type ExtendedSearchSubscriptionDetail = SearchSubscriptionDetail & {
  userAttached: boolean;
};

export interface DeviceMismatchState {
  confirm: boolean;
  extend: boolean;
  linkedToAccount: boolean;
}

export interface State {
  hasError: boolean;
  isLoading: boolean;
  confirmed: ExtendedSearchSubscriptionDetail[];
  unconfirmed: SearchSubscriptionDetail[];
  deviceMismatchState: DeviceMismatchState;
}

export const initialState: State = {
  isLoading: false,
  hasError: false,
  confirmed: [],
  unconfirmed: [],
  deviceMismatchState: null,
};

const searchSubscriptionReducer = createReducer(
  initialState,

  on(SearchSubscriptionActions.beginLoadSearchSubscriptions, state => {
    return {
      ...state,
      isLoading: true,
      hasError: false,
    };
  }),
  on(SearchSubscriptionActions.loadSearchSubscriptionsSuccess, (state, action) => {
    return {
      ...state,
      confirmed: action.confirmed,
      unconfirmed: action.unconfirmed,
      isLoading: false,
      hasError: false,
    };
  }),
  on(SearchSubscriptionActions.loadSearchSubscriptionsFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
    };
  }),
  on(SearchSubscriptionActions.toggleDeviceMismatchNotice, (state, action) => {
    return {
      ...state,
      deviceMismatchState: action.state,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return searchSubscriptionReducer(state, action);
}
