import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { myNewhomeMap } from 'app/shared/seo/mappings';
import { availableLanguages } from 'app/shared/utilities';

export const myNewhomeRouteMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  const languageIndex = 0;
  const myNewhomeIndex = 1;

  const lang = segments[languageIndex]?.path;
  if (!availableLanguages.some(p => p === lang)) {
    return null;
  }

  const myNewhomeKeywordLocalized = segments[myNewhomeIndex]?.path;
  if (myNewhomeMap[lang] !== myNewhomeKeywordLocalized) {
    return null;
  }

  const posParams = {
    lang: segments[languageIndex],
  };

  return {
    consumed: segments.slice(0, myNewhomeIndex + 1),
    posParams,
  };
};
