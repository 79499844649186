export const environment = {
  production: true,
  verbose: false,
  vapidPublicKey: 'BPAKm3mu3V1HaLSacB_1Pla_WlADd8s2QEMAfEbMzxha8EX8olN5-K_pxOOVu_6nuIw7wnm_vh8NgJ7RTao5DsM',
  baseUrl: 'https://service-build.newhome.ch/api',
  targomoKey: '5EQ94UZBUT2VMXVR2D0V205319609',
  enableServiceWorker: true,
  lastPolicyUpdate: '2023-09-13T00:00:00',
  componentsPageEnabled: true,

  emarsys: {
    enabled: false,
    merchantId: '128D53C6ADD11D8E',
    testMode: true,
  },

  admeira: {
    enabled: true,
    environment: 'staging',
  },

  userCentrics: {
    enabled: false,
    settingsId: 'Aj7eiaVRYLJAh6',
  },

  baseHost: 'https://build.newhome.ch',

  environmentName: 'build',

  identity: {
    url: 'https://id-build.newhome.ch',
    callbackUrl: 'https://build.newhome.ch/callback',
    postLogoutUrl: 'https://build.newhome.ch',
    clientId: 'ng-nnh-build',
  },

  gtm: {
    id: 'GTM-W6MXS7',
    auth: 'C8oEjNFtEdssyBA2RAKARQ',
    enabled: true,
    preview: 'env-281',
    gaEnvironment: 'dev',
  },

  logo: 'https://build.newhome.ch/ressourcen/logo/logo.png',

  sentry: {
    enabled: true,
    dsn: 'https://2dcb87b5e88346f5b5d912faf34d508a@sentry.newhome.ch/18',
  },
};
