import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  forwardRef,
} from '@angular/core';
import { OptionComponent } from '../common/option/option.component';
import { NH_AUTOCOMPLETE } from './tokens';

@Component({
  selector: 'nh-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NH_AUTOCOMPLETE,
      useExisting: AutocompleteComponent,
    },
  ],
})
export class AutocompleteComponent implements AfterViewInit {
  public dropdownOpen: boolean = false;
  public markedOption: OptionComponent;

  @Input() toggle: HTMLElement;

  @Output() valueSelected = new EventEmitter<any>();

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  @ContentChildren(forwardRef(() => OptionComponent), { descendants: true })
  private options: QueryList<OptionComponent>;

  ngAfterViewInit(): void {
    this.toggle.addEventListener('focus', () => this.open());
    this.toggle.addEventListener('keydown', $event => this.keyboardActions($event));
    this.toggle.addEventListener('blur', () => this.close());
  }


  open(): void {
    if (!this.dropdownOpen) {
      this.dropdownOpen = true;
      this.changeDetector.detectChanges();
    }
  }

  close(): void {
    if (this.dropdownOpen) {
      this.dropdownOpen = false;
      this.changeDetector.detectChanges();
    }
  }


  keyboardActions(e: KeyboardEvent) {
    switch (e.code) {
      case 'ArrowDown':
        this.nextOption();
        e.preventDefault();
        break;

      case 'ArrowUp':
        this.previousOption();
        e.preventDefault();
        break;

      case 'Escape':
        this.close();
        break;

      case 'Enter':
        if (!this.dropdownOpen) {
          this.open();
        } else if (this.markedOption) {
          this.selectOption(this.markedOption);
          this.close();
        }
        break;

      default:
        if (!e.ctrlKey && !e.altKey) {
          this.open();
        }
        break;
    }
  }

  nextOption() {
    if (this.markedOption !== this.options.last) {
      const index = this.options.toArray().indexOf(this.markedOption);
      this.markOption(this.options.get(index + 1));
    }
  }

  previousOption() {
    if (this.markedOption !== this.options.first) {
      const index = this.options.toArray().indexOf(this.markedOption);
      this.markOption(this.options.get(index - 1));
    }
  }

  scrollToOption(option: OptionComponent) {
    option.element.scrollIntoView({ block: 'nearest' });
  }

  markOption(option: OptionComponent) {
    this.options.forEach(option => {
      option.marked = false;
    });
    this.markedOption = option;
    this.markedOption.marked = true;
    this.changeDetector.detectChanges();
    this.scrollToOption(option);
  }

  selectOption(option: OptionComponent) {
    this.markOption(option);

    this.valueSelected.emit(option.value);
  }
}
