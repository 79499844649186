import { replaceAll, normalizeCharsMap } from 'app/shared/utilities';
import { SearchLocation } from 'newhome.dtos';
import { locationTypeMap } from '../mappings';

export const buildSearchLocationSeoString = (lang: string, searchLocation: SearchLocation) => {
  const type = locationTypeMap[lang][searchLocation.locationType];
  const location = replaceAll(searchLocation.displayName, normalizeCharsMap);

  const qndWorkaround = replaceAll(location, { [' ']: '-' });
  const replaceDouble = replaceAll(qndWorkaround, { ['--']: '-' });
  return `${type}-${replaceDouble.toLowerCase()}`;
};
