import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromWatchlist from './watchlist.reducer';
import { EffectsFeatureModule, EffectsModule } from '@ngrx/effects';
import { WatchlistEffects } from './watchlist.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromWatchlist.watchlistFeatureKey, fromWatchlist.reducer),
    EffectsModule.forFeature([WatchlistEffects]),
  ],
})
export class WatchlistModule {}
