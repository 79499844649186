import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { detailPrefixMap, offerTypeMap, providerResultMap } from 'app/shared/seo/mappings';
import { availableLanguages } from 'app/shared/utilities';

export const providerResultMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  if (segments.length < 4) {
    return null;
  }

  const [languageSegment, offerTypeSegment, detailPrefixSegment, providerResultSuffixSegment] = segments;

  if (!availableLanguages.some(p => p === languageSegment?.path)) {
    // invalid language
    return null;
  }

  const offerTypeRoutes = Object.values(offerTypeMap[languageSegment.path]);
  const offerTypeMatches = offerTypeRoutes.some(p => p === offerTypeSegment?.path);
  const detailPrefixMatches = detailPrefixMap[languageSegment?.path] === detailPrefixSegment?.path;

  const providerSuffixMatches = providerResultMap[languageSegment?.path] === providerResultSuffixSegment?.path;

  if (offerTypeMatches && detailPrefixMatches && providerSuffixMatches) {
    return {
      consumed: [languageSegment, offerTypeSegment, detailPrefixSegment, providerResultSuffixSegment],
      posParams: {
        lang: languageSegment,
        offerType: offerTypeSegment,
      },
    };
  }

  return null;
};
