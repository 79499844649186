import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { langPattern } from 'app/language';
import { providerNotFoundMap } from 'app/shared/seo/mappings';

export const providerNotFoundMatcher: UrlMatcher = (
  segments: UrlSegment[],
  groups: UrlSegmentGroup,
): UrlMatchResult => {
  const languageSegmentIndex = 0;
  const providerNotFoundSufflixIndex = 1;

  const languageSegment = segments[languageSegmentIndex];
  if (!languageSegment?.path.match(langPattern)) {
    return null;
  }

  const providerNotFoundSuffix = segments[providerNotFoundSufflixIndex]?.path;
  if (providerNotFoundMap[languageSegment?.path] !== providerNotFoundSuffix) {
    return null;
  }

  return {
    consumed: segments,
    posParams: {
      lang: languageSegment,
    },
  };
};
