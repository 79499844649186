import { Buffer as PolyBuffer } from 'buffer';

// see: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_.22Unicode_Problem.22
export function b64DecodeUnicode(str) {
  const base64 = str.replace(/\-/g, '+').replace(/\_/g, '/');

  return decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
}

export function base64UrlEncode(str): string {
  const base64 = btoa(str);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

function atob(str: string) {
  return PolyBuffer.from(str, 'base64').toString('binary');
}

function btoa(str: string) {
  return PolyBuffer.from(str.toString(), 'binary').toString('base64');
}
