import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as UserDataReducer from './user-data.reducer';

export const selectUserDataState = createFeatureSelector<UserDataReducer.State>(UserDataReducer.userDataFeatureKey);

export const selectProfile = createSelector(selectUserDataState, x => x.profile?.profile);

export const selectIsProfileLoading = createSelector(selectUserDataState, x => x.profile?.isLoading ?? false);

export const selectHasProfileError = createSelector(selectUserDataState, x => x.profile?.hasError ?? false);

export const selectUserInfo = createSelector(selectUserDataState, x => x.userInfo?.userInfo);

export const selectIsUserInfoLoading = createSelector(selectUserDataState, x => x.userInfo?.isLoading ?? false);

export const selectHasUserInfoError = createSelector(selectUserDataState, x => x.userInfo?.hasError ?? false);
