import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as GlobalAlertsActions from './global-alerts.actions';
import { BannerType } from '../banner/banner-type.enum';

export const globalAlertsFeatureKey = 'globalAlerts';
export const cookieKey = 'global_alerts';
export const newCookieKey = 'GlobalAlerts';

export class GlobalAlert {
  constructor(init?: Partial<GlobalAlert>) {
    (Object as any).assign(this, init);
  }

  id: string;
  title: string;
  text: string;
  size: 'medium' | 'big' | 'small' = 'medium';
  type: BannerType = BannerType.Cta;
  dismissible: boolean;
}

export interface State extends EntityState<GlobalAlert> {
  // additional entities state properties
}

export const selectAlertId = (alert: GlobalAlert): string => alert.id;

export const adapter: EntityAdapter<GlobalAlert> = createEntityAdapter<GlobalAlert>({
  selectId: selectAlertId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const globalAlertsReducer = createReducer(
  initialState,
  on(GlobalAlertsActions.initializedGlobalAlerts, (state, action) => adapter.addMany(action.alerts, state)),
  on(GlobalAlertsActions.addAlert, (state, action) => adapter.addOne(action.alert, state)),
  on(GlobalAlertsActions.dismissAllAlerts, (state, action) => adapter.removeAll(state)),
);

export function reducer(state: State | undefined, action: Action) {
  return globalAlertsReducer(state, action);
}
