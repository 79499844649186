import { DOCUMENT, isPlatformBrowser, LOCATION_INITIALIZED } from '@angular/common';
import { inject, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JsonServiceClient } from '@servicestack/client';
import { authConfig } from 'auth.config';
import { environment } from 'environments/environment';
import { from } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LanguageService } from './language';
import { OAuthService } from './shared/oauth2-oidc/lib/public_api';

@Injectable()
export class AuthGuard {
  canActivate() {
    return from(this.initializeApp()).pipe(map(() => true));
  }

  async initializeApp() {
    await this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const documentRef = await this.injector.get(DOCUMENT);

    this.translate.setDefaultLang('de');
    const platform = await this.injector.get(PLATFORM_ID);

    const patternLang = /^https?:\/\/[a-z0-9-.:]*\/(de|en|it|fr)\/?/g;
    const resultLang = patternLang.exec(documentRef.URL);
    const lang = resultLang ? resultLang[1] : this.language.getFallbackLanguage();

    this.oauthService.configure(authConfig);

    if (isPlatformBrowser(platform)) {
      this.oauthService.events.pipe(filter(e => e.type === 'session_terminated')).subscribe(_ => {
        location.reload();
      });

      this.oauthService.events
        .pipe(filter(e => e.type === 'token_refreshed'))
        .subscribe(() => (this.jsonServiceClient.bearerToken = this.oauthService.getAccessToken()));
    }

    try {
      await this.oauthService.loadDiscoveryDocument();

      const accessToken = this.oauthService.getAccessToken();
      if (accessToken) {
        this.jsonServiceClient.bearerToken = accessToken;
      } else {
        const url = new URL(this.documentRef.URL);
        const location = url.origin + url.pathname;
        if (location != environment.identity.callbackUrl) {
          const isAuthenticated = await this.oauthService.checkAuthenticatedState();
          if (isAuthenticated) {
            const state = url.pathname + url.search;
            this.oauthService.initCodeFlow(state, {
              ui_locales: lang,
            });
          }
        }
      }
    } catch (e) {
      console.error('could not reach identity server', e);
    }

    try {
      await this.translate.use(lang).toPromise();
    } catch (err) {}
  }

  constructor(
    @Inject(DOCUMENT)
    private readonly documentRef: Document,
    private readonly translate: TranslateService,
    private readonly injector: Injector,
    private readonly oauthService: OAuthService,
    private readonly jsonServiceClient: JsonServiceClient,
    private readonly language: LanguageService,
  ) {}
}
