import { Action, createReducer, on } from '@ngrx/store';
import { SearchListingEntry } from 'newhome.dtos';
import * as ResultAction from './result.actions';

export const resultFeatureKey = 'result';

export interface State {
  isSearchCoordinatesLoading: boolean;
}

export const initialState: State = {
  isSearchCoordinatesLoading: false,
};

const resultReducer = createReducer(
  initialState,

  on(ResultAction.beginCreateSearchSubscription, (state, action) => {
    return {
      ...state,
      creatingSearchSubscriptionLoading: true,
    };
  }),

  on(ResultAction.removeSearchSubscriptionResponse, state => {
    return {
      ...state,
      createSearchSubscriptionResponse: undefined,
    };
  }),

  on(ResultAction.createSearchSubscriptionSuccess, (state, action) => {
    return {
      ...state,
      creatingSearchSubscriptionLoading: false,
      createSearchSubscriptionResponse: action.response,
    };
  }),

  on(ResultAction.createSearchSubscriptionFailure, (state, action) => {
    return {
      ...state,
      creatingSearchSubscriptionLoading: false,
      createSearchSubscriptionResponse: undefined,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return resultReducer(state, action);
}
