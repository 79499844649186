import mappings from 'assets/seo/mappings.json';

export const customPerimeterMap = mappings['custom-perimeter'];
export const detailPrefixMap = mappings.properties;
export const detailSuffixMap = mappings.detail;
export const mediaMap = mappings.media;
export const myNewhomeMap = mappings['my-newhome'];
export const internalServerErrorMap = mappings['internal-server-error'];
export const landingMap = mappings.landing;
export const listPrefixMap = mappings.search;
export const listSuffixMap = mappings.list;
export const locationTypeMap = mappings.enums['location-type'];
export const notFoundMap = mappings['not-found'];
export const notAvailableMap = mappings['not-available'];
export const offerTypeMap = mappings.enums['offer-type'];
export const propertyNotFoundMap = mappings['property-not-found'];
export const propertySubTypeMap = mappings.enums['property-sub-type'];
export const propertyTypeMap = mappings.enums['property-type'];
export const providerNotFoundMap = mappings['provider-not-found'];
export const providerResultMap = mappings['provider-result'];
export const roomKeywordMap = mappings.rooms;
export const searchSubscriptionMap = mappings['search-subscription'];
export const watchlistMap = mappings.watchlist;
