import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

export const watchlistMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  const languageIndex = 0;
  const watchlistIndex = 1;

  const watchlistKeywordLocalized = segments[watchlistIndex]?.path;
  const warchlistPattern = /^(merkliste|watchlist|favoris|favoriti)$/;
  if (!watchlistKeywordLocalized?.match(warchlistPattern)) {
    return null;
  }

  const posParams = {
    lang: segments[languageIndex],
  };

  return {
    consumed: segments.slice(0, watchlistIndex + 1),
    posParams,
  };
};
