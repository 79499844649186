<button
  class="switch"
  role="switch"
  type="button"
  [class.switch--checked]="checked"
  [class.switch--disabled]="disabled"
  [id]="switchId"
  (click)="onClicked()"
>
  <div class="switch__indicator"></div>
</button>
