import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { JsonServiceClient } from '@servicestack/client';
import { addSearchLocations } from 'app/entities/search-location/search-location.actions';
import * as fromRoot from 'app/reducers';
import * as RouterSelector from 'app/router.selectors';
import { getLocalSearchSubscriptionGuids } from 'app/shared/search-subscription/local-search-subscriptions';
import { LocationRequest } from 'newhome.dtos';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, first, map, mapTo, skipWhile, switchMap, tap } from 'rxjs/operators';
import * as SearchSubscriptionActions from './search-subscription.actions';
import * as SearchSubscriptionSelectors from './search-subscription.selectors';
import { DOCUMENT, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SearchSubscriptionGuard  {
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return zip(this.store.select(RouterSelector.selectFilter), this.store.select(RouterSelector.selectLanguage)).pipe(
      first(),
      switchMap(([searchFilter, languageIso]) => {
        if (isPlatformServer(this.platformId)) {
          console.log(
            `LocationRequest called from SearchSubscriptionGuard with: ${this.document?.location?.href ?? ''}`,
          );
        }

        return searchFilter && searchFilter.location?.length > 0
          ? this.resolveLocationFromSeoIdentifier(searchFilter.location, languageIso)
          : of(true);
      }),
      switchMap(() => this.loadSearchSubscriptionsFromApi()),
    );
  }

  private loadSearchSubscriptionsFromApi() {
    this.store.dispatch(
      SearchSubscriptionActions.beginLoadSearchSubscriptions({
        guids: getLocalSearchSubscriptionGuids(this.platformId),
      }),
    );

    return this.store.select(SearchSubscriptionSelectors.selectIsLoading).pipe(
      skipWhile(flag => !flag),
      skipWhile(flag => flag),
      mapTo(true),
      catchError(_ => [true]),
    );
  }

  private resolveLocationFromSeoIdentifier(identifiers: string[], languageIso: string): Observable<boolean> {
    const request = new LocationRequest({
      identifiers,
      languageIso,
    });

    return from(this.client.get(request)).pipe(
      map(r => addSearchLocations({ searchLocations: r.identiferInfos })),
      tap(action => this.store.dispatch(action)),
      mapTo(true),
      catchError(_ => [true]),
    );
  }

  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly client: JsonServiceClient,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
