import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorDirective } from './error/error.directive';
import { FormFieldComponent } from './form-field.component';
import { FormattedValueDirective } from './formatted-value/formatted-value.directive';
import { InputDirective } from './controls/input.directive';
import { TextareaDirective } from './controls/textarea.directive';
import { LabelDirective } from './label/label.directive';

@NgModule({
  declarations: [
    FormFieldComponent,
    LabelDirective,
    ErrorDirective,
    FormattedValueDirective,
    InputDirective,
    TextareaDirective
  ],
  imports: [CommonModule],
  exports: [
    FormFieldComponent,
    LabelDirective,
    ErrorDirective,
    FormattedValueDirective,
    InputDirective,
    TextareaDirective
  ]
})
export class FormFieldModule { }
