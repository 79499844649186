import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import * as DiscoverReducer from 'app/discover/discover.reducer';
import * as ResultReducer from 'app/discover/result/result.reducer';
import * as SearchFormReducer from 'app/discover/shared/search-form/search-form.reducer';
import * as SearchInputReducer from 'app/discover/shared/search-input/search-input.reducer';
import * as WizardReducer from 'app/discover/wizard/wizard.reducer';
import * as CantonalBanksReducer from 'app/entities/cantonal-banks/cantonal-banks.reducer';
import * as CountryReducer from 'app/entities/country/country.reducer';
import * as SearchLocationReducer from 'app/entities/search-location/search-location.reducer';
import * as TravelDestinationReducer from 'app/entities/travel-destination/travel-destination.reducer';
import * as WatchlistReducer from 'app/entities/watchlist/watchlist.reducer';
import * as PreviewReducer from 'app/listings/listing/preview/preview.reducer';
import * as ProfileReducer from 'app/my-newhome/shared/user-profile/user-profile.reducers';
import * as DetailReducer from 'app/property/detail/detail.reducer';
import * as ProviderResultReducer from 'app/provider-directory/provider-result/provider-result.reducer';
import * as UserDataReducer from 'app/reducers/user-data';
import * as RouterSelectors from 'app/router.selectors';
import * as SearchSubscriptionReducer from 'app/search-subscription/search-subscription.reducer';
import * as EmarsysRecommendationsReducer from 'app/shared/emarsys-recommendations/emarsys-recommendations.reducer';
import * as GlobalAlertsReducer from 'app/shared/global-alerts/global-alerts.reducer';
import * as ObjectsMapReducer from 'app/shared/objects/objects-map/objects-map.reducer';
import * as StatisticReducer from 'app/shared/statistic/statistic.reducer';
import { environment } from 'environments/environment';
import * as PreviousSearch from './previous-search';
import * as Settings from './settings';

export interface State {
  router: RouterReducerState<any>;
  [CantonalBanksReducer.cantonalBanksFeatureKey]: CantonalBanksReducer.State;
  [CountryReducer.countriesFeatureKey]: CountryReducer.State;
  [DetailReducer.detailFeatureKey]: DetailReducer.State;
  [DiscoverReducer.discoverFeatureKey]: DiscoverReducer.State;
  [EmarsysRecommendationsReducer.emarsysRecommendationsFeatureKey]: EmarsysRecommendationsReducer.State;
  [GlobalAlertsReducer.globalAlertsFeatureKey]: GlobalAlertsReducer.State;
  [PreviewReducer.previewFeatureKey]: PreviewReducer.State;
  [PreviousSearch.previousSearchFeatureKey]: PreviousSearch.State;
  [ProfileReducer.profileFeatureKey]: ProfileReducer.State;
  [ProviderResultReducer.providerResultsFeatureKey]: ProviderResultReducer.State;
  [ResultReducer.resultFeatureKey]: ResultReducer.State;
  [SearchFormReducer.searchFormFeatureKey]: SearchFormReducer.State;
  [SearchInputReducer.searchInputFeatureKey]: SearchInputReducer.State;
  [SearchLocationReducer.searchLocationsFeatureKey]: SearchLocationReducer.State;
  [SearchSubscriptionReducer.searchSubscriptionFeatureKey]: SearchSubscriptionReducer.State;
  [Settings.settingsFeatureKey]: Settings.State;
  [StatisticReducer.statisticFeatureKey]: StatisticReducer.State;
  [TravelDestinationReducer.travelDestinationsFeatureKey]: TravelDestinationReducer.State;
  [UserDataReducer.userDataFeatureKey]: UserDataReducer.State;
  [WatchlistReducer.watchlistFeatureKey]: WatchlistReducer.State;
  [WizardReducer.wizardFeatureKey]: WizardReducer.State;
  [ObjectsMapReducer.objectsMapFeatureKey]: ObjectsMapReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  [CantonalBanksReducer.cantonalBanksFeatureKey]: CantonalBanksReducer.reducer,
  [CountryReducer.countriesFeatureKey]: CountryReducer.reducer,
  [DetailReducer.detailFeatureKey]: DetailReducer.reducer,
  [DiscoverReducer.discoverFeatureKey]: DiscoverReducer.reducer,
  [EmarsysRecommendationsReducer.emarsysRecommendationsFeatureKey]: EmarsysRecommendationsReducer.reducer,
  [GlobalAlertsReducer.globalAlertsFeatureKey]: GlobalAlertsReducer.reducer,
  [PreviewReducer.previewFeatureKey]: PreviewReducer.reducer,
  [PreviousSearch.previousSearchFeatureKey]: PreviousSearch.reducer,
  [ProfileReducer.profileFeatureKey]: ProfileReducer.reducer,
  [ProviderResultReducer.providerResultsFeatureKey]: ProviderResultReducer.reducer,
  [ResultReducer.resultFeatureKey]: ResultReducer.reducer,
  [SearchFormReducer.searchFormFeatureKey]: SearchFormReducer.reducer,
  [SearchInputReducer.searchInputFeatureKey]: SearchInputReducer.reducer,
  [SearchLocationReducer.searchLocationsFeatureKey]: SearchLocationReducer.reducer,
  [SearchSubscriptionReducer.searchSubscriptionFeatureKey]: SearchSubscriptionReducer.reducer,
  [Settings.settingsFeatureKey]: Settings.reducer,
  [StatisticReducer.statisticFeatureKey]: StatisticReducer.reducer,
  [TravelDestinationReducer.travelDestinationsFeatureKey]: TravelDestinationReducer.reducer,
  [UserDataReducer.userDataFeatureKey]: UserDataReducer.reducer,
  [WatchlistReducer.watchlistFeatureKey]: WatchlistReducer.reducer,
  [WizardReducer.wizardFeatureKey]: WizardReducer.reducer,
  [ObjectsMapReducer.objectsMapFeatureKey]: ObjectsMapReducer.reducer,
};

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<State>>('App Reducers');
export const reducerProvider = { provide: REDUCERS_TOKEN, useValue: reducers };

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

const selectEntities = createSelector(SearchLocationReducer.selectSearchLocations, entities => entities.entities);

export const selectFilteredSearchLocations = createSelector(
  (state: State) => state,
  RouterSelectors.selectFilter,
  selectEntities,
  (_, filter, entities) =>
    []
      .concat(filter.location)
      .filter(x => x)
      .map(x => entities[x])
      .filter(x => x),
);
