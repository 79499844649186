/* Options:
Date: 2024-07-24 11:58:52
Version: 8.30
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost/newhome.ch.marktplatz.api

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IPost
{
}

export interface IGet
{
}

export interface IPut
{
}

export interface IDelete
{
}

export enum StatisticType
{
    ContentBoxView = 1,
    ContentBoxClick = 2,
    AdvertClick = 5,
    SpecialPromotionView = 10,
    AdvertListingDetailMortgageLinkClick = 21,
    AdvertListingDetailApraisalLink = 22,
    AdvertListingDetailPrintClick = 25,
    AdvertListingDetailContactDialogOpen = 27,
    AdvertListingDetailOriginalClickRent = 28,
    AdvertListingDetailOriginalClickBuy = 29,
    AdvertListingDetailShared = 32,
    AdvertListingDetailCreditTrustClick = 33,
    AdvertListingDetailPhoneShowBuy = 40,
    AdvertListingDetailPhoneShowRent = 41,
    AdvertListingDetailPhoneClickBuy = 42,
    AdvertListingDetailPhoneClickRent = 43,
    FinanciallinkClick = 50,
    OnlineApplyClickBuy = 60,
    OnlineApplyClickRent = 61,
    VisitationLinkClickBuy = 62,
    VisitationLinkClickRent = 63,
}

export enum OfferType
{
    Buy = 1,
    Rent = 2,
}

export enum SearchPropertyType
{
    House = 1,
    Apartment = 2,
    Apartmenthouse = 3,
    Business = 4,
    Buildingland = 5,
    Parkingspace = 6,
    HouseApartment = 100,
    ResidentialCommercialBuilding = 200,
    All = 300,
}

export enum TravelSearchType
{
    Walk = 1,
    Bike = 2,
    Car = 3,
    Transit = 4,
}

export class GeograhyCoordinate
{
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<GeograhyCoordinate>) { (Object as any).assign(this, init); }
}

export class TravelDestination
{
    public id: number;
    public name: string;
    /** @description Marker name */
    // @ApiMember(Description="Marker name")
    public character: string;

    public street: string;
    public postalcode: string;
    public city: string;
    public searchType?: TravelSearchType;
    public maxTravelTime?: number;
    public coordinate: GeograhyCoordinate;

    public constructor(init?: Partial<TravelDestination>) { (Object as any).assign(this, init); }
}

export enum SalutationType
{
    Male = 1,
    Female = 2,
}

export enum LegalForm
{
    Private = 1,
    Business = 2,
}

export interface IUserProfileData
{
    company: string;
    salutation?: SalutationType;
    firstname: string;
    lastname: string;
    legalForm?: LegalForm;
    street: string;
    streetHouseNumber: string;
    poBox: string;
    zip: string;
    city: string;
    countryCode: string;
    phonePrivate: string;
    phoneOffice: string;
    websiteUrl: string;
    languageIso: string;
    useContactAddressAsInvoiceAddress: boolean;
    companyInvoice: string;
    salutationInvoice?: SalutationType;
    toTheAttentionOfInvoice: string;
    firstnameInvoice: string;
    lastnameInvoice: string;
    poBoxInvoice: string;
    streetInvoice: string;
    streetHouseNumberInvoice: string;
    zipInvoice: string;
    cityInvoice: string;
    countryCodeInvoice: string;
    eMailInvoice: string;
    phonePrivateInvoice: string;
    phoneOfficeInvoice: string;
    allowBaviouralObservation: boolean;
    acceptCreditTrust?: boolean;
    subscribeToNewsletter: boolean;
}

export interface IUserFinancingData
{
    propertyType?: SearchPropertyType;
    searchLocationIdentifiers: string[];
    mandantId?: number;
    minRooms?: number;
    maxRooms?: number;
}

export enum PropertySubType
{
    DetachedHouse = 101,
    DoubleApartmentBuilding = 102,
    CornerHouse = 103,
    MiddleHouse = 104,
    SteppedBuilding = 105,
    Villa = 106,
    Farmhouse = 107,
    HolidyHome = 108,
    TerracedHouse = 109,
    Rustic = 110,
    Chalet = 111,
    Castle = 112,
    RoomInHousingAssociationHouse = 113,
    Apartment = 201,
    AtticApartment = 202,
    PenthouseApartment = 203,
    MaisonetteApartment = 204,
    TerraceApartment = 205,
    StudioBedsit = 206,
    HolidayApartment = 207,
    Loft = 208,
    FurnishedApartment = 211,
    RoomInHousingAssociation = 212,
    ResidentialCommercialBuilding = 401,
    Office = 402,
    AgriculturalBuilding = 403,
    Pratice = 404,
    Warehouse = 405,
    Salesroom = 406,
    ManufacturingRoom = 407,
    CommericalBuilding = 408,
    CommercialPremisis = 409,
    Hotel = 410,
    Restaurant = 411,
    WorkshopHobbyRoom = 412,
    Studio = 413,
    BuildingLand = 501,
    IndustrialBuildingLand = 502,
    BuildingLandMixedZone = 503,
    AgriculturalLand = 504,
    CommercialBuildingLand = 505,
    UndergroundGarage = 601,
    Garage = 602,
    CoveredParkingSpace = 603,
    UncoveredParkingSpace = 604,
    MotorcycleParkingSpace = 605,
}

export enum AvailabilityType
{
    Immediately = 1,
    ByDate = 2,
    ByArragement = 3,
}

export enum EquipmentType
{
    GarageParkingSpace = 1,
    BalconyTerrace = 2,
    Lift = 3,
    WheelchairAccess = 4,
    Minergie = 5,
    NewBuilding = 6,
    OldBuilding = 7,
    PetsAllowed = 8,
    Groundfloor = 9,
    Showers = 10,
    ParkingSpace = 11,
    Garage = 12,
    RailConnection = 13,
    FullyDeveloped = 14,
    CarChargingStation = 15,
    ChildFriendly = 16,
    Fireplace = 17,
    Toilets = 18,
    ExcludeGroundfloor = 19,
}

export class AdvertSearchFilter implements IAdvertSearchFilter
{
    // @ApiMember(IsRequired=true)
    public location: string[];

    public travelDestinations: string[];
    /** @description Umkreis in KM */
    // @ApiMember(Description="Umkreis in KM")
    public radius?: number;

    public radiusCenterCoordinate: string;
    public propertyType?: SearchPropertyType;
    // @ApiMember(IsRequired=true)
    public offerType: OfferType;

    public propertySubtypes: PropertySubType[];
    public priceMin?: number;
    public priceMax?: number;
    public priceM2Min?: number;
    public priceM2Max?: number;
    public availabilityType?: AvailabilityType;
    public availabilityDate?: string;
    public roomsMin?: number;
    public roomsMax?: number;
    public livingAreaMin?: number;
    public livingAreaMax?: number;
    public plotAreaMin?: number;
    public plotAreaMax?: number;
    /** @description Nutzfläche Min */
    // @ApiMember(Description="Nutzfläche Min")
    public usableAreaMin?: number;

    /** @description Nutzfläche Max */
    // @ApiMember(Description="Nutzfläche Max")
    public usableAreaMax?: number;

    public floorLoadMin?: number;
    public floorLoadMax?: number;
    public roomHeightMin?: number;
    public roomHeightMax?: number;
    public numberOfVehiclesMin?: number;
    public numberOfVehiclesMax?: number;
    public constructionYearFrom?: number;
    public grossReturnFrom?: number;
    public equipments: EquipmentType[];
    public searchSubs?: number;

    public constructor(init?: Partial<AdvertSearchFilter>) { (Object as any).assign(this, init); }
}

export enum SearchSubscriptionFrequency
{
    Hourly = 1,
}

export class SearchSubscriptionEntry
{
    public guid: string;
    public id: number;
    // @ApiMember(IsRequired=true)
    public filter: AdvertSearchFilter;

    // @ApiMember(IsRequired=true)
    public languageIso: string;

    // @ApiMember(IsRequired=true)
    public name: string;

    // @ApiMember(IsRequired=true)
    public subscribeNewsletter: boolean;

    // @ApiMember(IsRequired=true)
    public frequency: SearchSubscriptionFrequency;

    public isEmail: boolean;
    // @ApiMember(IsRequired=true)
    public isPush: boolean;

    public eMail: string;
    public appPushIdentifier: string;
    public pushEndpoint: string;
    public pushAuthSecret: string;
    public pushP256dhKey: string;
    public getFinancingAdvice: boolean;
    public mandantId: number;

    public constructor(init?: Partial<SearchSubscriptionEntry>) { (Object as any).assign(this, init); }
}

export enum AdvertOrderType
{
    Default = 0,
    NewestByListingDate = 1,
    LowestPrice = 2,
    Availability = 3,
    City = 4,
    Has3DModel = 5,
}

export interface IAdvertSearchFilter
{
    availabilityDate?: string;
    availabilityType?: AvailabilityType;
    constructionYearFrom?: number;
    equipments: EquipmentType[];
    floorLoadMax?: number;
    floorLoadMin?: number;
    grossReturnFrom?: number;
    livingAreaMax?: number;
    livingAreaMin?: number;
    location: string[];
    travelDestinations: string[];
    numberOfVehiclesMax?: number;
    numberOfVehiclesMin?: number;
    offerType: OfferType;
    plotAreaMax?: number;
    plotAreaMin?: number;
    priceM2Max?: number;
    priceM2Min?: number;
    priceMax?: number;
    priceMin?: number;
    propertySubtypes: PropertySubType[];
    propertyType?: SearchPropertyType;
    radius?: number;
    radiusCenterCoordinate: string;
    roomHeightMax?: number;
    roomHeightMin?: number;
    roomsMax?: number;
    roomsMin?: number;
    usableAreaMax?: number;
    usableAreaMin?: number;
}

export interface IPagingSorting
{
    rowCount: number;
    skipCount: number;
    order: AdvertOrderType;
}

export enum AdvertReportCause
{
    Abuse = 1,
    Dubious = 2,
    LocationWrong = 3,
    Other = 4,
}

export enum PropertyType
{
    House = 1,
    Apartment = 2,
    Apartmenthouse = 3,
    Business = 4,
    Buildingland = 5,
    Parkingspace = 6,
}

export enum EmarsysConsentStatus
{
    Accepted = 1,
    Rejected = 2,
}

export enum ContentBoxPageType
{
    Startseite = 0,
    Trefferliste = 1,
    Detailseite = 2,
}

export enum StreetType
{
    Text = 1,
    OnRequest = 2,
}

export enum PriceType
{
    PurchasePrice = 1,
    RentPerMonth = 2,
    PricePerM2 = 3,
    PricePerM2PerYear = 4,
    PriceOnRequest = 5,
}

export enum FloorType
{
    Basement = 1,
    GroundFloor = 2,
    Floor1 = 3,
    Floor2 = 4,
    Floor3 = 5,
    Floor4 = 6,
    Floor5 = 7,
    Floor6 = 8,
    Floor7 = 9,
    Floor8 = 10,
    Attic = 11,
    FloorHigherThan8 = 12,
    Mezzanine = 13,
    MultiStorey = 14,
}

export enum UserRole
{
    None = 1,
    Pro = 2,
    Member = 3,
    Gemeinde = 4,
}

export enum LocationType
{
    City = 1,
    Canton = 2,
    Region = 3,
    District = 4,
    County = 5,
}

export class SearchLocation
{
    public displayName: string;
    public postalcode: string;
    /** @description District = Bezirk, County = Stadtkreis */
    // @ApiMember(Description="District = Bezirk, County = Stadtkreis")
    public locationType: LocationType;

    /** @description Identifer has to be passed to ListingSearch Services */
    // @ApiMember(Description="Identifer has to be passed to ListingSearch Services")
    public identifier: string;

    /** @description Identifier der für die URL verwendet werden kann. */
    // @ApiMember(Description="Identifier der für die URL verwendet werden kann.")
    public seoUrlIdentifier: string;

    public coordinate: GeograhyCoordinate;

    public constructor(init?: Partial<SearchLocation>) { (Object as any).assign(this, init); }
}

export class SearchListingEntry
{
    public immocode: number;
    public title: string;
    public offerType: OfferType;
    public propertyType: PropertyType;
    public propertySubType: PropertySubType;
    public secondaryPropertySubTypes: PropertySubType[];
    public streetType: StreetType;
    public street: string;
    public city: string;
    public postalCode: string;
    public latitude?: number;
    public longitude?: number;
    public contactLogoUrl: string;
    public contactCompanyName: string;
    public priceType: PriceType;
    public price?: number;
    public priceNegotiable: boolean;
    public availabilityType?: AvailabilityType;
    public availabilityDate?: string;
    /** @description Only House / Apartment */
    // @ApiMember(Description="Only House / Apartment")
    public floor?: FloorType;

    /** @description Only House / Apartment */
    // @ApiMember(Description="Only House / Apartment")
    public rooms?: number;

    /** @description Only House / Apartment */
    // @ApiMember(Description="Only House / Apartment")
    public livingArea?: number;

    /** @description House / Apartment or Apartmenthouse */
    // @ApiMember(Description="House / Apartment or Apartmenthouse")
    public constructionYear?: number;

    /** @description Used for PropertyType Buildingland and House / Apartment */
    // @ApiMember(Description="Used for PropertyType Buildingland and House / Apartment")
    public plotArea?: number;

    /** @description Only Business */
    // @ApiMember(Description="Only Business")
    public usableArea?: number;

    /** @description Only Apartmenthouse */
    // @ApiMember(Description="Only Apartmenthouse")
    public grossReturn?: number;

    public images: string[];
    public hasTopPromotion: boolean;
    public hasHighlight: boolean;
    public has3DModel: boolean;
    public customerId: number;
    public customerRole: UserRole;
    public legalForm?: LegalForm;
    public mandantCode: string;
    public hasSpecialPromotion: boolean;
    /** @description Only Search Subscription Results */
    // @ApiMember(Description="Only Search Subscription Results")
    public isNewResult?: boolean;

    public searchLocation: SearchLocation;

    public constructor(init?: Partial<SearchListingEntry>) { (Object as any).assign(this, init); }
}

export class WatchlistCheckEntry
{
    public immoCode: number;
    /** @description null if Immocode is no longer active */
    // @ApiMember(Description="null if Immocode is no longer active")
    public listing: SearchListingEntry;

    public constructor(init?: Partial<WatchlistCheckEntry>) { (Object as any).assign(this, init); }
}

export enum AuthProvider
{
    Newhome = 1,
    Google = 2,
    Facebook = 3,
}

export class UserInfo
{
    public eMail: string;
    public role: UserRole;
    public authenticationProvider: AuthProvider;
    public twoFactorAuthEnabled: boolean;

    public constructor(init?: Partial<UserInfo>) { (Object as any).assign(this, init); }
}

export class UserProfile implements IUserProfileData, IUserFinancingData
{
    public eMail: string;
    public company: string;
    public salutation?: SalutationType;
    public firstname: string;
    public lastname: string;
    public legalForm?: LegalForm;
    public street: string;
    public streetHouseNumber: string;
    public poBox: string;
    public zip: string;
    public city: string;
    public countryCode: string;
    public phonePrivate: string;
    public phoneOffice: string;
    public websiteUrl: string;
    public languageIso: string;
    public propertyType?: SearchPropertyType;
    public searchLocationIdentifiers: string[];
    public mandantId?: number;
    public minRooms?: number;
    public maxRooms?: number;
    public useContactAddressAsInvoiceAddress: boolean;
    public companyInvoice: string;
    public salutationInvoice?: SalutationType;
    /** @description zuhanden von, gibts nur auf der Rechnungsadresse */
    // @ApiMember(Description="zuhanden von, gibts nur auf der Rechnungsadresse")
    public toTheAttentionOfInvoice: string;

    public firstnameInvoice: string;
    public lastnameInvoice: string;
    public poBoxInvoice: string;
    public streetInvoice: string;
    public streetHouseNumberInvoice: string;
    public zipInvoice: string;
    public cityInvoice: string;
    public countryCodeInvoice: string;
    public eMailInvoice: string;
    public phonePrivateInvoice: string;
    public phoneOfficeInvoice: string;
    public isEmployee: boolean;
    public acceptCreditTrust?: boolean;
    public subscribeToNewsletter: boolean;
    /** @description Verhaltensbeobachtung erlaubt */
    // @ApiMember(Description="Verhaltensbeobachtung erlaubt")
    public allowBaviouralObservation: boolean;

    public constructor(init?: Partial<UserProfile>) { (Object as any).assign(this, init); }
}

export class Country
{
    public code: string;
    public name: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class SearchSubscriptionDetail
{
    public guid: string;
    public name: string;
    public languageIso: string;
    /** @description 4 newest entries */
    // @ApiMember(Description="4 newest entries")
    public adverts: SearchListingEntry[];

    public id: number;
    public filter: AdvertSearchFilter;

    public constructor(init?: Partial<SearchSubscriptionDetail>) { (Object as any).assign(this, init); }
}

export class ProviderData
{
    public providerId: number;
    public company: string;
    public salutation?: SalutationType;
    public firstname: string;
    public lastname: string;
    public street: string;
    public houseNumber: string;
    public postalcode: string;
    public city: string;
    public phoneOffice: string;
    public providerWebsiteUrl: string;
    public logoUrl: string;

    public constructor(init?: Partial<ProviderData>) { (Object as any).assign(this, init); }
}

export class AdvertCoordinate
{
    public immoCode: number;
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<AdvertCoordinate>) { (Object as any).assign(this, init); }
}

export class Mandant
{
    public name: string;
    public mandantId: number;
    public mandantCode: string;
    public url: string;

    public constructor(init?: Partial<Mandant>) { (Object as any).assign(this, init); }
}

export class OrtTrefferDto
{
    public id: string;
    public anzeige: string;
    public plz: string;
    public ort: string;

    public constructor(init?: Partial<OrtTrefferDto>) { (Object as any).assign(this, init); }
}

export class FaqInformation
{
    public propertiesCount: number;
    public propertiesCountWithOfferType: number;
    public averagePrice: number;
    public averagePrice3_5RoomApartment: number;
    public averagePrice4_5RoomApartment: number;
    public averagePrice5_5RoomApartment: number;
    public cheapest3RoomApartment: number;
    public averageRooms: number;
    public averageAreaM2: number;

    public constructor(init?: Partial<FaqInformation>) { (Object as any).assign(this, init); }
}

export class SeoInformation
{
    public identifier: string;
    public browserTitleTag: string;
    public textForH1: string;
    public metaDescription: string;

    public constructor(init?: Partial<SeoInformation>) { (Object as any).assign(this, init); }
}

export class ContentBlockMoreListingsItem
{
    public locationName: string;
    public cityPostalCode: string;
    public locationIdentifier: string;
    public locationType: LocationType;
    public offerType: OfferType;
    public propertyType: PropertyType;
    public propertySubType?: PropertySubType;

    public constructor(init?: Partial<ContentBlockMoreListingsItem>) { (Object as any).assign(this, init); }
}

export class ContentBlockMoreListings
{
    public itemsColumn1: ContentBlockMoreListingsItem[];
    public itemsColumn2: ContentBlockMoreListingsItem[];
    public itemsColumn3: ContentBlockMoreListingsItem[];

    public constructor(init?: Partial<ContentBlockMoreListings>) { (Object as any).assign(this, init); }
}

export class ExternalMedia
{
    public url: string;
    public description: string;

    public constructor(init?: Partial<ExternalMedia>) { (Object as any).assign(this, init); }
}

export enum ImageFormat
{
    Original = 1,
    Fullscreen = 2,
    Detail = 3,
    Thumbnail = 4,
    TrefferlisteMD = 5,
    TrefferlisteSM = 6,
    TrefferlisteXS = 7,
    TrefferlisteNextNewhome = 8,
}

export class ListingDetailImageFormat
{
    public format: ImageFormat;
    public url: string;

    public constructor(init?: Partial<ListingDetailImageFormat>) { (Object as any).assign(this, init); }
}

export enum ImageOrientation
{
    Portrait = 1,
    Landscape = 2,
}

export class ListingDetailImage
{
    public description: string;
    public isFloorPlan: boolean;
    public imageFormats: ListingDetailImageFormat[];
    public imageOrientation?: ImageOrientation;
    public width?: number;
    public height?: number;

    public constructor(init?: Partial<ListingDetailImage>) { (Object as any).assign(this, init); }
}

export class ListingResource
{
    public url: string;
    public description: string;
    public isFloorPlan: boolean;
    /** @description Size in Bytes if available */
    // @ApiMember(Description="Size in Bytes if available")
    public size?: number;

    public constructor(init?: Partial<ListingResource>) { (Object as any).assign(this, init); }
}

export class FinanceInfo
{
    public mandantCode: string;
    public mortgageRateLink: string;
    public mortgageRateText: string;
    public mortgageRatePrice: number;
    public apraisalLinkText: string;
    public apraisalLink: string;
    public finanzierungsLink: string;

    public constructor(init?: Partial<FinanceInfo>) { (Object as any).assign(this, init); }
}

export class Advertisement
{
    public advertId: number;
    public text: string;
    public link: string;

    public constructor(init?: Partial<Advertisement>) { (Object as any).assign(this, init); }
}

export enum UtilityAvailability
{
    Available = 1,
    NotAvailable = 2,
    Possible = 3,
    Planned = 4,
}

export class ListingDetailFeautres
{
    /** @description Hat Aussicht */
    // @ApiMember(Description="Hat Aussicht")
    public hasView: boolean;

    public minergie: boolean;
    public cableTVAvailable: boolean;
    public fiberAvailable: boolean;
    public basementAvailable: boolean;
    public atticAvailable: boolean;
    /** @description Bastelraum */
    // @ApiMember(Description="Bastelraum")
    public hobbyRoomAvailable: boolean;

    /** @description Abstellraum */
    // @ApiMember(Description="Abstellraum")
    public storageRoomAvailable: boolean;

    public fireplaceAvailable: boolean;
    public balconyAvailable: boolean;
    public garageAvailable: boolean;
    public parkingSpaceAvailable: boolean;
    public carChargingStation: boolean;
    public childFriendly: boolean;
    public elevatorAvailable: boolean;
    public wheelcharAccessable: boolean;
    public petsAllowed: boolean;
    /** @description Wohngemeinschaft */
    // @ApiMember(Description="Wohngemeinschaft")
    public housingAssociationAllowed: boolean;

    /** @description Küche zur Mitbenutzung */
    // @ApiMember(Description="Küche zur Mitbenutzung")
    public kitchenForSharedUse: boolean;

    /** @description BuildingLand - Erschliessung */
    // @ApiMember(Description="BuildingLand - Erschliessung")
    public buildingLandDevelopment: boolean;

    /** @description BuildingLand - Stromanschluss */
    // @ApiMember(Description="BuildingLand - Stromanschluss")
    public electricityAvailable: boolean;

    /** @description BuildingLand - Gasanschluss */
    // @ApiMember(Description="BuildingLand - Gasanschluss")
    public gasAvailable: boolean;

    /** @description BuildingLand - Wasser/Abwasser Anschluss */
    // @ApiMember(Description="BuildingLand - Wasser/Abwasser Anschluss")
    public waterAvailable: boolean;

    /** @description BuildingLand - Gleisanschluss */
    // @ApiMember(Description="BuildingLand - Gleisanschluss")
    public railConnection: boolean;

    public showerAvailability?: UtilityAvailability;
    public toiletAvailability?: UtilityAvailability;

    public constructor(init?: Partial<ListingDetailFeautres>) { (Object as any).assign(this, init); }
}

export enum ObjectCondition
{
    NewlyRenovated = 1,
    FirstTimeOccupancy = 2,
    Average = 3,
    LikeNew = 4,
    InNeedOfRenovation = 5,
    Good = 6,
    OldBuilding = 7,
    NewBuilding = 8,
}

export class ListingDetailDetails
{
    public immoCode: number;
    public propertySubType: PropertySubType;
    public secondaryPropertySubTypes: PropertySubType[];
    /** @description Objektnummer */
    // @ApiMember(Description="Objektnummer")
    public listingNumber: string;

    public availabilityType?: AvailabilityType;
    public availabilityDate?: string;
    public livingArea?: number;
    public rooms?: number;
    public cubicArea?: number;
    public plotArea?: number;
    public floorType?: FloorType;
    public floorsInBuildung?: number;
    public constructionYear?: number;
    public renovationYear?: number;
    public condition?: ObjectCondition;
    /** @description Buildingland - Ausnützungsziffer */
    // @ApiMember(Description="Buildingland - Ausnützungsziffer")
    public utilisationNumber?: number;

    /** @description Buildingland - Bauzone */
    // @ApiMember(Description="Buildingland - Bauzone")
    public buildingZone: string;

    /** @description Business - Nutzfläche */
    // @ApiMember(Description="Business - Nutzfläche")
    public usableArea?: number;

    /** @description Business - Teilfläche */
    // @ApiMember(Description="Business - Teilfläche")
    public partialArea?: number;

    /** @description Business - Maximale Raumhöhe */
    // @ApiMember(Description="Business - Maximale Raumhöhe")
    public maxRoomHeight?: number;

    public maxFloorLoad?: number;
    /** @description Business - Anzahl Etagen */
    // @ApiMember(Description="Business - Anzahl Etagen")
    public floors?: number;

    /** @description Apartmenthouse - Bruttorendite */
    // @ApiMember(Description="Apartmenthouse - Bruttorendite")
    public grossReturn?: number;

    /** @description Parking space - Anzahl Parkplätze */
    // @ApiMember(Description="Parking space - Anzahl Parkplätze")
    public parkingSpaces?: number;

    /** @description Parking space - Maximale Höhe des Fahrzeugs */
    // @ApiMember(Description="Parking space - Maximale Höhe des Fahrzeugs")
    public maxHeight?: number;

    public constructor(init?: Partial<ListingDetailDetails>) { (Object as any).assign(this, init); }
}

export class ListingDetailPrice
{
    public priceType: PriceType;
    /** @description Preis Verhandlungsbasis */
    // @ApiMember(Description="Preis Verhandlungsbasis")
    public priceNegotiable: boolean;

    public price?: number;
    /** @description Nebenkosten (nur bei Miete) */
    // @ApiMember(Description="Nebenkosten (nur bei Miete)")
    public priceAdditionalCost?: number;

    /** @description Nettomiete */
    // @ApiMember(Description="Nettomiete")
    public priceNet?: number;

    public priceM2?: number;
    /** @description Nebenkosten (nur bei Miete) */
    // @ApiMember(Description="Nebenkosten (nur bei Miete)")
    public priceAdditionalCostM2?: number;

    /** @description Nettomiete */
    // @ApiMember(Description="Nettomiete")
    public priceNetM2?: number;

    public constructor(init?: Partial<ListingDetailPrice>) { (Object as any).assign(this, init); }
}

export class ListingDetail
{
    public immocode: number;
    public title: string;
    public offerType: OfferType;
    public propertyType: PropertyType;
    public streetType: StreetType;
    public street: string;
    public postalCode: string;
    public city: string;
    public cantonName: string;
    public cantonCode: string;
    public longitude?: number;
    public latitude?: number;
    public model3DUrls: ExternalMedia[];
    public youtubeUrls: ExternalMedia[];
    public images: ListingDetailImage[];
    public documents: ListingResource[];
    public descriptionText: string;
    public descriptionTextPlain: string;
    public linkname: string;
    public link: string;
    public financeInfo: FinanceInfo;
    public financeInfoAdvertisement: Advertisement;
    public detailAdvertisement: Advertisement;
    public feature: ListingDetailFeautres;
    public detail: ListingDetailDetails;
    public price: ListingDetailPrice;
    public smkMember: boolean;
    public acceptsCreditTrust?: boolean;
    public isChiffre: boolean;
    public showContactForm: boolean;
    public isManagedByMe: boolean;
    public contactCompany: string;
    public contactSalutation?: SalutationType;
    public contactLastname: string;
    public contactFirstname: string;
    public contactStreet: string;
    public contactHouseNumber: string;
    public contactPostalCode: string;
    public contactCity: string;
    public contactPhonePrivate: string;
    public contactPhoneOffice: string;
    public contactPhoneMobile: string;
    public contactUrl: string;
    public contactVisitName: string;
    public contactVisitPhone: string;
    public contactVisitText: string;
    public contactPhonePrivateHint: string;
    public contactPhoneOfficeHint: string;
    public contactDirectApplicationLink: string;
    public contactLogoUrl: string;
    public contactProviderId?: number;
    public customerId: number;
    public customerRole: UserRole;
    public mandant: Mandant;
    public location: SearchLocation;
    public hasSpecialPromotion: boolean;
    public hasHighlightPromotion: boolean;
    public hasTopPromotion: boolean;
    public publishDate?: string;
    public legalForm?: LegalForm;

    public constructor(init?: Partial<ListingDetail>) { (Object as any).assign(this, init); }
}

export class Filiale
{
    public mandantCode: string;
    public adress: string;
    public city: string;
    public zip: string;
    public phone: string;
    public url: string;

    public constructor(init?: Partial<Filiale>) { (Object as any).assign(this, init); }
}

export class ObjectFeedDto
{
    public immocode: number;
    public offerType: OfferType;
    public propertyType?: PropertyType;
    public propertySubType?: PropertySubType;
    public title: string;
    public street: string;
    public postalCode: string;
    public city: string;
    public rooms?: number;
    public livingArea?: number;
    public price?: number;
    public availabilityType?: AvailabilityType;
    public availabilityDate?: string;
    public shortLink: string;
    public imageUrls: string[];

    public constructor(init?: Partial<ObjectFeedDto>) { (Object as any).assign(this, init); }
}

export enum ContentBoxType
{
    Newhome = 0,
    NewhomeFallback = 3,
}

export class ContentboxDisplay
{
    public id: number;
    public languageId?: number;
    public type: ContentBoxType;
    public position: number;
    public pageType: number;
    public offerTypes: number;
    public propertyTypes: number;
    public title: string;
    public description: string;
    public link: string;
    public linkText: string;
    public target: string;
    public isSponsored: boolean;
    public image: string;
    public kantonIds: number[];

    public constructor(init?: Partial<ContentboxDisplay>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class GetWatchlistEntriesResponse
{
    public entries: WatchlistCheckEntry[];

    public constructor(init?: Partial<GetWatchlistEntriesResponse>) { (Object as any).assign(this, init); }
}

export class SyncEntitiesWithUserProfileResponse
{
    public travelDestinations: TravelDestination[];
    public watchlistEntries: WatchlistCheckEntry[];
    public assignedSearchSubscriptionGuids: string[];

    public constructor(init?: Partial<SyncEntitiesWithUserProfileResponse>) { (Object as any).assign(this, init); }
}

export class GetTravelDestinationsResponse
{
    public entries: TravelDestination[];

    public constructor(init?: Partial<GetTravelDestinationsResponse>) { (Object as any).assign(this, init); }
}

export class AddTravelDestinationResponse
{
    public travelDestination: TravelDestination;

    public constructor(init?: Partial<AddTravelDestinationResponse>) { (Object as any).assign(this, init); }
}

export class UpdateTravelDestinationResponse
{
    public travelDestination: TravelDestination;

    public constructor(init?: Partial<UpdateTravelDestinationResponse>) { (Object as any).assign(this, init); }
}

export class GetUserInfoResponse
{
    public userInfo: UserInfo;

    public constructor(init?: Partial<GetUserInfoResponse>) { (Object as any).assign(this, init); }
}

export class GetUserProfileResponse
{
    public profile: UserProfile;

    public constructor(init?: Partial<GetUserProfileResponse>) { (Object as any).assign(this, init); }
}

export class UpdateUserProfileResponse
{
    public profile: UserProfile;

    public constructor(init?: Partial<UpdateUserProfileResponse>) { (Object as any).assign(this, init); }
}

export class SendDeleteUserProfileEmailResponse
{
    public canBeDeletedByConfirmation?: boolean;

    public constructor(init?: Partial<SendDeleteUserProfileEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetCountriesResponse
{
    public countries: Country[];

    public constructor(init?: Partial<GetCountriesResponse>) { (Object as any).assign(this, init); }
}

export class GetEntitiesResponse
{
    public watchlistEntries: WatchlistCheckEntry[];
    public travelDestinations: TravelDestination[];

    public constructor(init?: Partial<GetEntitiesResponse>) { (Object as any).assign(this, init); }
}

export class GetSearchSubscriptionsResponse
{
    public searchSubscriptionsConfirmed: SearchSubscriptionDetail[];
    public searchSubscriptionsUnconfirmend: SearchSubscriptionDetail[];

    public constructor(init?: Partial<GetSearchSubscriptionsResponse>) { (Object as any).assign(this, init); }
}

export class CreateSearchSubscriptionResponse
{
    public id: number;
    public guid: string;
    public name: string;
    public assignedToUser: boolean;
    public needEmailConfirmation: boolean;

    public constructor(init?: Partial<CreateSearchSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class DeleteSearchSubscriptionResponse
{
    public name: string;

    public constructor(init?: Partial<DeleteSearchSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class ExtendSearchSubscriptionResponse
{
    public creatorId?: number;
    public name: string;
    public extendedUntil: string;
    public searchSubscriptionGuid: string;

    public constructor(init?: Partial<ExtendSearchSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class GetSearchSubscriptionResponse
{
    public id: number;
    public name: string;
    public eMail: string;
    public isPush: boolean;
    public appPushIdentifier: string;
    public pushEndpoint: string;
    public pushAuthSecret: string;
    public pushP256dhKey: string;

    public constructor(init?: Partial<GetSearchSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class SendSearchSubscriptionConfirmationResponse
{
    public eMailSent: boolean;

    public constructor(init?: Partial<SendSearchSubscriptionConfirmationResponse>) { (Object as any).assign(this, init); }
}

export class ConfirmSearchSubscriptionResponse
{
    public creatorId?: number;
    public searchSubscriptionGuid: string;
    public name: string;

    public constructor(init?: Partial<ConfirmSearchSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class ProviderAdvertsResponse
{
    public provider: ProviderData;
    public entries: SearchListingEntry[];
    public coordinates: AdvertCoordinate[];
    public totalResultCount: number;
    public skipCount: number;

    public constructor(init?: Partial<ProviderAdvertsResponse>) { (Object as any).assign(this, init); }
}

export class GetCantonalBanksResponse
{
    public cantonalBanks: Mandant[];

    public constructor(init?: Partial<GetCantonalBanksResponse>) { (Object as any).assign(this, init); }
}

export class SearchLocationListingResponse
{
    public immoCode: number;
    public displayName: string;

    public constructor(init?: Partial<SearchLocationListingResponse>) { (Object as any).assign(this, init); }
}

export class LocationResponse
{
    public identiferInfos: SearchLocation[];

    public constructor(init?: Partial<LocationResponse>) { (Object as any).assign(this, init); }
}

export class GetLocationCoordinateResponse
{
    public coordinate: GeograhyCoordinate;

    public constructor(init?: Partial<GetLocationCoordinateResponse>) { (Object as any).assign(this, init); }
}

export class ZipLocationResponse
{
    public treffer: OrtTrefferDto[];

    public constructor(init?: Partial<ZipLocationResponse>) { (Object as any).assign(this, init); }
}

export class SearchLocationResponse
{
    public locations: SearchLocation[];

    public constructor(init?: Partial<SearchLocationResponse>) { (Object as any).assign(this, init); }
}

export class SearchListingResponse
{
    public entries: SearchListingEntry[];
    public coordinates: AdvertCoordinate[];
    public radiusCenter: GeograhyCoordinate;
    public specialPromotions: SearchListingEntry[];
    public moreEntries: SearchListingEntry[];
    public mandantId?: number;
    public cantonCodes: string[];
    public countryCodes: string[];
    public faqInformation: FaqInformation;
    public seoInformation: SeoInformation;
    public contentBlockMoreListings: ContentBlockMoreListings;
    public totalResultCount: number;
    public skipCount: number;

    public constructor(init?: Partial<SearchListingResponse>) { (Object as any).assign(this, init); }
}

export class GetAdvertSearchCountResponse
{
    public totalResultCount: number;

    public constructor(init?: Partial<GetAdvertSearchCountResponse>) { (Object as any).assign(this, init); }
}

export class GetSpecialPromtionsResponse
{
    public entries: SearchListingEntry[];

    public constructor(init?: Partial<GetSpecialPromtionsResponse>) { (Object as any).assign(this, init); }
}

export class ListingDetailResponse
{
    public detail: ListingDetail;

    public constructor(init?: Partial<ListingDetailResponse>) { (Object as any).assign(this, init); }
}

export class AdvertActiveResponse
{
    public detail: SearchListingEntry;

    public constructor(init?: Partial<AdvertActiveResponse>) { (Object as any).assign(this, init); }
}

export class AdvertPreviewResponse
{
    public detail: ListingDetail;

    public constructor(init?: Partial<AdvertPreviewResponse>) { (Object as any).assign(this, init); }
}

export class SendAdvertContactResponse
{
    public searchSubscriptionCreatedSuccessful: boolean;
    public createdSearchSubscription: CreateSearchSubscriptionResponse;

    public constructor(init?: Partial<SendAdvertContactResponse>) { (Object as any).assign(this, init); }
}

export class GetFilialeForAdvertResponse
{
    public filiale: Filiale;

    public constructor(init?: Partial<GetFilialeForAdvertResponse>) { (Object as any).assign(this, init); }
}

export class GetObjectFeedResponse
{
    public objectFeedList: ObjectFeedDto[];

    public constructor(init?: Partial<GetObjectFeedResponse>) { (Object as any).assign(this, init); }
}

export class GetEmarsysRecommendationAdvertsResponse
{
    public entries: SearchListingEntry[];

    public constructor(init?: Partial<GetEmarsysRecommendationAdvertsResponse>) { (Object as any).assign(this, init); }
}

export class GetContentBoxesResponse
{
    public contentboxes: ContentboxDisplay[];

    public constructor(init?: Partial<GetContentBoxesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public refreshTokenExpiry?: string;

    // @DataMember(Order=9)
    public profileUrl: string;

    // @DataMember(Order=10)
    public roles: string[];

    // @DataMember(Order=11)
    public permissions: string[];

    // @DataMember(Order=12)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=13)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @Route("/statistic/update", "POST")
export class StatisticRequest implements IReturnVoid, IPost
{
    public statistic: StatisticType;
    public languageIso: string;
    public contentBoxIds: number[];
    public advertId: number;
    public immoCode: number;
    public mandantCode: string;

    public constructor(init?: Partial<StatisticRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatisticRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/statistic/writeback")
export class ImpressionWritebackRequest implements IReturnVoid, IPost
{

    public constructor(init?: Partial<ImpressionWritebackRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpressionWritebackRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/watchlist/check", "GET")
export class GetWatchlistEntriesRequest implements IReturn<GetWatchlistEntriesResponse>, IGet
{
    /** @description List of ImmoCodes in localstorage */
    // @ApiMember(Description="List of ImmoCodes in localstorage")
    public immoCodes: number[];

    public constructor(init?: Partial<GetWatchlistEntriesRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWatchlistEntriesRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetWatchlistEntriesResponse(); }
}

// @Route("/watchlist/add", "PUT")
export class AddToWatchlistRequest implements IReturnVoid, IPut
{
    public immoCode: number;

    public constructor(init?: Partial<AddToWatchlistRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddToWatchlistRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/watchlist/remove", "DELETE")
export class RemoveFromWatchlistRequest implements IReturnVoid, IDelete
{
    public immoCode: number;

    public constructor(init?: Partial<RemoveFromWatchlistRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveFromWatchlistRequest'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/verkaufschance/leadAllowed", "GET")
export class LeadAllowedContactFormRequest implements IReturn<boolean>, IGet
{
    public immoCode: number;
    public email: string;
    public phone: string;

    public constructor(init?: Partial<LeadAllowedContactFormRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LeadAllowedContactFormRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/verkaufschance/leadAllowed", "GET")
export class LeadAllowedSearchSubscriptionRequest implements IReturn<boolean>, IGet
{
    public offerType: OfferType;
    public propertyType: SearchPropertyType;
    public mandantId: number;

    public constructor(init?: Partial<LeadAllowedSearchSubscriptionRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LeadAllowedSearchSubscriptionRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/user/sync/entities", "POST")
export class SyncEntitiesWithUserProfileRequest implements IReturn<SyncEntitiesWithUserProfileResponse>, IPost
{
    public travelDestinations: TravelDestination[];
    public watchlistImmocodes: number[];
    public searchSubscriptionGuids: string[];
    public appPushIdentifier: string;
    public pushEndpoint: string;
    public pushAuthSecret: string;
    public pushP256dhKey: string;

    public constructor(init?: Partial<SyncEntitiesWithUserProfileRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SyncEntitiesWithUserProfileRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SyncEntitiesWithUserProfileResponse(); }
}

// @Route("/user/travel/get", "GET")
export class GetTravelDestinationsRequest implements IReturn<GetTravelDestinationsResponse>, IGet
{

    public constructor(init?: Partial<GetTravelDestinationsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTravelDestinationsRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTravelDestinationsResponse(); }
}

// @Route("/user/travel/add", "PUT")
export class AddTravelDestinationRequest implements IReturn<AddTravelDestinationResponse>, IPut
{
    public travelDestination: TravelDestination;

    public constructor(init?: Partial<AddTravelDestinationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTravelDestinationRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new AddTravelDestinationResponse(); }
}

// @Route("/user/travel/update", "PUT")
export class UpdateTravelDestinationRequest implements IReturn<UpdateTravelDestinationResponse>, IPut
{
    public travelDestination: TravelDestination;

    public constructor(init?: Partial<UpdateTravelDestinationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTravelDestinationRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new UpdateTravelDestinationResponse(); }
}

// @Route("/user/travel/remove", "DELETE")
export class RemoveTravelDestinationRequest implements IReturnVoid, IDelete
{
    public travelDestinationId: number;

    public constructor(init?: Partial<RemoveTravelDestinationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveTravelDestinationRequest'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/user/getinfo", "GET")
export class GetUserInfoRequest implements IReturn<GetUserInfoResponse>, IGet
{

    public constructor(init?: Partial<GetUserInfoRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserInfoRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserInfoResponse(); }
}

// @Route("/user/emailexists", "GET")
export class UserEmailExistsRequest implements IReturn<boolean>, IGet
{
    public email: string;

    public constructor(init?: Partial<UserEmailExistsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserEmailExistsRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/user/getprofile", "GET")
export class GetUserProfileRequest implements IReturn<GetUserProfileResponse>, IGet
{

    public constructor(init?: Partial<GetUserProfileRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserProfileRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserProfileResponse(); }
}

// @Route("/user/updateprofiledata", "PUT")
export class UpdateUserProfileDataRequest implements IReturn<UpdateUserProfileResponse>, IPut
{
    public profileData: IUserProfileData;

    public constructor(init?: Partial<UpdateUserProfileDataRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUserProfileDataRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new UpdateUserProfileResponse(); }
}

// @Route("/user/updatefinancingdata", "PUT")
export class UpdateUserFinancingDataRequest implements IReturn<UpdateUserProfileResponse>, IPut
{
    public financingData: IUserFinancingData;

    public constructor(init?: Partial<UpdateUserFinancingDataRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUserFinancingDataRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new UpdateUserProfileResponse(); }
}

// @Route("/user/dataDisclosure", "POST")
export class SendDataDisclosureEmailRequest implements IReturnVoid, IPost
{
    public firstname: string;
    public lastname: string;
    public email: string;
    public phone: string;

    public constructor(init?: Partial<SendDataDisclosureEmailRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDataDisclosureEmailRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/user/delete", "POST")
export class SendDeleteUserProfileEmailRequest implements IReturn<SendDeleteUserProfileEmailResponse>, IPost
{
    public confirm: boolean;

    public constructor(init?: Partial<SendDeleteUserProfileEmailRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDeleteUserProfileEmailRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDeleteUserProfileEmailResponse(); }
}

// @Route("/country/getall", "GET")
export class GetCountriesRequest implements IReturn<GetCountriesResponse>, IGet
{
    public languageIso: string;

    public constructor(init?: Partial<GetCountriesRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCountriesRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetCountriesResponse(); }
}

// @Route("/user/getentities", "GET")
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse>, IGet
{
    /** @description List of ImmoCodes in localstorage */
    // @ApiMember(Description="List of ImmoCodes in localstorage")
    public immoCodes: number[];

    public constructor(init?: Partial<GetEntitiesRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEntitiesRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetEntitiesResponse(); }
}

// @Route("/seo/refresh")
export class SeoRefreshRequest implements IReturnVoid, IPost
{

    public constructor(init?: Partial<SeoRefreshRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeoRefreshRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/searchsubscription/getAll", "GET")
export class GetSearchSubscriptionsRequest implements IReturn<GetSearchSubscriptionsResponse>, IGet
{
    public guids: string[];

    public constructor(init?: Partial<GetSearchSubscriptionsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchSubscriptionsRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSearchSubscriptionsResponse(); }
}

// @Route("/listing/subscription/create", "POST")
export class CreateSearchSubscriptionRequest implements IReturn<CreateSearchSubscriptionResponse>, IPost
{
    public subscriptionDetails: SearchSubscriptionEntry;

    public constructor(init?: Partial<CreateSearchSubscriptionRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSearchSubscriptionRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateSearchSubscriptionResponse(); }
}

// @Route("/searchsubscription", "DELETE")
export class DeleteSearchSubscriptionRequest implements IReturn<DeleteSearchSubscriptionResponse>, IDelete
{
    public deletionKey: string;
    public guid: string;

    public constructor(init?: Partial<DeleteSearchSubscriptionRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSearchSubscriptionRequest'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DeleteSearchSubscriptionResponse(); }
}

// @Route("/searchsubscription/extend", "POST")
export class ExtendSearchSubscriptionRequest implements IReturn<ExtendSearchSubscriptionResponse>, IPost
{
    public extentionKey: string;

    public constructor(init?: Partial<ExtendSearchSubscriptionRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExtendSearchSubscriptionRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ExtendSearchSubscriptionResponse(); }
}

// @Route("/searchsubscription/get", "GET")
export class GetSearchSubscriptionRequest implements IReturn<GetSearchSubscriptionResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetSearchSubscriptionRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchSubscriptionRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSearchSubscriptionResponse(); }
}

// @Route("/searchsubscription/sendconfirmation", "POST")
export class SendSearchSubscriptionConfirmationRequest implements IReturn<SendSearchSubscriptionConfirmationResponse>, IPost
{
    public searchSubscriptionGuid: string;
    public languageIso: string;

    public constructor(init?: Partial<SendSearchSubscriptionConfirmationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSearchSubscriptionConfirmationRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSearchSubscriptionConfirmationResponse(); }
}

// @Route("/searchsubscription/confirmemail", "POST")
export class ConfirmSearchSubscriptionByEMailRequest implements IReturn<ConfirmSearchSubscriptionResponse>, IPost
{
    public confirmationKey: string;

    public constructor(init?: Partial<ConfirmSearchSubscriptionByEMailRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConfirmSearchSubscriptionByEMailRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConfirmSearchSubscriptionResponse(); }
}

/** @description Provider Adverts */
// @Route("/provider/advert", "GET")
export class ProviderAdvertsRequest implements IReturn<ProviderAdvertsResponse>, IGet
{
    public providerId: number;
    public searchPropertyType: SearchPropertyType;
    public offerType: OfferType;
    public rowCount: number;
    public skipCount: number;

    public constructor(init?: Partial<ProviderAdvertsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProviderAdvertsRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProviderAdvertsResponse(); }
}

// @Route("/mandant/getcantonalbanks", "GET")
export class GetCantonalBanksRequest implements IReturn<GetCantonalBanksResponse>, IGet
{
    public languageIso: string;

    public constructor(init?: Partial<GetCantonalBanksRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCantonalBanksRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetCantonalBanksResponse(); }
}

// @Route("/log/exception", "POST")
export class LogExceptionRequest implements IReturnVoid, IPost
{
    public message: string;
    public stacktrace: string;
    public url: string;
    public source: string;
    public useragent: string;
    public ipAddress: string;

    public constructor(init?: Partial<LogExceptionRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LogExceptionRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/listing/location/resolve", "GET")
export class LocationResolveRequest implements IReturn<SearchLocation>, IGet
{
    public keyword: string;

    public constructor(init?: Partial<LocationResolveRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocationResolveRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SearchLocation(); }
}

// @Route("/listing/search/immocode", "GET")
export class SearchLocationListingRequest implements IReturn<SearchLocationListingResponse>, IGet
{
    public keyword: string;

    public constructor(init?: Partial<SearchLocationListingRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchLocationListingRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SearchLocationListingResponse(); }
}

// @Route("/listing/location", "GET")
export class LocationRequest implements IReturn<LocationResponse>, IPost
{
    // @ApiMember(IsRequired=true)
    public identifiers: string[];

    // @ApiMember(IsRequired=true)
    public languageIso: string;

    public constructor(init?: Partial<LocationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocationRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocationResponse(); }
}

// @Route("/advert/search/location/getcoordinate")
export class GetLocationCoordinateRequest implements IReturn<GetLocationCoordinateResponse>, IGet
{
    public location: string;

    public constructor(init?: Partial<GetLocationCoordinateRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLocationCoordinateRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetLocationCoordinateResponse(); }
}

// @Route("/ZipLocation", "GET")
export class ZipLocationRequest implements IReturn<ZipLocationResponse>, IGet
{
    public keyword: string;

    public constructor(init?: Partial<ZipLocationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ZipLocationRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ZipLocationResponse(); }
}

// @Route("/listing/locations", "GET")
export class SearchLocationRequest implements IReturn<SearchLocationResponse>, IGet
{
    // @ApiMember(IsRequired=true)
    public keyword: string;

    // @ApiMember(IsRequired=true)
    public languageIso: string;

    public constructor(init?: Partial<SearchLocationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchLocationRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SearchLocationResponse(); }
}

/** @description Search */
// @Route("/advert/search", "GET")
export class SearchListingRequest extends AdvertSearchFilter implements IReturn<SearchListingResponse>, IGet, IPagingSorting
{
    public jumpToImmoCode?: number;
    public languageIso: string;
    public numberOfSpecialPromotions: number;
    public rowCount: number;
    public skipCount: number;
    public order: AdvertOrderType;
    public seoIdentifier: string;

    public constructor(init?: Partial<SearchListingRequest>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchListingRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SearchListingResponse(); }
}

// @Route("/advert/searchcount", "GET")
export class GetAdvertSearchCountRequest extends AdvertSearchFilter implements IReturn<GetAdvertSearchCountResponse>, IGet
{

    public constructor(init?: Partial<GetAdvertSearchCountRequest>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdvertSearchCountRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetAdvertSearchCountResponse(); }
}

// @Route("/advert/specialpromotion", "GET")
export class GetSpecialPromtionsRequest implements IReturn<GetSpecialPromtionsResponse>, IGet
{
    public offerType?: OfferType;
    public searchPropertyType?: SearchPropertyType;
    public location: string[];
    public numberOfSpecialPromotions: number;

    public constructor(init?: Partial<GetSpecialPromtionsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecialPromtionsRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSpecialPromtionsResponse(); }
}

// @Route("/advert/report", "POST")
export class ReportAdvetRequest implements IReturnVoid, IPost
{
    public immocode: number;
    public reportCause: AdvertReportCause;
    public languageIso: string;
    public email: string;
    public message: string;

    public constructor(init?: Partial<ReportAdvetRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReportAdvetRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/listing/detail", "GET")
export class ListingDetailRequest implements IReturn<ListingDetailResponse>, IGet
{
    public immocode: number;
    // @ApiMember(IsRequired=true)
    public languageIso: string;

    public constructor(init?: Partial<ListingDetailRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListingDetailRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListingDetailResponse(); }
}

// @Route("/advert/isactive", "GET")
export class AdvertActiveRequest implements IReturn<AdvertActiveResponse>, IGet
{
    public immoCode: number;

    public constructor(init?: Partial<AdvertActiveRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdvertActiveRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdvertActiveResponse(); }
}

// @Route("/advert/preview")
export class AdvertPreviewRequest implements IReturn<AdvertPreviewResponse>, IGet
{
    public objektVersionId: number;
    public languageIso: string;

    public constructor(init?: Partial<AdvertPreviewRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdvertPreviewRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdvertPreviewResponse(); }
}

// @Route("/listing/contact", "POST")
export class SendAdvertContactRequest implements IReturn<SendAdvertContactResponse>, IPost
{
    public immoCode: number;
    public languageIso: string;
    public name: string;
    public firstname: string;
    public street: string;
    public email: string;
    public phone: string;
    public postalcode: string;
    public city: string;
    public message: string;
    public allowLead: boolean;
    public allowNewsletter: boolean;
    public createSearchSubscription: boolean;

    public constructor(init?: Partial<SendAdvertContactRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendAdvertContactRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendAdvertContactResponse(); }
}

// @Route("/filiale/getFilialeForAdvert", "GET")
export class GetFilialeForAdvertRequest implements IReturn<GetFilialeForAdvertResponse>, IGet
{
    // @ApiMember(IsRequired=true)
    public immoCode: number;

    // @ApiMember(IsRequired=true)
    public languageIso: string;

    // @ApiMember(IsRequired=true)
    public mandantId: number;

    public constructor(init?: Partial<GetFilialeForAdvertRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFilialeForAdvertRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFilialeForAdvertResponse(); }
}

// @Route("/external/objectfeed", "GET")
export class GetObjectFeedRequest implements IReturn<GetObjectFeedResponse>, IGet
{
    public languageIso: string;
    public postalCodes: string[];
    public offerType: OfferType;
    public propertyTypes: PropertyType[];
    public partnerCode: string;
    public partnerExclude: boolean;
    public pictureRequired: boolean;

    public constructor(init?: Partial<GetObjectFeedRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetObjectFeedRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetObjectFeedResponse(); }
}

// @Route("/external/memberform/create", "POST")
export class CreateMemberFormCount implements IReturnVoid, IPost
{
    public product: string;

    public constructor(init?: Partial<CreateMemberFormCount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateMemberFormCount'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/emarsys/getrecommendation", "GET")
export class GetEmarsysRecommendationAdvertsRequest implements IReturn<GetEmarsysRecommendationAdvertsResponse>, IGet
{
    public immoCodes: number[];

    public constructor(init?: Partial<GetEmarsysRecommendationAdvertsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmarsysRecommendationAdvertsRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetEmarsysRecommendationAdvertsResponse(); }
}

// @Route("/emarsys/updateConsent", "POST")
export class UpdateEmarsysConsentRequest implements IReturnVoid, IPost
{
    public status: EmarsysConsentStatus;
    public kundenId: number;
    public email: string;

    public constructor(init?: Partial<UpdateEmarsysConsentRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEmarsysConsentRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/emarsys/deleteConsent", "DELETE")
export class DeleteEmarsysConsentRequest implements IReturnVoid, IPost
{
    public kundenId: number;

    public constructor(init?: Partial<DeleteEmarsysConsentRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEmarsysConsentRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/contentbox/display", "GET")
export class GetContentBoxesRequest implements IReturn<GetContentBoxesResponse>, IPost
{
    // @ApiMember(IsRequired=true)
    public pageType: ContentBoxPageType;

    // @ApiMember()
    public offerType?: OfferType;

    // @ApiMember()
    public propertyType?: SearchPropertyType;

    // @ApiMember(IsRequired=true)
    public location: string[];

    // @ApiMember(IsRequired=true)
    public languageIso: string;

    // @ApiMember(IsRequired=true)
    public amountOfBoxes: number;

    public constructor(init?: Partial<GetContentBoxesRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContentBoxesRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetContentBoxesResponse(); }
}

/** @description Sign In */
// @Route("/auth", "GET,POST")
// @Route("/auth/{provider}", "GET,POST")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public userName: string;

    // @DataMember(Order=3)
    public password: string;

    // @DataMember(Order=4)
    public rememberMe?: boolean;

    // @DataMember(Order=5)
    public accessToken: string;

    // @DataMember(Order=6)
    public accessTokenSecret: string;

    // @DataMember(Order=7)
    public returnUrl: string;

    // @DataMember(Order=8)
    public errorView: string;

    // @DataMember(Order=9)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

