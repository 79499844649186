import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject, delay, filter, first } from 'rxjs';
import { UserCentricsEvent, initializedWindowEventName, windowEventName } from './event';
import { UsercentricsUiObject, usercentricsUiObjectName } from './ui';

@Injectable({ providedIn: 'root' })
export class UsercentricsService {
  private readonly eventSubject = new Subject<UserCentricsEvent>();
  private readonly uiInitializedSubject = new BehaviorSubject<boolean>(false);

  private isInitialized = false;

  /**
   * Emits when a usercentrics related event occurs
   */
  readonly usercentricsEvent$ = this.eventSubject.asObservable();

  /**
   * Emits when the usercentrics UI is initialized
   */
  readonly usercentricsUiInitialized$ = this.uiInitializedSubject.asObservable();

  constructor(@Inject(DOCUMENT) private readonly documentRef: Document) {}

  /**
   * Initializes Usercentrics SDK and listens for events
   */
  async initialize(): Promise<void> {
    if (this.isInitialized) {
      return;
    }

    this.registerUsercentricsEventHandlers();
    this.addUsercentricsScripts();

    this.isInitialized = true;
  }

  /**
   * Denies consent to all non-required services
   */
  denyAllServices(): void {
    this.usercentricsUiInitialized$.pipe(
      filter(Boolean),
      first(),
      delay(100)
    ).subscribe(() => {
      const uiObject = this.documentRef.defaultView[usercentricsUiObjectName] as UsercentricsUiObject;
      uiObject.denyAllConsents();
    })
  }

  private registerUsercentricsEventHandlers(): void {
    // https://docs.usercentrics.com/#/v2-events?id=app-initialization-browser-ui
    this.documentRef.defaultView.addEventListener(initializedWindowEventName, (event: any) => {
      this.uiInitializedSubject.next(true);
    });

    // https://docs.usercentrics.com/#/v2-events?id=usage-as-window-event
    this.documentRef.defaultView.addEventListener(windowEventName, (event: any) => {
      this.eventSubject.next(event as UserCentricsEvent);
    });
  }

  private addUsercentricsScripts(): void {
    this.appendLinkElement('//app.usercentrics.eu', 'preconnect');
    this.appendLinkElement('//app.usercentrics.eu', 'preconnect');
    this.appendLinkElement('//api.usercentrics.eu', undefined, 'script');

    const script = this.documentRef.createElement('script');
    script.id = 'usercentrics-cmp';
    script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    script.setAttribute('data-settings-id', environment.userCentrics.settingsId);
    script.setAttribute('data-tcf-enabled', '');

    this.documentRef.head.appendChild(script);
  }

  private appendLinkElement(href: string, rel?: string, linkAs?: string): void {
    const link = this.documentRef.createElement('link');
    link.href = href;

    if (rel) {
      link.rel = rel;
    }

    if (linkAs) {
      link.as = linkAs;
    }

    this.documentRef.head.appendChild(link);
  }
}
