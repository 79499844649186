import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Breakpoints, LayoutService } from '@exanic/newhome-shared-components';
import { Store } from '@ngrx/store';
import { AdmeiraActions, AdmeiraReducer, AdmeiraSelectors } from 'app/shared/admeira';
import { ConsentProviderName, ConsentService } from 'app/shared/consent';
import { ScriptService } from 'app/shared/services/script/script.service';
import { environment } from 'environments/environment';
import {
  AdvertSearchFilter,
  EquipmentType,
  ListingDetail,
  LocationType,
  PropertySubType,
  SearchLocation,
} from 'newhome.dtos';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getAdmeiraScript } from '../script/script.store';
import { AdmeiraTagManager } from './admeira-tag-manager';
import {
  DetailAdmeiraTargeting,
  SearchFilterAdmeiraTargeting,
  conditionAdmeiraTargetingMap,
  offerTypeAdmeiraTargetingMap,
  propertySubTypeAdmeiraTargetingMap,
  propertyTypeAdmeiraTargetingMap,
} from './admeira.targeting';

declare var admTagMan: AdmeiraTagManager;
export declare type AdmeiraChannel = 'Liste' | 'Detail' | 'Verwaltung' | 'ROS';

@Injectable({
  providedIn: 'root',
})
export class AdmeiraService implements OnDestroy {
  public registered$: {
    [key: string]: BehaviorSubject<boolean>;
  } = {};
  private scriptLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public pageInited$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public containerVisible$: {
    [key: string]: BehaviorSubject<boolean>;
  } = {};

  private lazyLoadingContainers: string[];

  private isMobileView: boolean;
  private layoutChangedSubscription: Subscription;
  private languageChangeSubscription: Subscription;
  private loadSlotsSubscription: Subscription;

  private readonly admeiraSupported$: Observable<boolean>;

  constructor(
    private readonly layoutService: LayoutService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private readonly scriptService: ScriptService,
    private readonly store: Store<AdmeiraReducer.State>,
    private readonly consentService: ConsentService,
  ) {
    this.admeiraSupported$ = this.store.select(AdmeiraSelectors.selectIsAdmeiraSupported);

    this.layoutChangedSubscription = this.layoutService.subscribeToLayoutChanges().subscribe(_ => {
      this.isMobileView = this.layoutService.isBreakpointActive(Breakpoints.Mobile);
    });
  }

  initialize(language: string): void {
    // Load Admeira Script
    this.admeiraSupported$.pipe(filter(Boolean), take(1)).subscribe(() => this.loadScript(language));

    // Listen for consent updates and re evaluate ringier support
    this.consentService.onceConsentIsGiven(ConsentProviderName.Ringier, () => {
      this.store.dispatch(AdmeiraActions.checkAdmeiraSupport());
    });
  }

  public ngOnDestroy() {
    this.languageChangeSubscription?.unsubscribe();
    this.loadSlotsSubscription?.unsubscribe();
    this.layoutChangedSubscription?.unsubscribe();
  }

  public initPage$(
    channel: AdmeiraChannel,
    targeting: Partial<SearchFilterAdmeiraTargeting | DetailAdmeiraTargeting>,
    containers: string[],
    lazyLoadingContainers: string[] = [],
  ): Observable<boolean> {
    return this.admeiraSupported$.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => {
        const allContainers = [...containers, ...lazyLoadingContainers];
        this.lazyLoadingContainers = lazyLoadingContainers;

        return this.scriptLoaded$.pipe(
          filter(x => x),
          map(() => {
            if (isPlatformBrowser(this.platformId) && admTagMan) {
              if (environment.admeira.environment !== 'prod') {
                console.debug(`[initPage] ${channel}: ${allContainers.join(', ')}`);
              }

              const init = () => {
                admTagMan.init({
                  platform: this.isMobileView ? 'MobileWeb' : 'Desktop',
                  targeting,
                  channel,
                });
              };

              admTagMan.q.push(() => init());

              return true;
            }

            return false;
          }),
          catchError(() => of(false)),
          take(1),
          tap(inited => {
            this.cleanUpAdSlots(allContainers);

            for (const container of allContainers) {
              if (this.containerVisible$[container]) {
                this.containerVisible$[container].next(inited);
              } else {
                this.containerVisible$[container] = new BehaviorSubject(inited);
              }
              this.registered$[container] = new BehaviorSubject(false);
            }

            this.registerLoadSlotsSubscription();

            setTimeout(() => this.pageInited$.next(inited), 0);
          }),
        );
      }),
    );
  }

  public resetPage() {
    // to prevent ngAfterViewInited befor initPage$()
    this.pageInited$.next(false);
  }

  public registerSlot(mobileSlot: string, desktop: string, container: string, sizes?: string[]) {
    if (isPlatformBrowser(this.platformId) && this.containerVisible$[container]) {
      const slot = this.isMobileView ? mobileSlot : desktop;

      if (slot && admTagMan) {
        if (environment.admeira.environment !== 'prod') {
          console.debug(`[registerSlot] ${container}: ${slot}`);
        }

        const registerSlot = () => {
          admTagMan.registerSlot({
            slot,
            container,
            sizes,
            events: {
              adReady: data => {
                if (environment.admeira.environment !== 'prod') {
                  console.debug(`[adReady] ${container}: ${slot}`, data);
                }
                this.containerVisible$[container].next(true);
              },
              adLoaded: data => {
                if (environment.admeira.environment !== 'prod') {
                  console.debug(`[adLoaded] ${container}: ${slot}`, data);
                }
                this.containerVisible$[container].next(true);
              },
              adEmpty: data => {
                if (environment.admeira.environment !== 'prod') {
                  console.debug(`[adEmpty] ${container}: ${slot}`, data);
                }
                this.containerVisible$[container].next(false);
              },
              adError: data => {
                if (environment.admeira.environment !== 'prod') {
                  console.debug(`[adError] ${container}: ${slot}`, data);
                }
                this.containerVisible$[container].next(false);
              },
              adStatus: data => {
                if (environment.admeira.environment !== 'prod') {
                  console.debug(`[adStatus] ${container}: ${slot}`, data);
                }
              },
            },
          });
        };

        admTagMan.q.push(() => {
          registerSlot();
        });
      } else {
        this.containerVisible$[container].next(false);
      }
    } else {
      this.containerVisible$[container]?.next(false);
    }

    this.registered$[container]?.next(true);
  }

  public refreshSlot(mobileSlot: string, desktop: string, container: string) {
    if (isPlatformBrowser(this.platformId) && this.containerVisible$[container]) {
      const slot = this.isMobileView ? mobileSlot : desktop;

      if (slot && admTagMan) {
        if (environment.admeira.environment !== 'prod') {
          console.debug(`[refreshSlot] ${container}: ${slot}`);
        }

        admTagMan.q.push(() => {
          admTagMan.refreshSlot({
            container,
            slot,
          });
        });
      }
    }
  }

  public getSearchFilterTargeting(
    searchFilter: AdvertSearchFilter,
    searchLocations: SearchLocation[],
    cantonCodes?: string[],
  ) {
    const targeting: Partial<SearchFilterAdmeiraTargeting> = {};

    const propertyType = propertyTypeAdmeiraTargetingMap[searchFilter.propertyType];
    if (propertyType) {
      targeting.objektart = propertyType;
    }

    const propertySubtypes: string[] = [];
    for (const propertySubtype of searchFilter.propertySubtypes?.map(x => +x as PropertySubType) || []) {
      if (propertySubtype) {
        propertySubtypes.push(propertySubTypeAdmeiraTargetingMap[propertySubtype]);
      }
    }

    if (propertySubtypes.length) {
      targeting.unterobjektart = propertySubtypes.join('_');
    }

    if (searchFilter.offerType) {
      targeting.angebotsart = offerTypeAdmeiraTargetingMap[searchFilter.offerType];
    }

    if (searchFilter.priceMin || searchFilter.priceM2Min) {
      targeting.preisVon = (searchFilter.priceMin ?? searchFilter.priceM2Min).toString();
    }
    if (searchFilter.priceMax || searchFilter.priceM2Max) {
      targeting.preisBis = (searchFilter.priceMax ?? searchFilter.priceM2Max).toString();
    }

    if (searchFilter.roomsMin) {
      targeting.zimmerVon = searchFilter.roomsMin.toString();
    }
    if (searchFilter.roomsMax) {
      targeting.zimmerBis = searchFilter.roomsMax.toString();
    }

    if (searchFilter.livingAreaMin) {
      targeting.wohnflaecheVon = searchFilter.livingAreaMin.toString();
    }
    if (searchFilter.livingAreaMax) {
      targeting.wohnflaecheBis = searchFilter.livingAreaMax.toString();
    }

    if (searchFilter.plotAreaMin) {
      targeting.grundstuecksflaecheVon = searchFilter.plotAreaMin.toString();
    }
    if (searchFilter.plotAreaMax) {
      targeting.grundstuecksflaecheBis = searchFilter.plotAreaMax.toString();
    }

    if (searchFilter.equipments?.some(x => x === EquipmentType.Minergie)) {
      targeting.minergieSuche = 'ja';
    } else {
      targeting.minergieSuche = 'nein';
    }

    const cantons = searchLocations
      .filter(x => x.locationType === LocationType.Canton)
      .map(x => x.displayName?.replace(/[äàâëéèêüûöôïî]/, ''));

    if (cantons?.length) {
      targeting.kanton = cantons.join(', ');
    }

    const citys = searchLocations.map(x => x.displayName?.replace(/[äàâëéèêüûöôïî]/, ''));

    if (citys?.length) {
      targeting.ortschaft = citys;
    }

    if (cantonCodes?.length) {
      targeting.kantone = cantonCodes;
    }

    return targeting;
  }

  public getDetailTargeting(detail: ListingDetail) {
    const targeting: Partial<DetailAdmeiraTargeting> = {};

    const propertyType = propertyTypeAdmeiraTargetingMap[detail?.propertyType];
    if (propertyType) {
      targeting.objektart = propertyType;
    }

    if (detail?.detail?.propertySubType) {
      targeting.unterobjektart = propertySubTypeAdmeiraTargetingMap[detail.detail.propertySubType];
    }

    if (detail?.offerType) {
      targeting.angebotsart = offerTypeAdmeiraTargetingMap[detail.offerType];
    }

    if (detail?.immocode) {
      targeting.immocode = detail.immocode.toString();
    }

    if (detail?.price) {
      const { price, priceM2 } = detail.price;
      if (price || priceM2) {
        targeting.preisVon = (price ?? priceM2).toString();
        targeting.preisBis = (price ?? priceM2).toString();
      }
    }

    if (detail?.detail?.rooms) {
      targeting.zimmerVon = detail.detail.rooms.toString();
      targeting.zimmerBis = detail.detail.rooms.toString();
    }

    if (detail?.detail?.livingArea) {
      targeting.wohnflaecheVon = detail.detail.livingArea.toString();
      targeting.wohnflaecheBis = detail.detail.livingArea.toString();
    }

    if (detail?.detail?.plotArea) {
      targeting.grundstuecksflaecheVon = detail.detail.plotArea.toString();
      targeting.grundstuecksflaecheBis = detail.detail.plotArea.toString();
    }

    if (detail?.feature?.minergie) {
      targeting.minergieSuche = 'ja';
    } else {
      targeting.minergieSuche = 'nein';
    }

    if (detail?.feature?.garageAvailable) {
      targeting.garage = 'ja';
    } else {
      targeting.garage = 'nein';
    }

    if (detail?.feature?.parkingSpaceAvailable) {
      targeting.parkplatz = 'ja';
    } else {
      targeting.parkplatz = 'nein';
    }

    if (detail?.detail?.constructionYear) {
      targeting.baujahr = detail.detail.constructionYear.toString();
    }

    if (detail?.detail?.renovationYear) {
      targeting.renoviert = detail.detail.renovationYear.toString();
    }

    const condition = conditionAdmeiraTargetingMap[detail?.detail?.condition];

    if (condition) {
      targeting.objektzustand = condition;
    }

    if (detail?.location?.displayName) {
      targeting.ortschaft = detail.location.displayName;
    }

    if (detail?.cantonCode) {
      targeting.kantone = detail.cantonCode;
    }

    if (detail?.postalCode) {
      targeting.postleitzahlen = detail.postalCode;
    }

    return targeting;
  }

  private cleanUpAdSlots(containers: string[]) {
    this.loadSlotsSubscription?.unsubscribe();

    for (const container of containers) {
      if (this.containerVisible$.hasOwnProperty(container)) {
        const containerElement = this.documentRef.getElementById(container);
        if (containerElement) {
          containerElement.innerHTML = '';
        }
      }
    }

    for (const key in this.containerVisible$) {
      if (this.containerVisible$.hasOwnProperty(key)) {
        this.containerVisible$[key].next(false);
      }
    }

    for (const key in this.registered$) {
      if (this.registered$.hasOwnProperty(key)) {
        delete this.registered$[key];
      }
    }
  }

  private loadScript(lang: string) {
    this.scriptService.load(getAdmeiraScript(lang, environment.admeira.environment)).then(() => {
      this.scriptLoaded$.next(true);
    });
  }

  private registerLoadSlotsSubscription() {
    const registered$ = Object.entries(this.registered$).map(x =>
      this.lazyLoadingContainers.includes(x[0]) ? of(true) : x[1],
    );

    this.loadSlotsSubscription = combineLatest(Object.values(registered$))
      .pipe(
        map(x => x.every(b => b)),
        filter(x => x),
        take(1),
      )
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId) && admTagMan) {
          if (environment.admeira.environment !== 'prod') {
            console.debug('[loadSlots]');
          }
          admTagMan.q.push(() => {
            admTagMan.loadSlots();
          });
        }
      });
  }
}
