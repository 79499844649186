import { Action, createReducer, on } from '@ngrx/store';
import { ListingDetail } from 'newhome.dtos';
import * as PreviewActions from './preview.actions';

export const previewFeatureKey = 'preview';

export interface State {
  detail: ListingDetail;
  isLoading: boolean;
  hasError: boolean;
}

export const initialState: State = {
  detail: undefined,
  isLoading: false,
  hasError: false,
};

const previewReducer = createReducer(
  initialState,

  on(PreviewActions.beginLoadPreview, state => {
    return {
      ...state,
      isLoading: true,
      hasError: false,
    };
  }),
  on(PreviewActions.loadPreviewSuccess, (state, action) => {
    return {
      ...state,
      detail: action.detail,
      isLoading: false,
      hasError: false,
    };
  }),
  on(PreviewActions.loadPreviewFailure, (state, action) => {
    return {
      ...state,
      detail: undefined,
      isLoading: false,
      hasError: true,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return previewReducer(state, action);
}
