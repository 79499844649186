import { createAction, props } from '@ngrx/store';
import { SearchListingEntry, SearchListingRequest, SearchPropertyType } from 'newhome.dtos';
import { WizardStep } from './core/steps';

export const visitUpTo = createAction(
  '[Wizard] Visit Up To',
  props<{ step: WizardStep; filter: Partial<SearchListingRequest> }>(),
);

export const displayAll = createAction('[Wizard] Display All', props<{ propertyType: SearchPropertyType }>());

export const resetAdditional = createAction('[Wizard] Reset Additional');

export const resetAll = createAction('[Wizard] Reset All');

export const setOrigination = createAction('[Wizard] Set Origination', props<{ isFromLanding: boolean }>());

export const setSelectedProperty = createAction(
  '[Wizard] Set SelectedProperty',
  props<{ selectedProperty?: SearchListingEntry }>(),
);
