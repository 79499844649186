import { Component, forwardRef, Input, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { InputStepperConfig } from './input-stepper.config';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputDirective } from '@newhome/components/form-field';
import { ButtonTheme } from '@newhome/components/common/button';

const getRoundedValue = (value: number, step: number) => {
  const diff = value % step;

  return diff / step < 0.5 ? value - diff : value - diff + step;
};

@Component({
  selector: 'nh-input-stepper',
  templateUrl: './input-stepper.component.html',
  styleUrls: ['./input-stepper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputStepperComponent),
      multi: true,
    },
  ],
})
export class InputStepperComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() id: string;

  @Input() buttonTheme: ButtonTheme = 'primary';

  @Input()
  set config(value: InputStepperConfig) {
    this.configuration = value;

    this.setDisplayValue();
    this.setButtonStates();
  }

  public isDecrementDisabled: boolean;
  public isIncrementDisabled: boolean;
  public displayValue: string;
  public isAdjustmentSignalizationActive: boolean;

  public formControl = new FormControl();
  public configuration = new InputStepperConfig();

  private inputValue = 0;
  private debouncedPropagateChange = new Subject<number>();

  private formattedControlFocusOutSubscription: Subscription;

  @ViewChild(InputDirective, { static: false })
  inputDirective: InputDirective;

  constructor(private cdRef: ChangeDetectorRef, private readonly translate: TranslateService) {
    this.debouncedPropagateChange.pipe(debounceTime(200)).subscribe(value => this.propagateChange(value));
  }

  ngAfterViewInit() {
    this.formattedControlFocusOutSubscription = this.inputDirective?.blur.subscribe(_ => this.inputFocusOut());
  }

  ngOnDestroy() {
    this.debouncedPropagateChange.unsubscribe();
    this.formattedControlFocusOutSubscription?.unsubscribe();
  }

  setValue(value: number) {
    this.inputValue = value;
    this.adjustValue();
    this.setDisplayValue();
    this.debouncedPropagateChange.next(this.inputValue);

    if (this.inputValue !== value) {
      this.signalUserOfValueAdjustment();
    }

    this.formControl.patchValue(this.inputValue, { emitEvent: false });

    this.setButtonStates();
  }

  decrement() {
    this.setValue(this.formControl.value - this.configuration.step);
  }

  increment() {
    this.setValue(this.formControl.value + this.configuration.step);
  }

  inputFocusOut() {
    if (this.formControl.value !== this.inputValue) {
      // reset the value, if the user leaves the input empty
      this.formControl.value
        ? this.setValue(this.formControl.value)
        : (this.formControl.patchValue(this.inputValue, { emitEvent: false }), this.setDisplayValue());
    }
  }

  private adjustValue() {
    if (this.inputValue < this.configuration.min) {
      this.inputValue = this.configuration.min;
    } else if (this.inputValue > this.configuration.max) {
      this.inputValue = this.configuration.max;
    } else if (this.inputValue % this.configuration.step !== 0) {
      this.inputValue = getRoundedValue(this.inputValue, this.configuration.step);
    }
  }

  private setDisplayValue() {
    this.displayValue =
      this.configuration?.displayMappings && this.configuration?.displayMappings[this.inputValue]
        ? this.translate.instant(this.configuration?.displayMappings[this.inputValue])
        : this.formatInput(this.inputValue);
  }

  private setButtonStates() {
    this.isDecrementDisabled = this.inputValue <= this.configuration.min;
    this.isIncrementDisabled = this.inputValue >= this.configuration.max;
  }

  private signalUserOfValueAdjustment() {
    const cssAnimationTimeout = 750;
    this.isAdjustmentSignalizationActive = true;

    setTimeout(() => {
      this.isAdjustmentSignalizationActive = false;
      this.cdRef.markForCheck();
    }, cssAnimationTimeout * 2);
  }

  private formatInput(input: number): string {
    return this.configuration.unit ? `${input} ${this.configuration.unit}` : input.toString();
  }

  writeValue(value: any) {
    if (value !== undefined && value !== this.inputValue) {
      this.setValue(value);
    }
  }

  propagateChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // we don't need this but ControlValueAccessor does
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
