import { createAction, props } from '@ngrx/store';
import { IUserFinancingData, IUserProfileData, UserProfile } from 'newhome.dtos';

export const loadUserProfile = createAction('[User Profile] Load User Profile', props<{ userProfile: UserProfile }>());

export const beginUpdateUserProfileData = createAction(
  '[User Profile] Begin Update User Profile Data',
  props<{ userProfileData: IUserProfileData }>(),
);

export const beginUpdateUserFinancingData = createAction(
  '[User Profile] Begin Update User Financing Data',
  props<{ userFinancingData: IUserFinancingData }>(),
);

export const updateUserProfileSuccessful = createAction('[User Profile] Update User Profile Successful');
export const updateUserProfileFailure = createAction('[User Profile] Update User Profile Failure');

export const beginSendUserDeleteEMail = createAction(
  '[User Profile] Begin Send User Delete E-Mail',
  props<{ confirm?: boolean }>(),
);

export const beginSendDataDisclosureEMail = createAction(
  '[User Profile] Begin Send Data Disclosure E-Mail',
  props<{ firstname: string; lastname: string; email: string; phone: string }>(),
);

export const sendDataDisclosureEMailSuccessfull = createAction('[Profile] Data Disclosure E-Mail Successfull');

export const sendDataDisclosureEMailFailed = createAction('[User Profile] Data Disclosure E-Mail Failed');

export const openConfirmDeleteUserProfile = createAction('[User Profile] Begin open User Delete Confirm Dialog');

export const confirmDeleteUserProfileClosed = createAction('[User Profile] User Delete Confirm Dialog closed');

export const sendUserDeleteEMailSuccessfull = createAction('[Profile end User Delete E-Mail Successfull');

export const sendUserDeleteEMailFailed = createAction('[User Profile] end User Delete E-Mail Failed');
