import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'app/reducers';
import * as GlobalAlertsActions from 'app/shared/global-alerts/global-alerts.actions';
import { absoluteLinks } from './../../shared/legacy-navigation/absolute-links.map';
import { BannerType } from '../banner/banner-type.enum';
import { CookieService } from 'ngx-cookie-service';
import { cookieIdList } from './global-alerts-cookies-id-list';
import { LanguageService } from 'app/language';
import { cookieKey, newCookieKey } from './global-alerts.reducer';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalAlertsService {
  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly translate: TranslateService,
    private readonly cookieService: CookieService,
    private readonly languageService: LanguageService,
  ) {}

  public addAgbAlert(): void {
    const lang = this.languageService.getFallbackLanguage();

    const alertTextAgb = this.translate.instant('APP.AGB_CHANGE', {
      agb: absoluteLinks[lang].agb,
    });

    this.store.dispatch(
      GlobalAlertsActions.addAlert({
        alert: {
          dismissible: false,
          id: cookieIdList.agb,
          size: 'big',
          text: alertTextAgb,
          type: BannerType.Info,
          title: '',
        },
      }),
    );
  }

  public addPrivacyPolicyAlert(): void {
    const lang = this.languageService.getFallbackLanguage();

    const alertTextPrivacyPolicy = this.translate.instant('APP.PRIVACY_POLICY_CHANGE', {
      privacyUpdate: absoluteLinks[lang].privacyUpdate,
    });

    this.store.dispatch(
      GlobalAlertsActions.addAlert({
        alert: {
          dismissible: false,
          id: cookieIdList.privacyPolicy,
          size: 'big',
          text: alertTextPrivacyPolicy,
          type: BannerType.Info,
          title: '',
        },
      }),
    );
  }

  public shouldDisplayBlueBanner(key: string): boolean {
    // Check if Cookie exists
    if (this.cookieService.get(newCookieKey)) {
      // Convert Timestamp of cookie to unix time
      const cookieTimestamp = new Date(JSON.parse(this.cookieService.get(newCookieKey))['TIMESTAMP']).valueOf();
      // Change this date to future if banner should show up
      const cookieMaxAge = new Date(environment.lastPolicyUpdate).valueOf();
      if (cookieMaxAge < cookieTimestamp) {
        if (!JSON.parse(this.cookieService.get(newCookieKey))[key]) return true; // User has valid cookie but did not accept updated gtc

        return false;
      }
      // Outdated cookie: Display blue banner
      return true;
    }
    // Return false if cookie doesn't exist (new users dont get blue banner)
    return false;
  }

  public setNewCookie(): void {
    const expires = new Date();
    let cookiesToSet = {};
    // Check if Cookie exists
    if (this.cookieService.get(cookieKey)) {
      // Set new cookie with values from old one for new users
      for (let key of Object.values(cookieIdList))
        cookiesToSet[key] = JSON.parse(this.cookieService.get(cookieKey))[key] ?? false;

      // Delete old Cookie
      expires.setDate(expires.getDate() - 730);
      this.cookieService.set(cookieKey, '', expires, '/'); // Delete method doesn't work, set expired.
    }
    // Set cookie with value true for new users (so they don't get the blue banner)
    else {
      for (let key of Object.values(cookieIdList)) cookiesToSet[key] = true;
    }

    if (!this.cookieService.get(newCookieKey)) {
      cookiesToSet['TIMESTAMP'] = new Date();
      expires.setDate(expires.getDate() + 1460);
      this.cookieService.set(newCookieKey, JSON.stringify(cookiesToSet), expires, '/');
    }
  }

  public editCookie() {
    var agbChange: boolean;
    var privacyPolicyChange: boolean;
    var updateTimestamp: boolean;

    try {
      agbChange = JSON.parse(this.cookieService.get(newCookieKey))['NOTIFICATION_AGB_CHANGE'];
      privacyPolicyChange = JSON.parse(this.cookieService.get(newCookieKey))['NOTIFICATION_PRIVACY_POLICY_CHANGE'];
      updateTimestamp =
        new Date(JSON.parse(this.cookieService.get(newCookieKey))['TIMESTAMP']).valueOf() <
        new Date(environment.lastPolicyUpdate).valueOf();
    } catch {
      agbChange = false;
      privacyPolicyChange = false;
      updateTimestamp = true;
    }

    if (!agbChange || !privacyPolicyChange || updateTimestamp) {
      if (this.cookieService.get(newCookieKey)) {
        let cookiesToSet = {};
        for (let key of Object.values(cookieIdList)) cookiesToSet[key] = true;

        cookiesToSet['TIMESTAMP'] = updateTimestamp
          ? new Date()
          : JSON.parse(this.cookieService.get(newCookieKey))['TIMESTAMP'];
        const expires = new Date();
        expires.setDate(expires.getDate() + 730);
        this.cookieService.set(newCookieKey, JSON.stringify(cookiesToSet), expires, '/');
      }
    }
  }
}
