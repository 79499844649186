import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { IconKey } from './keys';

@Component({
  selector: 'nh-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input()
  key: IconKey;

  @Input()
  additionalClass: string = '';

  @Input()
  modifier = true;

  @Input()
  isAriaHidden = true;

  @Input()
  useLocalDefinition = true; // use false if icon is used inside a shadow root

  public className: string;
  public href: string;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.className = `icon`;

    if (this.modifier) {
      this.className += ` icon--${this.key}`;
    }

    if (this.additionalClass) {
      this.className += ` ${this.additionalClass}`;
    }

    if (this.useLocalDefinition) {
      this.href = `#${this.key}`;
    } else {
      this.href = `${environment.baseHost}/reslib/newhome/svgicons/symbol-defs.svg#${this.key}`;
    }

    this.cdRef.markForCheck();
  }
}
