import { LegalForm, OfferType, PropertySubType, SearchPropertyType, UserRole } from 'newhome.dtos';
import { PageType } from '../page-type';
import { CustomerRole } from './gtm.service';

export const pageTypeGtmMap = {
  [PageType.Landing]: 'Home',
  [PageType.Result]: 'Trefferliste',
  [PageType.Detail]: 'Detail',
  [PageType.DetailMedia]: 'Medien',
  [PageType.SearchSubscription]: 'Suchabos',
  [PageType.Watchlist]: 'Merklisten',
  [PageType.Messages]: 'Nachrichten',
  [PageType.MyNewhome]: 'Mein Newhome',
  [PageType.CustomerAccount]: 'Kunden Account',
  [PageType.FinancingData]: 'Finanzierungsdaten',
  [PageType.SearchSubscriptionResults]: 'Suchabo Trefferliste',
  [PageType.NotFound]: 'Not Found',
  [PageType.PropertyNotFound]: 'Property Not Found',
  [PageType.Wizard]: 'Wizard'
};

export const offerTypeGtmMap = {
  [OfferType.Buy]: 'Kauf',
  [OfferType.Rent]: 'Miete',
};

export const propertyTypeGtmMap = {
  [SearchPropertyType.All]: 'immobilien',
  [SearchPropertyType.Apartment]: 'wohnung',
  [SearchPropertyType.Apartmenthouse]: 'mehrfamilienhaus',
  [SearchPropertyType.Buildingland]: 'bauland',
  [SearchPropertyType.Business]: 'gewerbe',
  [SearchPropertyType.House]: 'haus',
  [SearchPropertyType.HouseApartment]: 'haus-wohnung',
  [SearchPropertyType.Parkingspace]: 'parkplatz',
};

export const propertySubTypeGtmMap = {
  [PropertySubType.AgriculturalBuilding]: 'landwirtschaftsobjekt',
  [PropertySubType.AgriculturalLand]: 'agrarland',
  [PropertySubType.Apartment]: 'wohnung',
  [PropertySubType.AtticApartment]: 'attikawohnung',
  [PropertySubType.BuildingLand]: 'wohnbauland',
  [PropertySubType.BuildingLandMixedZone]: 'gemischte-bauzone',
  [PropertySubType.Castle]: 'schloss',
  [PropertySubType.Chalet]: 'chalet',
  [PropertySubType.CommercialBuildingLand]: 'gewerbebauland',
  [PropertySubType.CommercialPremisis]: 'gewerberaeume',
  [PropertySubType.CommericalBuilding]: 'geschaeftshaus',
  [PropertySubType.CornerHouse]: 'eckhaus',
  [PropertySubType.CoveredParkingSpace]: 'gedeckter-parkplatz',
  [PropertySubType.DetachedHouse]: 'einfamilienhaus',
  [PropertySubType.DoubleApartmentBuilding]: 'doppeleinfamilienhaus',
  [PropertySubType.Farmhouse]: 'bauernhaus',
  [PropertySubType.FurnishedApartment]: 'moeblierte-wohnung',
  [PropertySubType.Garage]: 'garage',
  [PropertySubType.HolidayApartment]: 'ferienwohnung',
  [PropertySubType.HolidyHome]: 'ferienhaus',
  [PropertySubType.Hotel]: 'hotel',
  [PropertySubType.IndustrialBuildingLand]: 'industriebauland',
  [PropertySubType.Loft]: 'loft',
  [PropertySubType.MaisonetteApartment]: 'maisonettewohnung',
  [PropertySubType.ManufacturingRoom]: 'fabrikationsraeume',
  [PropertySubType.MiddleHouse]: 'mittelhaus',
  [PropertySubType.MotorcycleParkingSpace]: 'motorradplatz',
  [PropertySubType.Office]: 'buero',
  [PropertySubType.PenthouseApartment]: 'dachwohnung',
  [PropertySubType.Pratice]: 'praxis',
  [PropertySubType.ResidentialCommercialBuilding]: 'wohn-geschaeftshaus',
  [PropertySubType.Restaurant]: 'restaurant',
  [PropertySubType.RoomInHousingAssociation]: 'zimmer-in-wg',
  [PropertySubType.Rustic]: 'rustico',
  [PropertySubType.Salesroom]: 'verkaufsraeume',
  [PropertySubType.SteppedBuilding]: 'terrassenhaus',
  [PropertySubType.Studio]: 'atelier',
  [PropertySubType.StudioBedsit]: 'studio-zimmer',
  [PropertySubType.TerraceApartment]: 'terrassenwohnung',
  [PropertySubType.TerracedHouse]: 'reihenhaus',
  [PropertySubType.UncoveredParkingSpace]: 'ungedeckter-parkplatz',
  [PropertySubType.UndergroundGarage]: 'tiefgarage',
  [PropertySubType.Villa]: 'villa',
  [PropertySubType.Warehouse]: 'lager',
  [PropertySubType.WorkshopHobbyRoom]: 'werkstatt-hobby',
};

export const customerRoleGtmMap: { [key: number]: CustomerRole } = {
  [LegalForm.Private]: 'Privat',
  [LegalForm.Business]: 'Business',
};
