import { createAction, props } from '@ngrx/store';
import { SearchListingEntry } from 'newhome.dtos';

export const beginLoadEmarsysRecommendationsAdverts = createAction(
  '[EmarsysRecommendations] Begin Load EmarsysRecommendationsAdverts',
  props<{ immoCodes: number[] }>(),
);

export const loadEmarsysRecommendationsAdvertsSucces = createAction(
  '[EmarsysRecommendations] Load EmarsysRecommendationsAdverts Success',
  props<{ entires: SearchListingEntry[] }>(),
);

export const loadEmarsysRecommendationsAdvertsFailure = createAction(
  '[EmarsysRecommendations] Load EmarsysRecommendationsAdverts Failure',
  props<{ error: any }>(),
);
