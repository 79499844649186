import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { TravelDestination } from 'newhome.dtos';

export const loadTravelDestinations = createAction(
  '[TravelDestination/API] Load TravelDestinations',
  props<{ entries: TravelDestination[] }>(),
);

export const loadTravelDestinationsError = createAction(
  '[TravelDestination/API] Load TravelDestinations Failed',
  props<{ error: any }>(),
);

export const addTravelDestination = createAction(
  '[TravelDestination/API] Add TravelDestination',
  props<{ entry: TravelDestination }>(),
);

export const upsertTravelDestination = createAction(
  '[TravelDestination/API] Upsert TravelDestination',
  props<{ entry: TravelDestination }>(),
);

export const addTravelDestinations = createAction(
  '[TravelDestination/API] Add TravelDestinations',
  props<{ entries: TravelDestination[] }>(),
);

export const upsertTravelDestinations = createAction(
  '[TravelDestination/API] Upsert TravelDestinations',
  props<{ entries: TravelDestination[] }>(),
);

export const updateTravelDestination = createAction(
  '[TravelDestination/API] Update TravelDestination',
  props<{ entry: Update<TravelDestination> }>(),
);

export const updateTravelDestinations = createAction(
  '[TravelDestination/API] Update TravelDestinations',
  props<{ entries: Update<TravelDestination>[] }>(),
);

export const deleteTravelDestination = createAction(
  '[TravelDestination/API] Delete TravelDestination',
  props<{ id: number }>(),
);

export const deleteTravelDestinations = createAction(
  '[TravelDestination/API] Delete TravelDestinations',
  props<{ ids: number[] }>(),
);

export const removeLocalTravelDestinationEntries = createAction(
  '[TravelDestination/API] Remove Local TravelDestination Entries',
);

export const beginAddTravelDestination = createAction(
  '[TravelDestination/API] Begin Add TravelDestination Entry',
  props<{ entry: TravelDestination }>(),
);

export const beginDeleteTravelDestination = createAction(
  '[TravelDestination/API] Begin Delete TravelDestination Entry',
  props<{ id: number }>(),
);

export const deleteTravelDestinationFailed = createAction(
  '[TravelDestination/API] Delete TravelDestination Entry Failed',
);

export const addTravelDestinationFailed = createAction('[TravelDestination/API] Add TravelDestination Entry Failed');

export const beginLoadLocalTravelDestinations = createAction(
  '[TravelDestination/API] Begin Load Local TravelDestinations',
);

export const beginUpdateTravelDestination = createAction(
  '[TravelDestination/API] Begin Update TravelDestination Entry',
  props<{ entry: TravelDestination }>(),
);
