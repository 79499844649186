import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, Optional } from '@angular/core';
import { PillSize, defaultSize } from './sizing';
import { PillTheme, defaultTheme } from './theme';
import { NH_DIVIDED_PILL_WRAPPER } from './tokens';
import { DividedPillDirective } from './divided-pill/divided-pill.directive';

@Component({
  selector: 'nh-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PillComponent {
  @Input() icon: string;
  @Input() tabindex = 0;

  @Input()
  @HostBinding('attr.size')
  size: PillSize = defaultSize;

  @Input()
  @HostBinding('attr.theme')
  get theme(): PillTheme {
    return (
      this._theme ||
      this.dividedPillWrapper?.theme ||
      defaultTheme
    );
  };

  set theme(newTheme: PillTheme) {
    this._theme = newTheme;
  }

  private _theme: PillTheme;

  constructor(
    @Optional() @Inject(NH_DIVIDED_PILL_WRAPPER) private readonly dividedPillWrapper: DividedPillDirective,
  ) {}
}
