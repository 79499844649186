import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { langPattern } from 'app/language';
import { listingsRouteNames } from './route-names';

export const listingsMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  const languageIndex = 0;
  const advertiseIndex = 1;

  const language = segments[languageIndex]?.path;
  if (!language?.match(langPattern)) {
    return null;
  }

  const suffix = segments[advertiseIndex]?.path;
  if (listingsRouteNames[language] !== suffix) {
    return null;
  }

  const posParams = {
    lang: segments[languageIndex],
  };
  return {
    consumed: segments.slice(0, advertiseIndex + 1),
    posParams,
  };
};
