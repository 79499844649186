<div class="labeled-checkbox-container">
  <div class="content">
    <nh-checkbox [formControl]="control" [checkboxId]="checkboxId"></nh-checkbox>
    <label class="checkbox-label" [for]="checkboxId">
      <span><ng-content></ng-content></span>
      <span *ngIf="required"> *</span>
    </label>
  </div>
  <div class="error"
    *ngIf="control.invalid && control.touched">
    {{ errorMessage }}
  </div>
</div>
