import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/reducers';
import { environment } from 'environments/environment';
import { OfferType } from 'newhome.dtos';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { OAuthService } from '../oauth2-oidc/lib/public_api';
import { PageType } from '../page-type';
import { offerTypeMap } from '../seo/mappings';
import { ScriptService } from '../services/script/script.service';
import { getGtmScript } from '../services/script/script.store';
import { offerTypeGtmMap, pageTypeGtmMap } from './mapping';

export interface GoogleAnalyticsTag {
  event: GoogleAnalyticsEvent;
  gaEventCategory?: GoogleAnalyticsCategory;
  gaEventAction?: GoogleAnalyticsAction;
  gaEventLabel?: string;
  ['gtm.start']?: number;
  ['page.name']?: string;
  ['page.path']?: string;
  ['object.immocode']?: number;
  ['object.price.netto']?: number;
  ['object.rooms']?: number;
  ['object.plz']?: number;
  ['object.region']?: string;
  ['object.offer.type']?: string;
  ['object.type']?: string;
  ['object.subtype']?: string;
  ['object.origin']?: Origin;
  ['object.agency']?: number;
  ['object.mandantencode']?: string;
  ['object.customerrole']?: CustomerRole;
  ['object.form.financing-option']?: boolean;
  ['user.email']?: string;
  ['user.firstname']?: string;
  ['user.lastname']?: string;
  ['user.phonenumber']?: string;
  ['user.street']?: string;
  ['user.city']?: string;
  ['user.plz']?: string;
}

export type GoogleAnalyticsEvent = 'gaTriggerEvent' | 'gaIncrementGoalEvent' | 'gtm.js' | 'customAllPageView';
export type GoogleAnalyticsCategory = 'object' | 'search' | 'listing' | 'page' | 'user';
export type GoogleAnalyticsAction =
  | 'view'
  | 'object.favorite'
  | 'contact'
  | 'contact.send'
  | 'contact.called'
  | 'share'
  | 'object.shared'
  | 'favorite.add'
  | 'favorite.remove'
  | 'subscribe'
  | 'landing.view'
  | 'search-subscription.saved'
  | 'detail.show-more'
  | 'contact-phone.clicked'
  | 'contact-phone.showed'
  | 'visit-phone.clicked'
  | 'visit-phone.showed'
  | 'finance-link.clicked'
  | 'hypo-link.clicked'
  | 'hypo-calc.clicked'
  | 'result-radiusfilter.view'
  | 'result-travelfilter.view'
  | 'add'
  | 'registration.completed'
  | 'financing-advice-detail.called'
  | 'financing-advice-detail.send'
  | 'financing-advice-profile.called'
  | 'financing-advice-profile.send'
  | 'visit-link.clicked'
  | 'onlinecontact-link.clicked';

export type Origin = 'nh';

export type CustomerRole = 'Privat' | 'Business';

export interface CustomTag {
  ['page.language']?: string;
  ['page.name']?: string;
  ['page.path']?: string;
  ['page.type']?: string;
  ['page.environment']?: string;
  ['search.type']?: string;
  ['search.results']?: number;
  ['search.region']?: string;
  ['search.plz']?: string;
  ['search.offer.type']?: string;
  ['search.price.min']?: number;
  ['search.price.max']?: number;
  ['object.immocode']?: number;
  ['object.price.netto']?: number;
  ['object.rooms']?: number;
  ['object.plz']?: number;
  ['object.region']?: string;
  ['object.offer.type']?: string;
  ['object.type']?: string;
  ['object.subtype']?: string;
  ['object.origin']?: Origin;
  ['object.agency']?: number;
  ['object.mandantencode']?: string;
  ['object.customerrole']?: CustomerRole;
  ['object.form.financing-option']?: boolean;
  ['utm.source']?: string;
  ['utm.campaign']?: string;
  ['utm.medium']?: string;
  ['utm.term']?: string;
  ['utm.content']?: string;
  ['user.id']?: number;
  ['user.mandantencode']?: string;
  ['environment']?: string;
  ['search.array.top.immocode']?: number[];
  ['search.kanton']?: string;
  ['search.country']?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GtmService implements OnDestroy {
  private resolveEndedSubscription: Subscription;
  private navigationStartedSubscription: Subscription;
  private navigationEndedSubscription: Subscription;
  private userClaimsSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(PLATFORM_ID) private readonly platformId: Document,
    private readonly router: Router,
    private readonly store: Store<fromRoot.State>,
    private readonly oAuthService: OAuthService,
    private readonly scriptService: ScriptService,
  ) {}

  ngOnDestroy() {
    this.resolveEndedSubscription?.unsubscribe();
    this.navigationStartedSubscription?.unsubscribe();
    this.navigationEndedSubscription?.unsubscribe();
    this.userClaimsSubscription?.unsubscribe();
  }

  public init() {
    if (!environment.gtm.enabled) {
      return;
    }

    const gtmInitSubscription$ = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        this.setPagedata(this.router.routerState.snapshot);
        this.addGtmToDom();
        gtmInitSubscription$?.unsubscribe();
      });

    this.navigationStartedSubscription = this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        distinctUntilChanged((x: NavigationStart, y: NavigationStart) => x.url.split('?')[0] === y.url.split('?')[0]),
      )
      .subscribe(() => {
        this.pushOnDataLayer({
          navigationStartTime: Date.now(),
        });
        this.removeObjectDataLayerValues();
        this.removeSearchDataLayerValues();
      });

    this.navigationEndedSubscription = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        distinctUntilChanged((x: NavigationEnd, y: NavigationEnd) => x.url.split('?')[0] === y.url.split('?')[0]),
      )
      .subscribe(() => {
        this.pushGATag({
          event: 'customAllPageView',
        });
      });

    this.resolveEndedSubscription = this.router.events
      .pipe(
        filter(e => e instanceof ResolveEnd),
        map((e: ResolveEnd) => e.state),
        distinctUntilChanged(
          (x: RouterStateSnapshot, y: RouterStateSnapshot) => x.url.split('?')[0] === y.url.split('?')[0],
        ),
      )
      .subscribe(state => {
        this.setPagedata(state);
      });
  }

  public pushCustomTag(tag: CustomTag) {
    if (!environment.gtm.enabled) {
      return;
    }
    const dataLayer = this.getDataLayer();
    dataLayer.push(tag);
  }

  public pushGATag(tag: GoogleAnalyticsTag) {
    if (!environment.gtm.enabled) {
      return;
    }
    const dataLayer = this.getDataLayer();
    dataLayer.push(tag);
  }

  public pushCustomDimension(index: number, value: any) {
    const key = `dimension${index}`;
    this.pushOnDataLayer({ [key]: value });
  }

  private setPagedata(state: RouterStateSnapshot) {
    let mappedOfferType: string;

    if (!!state.root.queryParams.offerType) {
      mappedOfferType = offerTypeGtmMap[+state.root.queryParams.offerType];
    } else {
      const [language, offerTypeSegment] = state.url.split('/').filter(Boolean);
      if (language && offerTypeSegment) {
        const offerType = offerTypeMap[language][offerTypeSegment];
        mappedOfferType = offerTypeGtmMap[offerType];
      }
    }

    mappedOfferType = mappedOfferType ?? offerTypeGtmMap[OfferType.Rent];

    const pageType = this.getPageType(state);
    const statusCode = pageType === PageType.NotFound ? 404 : pageType === PageType.PropertyNotFound ? 410 : 200;

    this.userClaimsSubscription = this.oAuthService
      .isLoggedin()
      .pipe(map(isLoggedin => (isLoggedin ? this.oAuthService.getIdentityClaims() : undefined)))
      .subscribe((claims: any) => {
        let route = state.root;
        while (route.firstChild) {
          route = route.firstChild;
        }

        const path = new URL(this.documentRef.URL).origin + state.url.split('?')[0];
        const [language] = state.url.split('/').filter(Boolean);

        this.pushCustomTag({
          'page.language': route.params.lang || language,
          'page.path': path,
          'page.type': pageTypeGtmMap[pageType],
          'utm.source': route.queryParams.utm_source,
          'utm.campaign': route.queryParams.utm_campaign,
          'utm.medium': route.queryParams.utm_medium,
          'utm.term': route.queryParams.utm_term,
          'utm.content': route.queryParams.utm_content,
          'user.id': claims?.sub && +claims.sub,
          'user.mandantencode': claims?.mandant,
        });

        this.pushCustomDimension(1, claims?.mandant);
        this.pushCustomDimension(2, mappedOfferType);
        this.pushCustomDimension(3, pageTypeGtmMap[pageType]);
        this.pushCustomDimension(4, statusCode.toString());
        this.pushCustomDimension(5, claims?.sub && +claims.sub);
      });
  }

  private getPageType(routerState: RouterStateSnapshot) {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.data.pageType as PageType;
  }

  private addGtmToDom() {
    this.pushCustomTag({
      ['environment']: environment.gtm.gaEnvironment,
      ['page.environment']: environment.environmentName,
    });

    this.pushGATag({
      event: 'gtm.js',
      ['gtm.start']: new Date().getTime(),
    });

    this.scriptService.load(getGtmScript(environment.gtm.id, environment.gtm.auth, environment.gtm.preview));

    const ifrm = this.documentRef.createElement('iframe');
    ifrm.setAttribute(
      'src',
      `//www.googletagmanager.com/ns.html?id=${environment.gtm.id}&gtm_auth=${environment.gtm.auth}&gtm_preview=${environment.gtm.preview}`,
    );
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';

    const noscript = this.documentRef.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(ifrm);

    this.documentRef.body.insertBefore(noscript, this.documentRef.body.firstChild);
  }

  private pushOnDataLayer(obj: object) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private getDataLayer() {
    if (isPlatformBrowser(this.platformId)) {
      const windowRef = window as any;
      windowRef.dataLayer = windowRef.dataLayer || [];
      return windowRef.dataLayer;
    }

    return [];
  }

  private removeObjectDataLayerValues() {
    this.pushCustomTag({
      'object.agency': undefined,
      'object.immocode': undefined,
      'object.offer.type': undefined,
      'object.origin': undefined,
      'object.plz': undefined,
      'object.price.netto': undefined,
      'object.region': undefined,
      'object.rooms': undefined,
      'object.subtype': undefined,
      'object.type': undefined,
      'object.form.financing-option': undefined,
    });
  }

  private removeSearchDataLayerValues() {
    this.pushCustomTag({
      'search.type': undefined,
      'search.results': undefined,
      'search.region': undefined,
      'search.plz': undefined,
      'search.offer.type': undefined,
      'search.price.max': undefined,
      'search.price.min': undefined,
    });
  }
}
