import { createAction, props, union } from '@ngrx/store';

export const checkAdmeiraSupport = createAction('[Admeira] Check Support');
export const checkAdmeiraSupportSuccess = createAction(
  '[Admeira] Check Support Success',
  props<{ supported: boolean }>(),
);

const allActions = union({
  checkAdmeiraSupport,
  checkAdmeiraSupportSuccess,
});

export type AdmeiraActionUnion = typeof allActions;
