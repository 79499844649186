import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { BannerType } from '../banner/banner-type.enum';
import * as fromGlobalAlertActions from './global-alerts.actions';
import * as fromGlobalAlertReducer from './global-alerts.reducer';
import * as fromGlobalAlertSelectors from './global-alerts.selectors';
import { newCookieKey } from './global-alerts.reducer';
import { GlobalAlertsService } from './global-alerts.service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-global-alerts',
  templateUrl: './global-alerts.component.html',
  styleUrls: ['./global-alerts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAlertsComponent implements OnInit, OnDestroy {
  public alerts$: Observable<fromGlobalAlertReducer.GlobalAlert[]>;
  public alertType$: Observable<BannerType>;
  public alertSize$: Observable<string>;
  public alertDismissible$: Observable<boolean>;
  private navigationStartedSubscription: Subscription;

  public alertIds: string[];

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly store: Store<fromGlobalAlertReducer.State>,
    private readonly router: Router,
    readonly cookieService: CookieService,
    private readonly globalAlertsService: GlobalAlertsService,
  ) {
    this.alerts$ = store.select(fromGlobalAlertSelectors.selectAllAlerts);
    this.alertType$ = store.select(fromGlobalAlertSelectors.selectHighestAlertType);
    this.alertSize$ = store.select(fromGlobalAlertSelectors.selectBiggestAlertSize);
    this.alertDismissible$ = store.select(fromGlobalAlertSelectors.selectIfAlertDismissible);
  }

  ngOnInit(): void {
    this.globalAlertsService.editCookie();
    this.navigationStartedSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe(() => {
        this.closeAllAlerts();
      });
  }

  ngOnDestroy(): void {
    this.navigationStartedSubscription?.unsubscribe();
  }

  public closeAllAlerts() {
    this.store.dispatch(fromGlobalAlertActions.dismissAllAlerts());
  }

  public trackByAlert = (index, item: fromGlobalAlertReducer.GlobalAlert) => item?.id || null;
}
