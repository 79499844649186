import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as SettingsActions from './settings.actions';
import * as SettingsReducer from './settings.reducer';

const settingsKey = 'settings';

@Injectable()
export class SettingsEffects {
  updateListViewType$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingsActions.updateListViewType),
        map(a => {
          if (isPlatformBrowser(this.platformId)) {
            const replaceUrl = false;

            this.router.navigate([], {
              queryParams: {
                ...this.route.snapshot.queryParams,
                viewType: a.viewType,
              },
              replaceUrl,
            });
          }
        }),
      );
    },
    { dispatch: false },
  );

  updateSortOrder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingsActions.updateSortOrder),
        map(a => {
          if (isPlatformBrowser(this.platformId)) {
            const currentSettings = this.loadSettingsFromLocalStorage();
            currentSettings.orderType = a.orderType;

            this.storeSettingsInLocalStorage(currentSettings);
          }
        }),
      );
    },
    { dispatch: false },
  );

  // Initial effect needs to be registered at the end of a effect
  init$ = createEffect(() =>
    defer(() => {
      const settings = isPlatformBrowser(this.platformId)
        ? this.loadSettingsFromLocalStorage()
        : SettingsReducer.initialState;

      return [
        SettingsActions.setSettings({
          settings,
        }),
      ];
    }),
  );

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly actions$: Actions,
    private readonly router: Router,
    private route: ActivatedRoute,
  ) {}

  private loadSettingsFromLocalStorage(): SettingsReducer.State {
    if ('localStorage' in window){
      const settingsJson = localStorage?.getItem(settingsKey) || '{}';
      const settings = JSON.parse(settingsJson);

      return {
        ...SettingsReducer.initialState,
        ...settings,
      };
    }

    return SettingsReducer.initialState;
  }

  private storeSettingsInLocalStorage(settings: SettingsReducer.State) {
    localStorage.setItem(settingsKey, JSON.stringify(settings));
  }
}
