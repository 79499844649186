// https://github.com/ng-select/ng-select/blob/master/src/ng-option-highlight/lib/ng-option-highlight.directive.ts
import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[optionHighlight]',
})
export class OptionHighlightDirective implements OnChanges, AfterViewInit {
  @Input('optionHighlight') term: string;

  private element: HTMLElement;
  private label: string;

  constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly renderer: Renderer2) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnChanges() {
    if (this.canHighlight) {
      this.highlightLabel();
    }
  }

  ngAfterViewInit() {
    this.label = this.element.innerHTML;
    if (this.canHighlight) {
      this.highlightLabel();
    }
  }

  private escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private highlightLabel() {
    const label = this.label;
    if (!this.term) {
      this.setInnerHtml(label);
      return;
    }

    const alternationString = this.escapeRegExp(this.term).replace(' ', '|');
    const termRegex = new RegExp(alternationString, 'gi');
    this.setInnerHtml(label.replace(termRegex, `<span class=\"highlighted\">$&</span>`));
  }

  private get canHighlight() {
    return this.isDefined(this.term) && this.isDefined(this.label);
  }

  private setInnerHtml(html) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html);
  }

  private isDefined(value: any) {
    return value !== undefined && value !== null;
  }
}
