import { Action, createReducer, on } from '@ngrx/store';
import { SearchLocation } from 'newhome.dtos';
import * as SearchFormActions from './search-form.actions';

export const searchFormFeatureKey = 'searchForm';

export interface State {
  searchLocations: SearchLocation[];
}

export const initialState: State = {
  searchLocations: [],
};

const searchFormReducer = createReducer(
  initialState,

  on(SearchFormActions.clearSearchLocations, state => {
    return {
      ...state,
      searchLocations: [],
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return searchFormReducer(state, action);
}
