<div id="a-alerts" tabindex="-1" *ngIf="alerts$ | async as alerts">
  <p *ngIf="!alerts.length" class="visually-hidden">
    {{ 'SHARED.GLOBAL_ALERTS.NO_ALERTS' | translate }}
  </p>
  <app-banner-page-container class="fluid">
    <app-custom-banner
      *ngIf="alerts.length"
      [type]="alertType$ | async"
      [dismissible]="alertDismissible$ | async"
      [size]="alertSize$ | async"
      (closed)="closeAllAlerts()"
    >
      <p *ngFor="let alert of alerts; trackBy: trackByAlert" [innerHTML]="alert.text"></p>
    </app-custom-banner>
  </app-banner-page-container>
</div>
