import { Action, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CountryActions from './country.actions';
import { Country } from 'newhome.dtos';

export const countriesFeatureKey = 'countries';

export interface State extends EntityState<Country> {
  // additional entities state properties
}

export function selectCountryIdentifier(country: Country): string {
  return country.code;
}

export const adapter: EntityAdapter<Country> = createEntityAdapter<Country>({
  selectId: selectCountryIdentifier,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const countryReducer = createReducer(
  initialState,
  on(CountryActions.addCountry, (state, action) => adapter.addOne(action.country, state)),
  on(CountryActions.upsertCountry, (state, action) => adapter.upsertOne(action.country, state)),
  on(CountryActions.addCountrys, (state, action) => adapter.addMany(action.countrys, state)),
  on(CountryActions.upsertCountrys, (state, action) => adapter.upsertMany(action.countrys, state)),
  on(CountryActions.updateCountry, (state, action) => adapter.updateOne(action.country, state)),
  on(CountryActions.updateCountrys, (state, action) => adapter.updateMany(action.countrys, state)),
  on(CountryActions.deleteCountry, (state, action) => adapter.removeOne(action.id, state)),
  on(CountryActions.deleteCountrys, (state, action) => adapter.removeMany(action.ids, state)),
  on(CountryActions.loadCountrys, (state, action) => adapter.setAll(action.countrys, state)),
  on(CountryActions.clearCountrys, state => adapter.removeAll(state)),
);

export function reducer(state: State | undefined, action: Action) {
  return countryReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

const countryState = createFeatureSelector<State>(countriesFeatureKey);
export const selectAllCountries = createSelector(countryState, selectAll);
