import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { ButtonSize, defaultSize } from '@newhome/components/common/button';
import { ButtonTheme, defaultTheme } from '@newhome/components/common/button/theme';
import { ButtonToggleAlignment, defaultAlignment } from '../align';
import { ButtonToggleGroupComponent } from '../group/button-toggle-group.component';
import { NH_BUTTON_TOGGLE, NH_BUTTON_TOGGLE_GROUP } from '../tokens';

let uniqueId = 0;

@Component({
  selector: 'nh-button-toggle, [nhButtonToggle]',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NH_BUTTON_TOGGLE,
      useExisting: ButtonToggleComponent,
    },
  ]
})
export class ButtonToggleComponent implements OnInit, OnDestroy {
  /** Button toggle identifier */
  @Input() buttonToggleId = `button-toggle-${uniqueId++}`;

  /** Button toggle value */
  @Input() value: any;

  /** Whether the button is checked */
  @Input() get checked(): boolean {
    return this.buttonToggleGroup ? this.buttonToggleGroup._isSelected(this) : this._checked;
  }
  set checked(isChecked: boolean) {
    if (isChecked === this._checked) {
      return;
    }

    this._checked = isChecked;

    if (this.buttonToggleGroup) {
      this.buttonToggleGroup._syncButtonToggle(this, this._checked);
    }

    this.changeDetectorRef.markForCheck();
  }

  /** Whether the button is disabled */
  @Input() get disabled(): boolean {
    return this._disabled || (!!this.buttonToggleGroup && this.buttonToggleGroup.disabled);
  }
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  /** Event emits when the checked state changes */
  @Output() readonly change = new EventEmitter<boolean>();

  /** Button theme */
  @HostBinding('attr.theme')
  get theme(): ButtonTheme {
    return this.buttonToggleGroup?.theme || defaultTheme;
  }

  /** Button Size */
  @HostBinding('attr.size')
  get size(): ButtonSize {
    return this.buttonToggleGroup?.size || defaultSize;
  }

  /** How content inside toggle buttons is aligned */
  @HostBinding('attr.align')
  get alignment(): ButtonToggleAlignment {
    return this.buttonToggleGroup?.alignment || defaultAlignment;
  }

  get showCheckmark(): boolean {
    return this.buttonToggleGroup && this.buttonToggleGroup.showCheckmark;
  }

  private _checked = false;
  private _disabled = false;

  constructor(
    @Optional() @Inject(NH_BUTTON_TOGGLE_GROUP) private readonly buttonToggleGroup: ButtonToggleGroupComponent,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.buttonToggleGroup) {
      return;
    }

    if (this.buttonToggleGroup._isPreChecked(this)) {
      this.checked = true;
    } else if (this.buttonToggleGroup._isSelected(this) !== this._checked) {
      this.buttonToggleGroup._syncButtonToggle(this, this._checked);
    }
  }

  ngOnDestroy(): void {
    // Remove the toggle button from the group, once it's destroyed
    if (this.buttonToggleGroup?._isSelected(this)) {
      this.buttonToggleGroup._syncButtonToggle(this, false, false);
    }
  }

  onButtonClick(): void {
    if (this.checked || this.disabled) {
      return;
    }

    if (this.buttonToggleGroup) {
      this.buttonToggleGroup._syncButtonToggle(this, true, true);
      this.buttonToggleGroup._onTouchFn();
    }

    this.checked = true;
    this.change.emit(true);
  }

  _markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }
}
