import { ErrorHandler, Injectable } from '@angular/core';
import { createErrorHandler } from '@sentry/angular-ivy';
import { environment } from 'environments/environment';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private readonly sentryHandler = createErrorHandler({
    logErrors: environment.environmentName === 'dev',
  });

  handleError(error: unknown): void {
    if (environment.production) {
      this.sentryHandler.handleError(error);
    } else {
      console.error(error);
    }
  }
}
