import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

export const langPattern = /^(de|en|fr|it)$/;

export const languageKey = 'language';

@Injectable()
export class LanguageService {
  getFallbackLanguage() {
    let lang = this.cookies.get(languageKey);

    if (!lang) {
      lang = this.translate.defaultLang;
      const culture = this.translate.getBrowserCultureLang();
      if (culture) {
        const localLang = culture.split('-')[0];

        if (localLang.match(langPattern)) {
          lang = localLang;
        }
      }
    }

    if (lang && langPattern.test(lang)) {
      return lang;
    }

    return 'de';
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly translate: TranslateService,
    private readonly cookies: CookieService,
  ) {}
}
