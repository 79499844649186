import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, EventEmitter, HostBinding, Input, OnInit, Output, QueryList, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonSize } from '@newhome/components/common/button';
import { ButtonTheme } from '@newhome/components/common/button/theme';
import { ButtonToggleAlignment } from '../align';
import { ButtonToggleComponent } from '../button/button-toggle.component';
import { NH_BUTTON_TOGGLE_GROUP } from '../tokens';
import { ButtonToggleGroupOrientation, defaultOrientation } from './orientation';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'nh-button-toggle-group',
  templateUrl: 'button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  exportAs: 'nhButtonToggleGroup',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleGroupComponent),
      multi: true,
    },
    {
      provide: NH_BUTTON_TOGGLE_GROUP,
      useExisting: ButtonToggleGroupComponent,
    },
  ],
})
export class ButtonToggleGroupComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  /** Toggle button group orientation */
  @Input()
  @HostBinding('attr.orientation')
  orientation: ButtonToggleGroupOrientation = defaultOrientation;

  /** How content inside toggle buttons is aligned */
  @Input()
  alignment: ButtonToggleAlignment;

  /** Gap between toggle buttons */
  @Input()
  gap = '0px';

  /** Toggle button theme */
  @Input()
  theme: ButtonTheme;

  /** Size of the toggle buttons */
  @Input()
  size: ButtonSize;

  /** Whether to show checkmark on selected button */
  @Input() showCheckmark = false;

  /** Whether the toggle button group is disabled */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
    this.markButtonsForCheck();
  }

  /** Value of the toggle button group */
  @Input() get value(): any {
    const selected = this._selectionModel ? this._selectionModel.selected : [];
    return selected[0] ? selected[0].value : undefined;
  }
  set value(newValue: any) {
    this.setSelectionByValue(newValue);
    this.change.emit(this.value);
  }

  /** Event emits when the group value changes */
  @Output() readonly change = new EventEmitter<any>();

  /** Selected button toggle */
  get selected(): ButtonToggleComponent {
    const selected = this._selectionModel ? this._selectionModel.selected : [];
    return selected[0] || null;
  }

  @ContentChildren(forwardRef(() => ButtonToggleComponent), { descendants: true })
  private buttonToggles: QueryList<ButtonToggleComponent>;

  private _rawValue: any = undefined;
  private _disabled = false;
  private _selectionModel: SelectionModel<ButtonToggleComponent>;

  _onTouchFn = () => {};
  private _onChangeFn = (_: any) => {};

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<ButtonToggleComponent>(false, undefined, false);
  }

  ngAfterContentInit(): void {
    // Initially select buttons based on their checked state
    this._selectionModel.select(...this.buttonToggles.filter(toggle => toggle.checked));
  }

  writeValue(value: any): void {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (_: any) => unknown): void {
    this._onChangeFn = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this._onTouchFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _isSelected(button: ButtonToggleComponent): boolean {
    return this._selectionModel && this._selectionModel.isSelected(button);
  }

  _isPreChecked(button: ButtonToggleComponent): boolean {
    return this._rawValue !== undefined && this._rawValue === button.value;
  }

  _syncButtonToggle(button: ButtonToggleComponent, isChecked: boolean, doneByUser = false): void {
    // De-select when group value is out of sync
    if (this.selected && !button.checked) {
      this.selected.checked = false;
    }

    if (this._selectionModel) {
      if (isChecked) {
        this._selectionModel.select(button);
      }
      else {
        this._selectionModel.deselect(button);
      }
    }

    // Emit event when it's been performed by the user
    if (doneByUser) {
      this._rawValue = this.value;
      this._onChangeFn(this.value);
      this.change.emit(this.value);
    }
  }

  private setSelectionByValue(value: any): void {
    this._rawValue = value;

    if (!this.buttonToggles) {
      return;
    }

    this.clearSelection();

    const correspondingButton = this.buttonToggles.find(toggle =>
      toggle.value != null &&
      toggle.value === value
    );

    if (correspondingButton) {
      correspondingButton.checked = true;
      this._selectionModel.select(correspondingButton);
    }
  }

  private clearSelection(): void {
    this._selectionModel.clear();
    this.buttonToggles.forEach(toggle => toggle.checked = false);
  }

  private markButtonsForCheck(): void {
    this.buttonToggles?.forEach(toggle => toggle._markForCheck());
  }
}
