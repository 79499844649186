import { createAction, props } from '@ngrx/store';
import { SearchListingEntry, SearchLocation } from 'newhome.dtos';

export const beginLoadSearchLocations = createAction(
  '[Search Form] Begin Load Search Locations',
  props<{ keyword: string; urlDependent: boolean }>(),
);

export const loadSearchLocationsSuccess = createAction(
  '[Search Form] Load Search Locations Success',
  props<{ locations: SearchLocation[] }>(),
);

export const loadSearchLocationsFailure = createAction(
  '[Search Form] Load Search Locations Failure',
  props<{ error: any }>(),
);

export const clearSearchLocations = createAction('[Search Form] Clean Search Locations');

export const beginLookupImmoCode = createAction('[Search Form] Begin Lookup ImmoCode', props<{ immocode: number }>());

export const lookupImmoCodeSuccessful = createAction(
  '[Search Form] Lookup ImmoCode Successful',
  props<{ result: SearchListingEntry }>(),
);

export const lookupImmoCodeFailure = createAction('[Search Form] Lookup ImmoCode Failure');
