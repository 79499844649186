<section
  class="banner"
  [ngClass]="{
    'banner--danger': type === BannerType.Danger,
    'banner--warning': type === BannerType.Warning,
    'banner--info': type === BannerType.Info,
    'banner--success': type === BannerType.Success,
    'banner--cta': type === BannerType.Cta,
    'banner--small': size === 'small',
    'banner--medium': size === 'medium',
    'banner--big': size === 'big'
  }"
  role="alert"
>
  <div class="banner__wrapper">
    <h1 *ngIf="title" class="banner__heading" data-cy="banner-heading">
      {{ title }}
    </h1>
    <div class="banner__content" [class.alert__content--dismissable]="dismissible === true" data-cy="banner-content">
      <ng-content></ng-content>
    </div>
    <button
      *ngIf="dismissible"
      nhButton
      size="medium"
      theme="naked"
      class="banner__close"
      type="button"
      (click)="close()"
    >
      <span class="visually-hidden">{{ 'GENERAL.CLOSE' | translate }}</span>
      <nh-icon key="close"></nh-icon>
    </button>
  </div>
</section>
