import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchLocationModule } from './search-location/search-location.module';
import { CountryModule } from './country/country.module';
import { WatchlistModule } from './watchlist/watchlist.module';
import { TravelDestinationModule } from './travel-destination/travel-destination.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SearchLocationModule, CountryModule, WatchlistModule, TravelDestinationModule],
})
export class EntitiesModule {}
