import { isPlatformBrowser } from '@angular/common';
import { CreateSearchSubscriptionResponse } from 'newhome.dtos';

export const searchSubscriptionKey = 'search_subscriptions';

export const getLocalSearchSubscriptionGuids = (platformId: object): string[] => {
  if (isPlatformBrowser(platformId)) {
    let list: CreateSearchSubscriptionResponse[] = [];

    const item = localStorage.getItem(searchSubscriptionKey);
    if (item) {
      list = JSON.parse(item);
    }

    return list.map(x => x.guid);
  }
};

export const createSearchSubscriptionLocal = (
  searchSubscription: CreateSearchSubscriptionResponse,
  platformId: object,
) => {
  if (isPlatformBrowser(platformId)) {
    let list: CreateSearchSubscriptionResponse[] = [];
    const item = localStorage.getItem(searchSubscriptionKey);
    if (item) {
      list = JSON.parse(item);
    }

    list.push(searchSubscription);
    localStorage.setItem(searchSubscriptionKey, JSON.stringify(list));
  }
};
