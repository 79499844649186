<div class="form-field__label-wrapper" *ngIf="labelInstance">
  <label
    class="form-field__label"
    [class.disabled]="control?.disabled"
    [for]="control?.id || ''"
    (click)="_setFocusWithin()"
    #inputFieldLabel
  >
    <ng-content select="nh-label, [nhLabel]"></ng-content>
    <span aria-hidden="true" *ngIf="control?.required">&nbsp;*</span>
  </label>
</div>

<div
  class="form-field__input-wrapper"
  [class.invalid]="control?.hasErrors"
  [class.touched]="control?.touched"
  [class.disabled]="control?.disabled"
  [class.readonly]="control?.readonly"
  [class.bare]="isBare"
  (click)="_setFocusWithin()"
  #inputField
>
  <div class="form-field__grow-wrapper" *ngIf="textareaInstance; else inputTemplate">
    <ng-content select="textarea[nhInput]"></ng-content>
  </div>

  <ng-template #inputTemplate>
    <ng-content select="input[nhInput]"></ng-content>
  </ng-template>

  <div
    *ngIf="formattedValue && !isInputFocused"
    class="form-field__formatted-value"
    [attr.aria-controls]="control?.id || ''"
  >
    <ng-content select="[nhFormattedValue]"></ng-content>
  </div>

  <ng-content></ng-content>
</div>

<div *ngIf="control?.touched && control?.hasErrors" class="form-field__error-wrapper" data-testid="error-wrapper">
  <ng-content select="nh-error, [nhError]"></ng-content>
</div>
