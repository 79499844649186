import 'hammerjs';
import { Testability, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppBrowserModule } from 'app/app.browser.module';

if (environment.production) {
  enableProdMode();
}

document?.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .then(() => {
      /*
        Re-register the service worker config to enable the use of SwUpdate in Firefox.
        This only affects update-detection, not the service worker itself

        Source: https://stackoverflow.com/questions/50073753
      */
      if ('serviceWorker' in navigator && environment.production) {
        try {
          navigator.serviceWorker.register('/ngsw-worker.js');
        } catch {}
      }
    })
    .catch(err => console.log(err));
});

/**
 * Used by Cypress.waitForAngularIdle() (in commands.ts) to wait for all Pending request to finish
 */
function ngIsStable() {
  const test = (window as any).getAngularTestability(window.document.querySelector('body div')) as Testability; // you need to select an element managed by Angular. Usually any div in your html body

  return test.isStable();
}

window['ngIsStable'] = ngIsStable;
