export const isSameSiteNoneAvailable = (userAgent: string) => {
  if (!userAgent) {
    return true;
  }

  if (userAgent.includes('CPU iPhone OS 12') || userAgent.includes('iPad; CPU OS 12')) {
    return false;
  }

  if (
    userAgent.includes('Safari') &&
    userAgent.includes('Macintosh; Intel Mac OS X 10_14') &&
    userAgent.includes('Version/')
  ) {
    return false;
  }

  if (userAgent.includes('Chrome/5') || userAgent.includes('Chrome/6')) {
    return false;
  }

  return true;
};
