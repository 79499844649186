import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationType, SearchLocation } from 'newhome.dtos';

@Pipe({ name: 'location' })
export class LocationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(searchLocation: SearchLocation) {
    const displayName = searchLocation.displayName;

    if (searchLocation.postalcode && searchLocation.identifier.split(';')[0] === '2') {
      return `${searchLocation.postalcode} ${displayName}`;
    }

    if (searchLocation.locationType !== LocationType.City) {
      const locationType = this.translate.instant(`ENUM.LOCATIONTYPE.${LocationType[searchLocation.locationType]}`);

      return `${displayName} (${locationType})`;
    }

    return displayName;
  }
}
