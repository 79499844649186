import { Action, createReducer, on } from '@ngrx/store';
import { SearchListingEntry, SearchLocation, ListingDetail } from 'newhome.dtos';
import * as SearchInputActions from './search-input.actions';

export const searchInputFeatureKey = 'searchInput';

export interface State {
  searchLocations: SearchLocation[];
  immoCodeLookupResponse: SearchListingEntry;
  isLoadingSearchLocations: boolean;
  isLoadingImmoCode: boolean;
}

export const initialState: State = {
  searchLocations: [],
  isLoadingSearchLocations: false,
  immoCodeLookupResponse: undefined,
  isLoadingImmoCode: false,
};

const searchInputReducer = createReducer(
  initialState,

  on(SearchInputActions.beginLoadSearchLocations, state => {
    return {
      ...state,
      isLoadingSearchLocations: true,
      immoCodeLookupResponse: undefined,
    };
  }),

  on(SearchInputActions.loadSearchLocationsSuccess, (state, action) => {
    return {
      ...state,
      searchLocations: action.locations,
      isLoadingSearchLocations: false,
    };
  }),

  on(SearchInputActions.loadSearchLocationsFailure, state => {
    return {
      ...state,
      isLoadingSearchLocations: false,
    };
  }),

  on(SearchInputActions.lookupImmoCodeSuccessful, (state, action) => {
    return {
      ...state,
      isLoadingImmoCode: false,
      immoCodeLookupResponse: action.result,
    };
  }),

  on(SearchInputActions.beginLookupImmoCode, state => {
    return {
      ...state,
      isLoadingImmoCode: true,
    };
  }),

  on(SearchInputActions.lookupImmoCodeFailure, state => {
    return {
      ...state,
      isLoadingImmoCode: false,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return searchInputReducer(state, action);
}
