// From https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Script } from './script.store';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private scripts: {
    [name: string]: {
      loaded: boolean;
      src: string;
    };
  } = {};

  head: Element;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.head = this.document.getElementsByTagName('head')[0];
  }

  public load(script: Script) {
    if (!this.scripts[script.id]) {
      this.scripts[script.id] = { loaded: false, src: script.src };
    } else if (script.src !== this.scripts[script.id].src) {
      this.scripts[script.id].loaded = false;
    }

    return this.loadScript(script);
  }

  private loadScript(script: Script) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[script.id]?.loaded) {
        resolve({ script: script.id, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const scriptNode: any = this.document.createElement('script');
        scriptNode.type = 'text/javascript';
        scriptNode.src = this.scripts[script.id].src;
        scriptNode.id = script.id;
        if (scriptNode.readyState) {
          // IE
          scriptNode.onreadystatechange = () => {
            if (scriptNode.readyState === 'loaded' || scriptNode.readyState === 'complete') {
              scriptNode.onreadystatechange = null;
              this.scripts[script.id].loaded = true;
              resolve({ script: script.id, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          scriptNode.onload = () => {
            this.scripts[script.id].loaded = true;
            resolve({ script: script.id, loaded: true, status: 'Loaded' });
          };
        }
        scriptNode.onerror = (error: any) => resolve({ script: script.id, loaded: false, status: 'Loaded' });

        const existingSript = this.document.getElementById(script.id);
        if (existingSript) {
          this.head.removeChild(existingSript);
        }
        this.head.appendChild(scriptNode);
      }
    });
  }

  private applyArgs(src: string, args: { [key: string]: string }) {
    for (const arg of Object.entries(args)) {
      if (arg) {
        src = src.replace(`$${arg[0]}$`, arg[1]);
      }
    }

    return src;
  }
}
