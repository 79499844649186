import { createAction, props } from '@ngrx/store';
import { ObjectsViewType } from 'app/shared/objects/objects-view-type.enum';
import { AdvertOrderType } from 'newhome.dtos';

import * as SettingsReducer from './settings.reducer';

export const setSettings = createAction('[Settings] Set Settings', props<{ settings: SettingsReducer.State }>());
export const updateSortOrder = createAction('[Settings] Update Sort Order', props<{ orderType: AdvertOrderType }>());
export const updateListViewType = createAction(
  '[Settings] Update List View Type',
  props<{ viewType: ObjectsViewType }>(),
);
