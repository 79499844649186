import * as ProviderResultActions from './provider-result.actions';
import { ProviderAdvertsResponse } from 'newhome.dtos';
import { Action, createReducer, on } from '@ngrx/store';

export const providerResultsFeatureKey = 'providerResults';

export interface State {
  // additional entities state properties
  providerResponse: ProviderAdvertsResponse;
  isLoading: boolean;
  hasError: boolean;
}

export const initialState: State = {
  providerResponse: {
    entries: [],
    coordinates: [],
    skipCount: 0,
    totalResultCount: 0,
    provider: null,
  },
  isLoading: false,
  hasError: false,
};

const providerResultReducer = createReducer(
  initialState,

  on(ProviderResultActions.beginLoadProviderResults, state => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  on(ProviderResultActions.loadProviderResultsSuccess, (state, action) => {
    return {
      ...state,
      providerResponse: action.response,
      isLoading: false,
      hasError: false,
    };
  }),

  on(ProviderResultActions.loadProviderResultsFailure, state => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
    };
  }),
);

export const reducer = (state: State | undefined, action: Action) => providerResultReducer(state, action);
