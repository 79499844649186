import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthLogger, OAuthStorage } from './types';

import { OAuthService } from './oauth-service';
import { UrlHelperService } from './url-helper.service';

import { BrowserOAuthService } from './browser/browser-oauth.service';
import { createDefaultLogger, createDefaultStorage } from './factories';
import { DefaultOAuthInterceptor } from './interceptors/default-oauth.interceptor';
import {
  OAuthNoopResourceServerErrorHandler, OAuthResourceServerErrorHandler
} from './interceptors/resource-server-error-handler';
import { OAuthModuleConfig } from './oauth-module.config';
import { NoopOAuthService } from './server/server-oauth.service';
import { DefaultHashHandler, HashHandler } from './token-validation/hash-handler';
import { NullValidationHandler } from './token-validation/null-validation-handler';
import { ValidationHandler } from './token-validation/validation-handler';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class OAuthModule {
  // OAuth providers for the browser
  static forRootBrowser(
    config: OAuthModuleConfig = null,
    validationHandlerClass = NullValidationHandler,
  ): ModuleWithProviders<OAuthModule> {
    return {
      ngModule: OAuthModule,
      providers: [
        { provide: OAuthService, useClass: BrowserOAuthService },
        UrlHelperService,
        { provide: OAuthLogger, useFactory: createDefaultLogger },
        { provide: OAuthStorage, useFactory: createDefaultStorage },
        { provide: ValidationHandler, useClass: validationHandlerClass },
        { provide: HashHandler, useClass: DefaultHashHandler },
        {
          provide: OAuthResourceServerErrorHandler,
          useClass: OAuthNoopResourceServerErrorHandler,
        },
        { provide: OAuthModuleConfig, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DefaultOAuthInterceptor,
          multi: true,
        },
      ],
    };
  }

  // Noop providers for SSR
  static forRootServer(): ModuleWithProviders<OAuthModule> {
    return {
      ngModule: OAuthModule,
      providers: [
        { provide: OAuthService, useClass: NoopOAuthService },
      ]
    };
  }
}
