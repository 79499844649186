import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListingDetail, PriceType } from 'newhome.dtos';
import { PriceUnitPipe } from '../pipes/text-pipe/price-unit/price-unit.pipe';

const formatPrice = (price: number) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(private translate: TranslateService) {}

  public getPriceString(detail: ListingDetail) {
    if (!detail.price.priceNegotiable) {
      if (detail.price.priceType === PriceType.PriceOnRequest) {
        return undefined;
      }

      let price = formatPrice(detail.price.price);

      if (detail.price.priceType === PriceType.PricePerM2 || detail.price.priceType === PriceType.PricePerM2PerYear) {
        price = formatPrice(detail.price.priceM2);
      }

      const priceUnit = new PriceUnitPipe(this.translate).transform(detail.price.priceType);

      return `${price} ${priceUnit}`;
    }
  }
}
