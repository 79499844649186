import {
  ListingDetail,
  SearchPropertyType,
  OfferType,
  PropertyType,
  PropertySubType,
  SearchLocation,
} from 'newhome.dtos';
import { resolveOfferType, resolvePropertySubType, resolvePropertyType } from '../map.resolver';
import { detailPrefixMap, detailSuffixMap, mediaMap, roomKeywordMap } from '../mappings';
import { buildSearchLocationSeoString } from './search-location';
import { buildUrl } from './shared';

const buildRoomsSeoString = (detail: ListingDetail, language: string): string => {
  return detail.detail.rooms ? `${detail.detail.rooms.toFixed(1)}-${roomKeywordMap[language]}` : '';
};

export const buildDetailRouteUrl = (language: string, detail: ListingDetail): string => {
  const { offerType, detailPrefix, detailSuffix } = {
    offerType: resolveOfferType(language, detail.offerType),
    detailPrefix: detailPrefixMap[language],
    detailSuffix: detailSuffixMap[language],
  };

  const propertyType = resolvePropertyType(language, detail.propertyType || SearchPropertyType.All);

  const propertySubType = detail.detail.propertySubType
    ? resolvePropertySubType(language, detail.detail.propertySubType)
    : undefined;

  const roomString = buildRoomsSeoString(detail, language);
  const location = buildSearchLocationSeoString(language, detail.location);

  return buildUrl([
    offerType,
    detailPrefix,
    propertyType,
    propertySubType,
    location,
    roomString,
    detailSuffix,
    detail.immocode,
  ]);
};

export const buildDetailMediaRouteUrl = (language: string, detail: ListingDetail): string => {
  const detailUrl = buildDetailRouteUrl(language, detail).split('/');

  const media = mediaMap[language];

  return buildUrl([...detailUrl, media]);
};
