import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'app/reducers';
import * as RouterSelectors from 'app/router.selectors';
import { notFoundMap } from 'app/shared/seo/mappings';
import { map } from 'rxjs/operators';
import { LanguageService } from 'app/language';

@Injectable()
export class NotFoundGuard  {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private readonly language: LanguageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(RouterSelectors.selectRouter).pipe(
      map(x => x.state.url),
      map(url => {
        const patternLang = new RegExp('^/((de)|(en)|(it)|(fr))(\\/|\\?|$)');
        const resultLang = patternLang.exec(url);
        if (resultLang) {
          return resultLang[1];
        } else {
          return this.language.getFallbackLanguage();
        }
      }),
      map(lang => {
        return this.router.createUrlTree(['/', lang, notFoundMap[lang]]);
      }),
    );
  }
}
