import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTravelDestination from './travel-destination.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TravelDestinationEffects } from './travel-destination.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTravelDestination.travelDestinationsFeatureKey, fromTravelDestination.reducer),
    EffectsModule.forFeature([TravelDestinationEffects]),
  ],
})
export class TravelDestinationModule {}
