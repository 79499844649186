import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { WatchlistEntry } from './watchlist.reducer';

export const loadWatchlistEntries = createAction(
  '[Watchlist/API] Load Watchlist',
  props<{ entries: WatchlistEntry[] }>(),
);

export const loadWatchlistEntriesError = createAction(
  '[Watchlist/API] Load Watchlist Entries Failed',
  props<{ error: any }>(),
);

export const addWatchlistEntry = createAction(
  '[Watchlist/API] Add Watchlist Entry',
  props<{ entry: WatchlistEntry }>(),
);

export const upsertWatchlistEntry = createAction(
  '[Watchlist/API] Upsert Watchlist',
  props<{ entry: WatchlistEntry }>(),
);

export const addWatchlistEntries = createAction(
  '[Watchlist/API] Add Watchlist Entries',
  props<{ entries: WatchlistEntry[] }>(),
);

export const upsertWatchlistEntries = createAction(
  '[Watchlist/API] Upsert Watchlist Entries',
  props<{ entries: WatchlistEntry[] }>(),
);

export const updateWatchlistEntry = createAction(
  '[Watchlist/API] Update Watchlist Entry',
  props<{ entry: Update<WatchlistEntry> }>(),
);

export const updateWatchlistEntries = createAction(
  '[Watchlist/API] Update Watchlist Entries',
  props<{ entries: Update<WatchlistEntry>[] }>(),
);

export const deleteWatchlistEntry = createAction('[Watchlist/API] Delete Watchlist Entry', props<{ id: number }>());

export const deleteWatchlistEntries = createAction(
  '[Watchlist/API] Delete Watchlist Entries',
  props<{ ids: number[] }>(),
);

export const addWatchlistEntryFailed = createAction('[Watchlist/API] Add Watchlist Entry Failed');

export const removeLocalWatchlistEntries = createAction('[Watchlist/API] Remove Local Watchlist Entries');

export const beginAddWatchlistEntry = createAction(
  '[Watchlist/API] Begin Add Watchlist Entry',
  props<{ entry: WatchlistEntry }>(),
);

export const beginDeleteWatchlistEntry = createAction(
  '[Watchlist/API] Begin Delete Watchlist Entry',
  props<{ id: number }>(),
);

export const deleteWatchlistEntryFailed = createAction('[Watchlist/API] Delete Watchlist Entry Failed');

export const deleteExpiredWatchlistEntry = createAction(
  '[Watchlist/API] Delete Expired Watchlist Entry',
  props<{ id: number }>(),
);

export const beginLoadWatchlistEntries = createAction('[Watchlist/API] Begin Load Watchlist Entries');
