
// Sentry list of deny urls.
export const SENTRY_DENY_URLS = [
      // Google Adsense
      '/pagead\/js/i',
      '/googleads4\.g\.doubleclick\.net/all',
      // scarabresearch
      '/recommender\.scarabresearch\.com/all',
      // targamo
      '/maps\.targomo\.com/all',
      // doubleclick
      '/stats\.g\.doubleclick\.net/all',
      // ringierch opecloud
      '/ringierch\.profiles\.tagger\.opecloud\.com/all',
      // zenloop
      '/api\.zenloop\.com/all',
      // Facebook flakiness
      '/graph\.facebook\.com/i',
      // Facebook blocked
      '/connect\.facebook\.net\/en_US\/all\.js/i',
      // Woopra flakiness
      '/eatdifferent\.com\.woopra-ns\.com/i',
      '/static\.woopra\.com\/js\/woopra\.js/i',
      // Chrome extensions
      '/extensions\//i',
      '/^chrome:\/\//i',
      // Other plugins
      '/127\.0\.0\.1:4001\/isrunning/i',  // Cacaoweb
      '/webappstoolbarba\.texthelp\.com\//i',
      '/metrics\.itunes\.apple\.com\.edgesuite\.net\//i'
];
