import { Action, createReducer, on } from '@ngrx/store';
import { SearchListingResponse, SearchLocation } from 'newhome.dtos';
import * as DiscoverActions from './discover.actions';

export const discoverFeatureKey = 'discover';

export interface State {
  searchResponse: SearchListingResponse;
  isLoadingPropertiesCount: boolean;
  propertiesCount: number;
  isLoadingSearchResults: boolean;
  searchLocations: SearchLocation[];
  isLoadingSearchLocations: boolean;
}

export const initialState: State = {
  propertiesCount: 0,
  isLoadingPropertiesCount: false,
  searchResponse: {
    entries: [],
    radiusCenter: { latitude: null, longitude: null },
    skipCount: 0,
    cantonCodes: [],
    countryCodes: [],
    totalResultCount: 0,
    coordinates: [],
    specialPromotions: [],
    moreEntries: [],
    faqInformation: null,
    contentBlockMoreListings: null,
    seoInformation: null,
  },
  isLoadingSearchResults: false,
  searchLocations: [],
  isLoadingSearchLocations: false,
};

const discoverReducer = createReducer(
  initialState,

  on(DiscoverActions.beginLoadPropertiesCount, state => {
    return {
      ...state,
      isLoadingPropertiesCount: true,
    };
  }),

  on(DiscoverActions.loadPropertiesCountSuccessful, (state, action) => {
    return {
      ...state,
      isLoadingPropertiesCount: false,
      propertiesCount: action.count,
    };
  }),

  on(DiscoverActions.loadPropertiesCountFailure, state => {
    return {
      ...state,
      isLoadingPropertiesCount: false,
      propertiesCount: 0,
    };
  }),

  on(DiscoverActions.loadPropertiesFailure, state => {
    return {
      ...state,
      isLoadingPropertiesCount: false,
    };
  }),

  on(DiscoverActions.loadDiscovers, state => state),
  on(DiscoverActions.loadDiscoversSuccess, state => state),
  on(DiscoverActions.loadDiscoversFailure, state => state),

  on(DiscoverActions.beginLoadProperties, state => {
    return {
      ...state,
      isLoadingSearchResults: true,
    };
  }),

  on(DiscoverActions.loadPropertiesFailure, state => {
    return {
      ...state,
      isLoadingSearchResults: false,
    };
  }),

  on(DiscoverActions.loadPropertiesSuccessful, (state, action) => {
    return {
      ...state,
      isLoadingSearchResults: false,
      searchResponse: action.response,
      propertiesCount: action.response.totalResultCount,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return discoverReducer(state, action);
}
