import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: 'textarea[nhInput]' })
export class TextareaDirective {
  constructor(public readonly elementRef: ElementRef<HTMLTextAreaElement>) {}

  @HostListener('input')
  onInput(): void {
    // https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    const element = this.elementRef.nativeElement;
    const parent = element.parentNode as HTMLElement;

    if (parent) {
      parent.dataset.replicatedValue = element.value;
    }
  }
}
