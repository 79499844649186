import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteComponent } from './autocomplete.component';
import { IconModule } from '@newhome/components/icon';

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [CommonModule, ReactiveFormsModule, IconModule],
  exports: [AutocompleteComponent]
})
export class AutocompleteModule { }
