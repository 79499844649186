import { buildSearchLocationSeoString } from 'app/shared/seo/builder/search-location';
import { buildUrl } from 'app/shared/seo/builder/shared';
import { resolveOfferType, resolvePropertySubType, resolvePropertyType } from 'app/shared/seo/map.resolver';
import { customPerimeterMap, listPrefixMap, listSuffixMap } from 'app/shared/seo/mappings';
import { SearchListingRequest, SearchLocation, SearchPropertyType } from 'newhome.dtos';

export const buildResultsRouteUrl = (
  language: string,
  filter: Partial<SearchListingRequest>,
  locations: SearchLocation[],
): string => {
  const { offerType, wildcard, listSuffix, customPerimeter } = {
    offerType: resolveOfferType(language, filter.offerType) as string,
    wildcard: listPrefixMap[language],
    listSuffix: listSuffixMap[language],
    customPerimeter: customPerimeterMap[language],
  };

  const propertyType = resolvePropertyType(language, filter.propertyType || SearchPropertyType.All) as string;

  let searchLocation = locations?.filter(loc => filter.location?.length && filter.location[0] === loc.identifier);

  if (!searchLocation || !searchLocation.length) {
    searchLocation = locations?.filter(loc => loc.displayName);
  }

  // if there is a radius coordinate, we need to add it to the url and skip the location steps
  if (filter.radiusCenterCoordinate) {
    return buildUrl([offerType, wildcard, propertyType, customPerimeter, listSuffix]);
  }

  const propertySubType =
    filter.propertySubtypes &&
    filter.propertySubtypes.length === 1 &&
    ((resolvePropertySubType(language, filter.propertySubtypes[0]) || '') as string);

  if (
    (!searchLocation || !searchLocation.length) &&
    (!filter.travelDestinations || !filter.travelDestinations.length)
  ) {
    return buildSeoLandingPageLink(offerType, propertyType, propertySubType);
  }

  if (filter.travelDestinations) {
    return buildUrl([offerType, wildcard, propertyType, listSuffix]);
  }

  const locationIdentifier = searchLocation[0];

  const location =
    locationIdentifier?.displayName && locationIdentifier?.locationType
      ? buildSearchLocationSeoString(language, locationIdentifier)
      : '';

  if (!location) {
    return buildSeoLandingPageLink(offerType, propertyType, propertySubType);
  }

  return buildUrl([offerType, wildcard, propertyType, propertySubType, location, listSuffix]);
};

function buildSeoLandingPageLink(offerType: string, propertyType: string, propertySubType: string): string {
  return buildUrl([offerType, propertySubType || propertyType] as string[]);
}
