import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class ComponentsGuard  {
  canActivate(): boolean {
    return this.canLoad();
  }

  canLoad(): boolean {
    return environment.componentsPageEnabled;
  }
}
