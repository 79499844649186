import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { staticRoutes } from 'app/discover/static-routes';
import {
  IAdvertSearchFilter,
  ListingDetail,
  OfferType,
  PropertySubType,
  PropertyType,
  SearchListingRequest,
  SearchLocation,
  SearchPropertyType,
} from 'newhome.dtos';
import { buildDetailMediaRouteUrl, buildDetailRouteUrl } from './builder/detail';
import { buildResultsRouteUrl } from './builder/search-listing';
import { offerTypeMap, propertySubTypeMap, propertyTypeMap } from './mappings';

const alternateLangMap = {
  ['de']: ['de-ch', 'x-default'],
  ['en']: ['en-ch'],
  ['fr']: ['fr-ch'],
  ['it']: ['it-ch'],
};

export type relType = 'canonical' | 'alternate';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom, private translate: TranslateService, private router: Router) {}

  public setLandingCanonical(searchFilter: IAdvertSearchFilter) {
    let url = this.getLandingCanonicalUrl(this.translate.currentLang, searchFilter);
    url = this.toAbsolutUrl(url);

    this.setCanonical(url);
  }

  public setLandingAlternates(searchFilter: IAdvertSearchFilter) {
    const getUrl = (lang: string) => this.getLandingCanonicalUrl(lang, searchFilter);

    this.setAlternates(getUrl);
  }

  public setResultCanonical(searchFilter: SearchListingRequest, searchLocations: SearchLocation[]) {
    searchFilter = this.applyFilterTransforms(searchFilter);

    let url = this.getResultCanonicalUrl(this.translate.currentLang, searchFilter, searchLocations);
    url = this.toAbsolutUrl(url);

    this.setCanonical(url);
  }

  public setResultAlternates(searchFilter: SearchListingRequest, searchLocations: SearchLocation[]) {
    const getUrl = (lang: string) => this.getResultCanonicalUrl(lang, searchFilter, searchLocations);

    this.setAlternates(getUrl);
  }

  public setDetailCanonical(detail: ListingDetail) {
    let url = this.getDetailCanonicalUrl(this.translate.currentLang, detail);
    url = this.toAbsolutUrl(url);

    this.setCanonical(url);
  }

  public setDetailAlternates(detail: ListingDetail) {
    const getUrl = (lang: string) => this.getDetailCanonicalUrl(lang, detail);

    this.setAlternates(getUrl);
  }

  public setDetailMediaCanonical(detail: ListingDetail) {
    let url = this.getDetailMediaCanonicalUrl(this.translate.currentLang, detail);
    url = this.toAbsolutUrl(url);

    this.setCanonical(url);
  }

  public setDetailMediaAlternates(detail: ListingDetail) {
    const getUrl = (lang: string) => this.getDetailMediaCanonicalUrl(lang, detail);

    this.setAlternates(getUrl);
  }

  public removeMetaLinks(rel: relType) {
    const metaLinks = this.dom.head.querySelectorAll(`link[rel=${rel}]`);

    for (const metaLink of metaLinks) {
      this.dom.head.removeChild(metaLink);
    }
  }

  private setCanonical(url: string) {
    const link = this.createLinkElement(url, 'canonical');

    this.removeMetaLinks('canonical');

    this.dom.head.appendChild(link);
  }

  private setAlternates(getUrl: (lang: string) => string) {
    this.removeMetaLinks('alternate');

    for (const alternateLangMapEntry of Object.entries(alternateLangMap)) {
      let url = getUrl(alternateLangMapEntry[0]);
      url = this.toAbsolutUrl(url);
      for (const hreflang of alternateLangMapEntry[1]) {
        const link = this.createLinkElement(url, 'alternate', hreflang);
        this.dom.head.appendChild(link);
      }
    }
  }

  private createLinkElement(url: string, rel: relType, hreflang?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', rel);
    link.setAttribute('href', url);

    if (hreflang) {
      link.setAttribute('hreflang', hreflang);
    }

    return link;
  }

  private toAbsolutUrl(relativUrl: string) {
    const url = new URL(this.dom.URL);
    url.protocol = 'https';

    if (!relativUrl.startsWith('/')) {
      relativUrl = `/${relativUrl}`;
    }

    return `${url.origin}${relativUrl}`;
  }

  private getLandingCanonicalUrl(lang: string, searchFilter: IAdvertSearchFilter) {
    if (searchFilter.offerType) {
      const offerTypeRoute = offerTypeMap[lang][searchFilter.offerType];
      if (searchFilter.propertySubtypes && searchFilter.propertySubtypes.length === 1) {
        const propertyTypeRoute = propertySubTypeMap[lang][searchFilter.propertySubtypes[0]];

        return `${lang}/${offerTypeRoute}/${propertyTypeRoute}`;
      } else if (searchFilter.propertyType) {
        const propertyTypeRoute = propertyTypeMap[lang][searchFilter.propertyType];

        return `${lang}/${offerTypeRoute}/${propertyTypeRoute}`;
      }
    }

    return `${lang}/${staticRoutes.home}`;
  }

  private getResultCanonicalUrl(lang: string, searchFilter: SearchListingRequest, searchLocations: SearchLocation[]) {
    const seoUrl = buildResultsRouteUrl(lang, searchFilter, searchLocations).split('/');

    let queryParams = {};

    if (searchFilter.skipCount) {
      queryParams = {
        ...queryParams,
        skipCount: searchFilter.skipCount,
      };
    }

    return this.router.createUrlTree([lang, ...seoUrl], { queryParams }).toString();
  }

  private getDetailCanonicalUrl(lang: string, detail: ListingDetail) {
    const seoUrl = buildDetailRouteUrl(lang, detail).split('/');

    return this.router.createUrlTree([lang, ...seoUrl]).toString();
  }

  private getDetailMediaCanonicalUrl(lang: string, detail: ListingDetail) {
    const seoUrl = buildDetailMediaRouteUrl(lang, detail).split('/');

    return this.router.createUrlTree([lang, ...seoUrl]).toString();
  }

  private applyFilterTransforms(filter: SearchListingRequest): SearchListingRequest {
    // Remove Apartment filter and tag un-filtered page as canonical
    if (this.isFilteredByApartment(filter)) {
      const propertySubtypes = filter.propertySubtypes.filter(s => s !== PropertySubType.Apartment);
      return new SearchListingRequest({
        ...filter,
        propertySubtypes,
      });
    }

    return filter;
  }

  private isFilteredByApartment(filter: SearchListingRequest): boolean {
    const propertyTypesWithApartments = [SearchPropertyType.Apartment, SearchPropertyType.HouseApartment];

    return (
      filter.offerType === OfferType.Rent &&
      propertyTypesWithApartments.includes(filter.propertyType) &&
      filter.propertySubtypes?.includes(PropertySubType.Apartment)
    );
  }
}
