import { Params } from '@angular/router';
import {
  AdvertSearchFilter,
  IAdvertSearchFilter,
  IPagingSorting,
  AdvertOrderType,
  GeograhyCoordinate,
} from 'newhome.dtos';

export class FilterSeparation {
  filter: Partial<IAdvertSearchFilter>;
  pagingSorting: Partial<IPagingSorting>;
  other: any;
}

/**
 * Separates given queryparameters into a valid filterRequest object as well as a collection of leftovers (which we should handle as any other queryparameters)
 * Make sure to adjust the reference object everytime the SearchListingRequest interface changes!
 */
export const separateQueryParams = (queryParams: Params): FilterSeparation => {
  const referenceObjectFilter: IAdvertSearchFilter = {
    availabilityDate: 'any',
    availabilityType: 1,
    constructionYearFrom: 123,
    equipments: [],
    floorLoadMax: 123,
    floorLoadMin: 123,
    grossReturnFrom: 123,
    livingAreaMax: 123,
    livingAreaMin: 123,
    location: [],
    radiusCenterCoordinate: undefined,
    numberOfVehiclesMax: 123,
    numberOfVehiclesMin: 123,
    offerType: 1,
    plotAreaMax: 123,
    plotAreaMin: 123,
    priceM2Max: 123,
    priceM2Min: 123,
    priceMax: 123,
    priceMin: 123,
    propertySubtypes: [],
    propertyType: 1,
    radius: 123,
    roomHeightMax: 123,
    roomHeightMin: 123,
    roomsMin: 123,
    roomsMax: 123,
    usableAreaMax: 123,
    usableAreaMin: 123,
    travelDestinations: [],
  };

  const referenceObjectPagingSorting: IPagingSorting = {
    order: AdvertOrderType.Default,
    rowCount: 123,
    skipCount: 123,
  };

  const filterProperties = Object.keys(referenceObjectFilter);
  const pagingSortingProperties = Object.keys(referenceObjectPagingSorting);
  const givenQueryParams = Object.keys(queryParams);

  const filter = new AdvertSearchFilter();
  const pagingSorting = {};
  const other = {};

  for (const param of givenQueryParams) {
    if (filterProperties.some(x => x === param)) {
      filter[param] = queryParams[param];
    } else if (pagingSortingProperties.some(x => x === param)) {
      pagingSorting[param] = queryParams[param];
    } else {
      other[param] = queryParams[param];
    }
  }

  return {
    filter,
    pagingSorting,
    other,
  };
};
