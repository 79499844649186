import { DOCUMENT } from '@angular/common';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsentProviderName, ConsentService } from 'app/shared/consent';
import { OAuthService } from 'app/shared/oauth2-oidc/lib/public_api';
import { environment } from 'environments/environment';
import {
  AdvertSearchFilter,
  ListingDetail,
  OfferType,
  PropertySubType,
  PropertyType,
  SearchLocation,
  SearchPropertyType,
} from 'newhome.dtos';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScriptService } from '../script/script.service';
import { getEmarsysScript } from '../script/script.store';
import { ScrablQueueResult } from './scrabl-queue-result';

const selectImmoCodes = (result: ScrablQueueResult) => result.page.products.map(x => +x.item);

const emarsysOfferTypeMap = {
  [OfferType.Buy]: 'buy',
  [OfferType.Rent]: 'rent',
};

const emarsysPropertyTypeMap = {
  [SearchPropertyType.All]: 'properties',
  [SearchPropertyType.Apartment]: 'apartment',
  [SearchPropertyType.Apartmenthouse]: 'apartmentbuilding',
  [SearchPropertyType.Buildingland]: 'buildingland',
  [SearchPropertyType.Business]: 'business',
  [SearchPropertyType.House]: 'house',
  [SearchPropertyType.HouseApartment]: 'houseapartment',
  [SearchPropertyType.Parkingspace]: 'parkingspace',
};

const emarsysPropertySubTypeMap = {
  [PropertySubType.AgriculturalBuilding]: 'agriculturalbuilding',
  [PropertySubType.AgriculturalLand]: 'agriculturalland',
  [PropertySubType.Apartment]: 'apartment',
  [PropertySubType.AtticApartment]: 'atticapartment',
  [PropertySubType.BuildingLand]: 'buildingland',
  [PropertySubType.BuildingLandMixedZone]: 'buildinglandmixedconstructionzone',
  [PropertySubType.Castle]: 'castle',
  [PropertySubType.Chalet]: 'chalet',
  [PropertySubType.CommercialBuildingLand]: 'commercialbuildingland',
  [PropertySubType.CommercialPremisis]: 'commercialpremises',
  [PropertySubType.CommericalBuilding]: 'commercialbuilding',
  [PropertySubType.CornerHouse]: 'cornerbuilding',
  [PropertySubType.CoveredParkingSpace]: 'coveredparkingspace',
  [PropertySubType.DetachedHouse]: 'detachedhouse',
  [PropertySubType.DoubleApartmentBuilding]: 'doubleapartmentbuilding',
  [PropertySubType.Farmhouse]: 'farmhouse',
  [PropertySubType.FurnishedApartment]: 'furnishedapartment',
  [PropertySubType.Garage]: 'garage',
  [PropertySubType.HolidayApartment]: 'holidayapartment',
  [PropertySubType.HolidyHome]: 'holidayhome',
  [PropertySubType.Hotel]: 'hotel',
  [PropertySubType.IndustrialBuildingLand]: 'industrialbuildingland',
  [PropertySubType.Loft]: 'loft',
  [PropertySubType.MaisonetteApartment]: 'maisonette',
  [PropertySubType.ManufacturingRoom]: 'manufacturingrooms',
  [PropertySubType.MiddleHouse]: 'middlehouse',
  [PropertySubType.MotorcycleParkingSpace]: 'motorcycleparkingspace',
  [PropertySubType.Office]: 'office',
  [PropertySubType.PenthouseApartment]: 'penthouseapartment',
  [PropertySubType.Pratice]: 'practice',
  [PropertySubType.ResidentialCommercialBuilding]: 'residentialandcommercialbuilding',
  [PropertySubType.Restaurant]: 'restaurant',
  [PropertySubType.RoomInHousingAssociation]: 'roominahousingassociation',
  [PropertySubType.RoomInHousingAssociationHouse]: 'roominahousingassociation',
  [PropertySubType.Rustic]: 'rustic',
  [PropertySubType.Salesroom]: 'salesrooms',
  [PropertySubType.SteppedBuilding]: 'steppedbuilding',
  [PropertySubType.Studio]: 'studio',
  [PropertySubType.StudioBedsit]: 'studiobedsit',
  [PropertySubType.TerraceApartment]: 'terraceapartment',
  [PropertySubType.TerracedHouse]: 'terracedhouse',
  [PropertySubType.UncoveredParkingSpace]: 'uncoveredparkingspace',
  [PropertySubType.UndergroundGarage]: 'undergroundgarage',
  [PropertySubType.Villa]: 'villa',
  [PropertySubType.Warehouse]: 'warehouse',
  [PropertySubType.WorkshopHobbyRoom]: 'workshophobbyroom',
};

@Injectable({
  providedIn: 'root',
})
export class EmarsysService implements OnDestroy {
  private canSendEmarsysDataSubscription: Subscription;
  private userClaimsSubscription: Subscription;

  private readonly documentRef = inject(DOCUMENT);

  private get scarabQueue(): Array<unknown> {
    return this.documentRef.defaultView['ScarabQueue'] || [];
  }

  constructor(
    private readonly oauthService: OAuthService,
    private readonly cookieService: CookieService,
    private readonly translate: TranslateService,
    private readonly scriptService: ScriptService,
    private readonly consentService: ConsentService,
  ) {}

  initialize(): void {
    // Listen for consent updates and download emarsys script
    this.consentService.onceConsentIsGiven(ConsentProviderName.Emarsys, () => {
      this.loadScript();
    });
  }

  ngOnDestroy() {
    this.canSendEmarsysDataSubscription?.unsubscribe();
    this.userClaimsSubscription?.unsubscribe();
  }

  public sendResultEmarsysData(filter: AdvertSearchFilter, searchLocations: SearchLocation[]) {
    this.canSendEmarsysDataSubscription = this.canSendEmarsysData$().subscribe(canSendEmarsysData => {
      if (canSendEmarsysData) {
        this.pushEmail();
        this.pushTestMode();
        this.pushLanguage();

        this.pushCategory(filter.offerType, filter.propertyType, filter.propertySubtypes);

        const location = filter.location?.length && searchLocations.find(x => x.identifier === filter.location[0]);

        if (location) {
          this.pushTag(location.displayName);
        }

        this.scarabQueue.push(['go']);
      }
    });
  }

  public sendContactRequestEmarsysData(offerType: OfferType, propertyType: PropertyType, email: string) {
    this.canSendEmarsysDataSubscription = this.canSendEmarsysData$().subscribe(canSendEmarsysData => {
      if (canSendEmarsysData) {
        this.scarabQueue.push(['setEmail', email]);
        this.scarabQueue.push([
          'tag',
          'contactform_object',
          { offertype: emarsysOfferTypeMap[offerType], propertytype: emarsysPropertyTypeMap[propertyType] },
        ]);
        this.scarabQueue.push(['go']);
      }
    });
  }

  public sendAndGetDetailEmarsysData(detail: ListingDetail, success: (immoCodes: number[]) => void, error: () => void) {
    this.canSendEmarsysDataSubscription = this.canSendEmarsysData$().subscribe(canSendEmarsysData => {
      if (canSendEmarsysData) {
        this.pushEmail();
        this.pushTestMode();
        this.pushLanguage();

        this.pushCategory(detail.offerType, detail.propertyType, [detail.detail.propertySubType]);
        this.pushTag(detail.city);
        this.pushImmoCode(detail.immocode);

        this.pushRecommend('RELATED', success);

        this.scarabQueue.push(['go']);
      } else {
        error();
      }
    });
  }

  public getPersonalEmarsysData(success: (immoCodes: number[]) => void, error: () => void) {
    this.canSendEmarsysDataSubscription = this.canSendEmarsysData$().subscribe(canSendEmarsysData => {
      if (canSendEmarsysData) {
        this.pushEmail();
        this.pushTestMode();
        this.pushLanguage();

        this.pushRecommend('PERSONAL', success);

        this.scarabQueue.push(['go']);
      } else {
        error();
      }
    });
  }

  private pushRecommend(logic: 'PERSONAL' | 'CATEGORY' | 'RELATED', success: (immoCodes: number[]) => void) {
    this.scarabQueue.push([
      'recommend',
      {
        logic,
        containerId: '__no_container__',
        limit: '4',
        success: (result: ScrablQueueResult) => success(selectImmoCodes(result)),
      },
    ]);
  }

  private pushCategory(
    offerType: OfferType,
    propertyType: SearchPropertyType | PropertyType,
    propertySubTypes: PropertySubType[],
  ) {
    const categoryParts: string[] = [];

    categoryParts.push(emarsysOfferTypeMap[offerType]);
    categoryParts.push(emarsysPropertyTypeMap[propertyType ?? SearchPropertyType.All]);

    if (propertySubTypes?.length) {
      categoryParts.push(emarsysPropertySubTypeMap[propertySubTypes[0]]);
    }

    const category = categoryParts.filter(x => !!x).join('>');

    this.scarabQueue.push(['category', category]);
  }

  private pushTag(city: string) {
    this.scarabQueue.push(['tag', city]);
  }

  private pushImmoCode(immoCode: number) {
    this.scarabQueue.push(['view', immoCode.toString()]);
  }

  private pushTestMode() {
    if (environment.emarsys.testMode) {
      this.scarabQueue.push(['testMode']);
    }
  }

  private pushLanguage() {
    this.scarabQueue.push(['language', this.translate.currentLang]);
  }

  private pushEmail() {
    this.userClaimsSubscription = this.oauthService
      .isLoggedin()
      .pipe(map(isLoggedin => (isLoggedin ? this.oauthService.getIdentityClaims() : undefined)))
      .subscribe((claims: any) => {
        if (claims?.email) {
          this.scarabQueue.push(['setEmail', claims.email]);
        }
      });
  }

  private loadScript() {
    this.scriptService.load(getEmarsysScript(environment.emarsys.merchantId));
  }

  private canSendEmarsysData$() {
    const emaCookieKey = 'emaCookie';
    const hasEmaCookie = this.cookieService.get(emaCookieKey);
    if (hasEmaCookie) return of(false);
    return of(environment.emarsys.enabled);
  }
}
