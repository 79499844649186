import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ICON_KEYS } from '@newhome/components/icon';
import { InputStepperConfig } from 'app/shared/input-stepper/input-stepper.config';
import { map, Observable } from 'rxjs';

@Component({
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
})
export class ComponentsComponent implements OnInit, AfterViewInit {
  public iconKeys = ICON_KEYS;
  public iconCode: string;
  public shownElement: HTMLElement;
  readonly requiredTrueControl = new FormControl(false, [Validators.requiredTrue]);
  readonly selectControl = new FormControl('');
  readonly autocompleteControl = new FormControl('');
  readonly inputStepperControl = new FormControl(1);

  rawValue = 123_456;

  arrayOfElements = [
    'test1',
    'test2',
    'test3',
    'test4',
    'test5',
    'test6',
    'test7',
    'test8',
    'test9',
    'test10',
    'test11',
    'test12',
    'test13',
    'test14',
    'test15',
    'test16',
  ];

  public inputStepperConfig = new InputStepperConfig();

  filteredElements$: Observable<string[]>;

  @ViewChild('buttons') initialSection: ElementRef<HTMLElement> | undefined;

  readonly invalidInputControl = new FormControl('', [Validators.required, Validators.email]);
  readonly disabledInputControl = new FormControl({
    value: '',
    disabled: true,
  });

  constructor() {
    this.invalidInputControl.markAsTouched();
  }

  ngOnInit(): void {
    (function () {
      let collapsibleHeaders = document.querySelectorAll('.collapsible-header') || [];
      for (let h = 0; h < collapsibleHeaders.length; h++) {
        collapsibleHeaders[h].addEventListener('click', function (e) {
          e.preventDefault();
          this.parentElement.classList.toggle('expanded');
        });
      }
    })();

    this.filteredElements$ = this.autocompleteControl.valueChanges.pipe(
      map(filterText => this.arrayOfElements.filter(element => element.includes(filterText))),
    );

    this.inputStepperConfig = {
      min: 0,
      max: 8,
      step: 1,
      unit: '',
      displayMappings: { 0: 'DISCOVER.WIZARD.GROSS_RETURN.DOES_NOT_MATTER' },
    };
  }

  ngAfterViewInit(): void {
    if (this.initialSection) {
      this.showSection(this.initialSection.nativeElement);
    }
  }

  showIconCode(icon: string) {
    this.iconCode = '<nh-icon key="' + icon + '"></nh-icon>';
  }

  scrollToTop() {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  showSection(element: HTMLElement): void {
    if (this.shownElement && !this.shownElement.classList.contains('hidden')) {
      this.shownElement.classList.add('hidden');
    }
    element.classList.remove('hidden');
    this.shownElement = element;
  }

  autocompleteValueSelected(value) {
    this.autocompleteControl.setValue(value);
  }
}
