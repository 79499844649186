export enum ObjectsViewType {
  /**
   * Objects as tiles in a grid
   */
  Tiles = 0,

  /**
   * Objects as list with a map view to the side.
   * On mobile devices the map is omitted.
   */
  ListWithMap = 1,

  /**
   * Full-screen map overlay. Only
   * available on mobile devices.
   */
  MapOverlay = 2,
}
