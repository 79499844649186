import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@newhome/components/icon';
import { DividedPillDirective } from './divided-pill/divided-pill.directive';
import { PillComponent } from './pill.component';

@NgModule({
  declarations: [PillComponent, DividedPillDirective],
  imports: [CommonModule, IconModule],
  exports: [PillComponent, DividedPillDirective]
})
export class PillModule { }
