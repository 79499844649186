import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { RadioGroupDirective } from './radio-group.directive';
import { NH_RADIO_GROUP } from './tokens';

let uniqueId = 0;

@Component({
  selector: 'nh-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { tabindex: '0' },
})
export class RadioComponent implements OnInit {
  @Input() radioId = `radio-${uniqueId++}`;
  @Input() name: string;

  @Input() get checked(): boolean {
    return this._checked;
  }
  set checked(isChecked: boolean) {
    if (this._checked === isChecked) {
      return;
    }

    this._checked = isChecked;

    // When checking radio button, update radio group
    if (isChecked && this.radioGroup && this.radioGroup.value !== this.value) {
      this.radioGroup.selected = this;
    }
    // When un-checking selected radio button, reset radio group
    else if (!isChecked && this.radioGroup && this.radioGroup.value === this.value) {
      this.radioGroup.selected = null;
    }

    this.changeDetector.markForCheck();
  }

  @Input() get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (this._value === newValue) {
      return;
    }

    this._value = newValue;

    if (this.radioGroup) {
      // Check if radio should be checked based on group value
      if (!this.checked) {
        this.checked = this.radioGroup.value === newValue;
      }

      if (this.checked) {
        this.radioGroup.selected = this;
      }
    }

    this.changeDetector.markForCheck();
  }

  @Input() get disabled(): boolean {
    return this._disabled || (!!this.radioGroup && this.radioGroup.disabled);
  }
  set disabled(isDisabled: boolean) {
    if (this._disabled === isDisabled) {
      return;
    }

    this._disabled = isDisabled;
  }

  @Output() readonly change = new EventEmitter<boolean>();

  private _value: any = null;
  private _checked = false;
  private _disabled = false;

  constructor(
    @Optional() @Inject(NH_RADIO_GROUP) public radioGroup: RadioGroupDirective,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.radioGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this.value;

      if (this.checked) {
        this.radioGroup.selected = this;
      }

      this.name = this.radioGroup.name;
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  protected onRadioInteraction(event: Event): void {
    event.preventDefault();

    if (this.disabled || this.checked) {
      return;
    }

    this.checked = true;
    this.change.emit(true);

    if (this.radioGroup) {
      this.radioGroup._onChangeFn(this.value);
      this.radioGroup._emitChangeEvent();
    }
  }
}
