import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { supportedInputTypes } from './constants';
import { AbstractFormFieldControl, FormFieldControl } from './control';

let uniqueId = 0;

type InputElementType = HTMLInputElement | HTMLTextAreaElement;

@Directive({
  selector: `
    nh-form-field input[nhInput],
    nh-form-field textarea[nhInput]
  `,
  host: {
    class: 'nh-input',
    '[attr.aria-required]': 'required',
    '[attr.aria-readonly]': 'readonly',
    '[attr.aria-disabled]': 'disabled',
    '[attr.aria-invalid]': '(empty && required) ? null : hasErrors',
  },
  providers: [
    {
      provide: AbstractFormFieldControl,
      useExisting: InputDirective,
    },
  ],
})
export class InputDirective implements FormFieldControl<any> {
  value: any;

  @Input()
  @HostBinding('attr.id')
  id: string = `nhInput-${uniqueId++}`;

  @Input()
  get type(): string {
    return this._type;
  }
  set type(newType: string) {
    this.validateType(newType);
    this._type = newType;
  }

  protected _type = 'text';

  get element(): InputElementType {
    return this.elementRef.nativeElement;
  }

  get required(): boolean {
    return this.element.required;
  }

  get hasErrors(): boolean {
    return !!this.ngControl?.invalid;
  }

  get touched(): boolean {
    return !!this.ngControl?.touched;
  }

  get disabled(): boolean {
    return !!this.ngControl?.disabled;
  }

  get empty(): boolean {
    return !this.element?.value && !this.isBadInput();
  }

  get readonly(): boolean {
    return this.element.readOnly;
  }

  readonly focus = new EventEmitter<FocusEvent>();
  readonly blur = new EventEmitter<FocusEvent>();

  constructor(
    @Self()
    @Optional()
    public readonly ngControl: NgControl,
    private readonly elementRef: ElementRef<InputElementType>,
  ) {}

  private isBadInput(): boolean {
    return this.element.validity && this.element.validity.badInput;
  }

  private validateType(type: string): void {
    if (!supportedInputTypes.includes(type)) {
      throw new Error(`Input type ${type} is not supported by [nhInput]`);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    this.blur.emit(event);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    this.focus.emit(event);
  }
}
