import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { WebkitMessageHandler } from 'app/shared/webkit';
import { ConsentCategories } from '../categories';
import { ConsentService } from '../consent.service';

const attConstants = {
  queryParamName: 'att',
  storageKey: 'att',
  webkitHandlerName: 'cmpHandler',
} as const;

type AppTrackingTransparencyValue = 'accepted' | 'declined' | 'not-determined';

@Injectable({ providedIn: 'root' })
export class AppTrackingTransparencyService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly documentRef = inject(DOCUMENT);
  private readonly consentService = inject(ConsentService);

  /**
   * Initializes app tracking transparency service.
   * Will store `att` query parameter on the user's device.
   *
   * @param routeSnapshot activated route snapshot
   */
  initialize(routeSnapshot: ActivatedRouteSnapshot): void {
    if (!this.isAppTrackingTransparencySupported()) {
      return;
    }

    const newAtt =
      this.getQueryParameter<AppTrackingTransparencyValue>(routeSnapshot.queryParamMap, attConstants.queryParamName) ??
      'not-determined';

    const previousAtt = this.documentRef.defaultView.localStorage.getItem(
      attConstants.storageKey,
    ) as AppTrackingTransparencyValue;

    // Prevent overriding of determined value
    if (previousAtt && previousAtt !== 'not-determined' && newAtt === 'not-determined') {
      return;
    }

    this.documentRef.defaultView.localStorage.setItem(attConstants.storageKey, newAtt);

    this.consentService.onceConsentIsChanged(categories => this.handleTransitions(categories));
  }

  private getStoredValue(): AppTrackingTransparencyValue {
    return this.documentRef.defaultView.localStorage.getItem(attConstants.storageKey) as AppTrackingTransparencyValue;
  }

  private openDialog(): void {
    const handler = this.getWebkitMessageHandler();
    handler?.postMessage('');
  }

  private handleTransitions = (categories: ConsentCategories) => {
    const value = this.getStoredValue();

    if (value === 'not-determined') {
      this.openDialog();
    } else if (value === 'declined' && categories.marketing !== false) {
      this.consentService.denyAll();
    }
  };

  private getWebkitMessageHandler(): WebkitMessageHandler | undefined {
    const windowObject = this.documentRef.defaultView as any;
    const handlerName = attConstants.webkitHandlerName;

    return windowObject?.webkit?.messageHandlers?.[handlerName];
  }

  private isAppTrackingTransparencySupported(): boolean {
    return isPlatformBrowser(this.platformId) && this.getWebkitMessageHandler() !== undefined;
  }

  private getQueryParameter<V extends string = string>(parameters: ParamMap, parameterName: string): V | null {
    if (!parameters.has(parameterName)) {
      return null;
    }

    return parameters.get(parameterName) as V;
  }
}
