import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import * as SearchLocationReducer from 'app/entities/search-location/search-location.reducer';
import * as fromRoot from 'app/reducers';
import { SearchLocation } from 'newhome.dtos';
import { defer } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import * as PreviousSearchActions from './previous-search.actions';
import * as PreviousSearchReducer from './previous-search.reducer';
import { PreviousSearchFilter } from './previous-search.reducer';
import * as PreviousSearchSelectors from './previous-search.selectors';

const previousSearchKey = 'previousSearch';
const maxPreviousSearchesCount = 5;

@Injectable()
export class PreviousSearchEffects {
  beginUpdateLatestSearches$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreviousSearchActions.beginUpdateLatestSearches),
      filter(_ => isPlatformBrowser(this.platformId)),
      withLatestFrom(
        this.store.select(SearchLocationReducer.selectAllEntities),
        this.store.select(PreviousSearchSelectors.selectLatestSearches),
        this.store.select(PreviousSearchSelectors.selectLocation),
      ),
      switchMap(([action, searchLocations, latestSearches, storedLocations]) => {
        const latestSearchesEntries = this.combineLatestSearchEntries(action.currentSearchFilter, latestSearches);

        const uniqueSearchLocationEntities = this.getUniqueSearchLocationEntities(
          latestSearchesEntries,
          searchLocations,
          storedLocations,
        );

        this.storeSearchToLocalStorage(latestSearchesEntries, uniqueSearchLocationEntities);

        return [
          PreviousSearchActions.updateLatestSearches({
            latestSearches: latestSearchesEntries,
          }),
          PreviousSearchActions.updatePreviousSearchLocations({
            searchLocations: uniqueSearchLocationEntities,
          }),
        ];
      }),
    );
  });

  // Initial effect needs to be registered at the end of a effect
  init$ = createEffect(() =>
    defer(() => {
      const previousSearch = isPlatformBrowser(this.platformId)
        ? this.loadSearchFromLocalStorage()
        : PreviousSearchReducer.initialState;

      return [
        PreviousSearchActions.setPreviousSearch({
          searchLocations: previousSearch.storedLocations,
          latestSearches: previousSearch?.latestSearches || [],
        }),
      ];
    }),
  );

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly actions$: Actions,
    private readonly store: Store<fromRoot.State>,
  ) {}

  private loadSearchFromLocalStorage(): PreviousSearchReducer.State {
    let previousSearchJson = '{}';
    if (localStorage) {
      try {
        previousSearchJson = localStorage.getItem(previousSearchKey) || '{}';
      } catch {
        previousSearchJson = '{}';
      }
    }
    const previousSearch = JSON.parse(previousSearchJson);

    return {
      ...PreviousSearchReducer.initialState,
      ...previousSearch,
    };
  }

  private storeSearchToLocalStorage(
    latestSearches: Partial<PreviousSearchFilter>[],
    storedLocations: SearchLocation[],
  ): void {
    const entry: PreviousSearchReducer.State = {
      storedLocations,
      latestSearches,
    };

    localStorage.setItem(previousSearchKey, JSON.stringify(entry));
  }

  private combineLatestSearchEntries(
    currentSearch: Partial<PreviousSearchFilter>,
    latestSearches: Partial<PreviousSearchFilter>[],
  ) {
    const latestSearchEntries: Partial<PreviousSearchFilter>[] = [currentSearch, ...latestSearches].filter(
      (v, i, a) => a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i,
    );

    return latestSearchEntries.slice(0, maxPreviousSearchesCount);
  }

  private getUniqueSearchLocationEntities(
    latestSearchesEntries: Partial<PreviousSearchFilter>[],
    searchLocations: Dictionary<SearchLocation>,
    storedLocations: SearchLocation[],
  ) {
    const requiredIds = latestSearchesEntries.map(x => x.location || []).reduce((a, b) => a.concat(b), []);

    const searchLocationEntities = !requiredIds
      ? []
      : requiredIds.map(id => searchLocations[id] || storedLocations.find(x => x.identifier === id));
    return searchLocationEntities.filter(
      (location, index, self) => index === self.findIndex(x => x.identifier === location.identifier),
    );
  }
}
