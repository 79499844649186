import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSearchSubscription from './search-subscription.reducer';

export const selectSearchSubscriptionState = createFeatureSelector<fromSearchSubscription.State>(
  fromSearchSubscription.searchSubscriptionFeatureKey,
);

export const selectConfirmedSearchSubscriptions = createSelector(selectSearchSubscriptionState, x => x.confirmed);

export const selectUnconfirmedSearchSubscriptions = createSelector(selectSearchSubscriptionState, x => x.unconfirmed);

export const selectIsLoading = createSelector(selectSearchSubscriptionState, x => x.isLoading);

export const selectHasError = createSelector(selectSearchSubscriptionState, x => x.hasError);

export const selectDeviceMismatchState = createSelector(selectSearchSubscriptionState, x => x.deviceMismatchState);
