import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ProfileActions from './user-profile.actions';

export const profileFeatureKey = 'profile';

export interface State {
  isUpdatingProfile: boolean;
  isSendingProfileDeleteEMail: boolean;
  isSendingDataDisclosureEMail: boolean;
  hasSentDataDisclosureEMail: boolean;
}

export const initialState: State = {
  isUpdatingProfile: false,
  isSendingProfileDeleteEMail: false,
  isSendingDataDisclosureEMail: false,
  hasSentDataDisclosureEMail: false,
};

const profileReducer = createReducer(
  initialState,

  on(ProfileActions.beginUpdateUserProfileData, state => {
    return {
      ...state,
      isUpdatingProfile: true,
    };
  }),

  on(ProfileActions.beginUpdateUserFinancingData, state => {
    return {
      ...state,
      isUpdatingProfile: true,
    };
  }),

  on(ProfileActions.updateUserProfileSuccessful, state => {
    return {
      ...state,
      isUpdatingProfile: false,
    };
  }),

  on(ProfileActions.updateUserProfileFailure, state => {
    return {
      ...state,
      isUpdatingProfile: false,
    };
  }),

  on(ProfileActions.beginSendDataDisclosureEMail, state => {
    return {
      ...state,
      isSendingDataDisclosureEMail: true,
    };
  }),

  on(ProfileActions.sendDataDisclosureEMailSuccessfull, state => {
    return {
      ...state,
      isSendingDataDisclosureEMail: false,
      hasSentDataDisclosureEMail: true,
    };
  }),

  on(ProfileActions.sendDataDisclosureEMailFailed, state => {
    return {
      ...state,
      isSendingDataDisclosureEMail: false,
    };
  }),

  on(ProfileActions.beginSendUserDeleteEMail, state => {
    return {
      ...state,
      isSendingProfileDeleteEMail: true,
    };
  }),

  on(ProfileActions.sendUserDeleteEMailSuccessfull, state => {
    return {
      ...state,
      isSendingProfileDeleteEMail: false,
    };
  }),

  on(ProfileActions.sendUserDeleteEMailFailed, state => {
    return {
      ...state,
      isSendingProfileDeleteEMail: false,
    };
  }),

  on(ProfileActions.sendUserDeleteEMailFailed, state => {
    return {
      ...state,
      isSendingProfileDeleteEMail: false,
    };
  }),

  on(ProfileActions.confirmDeleteUserProfileClosed, state => {
    return {
      ...state,
      isSendingProfileDeleteEMail: false,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}

export const profileState = createFeatureSelector<State>(profileFeatureKey);

export const selectIsUpdatingProfile = createSelector(profileState, state => state.isUpdatingProfile);

export const selectIsSendingProfileDeleteEMail = createSelector(
  profileState,
  state => state.isSendingProfileDeleteEMail,
);

export const selectIsSendingDataDisclosureEMail = createSelector(
  profileState,
  state => state.isSendingDataDisclosureEMail,
);

export const selectHasSentDataDisclosureEMail = createSelector(
  profileState,
  state => state.hasSentDataDisclosureEMail,
);
