import { Action, createReducer, on } from '@ngrx/store';
import { UserInfo, UserProfile } from 'newhome.dtos';
import * as UserDataActions from './user-data.actions';

export const userDataFeatureKey = 'userData';

export interface State {
  profile: {
    profile: UserProfile;
    isLoading: boolean;
    hasError: boolean;
  };
  userInfo: {
    userInfo: UserInfo;
    isLoading: boolean;
    hasError: boolean;
  };
}

export const initialState: State = {
  profile: {
    profile: undefined,
    isLoading: false,
    hasError: false,
  },
  userInfo: {
    userInfo: undefined,
    isLoading: false,
    hasError: false,
  },
};

const userDataReducer = createReducer(
  initialState,

  on(UserDataActions.beginLoadProfile, state => {
    return {
      ...state,
      profile: {
        ...state.profile,
        isLoading: true,
        hasError: false,
      },
    };
  }),

  on(UserDataActions.loadProfileFailed, state => {
    return {
      ...state,
      profile: {
        ...state.profile,
        isLoading: false,
        hasError: true,
      },
    };
  }),

  on(UserDataActions.loadProfileSuccessful, (state, action) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        profile: action.profile,
        isLoading: false,
        hasError: false,
      },
    };
  }),

  on(UserDataActions.beginLoadUserInfo, state => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        isLoading: true,
        hasError: false,
      },
    };
  }),

  on(UserDataActions.loadUserInfoFailed, state => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        isLoading: false,
        hasError: true,
      },
    };
  }),

  on(UserDataActions.loadUserInfoSuccessful, (state, action) => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        userInfo: action.userInfo,
        isLoading: false,
        hasError: false,
      },
    };
  }),

  on(UserDataActions.clearUserStateAfterLogout, (state, action) => {
    return {
      ...state,
      profile: {
        profile: undefined,
        isLoading: false,
        hasError: false,
      },
      userInfo: {
        userInfo: undefined,
        isLoading: false,
        hasError: false,
      },
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return userDataReducer(state, action);
}
