import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { NH_AUTOCOMPLETE } from '../../autocomplete/tokens';
import { SelectComponent } from '../../select/select.component';
import { NH_SELECT } from '../../select/tokens';

@Component({
  selector: 'nh-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nh-option-container' },
})
export class OptionComponent {
  @Input() value: any;

  @ViewChild('option') optionContainer: ElementRef<HTMLDivElement>;

  get content(): string {
    return this.optionContainer?.nativeElement?.innerText || this.optionContainer?.nativeElement?.innerHTML;
  }
  public marked: boolean = false;

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(NH_AUTOCOMPLETE) private readonly autocompleteComponent: AutocompleteComponent,
    @Optional() @Inject(NH_SELECT) private readonly selectComponent: SelectComponent,
  ) {}

  onSelect() {
    if (this.autocompleteComponent) {
      this.autocompleteComponent.selectOption(this);
    }

    if (this.selectComponent) {
      this.selectComponent.selectOption(this);
    }
  }
}
