import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { JsonServiceClient } from '@servicestack/client';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { customerRoleGtmMap, offerTypeGtmMap, propertySubTypeGtmMap, propertyTypeGtmMap } from 'app/shared/gtm/mapping';
import { OAuthService } from 'app/shared/oauth2-oidc/lib/public_api';
import {
  AddToWatchlistRequest,
  GetWatchlistEntriesRequest,
  RemoveFromWatchlistRequest,
  SearchListingEntry,
  WatchlistCheckEntry,
} from 'newhome.dtos';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, flatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import * as WatchlistActions from './watchlist.actions';
import { WatchlistEntry } from './watchlist.reducer';

export const watchlistLocalStorageKey = 'localWatchlist';

export const mergeWatchlistEntries = (remote: WatchlistCheckEntry[], local: WatchlistEntry[]): WatchlistEntry[] => {
  const watchListEntriesRemote = remote.map(entry => {
    const watchListEntry: WatchlistEntry = {
      isExpired: entry.listing === undefined,
      entry: {
        immocode: entry.immoCode,
        ...entry.listing,
      },
    };
    return watchListEntry;
  });

  const validRemotes = watchListEntriesRemote.filter(x => !x.isExpired);
  const expiredRemoteImmocodes = watchListEntriesRemote.filter(x => x.isExpired).map(x => x.entry.immocode);
  const localSubstitutes = local
    .filter(x => expiredRemoteImmocodes.indexOf(x.entry.immocode) > -1)
    .map(x => {
      return {
        ...x,
        isExpired: true,
      };
    });

  const entries = [...validRemotes, ...localSubstitutes];
  return entries;
};

@Injectable()
export class WatchlistEffects {
  beginAddWatchlistEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WatchlistActions.beginAddWatchlistEntry),
      filter(_ => isPlatformBrowser(this.platformId)),
      switchMap(action => {
        return this.oauthService.isLoggedin().pipe(
          take(1),
          switchMap(isLoggedin => {
            if (isLoggedin) {
              return this.addToRemoteWatchlist$(action.entry);
            } else {
              return this.addToLocalWatchlist$(action.entry);
            }
          }),
        );
      }),
    );
  });

  beginDeleteWatchlistEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WatchlistActions.beginDeleteWatchlistEntry),
      filter(_ => isPlatformBrowser(this.platformId)),
      switchMap(action => {
        return this.oauthService.isLoggedin().pipe(
          take(1),
          switchMap(isLoggedin => {
            if (isLoggedin) {
              return this.removeFromRemoteWatchlist$(action.id);
            } else {
              return this.removeFromLocalWatchlist$(action.id);
            }
          }),
        );
      }),
    );
  });

  beginLoadWatchlistEntries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WatchlistActions.beginLoadWatchlistEntries),
      switchMap(_ => {
        return this.loadWatchlist$().pipe(
          map(entries => WatchlistActions.loadWatchlistEntries({ entries })),
          catchError(error => [WatchlistActions.loadWatchlistEntriesError({ error })]),
        );
      }),
    );
  });

  deleteExpiredWatchlistEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WatchlistActions.deleteExpiredWatchlistEntry),
      flatMap(a => this.removeFromLocalWatchlist$(a.id)),
    );
  });

  removeLocalWatchlistEntries$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WatchlistActions.removeLocalWatchlistEntries),
        filter(_ => isPlatformBrowser(this.platformId)),
        tap(_ => localStorage.setItem(watchlistLocalStorageKey, JSON.stringify([]))),
      );
    },
    { dispatch: false },
  );

  addWatchlistEntry$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WatchlistActions.addWatchlistEntry),
        tap(a => {
          this.pushGtmFavoriteAddTags(a.entry.entry);
          this.toastr.success(this.translate.instant('SHARED.WATCHLIST.ADDED_SUCCESS'));
        }),
      );
    },
    { dispatch: false },
  );

  deleteWatchlistEntry$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WatchlistActions.deleteWatchlistEntry),
        tap(a => {
          this.pushGtmFavoriteRemoveTag(a.id);
          this.toastr.success(this.translate.instant('SHARED.WATCHLIST.REMOVED_SUCCESS'));
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly client: JsonServiceClient,
    private readonly oauthService: OAuthService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly gtm: GtmService,
    private readonly router: Router,
    @Inject(DOCUMENT)
    private readonly documentRef: Document,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {}

  private pushGtmFavoriteRemoveTag(immocode: number) {
    this.gtm.pushGATag({
      event: 'gaTriggerEvent',
      gaEventCategory: 'object',
      gaEventAction: 'favorite.remove',
      gaEventLabel: `Object - ${immocode}`,
    });
  }

  private pushGtmFavoriteAddTags(entry: SearchListingEntry) {
    const path = new URL(this.documentRef.URL).origin + this.router.routerState.snapshot.url.split('?')[0];

    this.gtm.pushGATag({
      event: 'gaTriggerEvent',
      gaEventCategory: 'object',
      gaEventAction: 'favorite.add',
      gaEventLabel: `Object - ${entry.immocode}`,
    });

    this.gtm.pushGATag({
      event: 'gaIncrementGoalEvent',
      gaEventCategory: 'search',
      gaEventAction: 'object.favorite',
      gaEventLabel: offerTypeGtmMap[entry.offerType],
      'page.name': this.documentRef.title,
      'page.path': path,
      'object.immocode': entry.immocode,
      'object.price.netto': entry.price,
      'object.rooms': entry.rooms,
      'object.plz': entry.postalCode && +entry.postalCode,
      'object.region': entry.searchLocation.displayName,
      'object.offer.type': offerTypeGtmMap[entry.offerType],
      'object.type': propertyTypeGtmMap[entry.propertyType],
      'object.subtype': propertySubTypeGtmMap[entry.propertySubType],
      'object.origin': 'nh',
      'object.agency': entry.customerId,
      'object.mandantencode': entry.mandantCode,
      'object.customerrole': customerRoleGtmMap[entry.legalForm],
    });
  }

  private addToLocalWatchlist$(entry: WatchlistEntry) {
    const itemAsJson = localStorage.getItem(watchlistLocalStorageKey) || '[]';
    const localWatchlist: WatchlistEntry[] = JSON.parse(itemAsJson);

    const isDuplicate = localWatchlist.findIndex(p => p.entry.immocode === entry.entry.immocode) > -1;

    if (!isDuplicate) {
      localWatchlist.push(entry);
      localStorage.setItem(watchlistLocalStorageKey, JSON.stringify(localWatchlist));
    }

    return of(WatchlistActions.addWatchlistEntry({ entry }));
  }

  private addToRemoteWatchlist$(entry: WatchlistEntry) {
    const request = new AddToWatchlistRequest({
      immoCode: entry.entry.immocode,
    });

    return from(this.client.put(request)).pipe(
      map(_ => WatchlistActions.addWatchlistEntry({ entry })),
      catchError(_ => [WatchlistActions.addWatchlistEntryFailed()]),
    );
  }

  private removeFromLocalWatchlist$(immoCode: number) {
    const itemAsJson = localStorage.getItem(watchlistLocalStorageKey) || '[]';
    const localWatchlist: WatchlistEntry[] = JSON.parse(itemAsJson);

    const isExisting = localWatchlist.findIndex(p => p.entry.immocode === immoCode) > -1;

    if (isExisting) {
      localStorage.setItem(
        watchlistLocalStorageKey,
        JSON.stringify(localWatchlist.filter(x => x.entry.immocode !== immoCode)),
      );
      return of(WatchlistActions.deleteWatchlistEntry({ id: immoCode }));
    } else {
      return of(WatchlistActions.deleteWatchlistEntryFailed());
    }
  }

  private removeFromRemoteWatchlist$(immoCode: number) {
    const request = new RemoveFromWatchlistRequest({
      immoCode,
    });

    return from(this.client.delete(request)).pipe(
      map(_ => WatchlistActions.deleteWatchlistEntry({ id: immoCode })),
      catchError(_ => [WatchlistActions.addWatchlistEntryFailed()]),
    );
  }

  private loadWatchlist$(): Observable<WatchlistEntry[]> {
    return this.loadLocalWatchlist$().pipe(
      mergeMap(localWatchlist =>
        this.loadRemoteWatchlist$(localWatchlist).pipe(map(remoteWatchlist => [localWatchlist, remoteWatchlist])),
      ),
      map(([local, remote]) => {
        const validRemotes = remote.filter(x => !x.isExpired);
        const expiredRemoteImmocodes = remote.filter(x => x.isExpired).map(x => x.entry.immocode);
        const localSubstitutes = local
          .filter(x => expiredRemoteImmocodes.indexOf(x.entry.immocode) > -1)
          .map(x => {
            return {
              ...x,
              isExpired: true,
            };
          });

        const entries = [...validRemotes, ...localSubstitutes];
        return entries;
      }),
    );
  }

  private loadRemoteWatchlist$(localWatchlist?: WatchlistEntry[]): Observable<WatchlistEntry[]> {
    return this.oauthService.isLoggedin().pipe(
      take(1),
      map(isLoggedin => !isLoggedin && localWatchlist?.length === 0),
      switchMap(skipRemoteSync => {
        if (skipRemoteSync) {
          return of([]);
        }

        const request = new GetWatchlistEntriesRequest({
          immoCodes: localWatchlist?.map(x => x.entry.immocode),
        });

        return from(this.client.get(request)).pipe(
          map(response => {
            const { entries } = response;
            return entries.map(entry => {
              const watchListEntry: WatchlistEntry = {
                isExpired: entry.listing === undefined,
                entry: {
                  immocode: entry.immoCode,
                  ...entry.listing,
                },
              };
              return watchListEntry;
            });
          }),
        );
      }),
    );
  }

  private loadLocalWatchlist$(): Observable<WatchlistEntry[]> {
    if (!isPlatformBrowser(this.platformId)) {
      return of([]);
    }

    const itemAsJson = localStorage.getItem(watchlistLocalStorageKey) || '[]';
    const localWatchlist: WatchlistEntry[] = JSON.parse(itemAsJson);

    return of(localWatchlist);
  }
}
