import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { LinkTheme, defaultTheme } from './theme';
import { LinkSize, defaultSize } from './sizing';

@Component({
  selector: 'a[nhLink]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input()
  @HostBinding('attr.theme')
  theme: LinkTheme = defaultTheme;

  @Input()
  @HostBinding('attr.size')
  size: LinkSize = defaultSize;

  @Input() disabled = false;
}
