import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Mandant } from 'newhome.dtos';
import * as cantonalBankActions from './cantonal-banks.actions';

export const cantonalBanksFeatureKey = 'cantonalBanks';

export interface State extends EntityState<Mandant> {}

export const adapter = createEntityAdapter<Mandant>({
  selectId: mandant => mandant.mandantId,
  sortComparer: (a, b) => a.mandantCode.localeCompare(b.mandantCode),
});

export const initialState = adapter.getInitialState();

const cantonalBanksReducer = createReducer(
  initialState,
  on(cantonalBankActions.addCantonalBanks, (state, { cantonalBanks }) => {
    return adapter.setAll(cantonalBanks, state);
  }),
);

export function reducer(state: State | undefined, action: cantonalBankActions.CantonalBanksActionUnion): State {
  return cantonalBanksReducer(state, action);
}
