import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonSize, defaultSize, ButtonTheme, defaultTheme } from '@newhome/components/common/button';

@Component({
  selector: 'button[nhButton], a[nhButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  @HostBinding('attr.theme')
  theme: ButtonTheme = defaultTheme;

  @Input()
  @HostBinding('attr.size')
  size: ButtonSize = defaultSize;
}
