import { EMPTY, Observable } from 'rxjs';
import { AuthConfig } from './auth.config';
import { OAuthEvent, OAuthSuccessEvent } from './events';
import { LoginOptions, TokenResponse } from './types';

export abstract class OAuthService extends AuthConfig {
  // caching
  protected static discoveryCacheCount = 0;
  protected static jwkCacheCount = 0;
  protected static maxCacheCount = 50;
  protected static discoveryDocumentCached: any;
  protected static jwkDocumentCached: any;

  /**
   * The received (passed around) state, when logging
   * in with implicit flow.
   */
  public state?: string = '';

  /**
   * Informs about events, like token_received or token_expires.
   * See the string enum EventType for a full list of event types.
   */
  public events: Observable<OAuthEvent> = EMPTY;

  public abstract configure(config: AuthConfig): void;

  public abstract isLoggedin(): Observable<boolean>;
  public abstract logOut(noRedirectToLogoutUrl?: boolean, state?: string): void;

  public abstract getIdentityClaims(): object;
  public abstract getAccessToken(): string;
  public abstract refreshToken(): Promise<TokenResponse>;

  public abstract loadDiscoveryDocument(fullUrl?: string): Promise<OAuthSuccessEvent>;
  public abstract loadDiscoveryDocumentAndTryLogin(options?: LoginOptions): Promise<boolean>;

  public abstract initCodeFlow(additionalState?: string, params?: object): void;

  public abstract checkAuthenticatedState(): Promise<boolean>;
}
