<div class="input-stepper" [class.input-stepper--adjustment-signalization]="isAdjustmentSignalizationActive">
  <button
    nhButton
    type="button"
    [theme]="buttonTheme"
    size="small"
    (click)="decrement()"
    [disabled]="isDecrementDisabled"
    data-cy="input-stepper-minus"
  >
    <nh-icon key="minus"></nh-icon>
    <span class="visually-hidden">{{ 'SHARED.INPUT_STEPPER.DECREMENT_TEXT' | translate }}</span>
  </button>
  <div class="input-stepper__control-wrapper">
    <nh-form-field alignment="center" type="number" inputmode="decimal" size="large">
      <input nhInput [formControl]="formControl" />
      <div nhFormattedValue>{{ displayValue }}</div>
    </nh-form-field>
  </div>
  <button
    nhButton
    type="button"
    [theme]="buttonTheme"
    size="small"
    (click)="increment()"
    [disabled]="isIncrementDisabled"
    data-cy="input-stepper-plus"
  >
    <nh-icon key="plus"></nh-icon>
    <span class="visually-hidden">{{ 'SHARED.INPUT_STEPPER.INCREMENT_TEXT' | translate }}</span>
  </button>
</div>
