import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as cacheBusting from '../../i18n-cache-busting.json';

@Injectable({ providedIn: 'root' })
export class CacheBustingTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    let path = `/assets/i18n/${lang}.json`;
    const d = 'default';

    if (cacheBusting) {
      path = `${path}?v=${cacheBusting[d][lang]}`;
    }

    return this.http.get(path);
  }
}
