import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LanguageService } from './language';
import { landingMap } from './shared/seo/mappings';
import { MobileAppService } from '@exanic/newhome-shared-components';
import { AppTrackingTransparencyService } from './shared/consent';

@Injectable()
export class AppCanActivateGuard {
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree> {
    this.initialize(route);

    const lang = route.params.lang || this.language.getFallbackLanguage();

    const url = ['/', lang, landingMap[lang]];
    return this.router.createUrlTree(url);
  }

  constructor(
    private readonly router: Router,
    private readonly language: LanguageService,
    private readonly mobileAppService: MobileAppService,
    private readonly attService: AppTrackingTransparencyService,
  ) {}

  /**
   * Initializes services that require initial parameters to be set
   * @param routeSnapshot initial route snapshot
   */
  initialize(routeSnapshot: ActivatedRouteSnapshot): void {
    this.attService.initialize(routeSnapshot);
    this.mobileAppService.initialize(routeSnapshot);
  }
}
