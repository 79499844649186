export interface Script {
  id: string;
  src: string;
}

export const getEmarsysScript = (merchantId: string): Script => {
  return {
    id: 'scarabScript',
    src: `https://cdn.scarabresearch.com/js/${merchantId}/scarab-v2.js`,
  };
};

export const getGtmScript = (gtmId: string, gtmAuth: string, gtmPreview: string): Script => {
  return {
    id: 'GTMscript',
    src: `https://www.googletagmanager.com/gtm.js?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}`,
  };
};

export const getAdmeiraScript = (lang: string, environment: string = 'prod'): Script => {
  return {
    id: 'admeiraScript',
    src: `https://cdn.ringier-advertising.ch/${environment}/tagmanager/newhome.ch_${lang}/latest/atm.js`,
  };
};
