import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { offerTypeMap } from 'app/shared/seo/mappings';

export const teasersearchRouteMatcher: UrlMatcher = (
  segments: UrlSegment[],
  groups: UrlSegmentGroup,
): UrlMatchResult => {
  const languageIndex = 0;
  const teasersearchIndex = 1;
  const offerTypeIndex = 2;

  const lang = segments[languageIndex].path;
  if (!lang) {
    return null;
  }

  const teasersearchKeywordLocalized = segments[teasersearchIndex]?.path;
  const teasersearchPattern = /^(teasersuche|teasersearch|rechercheteaser|ricercateaser)$/;
  if (!teasersearchKeywordLocalized?.match(teasersearchPattern)) {
    return null;
  }

  const offerTypeString = segments[offerTypeIndex]?.path;
  if (!offerTypeMap[lang][offerTypeString]) {
    return null;
  }

  const posParams = {
    lang: segments[languageIndex],
    offerType: segments[offerTypeIndex],
  };

  return {
    consumed: segments.slice(0, offerTypeIndex + 1),
    posParams,
  };
};
