import { isPlatformBrowser } from '@angular/common';
import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { ConsentCategories } from './categories';
import { ConsentProviderName } from './providers';
import { UsercentricsService } from './usercentrics/usercentrics.service';

@Injectable({ providedIn: 'root' })
export class ConsentService implements OnDestroy {
  private readonly destroyedSubject = new Subject<void>();

  private readonly platformId = inject(PLATFORM_ID);
  private readonly usercentricsService = inject(UsercentricsService);

  /**
   * Initialize consent management
   */
  initialize(): void {
    if (!this.isConsentManagementPlatformSupported()) {
      return;
    }

    this.usercentricsService.initialize();
  }

  /**
   * Registers a callback function to be called when consent is changed explicitely.
   * @param callback function to be called once consent is changed
   */
  onceConsentIsChanged(callback: (categories: ConsentCategories) => unknown): void {
    this.usercentricsService.usercentricsEvent$
      .pipe(
        filter(event => event.detail.type === 'explicit'),
        map(event => event.detail.ucCategory),
        takeUntil(this.destroyedSubject),
      )
      .subscribe(callback);
  }

  /**
   * Registers a callback function to be called when a given provider
   * has been explicitely given consent to by the user
   * @param providerName provider name to check
   * @param callback function to be called once consent is given
   */
  onceConsentIsGiven(providerName: ConsentProviderName, callback: () => unknown): void {
    this.usercentricsService.usercentricsEvent$
      .pipe(
        filter(event => event.detail.type === 'explicit' && event.detail[providerName] === true),
        takeUntil(this.destroyedSubject),
      )
      .subscribe(callback);
  }

  /**
   * Denies all services
   */
  denyAll(): void {
    this.usercentricsService.denyAllServices();
  }

  ngOnDestroy(): void {
    this.destroyedSubject.next();
    this.destroyedSubject.complete();
  }

  private isConsentManagementPlatformSupported(): boolean {
    return environment.userCentrics.enabled && isPlatformBrowser(this.platformId);
  }
}
