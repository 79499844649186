import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutService } from '@exanic/newhome-shared-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { init as initSentry } from '@sentry/angular-ivy';
import { JsonServiceClient } from '@servicestack/client';
import { ExanicLoggerService } from 'app/shared/services/exanic-logger.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { NgProgressModule } from 'ngx-progressbar';
import { ToastrModule } from 'ngx-toastr';
import packageInfo from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserTokenStoreService } from './browser-token-store.service';
import { CustomSerializer } from './custom-route-serializer';
import { CustomServiceClient } from './custom-service-client';
import { EntitiesModule } from './entities/entities.module';
import { TravelDestinationEffects } from './entities/travel-destination/travel-destination.effects';
import { WatchlistEffects } from './entities/watchlist/watchlist.effects';
import { LanguageService } from './language';
import { metaReducers, reducerProvider, REDUCERS_TOKEN } from './reducers';
import { PreviousSearchEffects } from './reducers/previous-search/previous-search.effects';
import { SettingsEffects } from './reducers/settings/settings.effects';
import { UserDataEffects } from './reducers/user-data/user-data.effects';
import { CustomErrorHandler } from './shared/custom-error-logger';
import { GlobalAlertsModule } from './shared/global-alerts/global-alerts.module';
import { GtmService } from './shared/gtm/gtm.service';
import { OAuthModule, OAuthStorage } from './shared/oauth2-oidc/lib/public_api';
import { CanonicalService } from './shared/seo/canonical.service';
import { MetatagService } from './shared/seo/metatag.service';
import { TitleService } from './shared/seo/title.service';
import { AdmeiraService } from './shared/services/admeira/admeira.service';
import { EmarsysService } from './shared/services/emarsys/emarsys.service';
import { ScriptService } from './shared/services/script/script.service';
import { StatisticModule } from './shared/statistic/statistic.module';
import { translateBrowserLoaderFactory } from './translate-browser-loader';
import { SENTRY_DENY_URLS } from './sentry/sentry.denyUrls';
import { SENTRY_IGNORE_ERRORS } from './sentry/sentry.ignoreErrors';
import { AdmeiraModule } from './shared/admeira/admeira.module';
import { BannerModule } from './shared/banner/banner.module';

if (environment.sentry.enabled) {
  initSentry({
    allowUrls: [/https?:\/\/((www|build([1-9]|1[0-5])?|test|integration)\.)?newhome\.ch/], // exclude non newhome errors
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS,
    dsn: environment.sentry.dsn,
    environment: environment.environmentName,
    release: packageInfo.version,
    maxBreadcrumbs: 50,
    beforeSend(event, hint) {
      const error: any = hint.originalException;
      if (error && error.message && error.stack)
        if (error.stack.match(/\.\/src\//)) {
          // filter any exceptions that do not origin from exanic angular code
          event.tags['nh-code'] = 'true';
          return error;
        } else {
          if (event && event.tags) event.tags['nh-code'] = 'false';
          // in all other cases: return 30% of events
          if (Math.floor(Math.random() * 100) + 1 <= 30) return event;
          else return null;
        }

      return event;
    },
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: 'toast-positioning',
    }),
    EntitiesModule,
    GlobalAlertsModule,
    TranslateModule.forRoot({
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      SettingsEffects,
      PreviousSearchEffects,
      UserDataEffects,
      WatchlistEffects,
      TravelDestinationEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
    }),
    OAuthModule.forRootBrowser(),
    BannerModule,
    StatisticModule,
    NgProgressModule,
    AdmeiraModule,
  ],
  providers: [
    CookieService,
    {
      provide: JsonServiceClient,
      useClass: CustomServiceClient,
    },
    {
      provide: OAuthStorage,
      useClass: BrowserTokenStoreService,
    },
    { provide: 'googleTagManagerId', useValue: environment.gtm.id },
    { provide: 'googleTagManagerAuth', useValue: environment.gtm.auth },
    {
      provide: 'googleTagManagerPreview',
      useValue: environment.gtm.preview,
    },
    reducerProvider,
    CanonicalService,
    MetatagService,
    TitleService,
    LayoutService,
    LanguageService,
    ScriptService,
    EmarsysService,
    AdmeiraService,
    GtmService,
    ExanicLoggerService,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
