import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { GetEntitiesRequest, TravelDestination } from 'newhome.dtos';
import { OAuthService } from './shared/oauth2-oidc/lib/oauth-service';
import { EMPTY, from, switchMap, take, tap } from 'rxjs';
import { JsonServiceClient } from '@servicestack/client';
import { mergeWatchlistEntries, watchlistLocalStorageKey } from './entities/watchlist/watchlist.effects';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/reducers';
import * as WatchlistActions from 'app/entities/watchlist/watchlist.actions';
import * as TravelDestinationActions from 'app/entities/travel-destination/travel-destination.actions';
import { WatchlistEntry } from './entities/watchlist/watchlist.reducer';
import { isPlatformBrowser } from '@angular/common';
import { travelDestinationLocalStorageKey } from './entities/travel-destination/travel-destination.effects';
import { UpdateService } from './shared/services/update/update.service';
import { ConsentService } from './shared/consent';
import { AdmeiraService } from './shared/services/admeira/admeira.service';
import { TranslateService } from '@ngx-translate/core';
import { EmarsysService } from './shared/services/emarsys/emarsys.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private readonly injector: Injector,
    private readonly oauthService: OAuthService,
    private readonly jsonServiceClient: JsonServiceClient,
    private readonly store: Store<fromRoot.State>,
    private readonly updateService: UpdateService,
    private readonly consentService: ConsentService,
    private readonly admeiraService: AdmeiraService,
    private readonly emarsysService: EmarsysService,
    private readonly translate: TranslateService,
  ) {}

  async initApp(): Promise<void> {
    const platform = await this.injector.get(PLATFORM_ID);
    await this.loadEntities(platform);

    if (isPlatformBrowser(platform)) {
      this.updateService.configureUpdatePolicy();
      this.admeiraService.initialize(this.translate.currentLang);
      this.emarsysService.initialize();
      this.consentService.initialize();
    }
  }

  private async loadEntities(platformId: any) {
    const localWatchlist = this.getLocalWatchlist(platformId);
    const request = new GetEntitiesRequest({
      immoCodes: localWatchlist.map(x => x.entry.immocode),
    });

    this.oauthService
      .isLoggedin()
      .pipe(
        take(1),
        switchMap(isLoggedIn => {
          if (isLoggedIn || localWatchlist.length) {
            return from(this.jsonServiceClient.get(request)).pipe(
              tap(response => {
                const watchlistEntries = mergeWatchlistEntries(response.watchlistEntries, localWatchlist);

                this.store.dispatch(
                  WatchlistActions.loadWatchlistEntries({
                    entries: watchlistEntries,
                  }),
                );

                this.store.dispatch(
                  TravelDestinationActions.loadTravelDestinations({
                    entries: isLoggedIn ? response.travelDestinations : this.getLocalTravelDestinations(platformId),
                  }),
                );
              }),
            );
          }

          return EMPTY;
        }),
      )
      .subscribe();
  }

  private getLocalWatchlist(platformId: any): WatchlistEntry[] {
    if (!isPlatformBrowser(platformId)) {
      return [];
    }
    return JSON.parse(localStorage.getItem(watchlistLocalStorageKey) || '[]');
  }

  private getLocalTravelDestinations(platformId: any): TravelDestination[] {
    if (!isPlatformBrowser(platformId)) {
      return [];
    }
    return JSON.parse(localStorage.getItem(travelDestinationLocalStorageKey) || '[]');
  }
}
