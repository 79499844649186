import { Pipe, PipeTransform } from '@angular/core';
import { SearchListingEntry, ListingDetail, PriceType } from 'newhome.dtos';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'priceUnit',
})
export class PriceUnitPipe implements PipeTransform {
  transform(value: PriceType, ...args: unknown[]): unknown {
    const priceUnitStatic = 'CHF';

    switch (value) {
      case PriceType.PurchasePrice:
        return priceUnitStatic;

      case PriceType.RentPerMonth:
        const month = this.translate.instant('GENERAL.MONTH');
        return `${priceUnitStatic}/${month}`;

      case PriceType.PricePerM2:
        return `${priceUnitStatic}/m<sup>2</sup>`;

      case PriceType.PricePerM2PerYear:
        const year = this.translate.instant('GENERAL.YEAR');
        return `${priceUnitStatic}/m<sup>2</sup>/${year}`;

      case PriceType.PriceOnRequest:
        return '';
    }
  }

  constructor(private readonly translate: TranslateService) {}
}
