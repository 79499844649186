export const locationRoute = 'location';
export const propertyTypeRoute = 'property-type';
export const subPropertyTypeRoute = 'sub-property-type';
export const roomsRoute = 'rooms';
export const vehiclesRoute = 'vehicles';
export const livingAreaRoute = 'living-area';
export const usableAreaRoute = 'usable-area';
export const plotAreaRoute = 'plot-area';
export const constructionYearRoute = 'construction-year';
export const priceRoute = 'price';
export const priceM2Route = 'price-m2';
export const floorLoadRoute = 'floor-load';
export const grossReturnRoute = 'gross-return';
export const roomHeightRoute = 'room-height';
export const equipmentRoute = 'equipment';

