export const defaultTheme: ButtonTheme = 'primary';
export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'secondary-ghost'
  | 'white'
  | 'light-mint'
  | 'borderless'
  | 'sand'
  | 'light-sand'
  | 'naked'
  | 'light-grey';
