import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';

const getQueryParamsFromString = (url: string) =>
  url
    .split('?')[1]
    ?.split('&')
    .reduce((prev, current) => {
      const param = decodeURIComponent(current).split('=');
      return {
        ...prev,
        [param[0]]: param[1],
      };
    }, {}) ?? {};

@Injectable({
  providedIn: 'root',
})
export class UtmGuard  {
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ) {
    const queryParamsCurrent: any = getQueryParamsFromString(currentState.url);
    const queryParamsNext: any = getQueryParamsFromString(nextState.url);

    // if any utm param is set and equals the previous params => clean utm params
    if (
      (queryParamsCurrent.utm_source ||
        queryParamsNext.utm_source ||
        queryParamsCurrent.utm_campaign ||
        queryParamsNext.utm_campaign ||
        queryParamsCurrent.utm_medium ||
        queryParamsNext.utm_medium ||
        queryParamsCurrent.utm_term ||
        queryParamsNext.utm_term ||
        queryParamsCurrent.utm_content ||
        queryParamsNext.utm_content) &&
      queryParamsCurrent.utm_source === queryParamsNext.utm_source &&
      queryParamsCurrent.utm_campaign === queryParamsNext.utm_campaign &&
      queryParamsCurrent.utm_medium === queryParamsNext.utm_medium &&
      queryParamsCurrent.utm_term === queryParamsNext.utm_term &&
      queryParamsCurrent.utm_content === queryParamsNext.utm_content
    ) {
      if (isPlatformServer(this.platformId)) {
        console.log(
          `Utm guard log with queryParams: ${JSON.stringify(queryParamsCurrent)} and ${JSON.stringify(
            queryParamsNext,
          )}`,
        );
      }
      return this.router.createUrlTree([nextState.url.split('?')[0]], {
        queryParams: {
          ...queryParamsNext,
          utm_source: null,
          utm_campaign: null,
          utm_medium: null,
          utm_term: null,
          utm_content: null,
        },
        replaceUrl: true,
      } as NavigationExtras);
    } else {
      return true;
    }
  }

  constructor(private readonly router: Router, @Inject(PLATFORM_ID) private readonly platformId: object) {}
}
