import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BannerType } from '../banner/banner-type.enum';
import { alertSizeOrder } from './global-alerts-size-order';
import * as fromGlobalAlerts from './global-alerts.reducer';

export const selectAlertState = createFeatureSelector<fromGlobalAlerts.State>(fromGlobalAlerts.globalAlertsFeatureKey);
export const { selectIds, selectEntities, selectAll, selectTotal } = fromGlobalAlerts.adapter.getSelectors();

export const alertAlertIds = createSelector(selectAlertState, selectIds);

export const selectAlertEntities = createSelector(selectAlertState, selectEntities);

export const selectAllAlerts = createSelector(selectAlertState, selectAll);

export const selectBiggestAlertSize = createSelector(selectAllAlerts, alerts => {
  const orderBySize = alerts.sort((a, b) => alertSizeOrder[a.size] - alertSizeOrder[b.size]).map(alert => alert.size);

  return orderBySize.length ? orderBySize[0] : undefined;
});

export const selectHighestAlertType = createSelector(selectAllAlerts, alerts => {
  return Math.min(...alerts.map(alert => alert.type)) as BannerType;
});

export const selectIfAlertDismissible = createSelector(selectAllAlerts, alerts => {
  return alerts.some(alert => alert.dismissible);
});
