import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { langPattern } from 'app/language';
import { internalServerErrorMap } from 'app/shared/seo/mappings';

export const internalServerErrorMatcher: UrlMatcher = (
  segments: UrlSegment[],
  groups: UrlSegmentGroup,
): UrlMatchResult => {
  const languageIndex = 0;
  const internalServerErrorSuffixIndex = 1;

  const language = segments[languageIndex]?.path;
  if (!language?.match(langPattern)) {
    return null;
  }

  const internalServerErrorSuffix = segments[internalServerErrorSuffixIndex]?.path;
  if (internalServerErrorMap[language] !== internalServerErrorSuffix) {
    return null;
  }

  return {
    consumed: segments,
    posParams: {
      lang: segments[languageIndex],
    },
  };
};
