import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { ngrxDevtoolModulesDefinition } from './build-specifics';

@NgModule({
  imports: [AppModule, ngrxDevtoolModulesDefinition, BrowserAnimationsModule],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
