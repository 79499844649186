import {
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BasicControlValueAccessor } from 'app/shared/forms/basic-control';
import { OptionComponent } from '../common/option/option.component';
import { NH_SELECT } from './tokens';
import { defaultTheme, SelectTheme } from './theme';

@Component({
  selector: 'nh-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NH_SELECT,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent extends BasicControlValueAccessor<any> {
  public dropdownOpen: boolean = false;
  public markedOption: OptionComponent;
  public selectedOption: OptionComponent;

  @ContentChildren(forwardRef(() => OptionComponent), { descendants: true })
  private options: QueryList<OptionComponent>;

  @Input()
  @HostBinding('attr.theme')
  theme: SelectTheme = defaultTheme;

  @Input()
  selectId: string = defaultTheme;

  @Input()
  labelValue: string = defaultTheme;

  constructor() {
    super();
  }

  writeValue(value: any): void {
    setTimeout(() => {
      super.writeValue(value);
      this.selectedOption = this.findOptionByValue(value);
    }, 0);
  }

  open(): void {
    if (!this.dropdownOpen) {
      this.dropdownOpen = true;
    }
  }

  close(): void {
    if (this.dropdownOpen) {
      this.dropdownOpen = false;
    }
  }

  openClose(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  keyboardActions(e: KeyboardEvent) {
    switch (e.code) {
      case 'ArrowDown':
        e.preventDefault(); // prevent from scrolling
        this.nextOption();
        break;

      case 'ArrowUp':
        e.preventDefault(); // prevent from scrolling
        this.previousOption();
        break;

      case 'Escape':
        this.close();
        break;

      case 'Enter':
        if (!this.dropdownOpen) {
          this.open();
        } else if (this.markedOption) {
          this.close();
        }
        break;

      case 'Space':
        this.open();
        e.preventDefault();
        break;
    }
  }

  nextOption() {
    if (this.markedOption !== this.options.last) {
      const index = this.options.toArray().indexOf(this.markedOption);
      this.markAndSelectOption(this.options.get(index + 1));
    }
  }

  previousOption() {
    if (this.markedOption !== this.options.first) {
      const index = this.options.toArray().indexOf(this.markedOption);
      this.markAndSelectOption(this.options.get(index - 1));
    }
  }

  scrollToOption(option: OptionComponent) {
    option.element.scrollIntoView({ block: 'nearest' });
  }

  markAndSelectOption(option: OptionComponent) {
    this.options.forEach(option => {
      option.marked = false;
    });

    this.markedOption = option;
    this.markedOption.marked = true;
    this.selectOption(this.markedOption);
    this.scrollToOption(option);
  }

  selectOption(option: OptionComponent) {
    this.onValueChanged(option.value);
    this.selectedOption = option;
  }

  private findOptionByValue(value: any): OptionComponent {
    return this.options?.find(option => option.value === value);
  }
}
