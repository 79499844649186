import {
  Directive,
  ElementRef,
  EnvironmentInjector,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  createComponent,
} from '@angular/core';
import { BadgeComponent } from './badge.component';
import { BadgePosition, defaultPosition } from './position';

@Directive({
  selector: '[nhBadge]',
  host: { class: 'nh-badge-host' },
})
export class BadgeDirective implements OnInit {
  @Input('nhBadge')
  set content(newContent: string) {
    this._content = this.normalizeContent(newContent);
  }
  get content(): string {
    return this._content;
  }

  @Input('nhBadgePosition')
  @HostBinding('attr.badge-position')
  position: BadgePosition = defaultPosition;

  private _content: string;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    environmentInjector: EnvironmentInjector,
  ) {
    createComponent(BadgeComponent, { environmentInjector });
  }

  ngOnInit(): void {
    this.createBadgeElement();
  }

  private createBadgeElement(): void {
    const badgeElement = this.renderer.createElement('span') as HTMLElement;
    badgeElement.innerHTML = this.content;
    badgeElement.classList.add('nh-badge-content');
    badgeElement.setAttribute('aria-hidden', 'true');

    this.elementRef.nativeElement.appendChild(badgeElement);
  }

  private normalizeContent(content: string): string {
    return (content ?? '').trim();
  }
}
