import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractFormFieldControl, FormFieldControl } from './controls/control';
import { FormFieldAlignment, defaultAlignment } from './alignment';
import { ErrorDirective } from './error/error.directive';
import { FormattedValueDirective } from './formatted-value/formatted-value.directive';
import { TextareaDirective } from './controls/textarea.directive';
import { LabelDirective } from './label/label.directive';
import { FormFieldSize, defaultSize } from './sizing';
import { FormFieldTheme, defaultTheme } from './theme';
import { InputDirective } from './controls/input.directive';

@Component({
  selector: 'nh-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nh-form-field' },
})
export class FormFieldComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  @Input()
  @HostBinding('attr.theme')
  theme: FormFieldTheme = defaultTheme;

  @Input()
  @HostBinding('attr.size')
  size: FormFieldSize = defaultSize;

  @Input()
  @HostBinding('attr.alignment')
  alignment: FormFieldAlignment = defaultAlignment;

  @ViewChild('inputFieldLabel') label: ElementRef<HTMLElement>;
  @ViewChild('inputField') inputField: HTMLElement;

  @ContentChild(AbstractFormFieldControl) public control: FormFieldControl<any>;

  @ContentChild(TextareaDirective) public textareaInstance: TextareaDirective | undefined;
  @ContentChild(InputDirective) inputInstance: InputDirective | undefined;
  @ContentChild(LabelDirective) public labelInstance: LabelDirective | undefined;
  @ContentChild(FormattedValueDirective) public formattedValue: FormattedValueDirective;
  @ContentChildren(ErrorDirective) public errorInstances: QueryList<ErrorDirective>;

  isInputFocused = false;

  get isBare(): boolean {
    return !this.control;
  }

  private readonly subscriptions: Subscription[] = [];

  ngAfterContentInit(): void {
    if (!this.control) {
      return;
    }

    this.subscriptions.push(
      this.control.focus.subscribe(() => {
        this.isInputFocused = true;
        this.changeAdaptiveLabelClass();
      }),
      this.control.blur.subscribe(() => {
        this.isInputFocused = false;
        this.changeAdaptiveLabelClass();
      }),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.changeAdaptiveLabelClass();
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  _setFocusWithin(): void {
    this.control && this.control.element.focus();
  }

  private changeAdaptiveLabelClass(): void {
    if (this.label && this.theme === 'adaptive') {
      const hasValue = this.inputInstance?.element.value || this.textareaInstance?.elementRef.nativeElement.value;
      const labelClassList = this.label.nativeElement.classList;

      if (!hasValue) {
        labelClassList.toggle('unfocused', !this.isInputFocused);
      }
    }
  }
}
