import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
import { AppCanActivateGuard } from './app.guard';
import { ComponentsGuard } from './components';
import { PreloadSelectedModulesList } from './custom-router-preload-strategy';
import { discoverMatcher } from './discover/discover-routing.matcher';
import { DiscoverGuard } from './discover/discover.guard';
import { redirectToLandingRouteMatcher } from './discover/landing/landing-route.matcher';
import { AuthGuard } from './auth.guard';
import { internalServerErrorMatcher } from './internal-server-error/internal-server-error-routing.matcher';
import { listingsMatcher } from './listings/listings-routing.matcher';
import { LoadInfoGuard } from './my-newhome/load-info.guard';
import { myNewhomeRouteMatcher } from './my-newhome/my-newhome-routing.matcher';
import { notAvailableMatcher } from './not-available/not-available-routing.matcher';
import { notFoundMatcher } from './not-found/not-found-routing-matcher';
import { NotFoundGuard } from './not-found/not-found.guard';
import { propertyMatcher } from './property/property-routing.matcher';
import { providerNotFoundMatcher } from './provider-directory/provider-not-found/provider-not-found-routing-matcher';
import { providerResultMatcher } from './provider-directory/provider-result/provider-result-routing.matcher';
import { searchSubscriptionMatcher } from './search-subscription/search-subscription-routing-matcher';
import { SearchSubscriptionGuard } from './search-subscription/search-subscription.guard';
import { UtmGuard } from './shared/gtm/utm/utm.guard';
import { RequiresLoginGuard } from './shared/requires-login.guard';
import { teasersearchRouteMatcher } from './teasersearch/teasersearch-routing.matcher';
import { TeasersearchGuard } from './teasersearch/teasersearch.guard';
import { watchlistMatcher } from './watchlist/watchlist-routing.matcher';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        matcher: redirectToLandingRouteMatcher,
        component: AppComponent,
        canActivate: [AppCanActivateGuard],
        canDeactivate: [UtmGuard],
      },
      {
        path: 'callback',
        loadChildren: () => import('./identity-callback/identity-callback.module').then(m => m.IdentityCallbackModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'logout',
        loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
      },
      {
        path: 'startup',
        loadChildren: () => import('./please-wait/please-wait.module').then(m => m.PleaseWaitModule),
      },
      {
        path: 'components',
        canActivate: [ComponentsGuard],
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
      },
      {
        matcher: teasersearchRouteMatcher,
        canActivate: [TeasersearchGuard],
        canDeactivate: [UtmGuard],
        loadChildren: () => import('./teasersearch/teasersearch.module').then(m => m.TeasersearchModule),
      },
      {
        matcher: myNewhomeRouteMatcher,
        loadChildren: () => import('./my-newhome//my-newhome.module').then(m => m.MyNewhomeModule),
        canActivate: [LoadInfoGuard],
        canDeactivate: [UtmGuard],
      },
      {
        matcher: propertyMatcher,
        loadChildren: () => import('./property/property.module').then(m => m.PropertyModule),
        data: { preload: false },
        canDeactivate: [UtmGuard],
      },
      {
        matcher: discoverMatcher,
        loadChildren: () => import('./discover/discover.module').then(m => m.DiscoverModule),
        canActivate: [DiscoverGuard],
        canDeactivate: [UtmGuard],
        data: { resolve: true },
      },
      {
        matcher: searchSubscriptionMatcher,
        canActivate: [SearchSubscriptionGuard],
        canDeactivate: [UtmGuard],
        loadChildren: () =>
          import('./search-subscription/search-subscription.module').then(m => m.SearchSubscriptionModule),
      },
      {
        matcher: watchlistMatcher,
        canDeactivate: [UtmGuard],
        loadChildren: () => import('./watchlist/watchlist.module').then(m => m.WatchlistModule),
      },
      {
        matcher: providerResultMatcher,
        canDeactivate: [UtmGuard],
        loadChildren: () =>
          import('./provider-directory/provider-result/provider-result.module').then(m => m.ProviderResultModule),
      },
      {
        matcher: providerNotFoundMatcher,
        canDeactivate: [UtmGuard],
        loadChildren: () =>
          import('./provider-directory/provider-not-found/provider-not-found.module').then(
            m => m.ProviderNotFoundModule,
          ),
      },
      {
        matcher: notAvailableMatcher,
        canDeactivate: [UtmGuard],
        loadChildren: () => import('./not-available/not-available.module').then(m => m.NotAvailableModule),
      },
      {
        matcher: internalServerErrorMatcher,
        canDeactivate: [UtmGuard],
        loadChildren: () =>
          import('./internal-server-error/internal-server-error.module').then(m => m.InternalServerErrorModule),
      },
      {
        matcher: listingsMatcher,
        loadChildren: () => import('./listings/listings.module').then(m => m.ListingsModule),
        canActivate: [RequiresLoginGuard],
        canDeactivate: [UtmGuard],
      },
      {
        matcher: notFoundMatcher,
        canDeactivate: [UtmGuard],
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
      },
      {
        path: '**',
        canActivate: [NotFoundGuard],
        canDeactivate: [UtmGuard],
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production && environment.verbose,
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadSelectedModulesList,
    }),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    DiscoverGuard,
    NotFoundGuard,
    AppCanActivateGuard,
    PreloadSelectedModulesList,
    SearchSubscriptionGuard,
    RequiresLoginGuard,
    UtmGuard,
    LoadInfoGuard,
  ],
})
export class AppRoutingModule {}
