import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ErrorResponse, JsonServiceClient } from '@servicestack/client';
import * as fromRoot from 'app/reducers';
import * as RouterSelectors from 'app/router.selectors';
import { environment } from 'environments/environment';
import { from, Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { OAuthService } from './shared/oauth2-oidc/lib/public_api';
import { internalServerErrorMap } from './shared/seo/mappings';
import { setUser as setSentryUser } from '@sentry/angular-ivy';

@Injectable()
export class CustomServiceClient extends JsonServiceClient implements OnDestroy {
  private languageSubscription: Subscription;

  constructor(
    private injector: Injector,
    store: Store<fromRoot.State>,
    oauthService: OAuthService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super(environment.baseUrl);

    if (isPlatformServer(this.platformId)) {
      this.headers.set('X-newhome-ssr', 'on');
    }

    this.headers.set('ngsw-bypass', 'true');
    this.responseFilter = response => {
      if (response.status === 403 && response.headers.get('cf-mitigated') == 'challenge') {
        this.navigateToCloudflareChallenge();
      }
    };

    this.exceptionFilter = (response, error: ErrorResponse | Error | any) => {
      if (error instanceof Error) {
        if (response?.status === 500) {
          if (environment.production) {
            this.navigateToErrorPage(store, internalServerErrorMap);
          } else {
            console.error('API Exception', error, response);
          }

          return;
        }
      }

      if (error instanceof ErrorResponse) {
        switch (error.type) {
          case 'RefreshTokenException':
            console.log('RefreshTokenException', error);
            break;
        }
      }
    };

    this.onAuthenticationRequired = () => {
      // try refresh token
      return oauthService
        .refreshToken()
        .then(response => (this.bearerToken = response.access_token))
        .catch(_ => {
          if (isPlatformServer(this.platformId)) {
            // if we are a server, lets navigate to the main-page in order to cancel out eventual errors
            this.router.createUrlTree(['/startup']);
          }

          const state = window.location.pathname + window.location.search;
          this.languageSubscription = store.select(RouterSelectors.selectLanguage).subscribe(lang => {
            oauthService.initCodeFlow(state, {
              ui_locales: lang || 'de',
            });
          });
        });
    };

    this.send = <T>(request: any | null, args?: any, url?: string): Promise<T> => {
      return oauthService
        .isLoggedin()
        .pipe(
          take(1),
          tap(isLoggedin => {
            if (isLoggedin) {
              this.bearerToken = oauthService.getAccessToken();
              const claims: any = oauthService.getIdentityClaims();
              setSentryUser({
                username: `${claims?.sub} ${claims?.email}`,
              });
            }
          }),
          switchMap(() => from(super.send<T>(request, args, url))),
        )
        .toPromise();
    };
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

  get router() {
    return this.injector.get(Router);
  }

  private navigateToErrorPage(store: Store<fromRoot.State>, pageMap: Record<string, string>) {
    const languageSubscription = store
      .select(RouterSelectors.selectLanguage)
      .pipe(
        filter(lang => !!lang),
        take(1),
      )
      .subscribe(lang => {
        this.router.navigate(['/', lang, pageMap[lang]]).then(_ => languageSubscription.unsubscribe());
      });
  }

  private navigateToCloudflareChallenge() {
    window.location.href = window.location.origin + '/cf-challenge';
  }
}
