<input
  class="radio"
  [class.radio--checked]="checked"
  [class.radio--disabled]="disabled"
  [checked]="checked"
  [disabled]="disabled"
  [id]="radioId"
  [attr.name]="name"
  [attr.value]="value"
  type="radio"
  tabindex="-1"
/>
