import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ApplicationRef, Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { concat, interval, Subscription } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UpdateService implements OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly updates: SwUpdate,
    private readonly applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {}

  configureUpdatePolicy(): void {
    if (environment.enableServiceWorker && this.updates.isEnabled && isPlatformBrowser(this.platformId)) {
      this.checkUpdates();
    }
  }

  private checkUpdates(): void {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    // Periodically check for pending updates
    const cycleSubscription = everySixHoursOnceAppIsStable$.subscribe(() => {
      this.updates.checkForUpdate();
    });

    // Queue reload once update is available and has been activated
    const availabilitySubscription = this.updates.versionUpdates
      .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
      .subscribe(() => {
        this.queueReload();
      });

    this.subscriptions.push(cycleSubscription, availabilitySubscription);
  }

  private queueReload(): void {
    const nextNavigation$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1),
    );

    // Perform a reload after the next router navigation event
    const queueSubscription = nextNavigation$.subscribe(() => {
      this.document.location.reload();
    });

    this.subscriptions.push(queueSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
