import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { langPattern } from 'app/language';
import { notFoundMap } from 'app/shared/seo/mappings';

export const notFoundMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  const languageIndex = 0;
  const notFoundSufflixIndex = 1;

  const language = segments[languageIndex]?.path;
  if (!language?.match(langPattern)) {
    return null;
  }

  const notFoundSuffix = segments[notFoundSufflixIndex]?.path;
  if (notFoundMap[language] !== notFoundSuffix) {
    return null;
  }

  return {
    consumed: segments,
    posParams: {
      lang: segments[languageIndex],
    },
  };
};
