import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as PreviousSearchReducer from './previous-search.reducer';

export const selectPreviousSearchState = createFeatureSelector<PreviousSearchReducer.State>(
  PreviousSearchReducer.previousSearchFeatureKey,
);

export const selectLocation = createSelector(selectPreviousSearchState, x => x.storedLocations);

export const selectLatestSearches = createSelector(selectPreviousSearchState, x => x.latestSearches);
