import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LogExceptionRequest } from 'newhome.dtos';
import { JsonServiceClient } from '@servicestack/client';
import { Router } from '@angular/router';

@Injectable()
export class ExanicLoggerService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly client: JsonServiceClient,
    private readonly router: Router,
  ) {}

  error(message: string, stacktrace: any) {
    if (!(!message && !stacktrace)) {
      const request = new LogExceptionRequest();
      const url = this.client.createUrlFromDto('POST', request);
      request.message = message;
      request.stacktrace = stacktrace;
      request.url = this.router.routerState.snapshot.url;
      return this.httpClient.post(url, request, {
        headers: {
          'content-type': 'application/json',
          'ngsw-bypass': ' true',
        },
      });
    }
  }
}
