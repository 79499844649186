import { DOCUMENT, isPlatformBrowser, isPlatformServer, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { Event, Router, Scroll } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'app/reducers';
import * as RouterSelectors from 'app/router.selectors';
import * as GlobalAlertsActions from 'app/shared/global-alerts/global-alerts.actions';
import { WebPushPayloadDto } from 'app/shared/push-notification.model';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { languageKey } from './language';
import { BannerType } from './shared/banner/banner-type.enum';
import { ConsentService } from './shared/consent';
import { cookieIdList } from './shared/global-alerts/global-alerts-cookies-id-list';
import { GlobalAlert } from './shared/global-alerts/global-alerts.reducer';
import { GlobalAlertsService } from './shared/global-alerts/global-alerts.service';
import { GtmService } from './shared/gtm/gtm.service';
import { MetatagService } from './shared/seo/metatag.service';
import { TitleService } from './shared/seo/title.service';
import { AppInitService } from './app-init.service';

const offlineWarningAlertId = 'OFFLINE_WARNING';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private navigatorRef: any;
  private pushIdentifier: string;

  private subscriptions: Subscription[] = [];

  constructor(
    @Optional() @Inject('PUSH_IDENTIFIER') private identifier: string,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private transferState: TransferState,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private readonly swPush: SwPush,
    private readonly gtmService: GtmService,
    private readonly title: TitleService,
    private readonly meta: MetatagService,
    private readonly store: Store<fromRoot.State>,
    private readonly viewportScroller: ViewportScroller,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly cookieService: CookieService,
    private readonly globalAlertService: GlobalAlertsService,
    private readonly appInitService: AppInitService,
  ) {
    const transferKey: StateKey<string> = makeStateKey<string>('pushIdentifier');

    if (isPlatformServer(platformId)) {
      this.pushIdentifier = this.identifier;
      this.transferState.set(transferKey, this.pushIdentifier);
    } else {
      this.pushIdentifier = this.transferState.get<string>(transferKey, '');
      if (this.pushIdentifier || !localStorage.getItem('pushIdentifier')) {
        localStorage.setItem('pushIdentifier', this.pushIdentifier);
      }
    }

    this.subscriptions.push(this.subscribeToNotifications(), this.subscribeToNotificationClicks());

    if (isPlatformBrowser(platformId)) {
      this.navigatorRef = navigator;
      this.registerOfflineEvent();
      this.registerScrollbevhaiour();

      this.gtmService.init();

      this.subscriptions.push(
        store.select(RouterSelectors.selectLanguage).subscribe(lang => {
          this.cookieService.set(languageKey, lang, 3600, '/', null, environment.production);
          documentRef.documentElement.lang = lang;

          globalAlertService.setNewCookie();

          if (globalAlertService.shouldDisplayBlueBanner(cookieIdList.privacyPolicy))
            globalAlertService.addPrivacyPolicyAlert();

          this.initBrowserUpdate(lang);
        }),
      );
    }
  }

  ngOnInit(): void {
    this.title.setDefaultTitle();
    this.meta.setCopyright();
    this.meta.setOpenGraphUrl();
    this.meta.setOpenGraphImage();
  }

  ngAfterViewInit(): void {
    this.appInitService.initApp();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  private registerOfflineEvent() {
    const offlineEvent = fromEvent(window, 'offline');
    const onlineEvent = fromEvent(window, 'online');

    this.subscriptions.push(
      // offlineWarningSubscription
      offlineEvent.subscribe(_ => {
        // TODO: Offline Page Handling
        this.store.dispatch(
          GlobalAlertsActions.addAlert({
            alert: new GlobalAlert({
              dismissible: false,
              id: offlineWarningAlertId,
              size: 'big',
              text: this.translate.instant('APP.OFFLINE_WARNING.TEXT'),
              type: BannerType.Warning,
              title: this.translate.instant('APP.OFFLINE_WARNING.TITLE'),
            }),
          }),
        );
      }),

      // onlineWarningSubscription
      onlineEvent.subscribe(_ => {
        this.store.dispatch(GlobalAlertsActions.dismissAllAlerts());
      }),
    );
  }

  private subscribeToNotifications(): Subscription {
    return this.swPush.messages.subscribe((x: WebPushPayloadDto) => {
      this.navigatorRef?.serviceWorker.getRegistration().then(reg => {
        reg.showNotification(x.NotificationTitle, {
          silent: false,
          body: x.NotificationBody,
          icon: x.NotificationIconUrl,
          tag: x.NotificationTag,
          timestamp: x.NotificationTimestamp,
          vibrate: [100, 50, 100],
          data: {
            url: x.NotificationClickUrl,
            tpye: x.NotificationType,
          },
        });
      });
    });
  }

  private subscribeToNotificationClicks() {
    return this.swPush.notificationClicks.subscribe(click => {
      window.focus();
      window.open(click.notification.data.url, '_self');
    });
  }

  /**
   * Custom scroll behaviour, since we should not scroll to top in wizard module
   * https://stackoverflow.com/questions/56663065/changing-query-params-page-scrolls-top-angular
   */
  private registerScrollbevhaiour() {
    this.subscriptions.push(
      this.router.events.pipe(filter((e: Event): e is Scroll => e instanceof Scroll)).subscribe(e => {
        if (!/^\/(de|en|fr|it)\/wizard/.test(this.router.url) && !/^\/(de|en|fr|it)\/home/.test(this.router.url)) {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }),
    );
  }

  private initBrowserUpdate(lang: string) {
    const browserUpdateJs = import('browser-update');
    browserUpdateJs.then(browserUpdate => {
      browserUpdate.default({
        required: {
          e: -6,
          f: -6,
          o: -6,
          s: -6,
          c: -6,
        },
        insecure: true,
        unsupported: false,
        api: 2020.04,
        reminderClosed: 1,
        l: lang,
      });
    });
  }
}
