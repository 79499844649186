import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthConfig } from "../auth.config";
import { OAuthSuccessEvent } from "../events";
import { OAuthService } from "../oauth-service";
import { LoginOptions, TokenResponse } from "../types";

@Injectable()
export class NoopOAuthService extends OAuthService {
  constructor() {
    super();
  }

  public configure(config: AuthConfig): void {

  }

  public isLoggedin(): Observable<boolean> {
    return of(false);
  }

  public logOut(noRedirectToLogoutUrl?: boolean, state?: string): void {

  }

  public getIdentityClaims(): object {
    return {};
  }

  public getAccessToken(): string {
    return null;
  }

  public refreshToken(): Promise<TokenResponse> {
    return Promise.resolve(null);
  }

  public loadDiscoveryDocument(fullUrl?: string): Promise<OAuthSuccessEvent> {
    return Promise.resolve(null);
  }

  public loadDiscoveryDocumentAndTryLogin(options?: LoginOptions): Promise<boolean> {
    return Promise.resolve(false);
  }

  public initCodeFlow(additionalState?: string, params?: object): void {

  }

  public checkAuthenticatedState(): Promise<boolean> {
    return Promise.resolve(false);
  }
}
