import { HostListener, Input, Directive, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export abstract class CheckboxControls implements ControlValueAccessor {
  @Input() checked = false;
  @Input() disabled = false;
  @Output() change = new EventEmitter<boolean>();

  touched = false;

  private onChange = (_: boolean) => {};
  private onTouch = () => {};

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: (_: boolean) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClicked(): void {
    if (!this.disabled) {
      this.markAsTouched();
      const checked = !this.checked;
      this.writeValue(checked);
      this.onChange(checked);
      this.change.emit(checked);
    }
  }

  markAsTouched(): void {
    this.touched = true;
    this.onTouch();
  }
}
