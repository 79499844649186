import { Action, createReducer, on } from '@ngrx/store';
import { IAdvertSearchFilter, LocationType, SearchLocation } from 'newhome.dtos';
import * as PreviousSearchActions from './previous-search.actions';

export const previousSearchFeatureKey = 'previousSearch';

export type PreviousSearchFilter = IAdvertSearchFilter & {
  immocode: number;
  locationType: LocationType;
  locationDisplayName: string;
};

export interface State {
  storedLocations: SearchLocation[];
  latestSearches: Partial<PreviousSearchFilter>[];
}

export const initialState: State = {
  storedLocations: [],
  latestSearches: [],
};

const settingsReducer = createReducer(
  initialState,
  on(PreviousSearchActions.setPreviousSearch, (state, action) => {
    return {
      ...state,
      storedLocations: action.searchLocations,
      latestSearches: action.latestSearches,
    };
  }),
  on(PreviousSearchActions.updateLatestSearches, (state, action) => {
    return {
      ...state,
      latestSearches: action.latestSearches,
    };
  }),
  on(PreviousSearchActions.updatePreviousSearchLocations, (state, action) => {
    return {
      ...state,
      storedLocations: action.searchLocations,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return settingsReducer(state, action);
}
