import { InternationalizedLinkMap } from '../seo/link-map';
import { LegacyNavigationPage } from './legacy-navigation.builder';

export const absoluteLinks: InternationalizedLinkMap<LegacyNavigationPage> = {
  de: {
    promotion: '/blog/de/inserieren/promotions/',
    advertise: '/blog/de/inserieren/',
    advertiseNewTenant:
      '/blog/de/inserieren/kostenlos-nachmieter-finden/?utm_source=eigenwerbung&utm_medium=banner&utm_campaign=startseite_nachmieter',
    aboutus: '/blog/de/wer-ist-newhome-ch/',
    agb: '/blog/de/rechtliches/',
    affordabilityCheck:
      '/blog/de/kaufen/immobilienkauf/tragbarkeitscheck-laesst-sich-ihr-traum-vom-eigenheim-finanzieren/',
    blog: '/blog/de/',
    businessCustomer: '/blog/de/inserieren/#neukunde',
    privacy: '/blog/de/datenschutzbestimmungen/',
    privacyUpdate: '/blog/de/aktualisierung-der-richtlinien/',
    legalNotice: '/blog/de/impressum/',
    vacancies: '/blog/de/offene-stellen/',
    advertisement: '/blog/de/werben-sie-gezielt-und-kostenguenstig-auf-newhome-ch/',
    security: '/blog/de/sicherheit-auf-newhome-ch/',
    partner: '/blog/de/netzwerk-2-2/',
    subscribeNewsletter: '/blog/de/newsletter-anmelden/',
    smkInfo: '/blog/de/schweizerische-maklerkammer-die-geprueften-immobilien-spezialisten/',
    factsAndFigures: '/blog/de/werben-sie-gezielt-und-kostenguenstig-auf-newhome-ch/#mediadaten',
    faq: '/blog/de/haufige-fragen/',
    findNewTenant: '/blog/de/inserieren/kostenlos-nachmieter-finden/',
    team: '/blog/de/das-newhome-ch-team-stellt-sich-vor/',
    events: '/blog/de/veranstaltungen-und-events-der-immobilienbranche/',

    providerDirectorySearch: '/de/anbieterverzeichnis',
    providerDirectoryDetail: '/de/anbieterverzeichnis/detail',
    contact: '/de/kontakt',

    debtEnforcementExcerpt: '/blog/de/credittrust-de/',

    mail: '/de/konto/postfach',
    invoice: '/de/konto/rechnungen',
    advertAdministration: '/de/inserieren/verwalten',
    advertAdministrationStatistic: '/de/inserieren/download',
    businessSubscriptionAdmin: '/de/professionalservices/businesspackage',
    providerDirectoryAdmin: '/de/professionalservices/anbieterverzeichnis',
    importAdmin: '/de/professionalservices/importschnittstellen',
    exportAdmin: '/de/professionalservices/exporthub',
    exportPortiaAdmin: '/de/professionalservices/portia',
    partnersiteAdmin: '/de/professionalservices/partnerseite',
    businessPlusAdmin: '/de/professionalservices/businessplus',
    businessConnectAdmin: '/de/professionalservices/businesconnector',
    memberArea: '/blog/de/exklusiver-memberbereich/',
    mortgages: '/blog/de/kaufen/hypotheken/',
    moving: '/blog/de/umziehen/',
    pdfFlyer: '/de/pdf/generate',
    pressEnquiries: '/blog/de/pressecorner/',
    services: '/blog/de/dienstleistungen/',
    sitemap: '/blog/de/sitemap',
    buy: '/blog/de/kaufen/',
    rent: '/blog/de/mieten/',
    letting: '/blog/de/mieten/wohnung-vermieten/',
    sell: '/blog/de/kaufen/immobilienverkauf/',
    lexicon: '', // TODO folgt noch
    immotalk: '/blog/de/kategorie/newhome-news/newhome-talk/',
    search: '/de/wizard/location',
    rentalDeposit: '/blog/de/mieten/firstcaution/',
    emigration: '/blog/de/kategorie/auswandern-ch/',
    valuation: '/blog/de/kategorie/kaufen/immobilienbewertung/',
    serviceDescription: '', // TODO folgt noch
    advertiserServices: '/blog/de/dienstleistungen-inserieren/',
    values: '/blog/de/wer-ist-newhome-ch/werte-kultur/',
    management: '/blog/de/wer-ist-newhome-ch/verwaltungsrat/',
  },
  en: {
    promotion: '/blog/en/services/highlight-your-listing/',
    advertise: '/blog/en/advertising/',
    advertiseNewTenant:
      '/blog/en/advertising/find-a-new-tenant-free-of-charge/?utm_source=eigenwerbung&utm_medium=banner&utm_campaign=startseite_nachmieter',
    aboutus: '/blog/en/about-us/',
    agb: '/blog/en/legal-info/',
    affordabilityCheck: '/blog/en/buying/property-purchase/new-feature-affordability-check-for-sale-properties/',
    blog: '/blog/en/',
    businessCustomer: '/blog/en/advertising/#newclient',
    privacy: '/blog/en/privacy-policy/',
    privacyUpdate: '/blog/en/updating-our-guidelines/',
    legalNotice: '/blog/en/legal-notice/',
    vacancies: '/blog/en/vacancies/',
    advertisement: '/blog/en/take-advantage-of-targeted-low-cost-advertising-on-newhome-ch/',
    security: '/blog/en/security-on-newhome-ch/',
    partner: '/blog/en/network/',
    subscribeNewsletter: '/blog/en/subscribe-newsletter/',
    smkInfo: '/blog/en/swiss-chamber-of-real-estate-brokers-the-certified-real-estate-specialists/',
    factsAndFigures: '/blog/en/take-advantage-of-targeted-low-cost-advertising-on-newhome-ch/#mediadata',
    faq: '/blog/en/frequently-asked-questions/',
    findNewTenant: '/blog/en/advertising/find-a-new-tenant-free-of-charge/',
    team: '/blog/en/introducing-the-newhome-ch-team/',
    events: '/blog/de/veranstaltungen-und-events-der-immobilienbranche/',

    providerDirectorySearch: '/en/provider_directory',
    providerDirectoryDetail: '/en/provider_directory/details',
    contact: '/en/contact',

    debtEnforcementExcerpt: '/blog/en/credittrust-en/',

    mail: '/en/account/mailbox',
    invoice: '/en/account/invoices',
    advertAdministration: '/en/listings/manage',
    advertAdministrationStatistic: '/en/listings/download',
    businessSubscriptionAdmin: '/en/professional_services/businesspackage',
    providerDirectoryAdmin: '/en/professional_services/providerdirectory',
    importAdmin: '/en/professional_services/importinterfaces',
    exportAdmin: '/en/professionalservices/exporthub',
    exportPortiaAdmin: '/en/professional_services/portia',
    partnersiteAdmin: '/en/professional_services/partnersite',
    businessPlusAdmin: '/en/professional_services/business_plus',
    businessConnectAdmin: '/en/professional_services/businesconnector',
    memberArea: '/blog/en/exclusive-member-area/',
    mortgages: '/blog/en/buying/mortgages/',
    moving: '/blog/en/moving/',
    pdfFlyer: '/en/pdf/generate',
    pressEnquiries: '/blog/en/media-corner/',
    services: '/blog/en/services/',
    sitemap: '/blog/en/sitemap',
    buy: '/blog/en/buying/',
    rent: '/blog/en/renting/',
    letting: '/blog/en/renting/renting-out-a-flat/',
    sell: '/blog/en/buying/property-sale/',
    lexicon: '', // TODO folgt noch
    immotalk: '/blog/de/kategorie/newhome-news/newhome-talk/',
    search: '/en/wizard/location',
    rentalDeposit: '/blog/en/renting/firstcaution/',
    emigration: '/blog/en/category/emigrating-ch/',
    valuation: '/blog/en/category/buying/property-valuation/',
    serviceDescription: '', // TODO folgt noch
    advertiserServices: '/blog/en/services/',
    values: '/blog/en/about-us/values-culture/',
    management: '/blog/en/about-us/management/',
  },
  it: {
    promotion: '/blog/it/pubblicare-annunci-immobiliari/promotions-it/',
    advertise: '/blog/it/pubblicare-annunci-immobiliari/',
    advertiseNewTenant:
      '/blog/it/pubblicare-annunci-immobiliari/trovare-un-nuovo-inquilino-gratuitamente/?utm_source=eigenwerbung&utm_medium=banner&utm_campaign=startseite_nachmieter',
    aboutus: '/blog/it/chi-e-newhome-ch/',
    agb: '/blog/it/aspetti-giuridici/',
    affordabilityCheck:
      '/blog/it/acquistare/acquisto-di-beni-immobili/nuova-funzione-controllo-di-sostenibilita-per-lacquisto-di-immobili/',
    blog: '/blog/it/',
    businessCustomer: '/blog/it/pubblicare-annunci-immobiliari/#nuoviclienti',
    privacy: '/blog/it/protezione-dei-dati/',
    privacyUpdate: '/blog/it/aggiorniamo-le-nostre-linee-guida/',
    legalNotice: '/blog/it/colophon/',
    vacancies: '/blog/it/posizioni-aperte/',
    advertisement: '/blog/it/pubblicita-mirata-e-conveniente-su-newhome-ch/',
    security: '/blog/it/sicurezza-su-newhome-ch/',
    partner: '/blog/it/rete/',
    subscribeNewsletter: '/blog/it/iscrizione-alla-newsletter/',
    smkInfo: '/blog/it/camera-svizzera-dei-mediatori-immobiliari-gli-specialisti-immobiliari-testati/',
    factsAndFigures: '/blog/it/pubblicita-mirata-e-conveniente-su-newhome-ch/#datimedia',
    faq: '/blog/it/domande-frequenti/',
    findNewTenant: '/blog/fr/publier-une-annonce/trouve-un-nouveau-locataire-gratuitement/',
    team: '/blog/it/il-team-di-newhome-ch-si-presenta/',
    events: '/blog/de/veranstaltungen-und-events-der-immobilienbranche/',

    providerDirectorySearch: '/it/elenco-delle-agenzie',
    providerDirectoryDetail: '/it/elenco-delle-agenzie/dettagli',
    contact: '/it/contatto',

    debtEnforcementExcerpt: '/blog/it/credittrust-it/',

    mail: '/it/account/casellapostale',
    invoice: '/it/account/fatture',
    advertAdministration: '/it/pubblicaannuncio/gestisci',
    advertAdministrationStatistic: '/it/pubblicaannuncio/scarica',
    businessSubscriptionAdmin: '/it/serviziprofessionali/businesspackage',
    providerDirectoryAdmin: '/it/serviziprofessionali/elencofornitori',
    importAdmin: '/it/serviziprofessionali/portaliimportazione',
    exportAdmin: '/it/professionalservices/exporthub',
    exportPortiaAdmin: '/it/serviziprofessionali/portia',
    partnersiteAdmin: '/it/serviziprofessionali/partnersite',
    businessPlusAdmin: '/it/serviziprofessionali/businessplus',
    businessConnectAdmin: '/it/serviziprofessionali/businessconnector',
    memberArea: '/blog/it/area-riservata-ai-membri/',
    mortgages: '/blog/it/acquistare/ipoteche/',
    moving: '/blog/it/traslocare/',
    pdfFlyer: '/it/pdf/genera',
    pressEnquiries: '/blog/it/press-corner/',
    services: '/blog/it/servizi/',
    sitemap: '/blog/it/sitemap',
    buy: '/blog/it/acquistare/',
    rent: '/blog/it/affittare/',
    letting: '/blog/it/affittare/affittare-un-appartamento/',
    sell: '/blog/it/acquistare/vendita-immobili/',
    lexicon: '', // TODO folgt noch
    immotalk: '/blog/de/kategorie/newhome-news/newhome-talk/',
    search: '/it/wizard/location',
    rentalDeposit: '/blog/it/affittare/firstcaution/',
    emigration: '/blog/it/categoria/emigrare-ch/',
    valuation: '/blog/de/kategorie/kaufen/immobilienbewertung/',
    serviceDescription: '', // TODO folgt noch
    advertiserServices: '/blog/it/servizi/',
    values: '/blog/it/chi-e-newhome-ch/valori-e-cultura/',
    management: '/blog/it/chi-e-newhome-ch/consiglio-di-amministrazione/',
  },
  fr: {
    promotion: '/blog/fr/publier-une-annonce/promotions-fr/',
    advertise: '/blog/fr/publier-une-annonce/',
    advertiseNewTenant:
      '/blog/fr/publier-une-annonce/trouve-un-nouveau-locataire-gratuitement/?utm_source=eigenwerbung&utm_medium=banner&utm_campaign=startseite_nachmieter',
    aboutus: '/blog/fr/qui-est-newhome-ch/',
    agb: '/blog/fr/droits/',
    affordabilityCheck:
      '/blog/fr/acheter/achat-immobilier/nouvelle-fonctionnalite-verification-de-la-capacite-financiere-pour-les-objets-dachat/',
    blog: '/blog/fr/',
    businessCustomer: '/blog/fr/publier-une-annonce/#nouveauclient',
    privacy: '/blog/fr/politique-de-confidentialite/',
    privacyUpdate: '/blog/fr/mise-a-jour-de-nos-directives/',
    legalNotice: '/blog/fr/mentions-legales/',
    vacancies: '/blog/fr/postes-vacants/',
    advertisement: '/blog/fr/faites-une-publicite-ciblee-a-un-prix-avantageux-sur-newhome-ch/',
    security: '/blog/fr/securite-garantie-sur-newhome-ch/',
    partner: '/blog/fr/netzwerk/',
    subscribeNewsletter: '/blog/fr/abonnement-newsletter/',
    smkInfo: '/blog/fr/chambre-suisse-des-courtiers-en-immobilier-les-specialistes-certifies-de-limmobilier/',
    factsAndFigures: '/blog/fr/faites-une-publicite-ciblee-a-un-prix-avantageux-sur-newhome-ch/#donneesmedias',
    faq: '/blog/fr/questions-frequentes/',
    findNewTenant: '/blog/it/pubblicare-annunci-immobiliari/trovare-un-nuovo-inquilino-gratuitamente/',
    team: '/blog/fr/lequipe-de-newhome-ch-se-presente/',
    events: '/blog/de/veranstaltungen-und-events-der-immobilienbranche/',

    providerDirectorySearch: '/fr/repertoiredesprestataires',
    providerDirectoryDetail: '/fr/repertoiredesprestataires/detail',
    contact: '/fr/contact',

    debtEnforcementExcerpt: '/blog/fr/credittrust-fr/',

    mail: '/fr/compte/casepostale',
    invoice: '/fr/compte/factures',
    advertAdministration: '/fr/placerannonce/gerer',
    advertAdministrationStatistic: '/fr/placerannonce/telecharger',
    businessSubscriptionAdmin: '/fr/professionalservices/businesspackage',
    providerDirectoryAdmin: '/fr/professionalservices/repetoirefournisseurs',
    importAdmin: '/fr/professionalservices/passarellesimportation',
    exportAdmin: '/fr/professionalservices/exporthub',
    exportPortiaAdmin: '/fr/servicesprofessionnel/portia',
    partnersiteAdmin: '/fr/professionalservices/partnersite',
    businessPlusAdmin: '/fr/professionalservices/businessplus',
    businessConnectAdmin: '/fr/professionalservices/businesconnector',
    memberArea: '/blog/fr/espace-membre-exclusif/',
    mortgages: '/blog/fr/acheter/hypotheques/',
    moving: '/blog/fr/demenager/',
    pdfFlyer: '/fr/pdf/creer',
    pressEnquiries: '/blog/fr/coin-presse/',
    services: '/blog/fr/services/',
    sitemap: '/blog/fr/sitemap',
    buy: '/blog/fr/acheter/',
    rent: '/blog/fr/louer/',
    letting: '/blog/fr/louer/louer-un-appartement/',
    sell: '/blog/fr/acheter/vente-de-biens-immobiliers/',
    lexicon: '', // TODO folgt noch
    immotalk: '/blog/de/kategorie/newhome-news/newhome-talk/',
    search: '/fr/wizard/location',
    rentalDeposit: '/blog/fr/louer/firstcaution/',
    emigration: '/blog/de/kategorie/auswandern-ch/',
    valuation: '/blog/fr/categorie/acheter/evaluation-immobiliere/',
    serviceDescription: '', // TODO folgt noch
    advertiserServices: '/blog/fr/services/',
    values: '/blog/fr/qui-est-newhome-ch/valeurs-et-culture/',
    management: '/blog/fr/qui-est-newhome-ch/conseil-de-direction/',
  },
};
