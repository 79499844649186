export type IconKey = (typeof ICON_KEYS)[number];
export const ICON_KEYS = [
  '360-view',
  'accessible',
  'balcony',
  'bell',
  'building-land',
  'cable-tv',
  'cards',
  'cards-small',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'caret-small-down',
  'caret-small-up',
  'cellar',
  'clock',
  'checkmark',
  'child-friendly',
  'chimney',
  'close',
  'compartment',
  'close-small',
  'contact',
  'craft-room',
  'edit',
  'electricity',
  'estrich',
  'facebook',
  'flatshare',
  'garage',
  'gas',
  'glass-fibre',
  'hearth',
  'hearth-fill',
  'info',
  'instagram',
  'kitchenshare',
  'lift',
  'link-ext',
  'link-int',
  'linkedin',
  'list',
  'list-small',
  'map',
  'map-fullscreen',
  'map-med',
  'map-small',
  'minergie',
  'minus-big',
  'minus',
  'more',
  'my-newhome',
  'my-newhome-full',
  'newhome-poi',
  'parking-space',
  'pdf',
  'pet',
  'phone-number',
  'plus-big',
  'plus',
  'position',
  'print',
  'rails',
  'report',
  'search',
  'share',
  'shower',
  'storeroom',
  'twitter',
  'view',
  'water',
  'img-placeholder',
  'newhome-poi-line-medium',
  'newhome-poi-line-small',
  'newhome-poi-full',
  'link-share',
  'xing',
  'poi-shopping',
  'poi-school',
  'poi-culture_leisure',
  'poi-sport',
  'poi-food_drink',
  'poi-public_transport',
  'poi-recreation_area',
  'trash',
  'transit',
  'bike',
  'car',
  'walk',
  'mapstyle',
  'sort',
  'sort-360',
  'sort-small',
  'sort-price',
  'sort-price-small',
  'sort-now',
  'sort-now-small',
  'sort-new',
  'sort-new-small',
  'sort-place',
  'toilets',
  'travel-time',
  'badge-new',
  'youtube',
  'car-charging-station',
  'tiktok',
] as const;
