import { offerTypeMap, propertySubTypeMap, propertyTypeMap } from 'app/shared/seo/mappings';
import { OfferType, PropertySubType, PropertyType, SearchPropertyType } from 'newhome.dtos';

const validLanguageCodes = ['de', 'fr', 'it', 'en'];
const isValidLanguageCode = (lang: string) => validLanguageCodes.some(code => code === lang);

export const resolveOfferType = (
  lang: string,
  offerTypeParam: OfferType | string | OfferType[] | string[],
): OfferType | string => {
  if (!isValidLanguageCode(lang)) {
    throw new Error('Invalid language code');
  }

  const offerType = offerTypeParam instanceof Array && offerTypeParam.length ? offerTypeParam[0] : offerTypeParam;

  const languageMap = offerTypeMap[lang];
  const mappedOfferType = languageMap[offerType];
  if (!mappedOfferType) {
    throw new Error('Invalid OfferType');
  }

  return mappedOfferType;
};

export const resolvePropertyType = (
  lang: string,
  propertyType: SearchPropertyType | PropertyType | string,
): SearchPropertyType | string => {
  if (!isValidLanguageCode(lang)) {
    throw new Error('Invalid language code');
  }

  const languageMap = propertyTypeMap[lang];
  const mappedPropertyType = languageMap[propertyType];
  if (!mappedPropertyType) {
    throw new Error('Invalid PropertyType');
  }

  return mappedPropertyType;
};

export const resolvePropertySubType = (
  lang: string,
  propertySubType: PropertySubType | string,
): PropertySubType | string => {
  if (!isValidLanguageCode(lang)) {
    throw new Error('Invalid language code');
  }

  const languageMap = propertySubTypeMap[lang];
  const mappedPropertySubType = languageMap[propertySubType];
  if (!mappedPropertySubType) {
    return undefined;
  }

  return mappedPropertySubType;
};
