import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'environments/environment';
import { of, switchMap } from 'rxjs';
import * as AdmeiraActions from './admeira.actions';

@Injectable()
export class AdmeiraEffects {
  private readonly window: Window | undefined;

  readonly checkAdmeiraSupport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdmeiraActions.checkAdmeiraSupport),
      switchMap(() => {
        const supported =
          isPlatformBrowser(this.platformId) &&
          environment.admeira.enabled &&
          this.window['newhomeNoAdmeiraAdverts'] !== true;

        const successAction = AdmeiraActions.checkAdmeiraSupportSuccess({ supported });
        return of(successAction);
      })
    )
  );

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly actions$: Actions<AdmeiraActions.AdmeiraActionUnion>,
  ) {
    this.window = document?.defaultView;
  }
}
