import { Directive, EventEmitter } from '@angular/core';
import { AbstractControlDirective, NgControl } from '@angular/forms';

@Directive()
export abstract class AbstractFormFieldControl<T> {
  value: T | null;

  readonly id: string;
  readonly ngControl: NgControl | AbstractControlDirective | null;
  readonly element: HTMLElement;
  readonly required: boolean;
  readonly hasErrors: boolean;
  readonly touched: boolean;
  readonly disabled: boolean;
  readonly readonly: boolean;

  readonly blur: EventEmitter<FocusEvent>;
  readonly focus: EventEmitter<FocusEvent>;
}

export interface FormFieldControl<T> extends AbstractFormFieldControl<T> {}
