import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JsonServiceClient } from '@servicestack/client';
import { addSearchLocations } from 'app/entities/search-location/search-location.actions';
import * as fromRoot from 'app/reducers';
import * as RouterSelectors from 'app/router.selectors';
import { GetUserInfoRequest, GetUserProfileRequest, LocationRequest } from 'newhome.dtos';
import { from, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import * as UserData from './user-data.actions';
import { APP_BASE_HREF, isPlatformServer } from '@angular/common';

@Injectable()
export class UserDataEffects {
  beginLoadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserData.beginLoadProfile),
      switchMap(_ => from(this.client.get(new GetUserProfileRequest()))),
      switchMap(profileResponse => {
        if (profileResponse?.profile?.searchLocationIdentifiers?.length > 0) {
          return this.store.select(RouterSelectors.selectLanguage).pipe(
            first(),
            switchMap(languageIso => {
              if (isPlatformServer(this.platformId)) {
                console.log(`LocationRequest called from UserDataEffects with: ${this.requestUrl}`);
              }

              const request = new LocationRequest({
                identifiers: profileResponse.profile.searchLocationIdentifiers,
                languageIso,
              });

              return from(this.client.get(request)).pipe(
                map(r => addSearchLocations({ searchLocations: r.identiferInfos })),
                tap(action => this.store.dispatch(action)),
                map(_ => profileResponse),
              );
            }),
          );
        }

        return of(profileResponse);
      }),
      map(profileResponse =>
        UserData.loadProfileSuccessful({
          profile: profileResponse?.profile,
        }),
      ),
      catchError(_ => of(UserData.loadProfileFailed())),
    );
  });

  beginLoadInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserData.beginLoadUserInfo),
      switchMap(_ => from(this.client.get(new GetUserInfoRequest()))),
      map(infoResponse =>
        UserData.loadUserInfoSuccessful({
          userInfo: infoResponse?.userInfo,
        }),
      ),
      catchError(_ => of(UserData.loadUserInfoFailed())),
    );
  });

  constructor(
    private readonly client: JsonServiceClient,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Optional() @Inject(APP_BASE_HREF) private readonly requestUrl: string,
    private readonly actions$: Actions,
    private readonly store: Store<fromRoot.State>,
  ) {}
}
