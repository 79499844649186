// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
export const supportedInputTypes = [
  'email',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'url'
];
