import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

export const searchSubscriptionMatcher: UrlMatcher = (
  segments: UrlSegment[],
  groups: UrlSegmentGroup,
): UrlMatchResult => {
  const languageIndex = 0;
  const searchSubscriptionIndex = 1;

  const searchSubscriptionKeywordLocalized = segments[searchSubscriptionIndex]?.path;
  const searchSubscriptionPattern = /^(suchabo|search-subscription|alertes|abbonamenti-ricerca)$/;
  if (!searchSubscriptionKeywordLocalized?.match(searchSubscriptionPattern)) {
    return null;
  }

  const posParams = {
    lang: segments[languageIndex],
  };

  return {
    consumed: segments.slice(0, searchSubscriptionIndex + 1),
    posParams,
  };
};
