import { Action, createReducer, on } from '@ngrx/store';
import { ObjectsViewType } from 'app/shared/objects/objects-view-type.enum';
import { AdvertOrderType } from 'newhome.dtos';
import * as SettingsActions from './settings.actions';

export const settingsFeatureKey = 'settings';

export interface State {
  orderType: AdvertOrderType;
  listViewType: ObjectsViewType;
}

export const initialState: State = {
  orderType: AdvertOrderType.Default,
  listViewType: ObjectsViewType.Tiles,
};

const settingsReducer = createReducer(
  initialState,

  on(SettingsActions.setSettings, (state, actions) => {
    return {
      ...state,
      ...actions.settings,
    };
  }),

  on(SettingsActions.updateListViewType, (state, action) => {
    return {
      ...state,
      listViewType: action.viewType,
    };
  }),

  on(SettingsActions.updateSortOrder, (state, action) => {
    return {
      ...state,
      orderType: action.orderType,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return settingsReducer(state, action);
}
