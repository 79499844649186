import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { JsonServiceClient } from '@servicestack/client';
import { OAuthService } from 'app/shared/oauth2-oidc/lib/public_api';
import { getObjectDifference } from 'app/shared/utilities';
import {
  AddTravelDestinationRequest,
  AddTravelDestinationResponse,
  RemoveTravelDestinationRequest,
  TravelDestination,
  UpdateTravelDestinationRequest,
} from 'newhome.dtos';
import { ToastrService } from 'ngx-toastr';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as TravelDestinationActions from './travel-destination.actions';
import * as fromTravelDestination from './travel-destination.reducer';

export const travelDestinationLocalStorageKey = 'localTravelDestination';

@Injectable()
export class TravelDestinationEffects {
  beginAddTravelDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TravelDestinationActions.beginAddTravelDestination),
      filter(_ => isPlatformBrowser(this.platformId)),
      switchMap(action => {
        return this.oauthService.isLoggedin().pipe(
          take(1),
          switchMap(isLoggedin => {
            if (isLoggedin) {
              return this.addToRemoteTravelDestination$(action.entry);
            } else {
              return this.addToLocalTravelDestination$(action.entry);
            }
          }),
        );
      }),
    );
  });

  beginDeleteTravelDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TravelDestinationActions.beginDeleteTravelDestination),
      filter(_ => isPlatformBrowser(this.platformId)),
      switchMap(action => {
        return this.oauthService.isLoggedin().pipe(
          take(1),
          switchMap(isLoggedin => {
            if (isLoggedin) {
              return this.removeFromRemoteTravelDestination$(action.id);
            } else {
              return this.removeFromLocalTravelDestination$(action.id);
            }
          }),
        );
      }),
    );
  });

  beginLoadLocalTravelDestinations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TravelDestinationActions.beginLoadLocalTravelDestinations),
      switchMap(_ => this.loadLocalTravelDestinations()),
    );
  });

  beginUpdateTravelDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TravelDestinationActions.beginUpdateTravelDestination),
      filter(_ => isPlatformBrowser(this.platformId)),
      withLatestFrom(this.store.select(fromTravelDestination.selectTravelDestinations)),
      switchMap(([action, destinations]) => {
        return this.oauthService.isLoggedin().pipe(
          switchMap(isLoggedin => {
            if (isLoggedin) {
              return this.updateRemoteTravelDestination$(action.entry, destinations);
            } else {
              return this.updateLocalTravelDestination$(action.entry, destinations);
            }
          }),
        );
      }),
    );
  });

  removeLocalTravelDestinationEntries$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TravelDestinationActions.removeLocalTravelDestinationEntries),
        filter(_ => isPlatformBrowser(this.platformId)),
        tap(_ => localStorage.setItem(travelDestinationLocalStorageKey, JSON.stringify([]))),
      );
    },
    { dispatch: false },
  );

  addTravelDestination$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TravelDestinationActions.addTravelDestination),
        tap(a => {
          this.toastr.success(this.translate.instant('SHARED.TRAVEL_DESTINATION.ADDED_SUCCESS'));
        }),
      );
    },
    { dispatch: false },
  );

  addTravelDestinations$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TravelDestinationActions.addTravelDestinations),
        filter(_ => isPlatformBrowser(this.platformId)),
        switchMap(action => {
          return this.oauthService.isLoggedin().pipe(
            take(1),
            switchMap(isLoggedin => {
              if (isLoggedin) {
                return of(
                  action.entries.forEach(entry => {
                    return this.addToRemoteTravelDestination$(entry);
                  }),
                );
              } else {
                return of(
                  action.entries.forEach(entry => {
                    return this.addToLocalTravelDestination$(entry);
                  }),
                );
              }
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );

  deleteTravelDestination$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TravelDestinationActions.deleteTravelDestination),
        tap(a => {
          this.toastr.success(this.translate.instant('SHARED.TRAVEL_DESTINATION.REMOVED_SUCCESS'));
        }),
      );
    },
    { dispatch: false },
  );

  deleteTravelDestinations$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TravelDestinationActions.deleteTravelDestinations),
        filter(_ => isPlatformBrowser(this.platformId)),
        switchMap(action => {
          return this.oauthService.isLoggedin().pipe(
            take(1),
            switchMap(isLoggedin => {
              if (isLoggedin) {
                return of(
                  action.ids.forEach(id => {
                    return this.removeFromRemoteTravelDestination$(id);
                  }),
                );
              } else {
                return of(
                  action.ids.forEach(id => {
                    return this.removeFromLocalTravelDestination$(id);
                  }),
                );
              }
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );

  updateTravelDestination$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TravelDestinationActions.updateTravelDestination),
        tap(a => {
          this.toastr.success(this.translate.instant('SHARED.TRAVEL_DESTINATION.UPDATED_SUCCESS'));
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly client: JsonServiceClient,
    private readonly oauthService: OAuthService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private store: Store<fromTravelDestination.State>,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {}

  private loadLocalTravelDestinations() {
    let itemAsJson = '[]';
    if (isPlatformBrowser(this.platformId)) {
      itemAsJson = localStorage?.getItem(travelDestinationLocalStorageKey) || '[]';
    }
    const localTravelDestination: TravelDestination[] = JSON.parse(itemAsJson);
    const tempTravelDestinations = this.temporaryMapTravelDestinations(localTravelDestination);

    return of(
      TravelDestinationActions.loadTravelDestinations({
        entries: tempTravelDestinations,
      }),
    );
  }

  private addToLocalTravelDestination$(entry: TravelDestination) {
    const itemAsJson = localStorage.getItem(travelDestinationLocalStorageKey) || '[]';
    const localTravelDestination: TravelDestination[] = JSON.parse(itemAsJson);

    const isDuplicate = localTravelDestination.findIndex(p => p.id === entry.id) > -1;

    if (!isDuplicate) {
      localTravelDestination.push(entry);
      localStorage.setItem(travelDestinationLocalStorageKey, JSON.stringify(localTravelDestination));
    }

    return of(TravelDestinationActions.addTravelDestination({ entry }));
  }

  private addToRemoteTravelDestination$(entry: TravelDestination) {
    const request = new AddTravelDestinationRequest({
      travelDestination: entry,
    });

    return from(this.client.put(request)).pipe(
      map((response: AddTravelDestinationResponse) =>
        TravelDestinationActions.addTravelDestination({
          entry: response.travelDestination,
        }),
      ),
      catchError(_ => [TravelDestinationActions.addTravelDestinationFailed()]),
    );
  }

  private removeFromLocalTravelDestination$(id: number) {
    const itemAsJson = localStorage.getItem(travelDestinationLocalStorageKey) || '[]';
    const localTravelDestination: TravelDestination[] = JSON.parse(itemAsJson);

    const isExisting = localTravelDestination.findIndex(p => p.id === id) > -1;

    if (isExisting) {
      localStorage.setItem(
        travelDestinationLocalStorageKey,
        JSON.stringify(localTravelDestination.filter(x => x.id !== id)),
      );
      return of(TravelDestinationActions.deleteTravelDestination({ id }));
    } else {
      return of(TravelDestinationActions.deleteTravelDestinationFailed());
    }
  }

  private removeFromRemoteTravelDestination$(id: number) {
    const request = new RemoveTravelDestinationRequest({
      travelDestinationId: +id,
    });

    return from(this.client.delete(request)).pipe(
      map(_ => TravelDestinationActions.deleteTravelDestination({ id })),
      catchError(_ => [TravelDestinationActions.deleteTravelDestinationFailed()]),
    );
  }

  private updateLocalTravelDestination$(entry: TravelDestination, currentEntries: TravelDestination[]) {
    const newTravelDestinations = currentEntries.map(d => (d.id === entry.id ? { ...d, ...entry } : d));

    const oldEntry = currentEntries.find(d => d.id === entry.id);
    const newTravelDestinationsAsJson = JSON.stringify(newTravelDestinations);

    localStorage.setItem(travelDestinationLocalStorageKey, newTravelDestinationsAsJson);

    const changes = getObjectDifference(oldEntry, entry);
    const editSubmission: Update<TravelDestination> = {
      id: entry.id,
      changes,
    };

    return of(
      TravelDestinationActions.updateTravelDestination({
        entry: editSubmission,
      }),
    );
  }

  private updateRemoteTravelDestination$(entry: TravelDestination, currentEntries: TravelDestination[]) {
    const oldEntry = currentEntries.find(d => d.id === entry.id);
    const request = new UpdateTravelDestinationRequest({
      travelDestination: entry,
    });

    const changes = getObjectDifference(oldEntry, entry);
    const editSubmission: Update<TravelDestination> = {
      id: entry.id,
      changes,
    };

    return from(this.client.put(request)).pipe(
      map(_ =>
        TravelDestinationActions.updateTravelDestination({
          entry: editSubmission,
        }),
      ),
    );
  }

  // TODO: Remove at January 2021 (only needed for the current change of traveltimes)
  private temporaryMapTravelDestinations(value: TravelDestination[]): TravelDestination[] {
    return value?.map(
      d =>
        new TravelDestination({
          ...d,
          maxTravelTime: d.maxTravelTime || 30,
          searchType: d.searchType || 4,
        }),
    );
  }
}
