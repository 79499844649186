import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { JsonServiceClient } from '@servicestack/client';
import { addSearchLocations } from 'app/entities/search-location/search-location.actions';
import * as fromRoot from 'app/reducers';
import * as RouterSelectors from 'app/router.selectors';
import { notFoundMap, landingMap } from 'app/shared/seo/mappings';
import { hasFilterInQueryString } from 'app/shared/utilities';
import { LocationRequest, LocationResolveRequest } from 'newhome.dtos';
import { from, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
@Injectable()
export class DiscoverGuard  {
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(RouterSelectors.selectRouter).pipe(
      first(),
      switchMap(router => {
        if (
          hasFilterInQueryString(router.state.queryParams) &&
          router.state.queryParams.location &&
          !router.state.queryParams.travelDestinations &&
          !router.state.queryParams.radiusCenterCoordinate
        ) {
          if (isPlatformServer(this.platformId)) {
            console.log(`LocationRequest called from DiscoverGuard with: ${this.document?.location?.href ?? ''}`);
          }

          return this.resolveLocationsFromIdIdentifier(router.state.queryParams.location, router.state.lang);
        } else if (router.state.params.locationIdentifier) {
          const { locationIdentifier, lang } = router.state.params;
          return this.resolveLocationFromSeoIdentifier(locationIdentifier, lang);
        }

        return of(true);
      }),
    );
  }

  resolveLocationFromSeoIdentifier(identifier: string, lang: string): Observable<boolean | UrlTree> {
    const request = new LocationResolveRequest({
      keyword: identifier,
    });

    return from(this.client.get(request)).pipe(
      map(r => addSearchLocations({ searchLocations: [r] })),
      tap(action => this.store.dispatch(action)),
      map(_ => true),
      catchError(_ => {
        this.toastr.error(this.translate.instant('DISCOVER.RESULT.SEO_NOT_FOUND'));
        return of(this.router.createUrlTree(['/', lang, landingMap[lang]]));
      }),
    );
  }

  resolveLocationsFromIdIdentifier(identifiers: string[], languageIso: string): Observable<boolean | UrlTree> {
    const request = new LocationRequest({ identifiers, languageIso });

    return from(this.client.get(request)).pipe(
      map(r => addSearchLocations({ searchLocations: r.identiferInfos })),
      tap(action => this.store.dispatch(action)),
      map(_ => true),
      catchError(_ => of(this.router.parseUrl(`/${languageIso}/${notFoundMap[languageIso]}`))),
    );
  }

  constructor(
    private client: JsonServiceClient,
    private store: Store<fromRoot.State>,
    private router: Router,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
