<button
  nhButton
  type="button"
  class="button-toggle"
  [class.button-toggle--checked]="checked"
  [theme]="theme"
  [size]="size"
  [id]="buttonToggleId"
  [disabled]="disabled"
  (click)="onButtonClick()"
>
  <nh-button-toggle-checkmark
    *ngIf="showCheckmark && checked"
    class="button-toggle__checkmark">
  </nh-button-toggle-checkmark>

  <ng-content></ng-content>
</button>
