import { createAction, props } from '@ngrx/store';
import { UserInfo, UserProfile } from 'newhome.dtos';

export const beginLoadProfile = createAction('[Profile] Begin Load UserProfile');
export const loadProfileSuccessful = createAction(
  '[Profile] Load UserProfile Successful',
  props<{ profile: UserProfile }>(),
);
export const loadProfileFailed = createAction('[Profile] Load UserProfile Failed');

export const beginLoadUserInfo = createAction('[Profile] Begin Load UserInfo');
export const loadUserInfoSuccessful = createAction(
  '[Profile] Load UserInfo Successful',
  props<{ userInfo: UserInfo }>(),
);
export const loadUserInfoFailed = createAction('[Profile] Load UserInfo Failed');

export const clearUserStateAfterLogout = createAction('[Profile] Clear User State After Logout');