import { UrlMatcher, UrlSegment, UrlMatchResult } from '@angular/router';
import { availableLanguages } from 'app/shared/utilities';
import { propertyNotFoundMap, offerTypeMap, detailPrefixMap } from 'app/shared/seo/mappings';

export const propertyMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
  // immo shorturl lookahead
  const [shortUrlSegment] = segments;
  if (shortUrlSegment?.path === 'id') {
    return {
      // do not consume anything - we are actually consuming 'id' in our sub-router
      consumed: [],
    };
  }

  const [languageSegment] = segments;
  if (!availableLanguages.some(p => p === languageSegment?.path)) {
    // invalid language
    return null;
  }

  // property-not-found subroute lookahead
  const propertyNotFoundRoute = propertyNotFoundMap[languageSegment?.path];
  const [, propertyNotFoundSegment] = segments;
  if (segments.length === 2 && propertyNotFoundSegment.path === propertyNotFoundRoute) {
    return {
      consumed: [languageSegment],
      posParams: {
        lang: languageSegment,
      },
    };
  }

  // detail-seo subroute lookahead
  const [, offerTypeSegment, detailSegment] = segments;
  const offerTypeRoutes = Object.values(offerTypeMap[languageSegment.path]);
  const offerTypeMatches = offerTypeRoutes.some(p => p === offerTypeSegment?.path);
  const detailRouteMatches = detailPrefixMap[languageSegment?.path] === detailSegment?.path;
  if (offerTypeMatches && detailRouteMatches) {
    return {
      consumed: [languageSegment],
      posParams: {
        lang: languageSegment,
      },
    };
  }

  return null;
};
