import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@newhome/components/button';
import { ButtonToggleModule } from '@newhome/components/button-toggle';
import { FormFieldModule } from '@newhome/components/form-field';
import { IconModule } from '@newhome/components/icon';
import { LinkModule } from '@newhome/components/link';
import { RadioModule } from '@newhome/components/radio';
import { SwitchModule } from '@newhome/components/switch';
import { ThousandSeparatorPipeModule } from 'app/shared/pipes/thousand-separator-pipe/thousand-separator-pipe.module';
import { ComponentsRoutingModule } from './components-routing.module';
import { ComponentsComponent } from './components.component';
import { CheckboxModule } from '@newhome/components/checkbox';
import { PillModule } from '@newhome/components/pill/pill.module';
import { LabeledCheckboxModule } from '@newhome/components/labeled-checkbox';
import { BadgeModule } from '@newhome/components/badge';
import { SelectModule } from '@newhome/components/select';
import { OptionModule } from '@newhome/components/common/option';
import { AutocompleteModule } from '@newhome/components/autocomplete';
import { OptionHighlightModule } from 'app/shared/option-highlight/option-highlight.module';
import { InputStepperModule } from 'app/shared/input-stepper/input-stepper.module';

const COMPONENTS = [
  SwitchModule,
  RadioModule,
  ButtonModule,
  ButtonToggleModule,
  LinkModule,
  IconModule,
  FormFieldModule,
  CheckboxModule,
  PillModule,
  LabeledCheckboxModule,
  SelectModule,
  OptionModule,
  AutocompleteModule,
  BadgeModule,
  InputStepperModule,
];

@NgModule({
  declarations: [ComponentsComponent],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ThousandSeparatorPipeModule,
    OptionHighlightModule,
    ...COMPONENTS,
  ],
})
export class ComponentsModule {}
