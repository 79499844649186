import { createAction, props, union } from '@ngrx/store';
import { Mandant } from 'newhome.dtos';

export const beginLoadCantonalBanks = createAction('[Cantonal banks] Load Cantonal Banks');

export const addCantonalBanks = createAction(
  '[Cantonal banks] Load Cantonal Banks Success',
  props<{ cantonalBanks: Mandant[] }>(),
);

const allActions = union({
  beginLoadCantonalBanks,
  addCantonalBanks,
});

export type CantonalBanksActionUnion = typeof allActions;
