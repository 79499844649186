import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfferType, SearchListingRequest } from 'newhome.dtos';
import { RouterStateUrl } from './custom-route-serializer';
import * as fromRoot from './reducers';
import { offerTypeMap } from './shared/seo/mappings';

export const selectRouter = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectImmoCode = createSelector(selectRouter, router => router && router.state.params.immoCode);
export const selectParams = createSelector(selectRouter, router => router && router.state.params);
export const selectLanguage = createSelector(selectRouter, router => router?.state?.lang ?? 'de');
export const selectQueryParams = createSelector(selectRouter, router => router && router.state.queryParams);
export const selectReturnUrl = createSelector(selectRouter, router => router && router.state.queryParams.returnUrl);
export const selectViewType = createSelector(selectRouter, router => router && +router.state.queryParams.viewType);
export const selectUrl = createSelector(selectRouter, router => router && router.state.url);
export const selectFilter = createSelector(selectUrl, selectQueryParams, (url, params) => {
  if (!params) {
    return new SearchListingRequest();
  }

  const [language, offerTypeSegment] = url.split('/').filter(Boolean);

  // OfferType can be read from a query param or url segment
  let offerType: OfferType;
  if (params.offerType && params.offerType.length > 0) {
    offerType = params.offerType instanceof Array && params.offerType.length ? +params.offerType[0] : +params.offerType;
  } else if (language && offerTypeMap[language] && offerTypeSegment && offerTypeMap[language][offerTypeSegment]) {
    offerType = offerTypeMap[language][offerTypeSegment];
  } else {
    offerType = OfferType.Rent;
  }

  return new SearchListingRequest({
    offerType: offerType,
    location: params.location && [].concat(params.location).filter(itm => !!itm),
    propertyType: params.propertyType && +params.propertyType,
    roomsMin: params.roomsMin && +params.roomsMin,
    roomsMax: params.roomsMax && +params.roomsMax,
    numberOfVehiclesMin: params.numberOfVehiclesMin && +params.numberOfVehiclesMin,
    numberOfVehiclesMax: params.numberOfVehiclesMax && +params.numberOfVehiclesMax,
    livingAreaMin: params.livingAreaMin && +params.livingAreaMin,
    livingAreaMax: params.livingAreaMax && +params.livingAreaMax,
    usableAreaMin: params.usableAreaMin && +params.usableAreaMin,
    usableAreaMax: params.usableAreaMax && +params.usableAreaMax,
    plotAreaMin: params.plotAreaMin && +params.plotAreaMin,
    plotAreaMax: params.plotAreaMax && +params.plotAreaMax,
    priceMin: params.priceMin && +params.priceMin,
    priceMax: params.priceMax && +params.priceMax,
    priceM2Min: params.priceM2Min && +params.priceM2Min,
    priceM2Max: params.priceM2Max && +params.priceM2Max,
    floorLoadMin: params.floorLoadMin && +params.floorLoadMin,
    floorLoadMax: params.floorLoadMax && +params.floorLoadMax,
    roomHeightMin: params.roomHeightMin && Math.round(+params.roomHeightMin),
    roomHeightMax: params.roomHeightMax && Math.round(+params.roomHeightMax),
    constructionYearFrom: params.constructionYearFrom && +params.constructionYearFrom,
    grossReturnFrom: params.grossReturnFrom && +params.grossReturnFrom,
    rowCount: +params.rowCount || 20,
    skipCount: params.skipCount && +params.skipCount,
    radius: params.radius && +params.radius,
    order: params.order,
    propertySubtypes:
      params.propertySubtypes &&
      []
        .concat(params.propertySubtypes)
        .filter(itm => !!itm)
        .map(itm => +itm),
    equipments:
      params.equipments &&
      []
        .concat(params.equipments)
        .filter(itm => !!itm)
        .map(itm => +itm),
    travelDestinations: params.travelDestinations && [].concat(params.travelDestinations).filter(itm => !!itm),
    radiusCenterCoordinate: params.radiusCenterCoordinate,
    seoIdentifier: url.split('?')[0],
  });
});
