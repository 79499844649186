import { createAction, props } from '@ngrx/store';
import { SearchLocation } from 'newhome.dtos';
import { PreviousSearchFilter } from './previous-search.reducer';

export const setPreviousSearch = createAction(
  '[Previous Search] Set PreviousSearch',
  props<{
    searchLocations: SearchLocation[];
    latestSearches: Partial<PreviousSearchFilter>[];
  }>(),
);

export const beginUpdateLatestSearches = createAction(
  '[Previous Search] Begin Update Latest Searches',
  props<{
    currentSearchFilter: Partial<PreviousSearchFilter>;
  }>(),
);

export const updateLatestSearches = createAction(
  '[Previous Search] Update Latest Searches',
  props<{
    latestSearches: Partial<PreviousSearchFilter>[];
  }>(),
);

export const updatePreviousSearchLocations = createAction(
  '[Previous Search] Update Previous Search Locations',
  props<{
    searchLocations: SearchLocation[];
  }>(),
);
