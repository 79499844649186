import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { firstCharToUppercase, getResultTitle } from 'app/shared/utilities';
import {
  IAdvertSearchFilter,
  ListingDetail,
  OfferType,
  PropertySubType,
  PropertyType,
  SearchListingRequest,
  SearchLocation,
  SearchPropertyType,
  SeoInformation,
} from 'newhome.dtos';
import { GtmService } from '../gtm/gtm.service';
import { PriceService } from './price.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(
    private title: Title,
    private translate: TranslateService,
    private meta: Meta,
    private gtm: GtmService,
    private priceService: PriceService,
  ) {}

  public setLandingTitle(searchFilter: IAdvertSearchFilter) {
    const title = this.getLandingTitle(searchFilter);

    this.setTitle(title);
  }

  public setResultTitle(
    searchFilter: SearchListingRequest,
    searchLocations: SearchLocation[],
    seoInformation: SeoInformation,
  ) {
    const browserTitleTag = seoInformation?.browserTitleTag?.trim() ?? '';
    if (browserTitleTag.length > 0) {
      this.setTitle(browserTitleTag);
    } else {
      const title = getResultTitle(searchFilter, searchLocations, 'DISCOVER.RESULT.SEO_TITLE', this.translate, false);
      this.setTitle(title);
    }
  }

  public setDetailTitle(detail: ListingDetail) {
    const title = this.getDetailTitle(detail);
    this.setTitle(title);
  }

  public setDefaultTitle() {
    const title = this.translate.instant('APP.SEO_TITLE');
    this.title.setTitle(title);
    this.meta.updateTag({
      property: 'og:title',
      content: title,
    });
  }

  private setTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({
        property: 'og:title',
        content: title,
      });

      this.gtm.pushCustomTag({
        'page.name': title,
      });
    }
  }

  private getLandingTitle(searchFilter: IAdvertSearchFilter): string {
    if (searchFilter.offerType) {
      const offerTypeText = this.translate.instant('ENUM.OFFERTYPE.' + OfferType[searchFilter.offerType]).toLowerCase();
      if (searchFilter.propertySubtypes && searchFilter.propertySubtypes.length === 1) {
        const propertyTypeText = this.translate.instant(
          'ENUM.PROPERTYSUBTYPE.' + PropertySubType[searchFilter.propertySubtypes[0]],
        );

        return this.translate.instant('DISCOVER.LANDING.SEO_TITLE', {
          offerTypeText,
          propertyTypeText,
        });
      } else if (searchFilter.propertyType) {
        const propertyTypeText = this.translate.instant(
          'ENUM.PROPERTYTYPE.' + SearchPropertyType[searchFilter.propertyType],
        );

        return this.translate.instant('DISCOVER.LANDING.SEO_TITLE', {
          offerTypeText,
          propertyTypeText,
        });
      }
    }

    return this.translate.instant('DISCOVER.LANDING.SEO_TITLE_DEFAULT');
  }

  private getDetailTitle(detail: ListingDetail): string {
    if (detail.propertyType && detail.offerType && detail.location) {
      const propertySubType =
        detail.detail.propertySubType &&
        this.translate.instant(`ENUM.PROPERTYSUBTYPE.${PropertySubType[detail.detail.propertySubType]}`);
      const offerType = this.translate.instant('ENUM.OFFERTYPE.' + OfferType[detail.offerType]).toLowerCase();
      const location = detail.location.displayName;
      const rooms = detail.detail.rooms?.toString().replace('.5', ' 1/2');
      const price = this.priceService.getPriceString(detail);
      const plotArea = detail.detail.plotArea && `${detail.detail.plotArea} m²`;

      let parts: string[];

      switch (detail.propertyType) {
        case PropertyType.House:
          parts = this.getDetailHouseTitle(offerType, propertySubType, location, rooms);
          break;
        case PropertyType.Apartment:
          parts = this.getDetailApartmentTitle(offerType, propertySubType, location, rooms);
          break;

        case PropertyType.Apartmenthouse:
          parts = this.getDetailApartmenthouseTitle(offerType, location, price);
          break;

        case PropertyType.Business:
          parts = this.getDetailBusinessTitle(propertySubType, offerType, location, price);
          break;

        case PropertyType.Buildingland:
          parts = this.getDetailBuildinglandTitle(propertySubType, offerType, location, plotArea);
          break;

        case PropertyType.Parkingspace:
          parts = this.getDetailParkingspaceTitle(propertySubType, offerType, location, price);
          break;
      }

      parts.push(this.translate.instant('DETAIL.SEO.TITLE_END'));

      return firstCharToUppercase(parts.join(' '));
    }

    return undefined;
  }

  private getDetailHouseTitle(
    offerType: OfferType,
    propertySubType: PropertySubType,
    location: string,
    rooms: string,
  ): string[] {
    let propertyTypeName = PropertyType[PropertyType.House];
    if (!rooms) {
      propertyTypeName = propertyTypeName + '_without_rooms';
    }

    return [
      this.translate.instant('DETAIL.SEO.TITLE_START.' + propertyTypeName, {
        offerType,
        propertySubType,
        location,
        rooms,
      }),
    ];
  }

  private getDetailApartmentTitle(
    offerType: string,
    propertySubType: string,
    location: string,
    rooms: string,
  ): string[] {
    let propertyTypeName = PropertyType[PropertyType.Apartment];
    if (!rooms) {
      propertyTypeName = propertyTypeName + '_without_rooms';
    }

    return [
      this.translate.instant('DETAIL.SEO.TITLE_START.' + propertyTypeName, {
        offerType,
        propertySubType,
        location,
        rooms,
      }),
    ];
  }

  private getDetailApartmenthouseTitle(offerType: string, location: string, price: string): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.TITLE_START.' + PropertyType[PropertyType.Apartmenthouse], {
        offerType,
        location,
      }),
    ];

    if (price) {
      parts.push(
        this.translate.instant('DETAIL.SEO.FOR_PRICE', {
          price,
        }),
      );
    }

    return parts;
  }

  private getDetailBusinessTitle(
    propertySubType: string,
    offerType: string,
    location: string,
    price: string,
  ): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.TITLE_START.' + PropertyType[PropertyType.Business], {
        propertySubType,
        offerType,
        location,
      }),
    ];

    if (price) {
      parts.push(
        this.translate.instant('DETAIL.SEO.FOR_PRICE', {
          price,
        }),
      );
    }

    return parts;
  }

  private getDetailBuildinglandTitle(
    propertySubType: string,
    offerType: string,
    location: string,
    plotArea: string,
  ): string[] {
    return [
      this.translate.instant('DETAIL.SEO.TITLE_START.' + PropertyType[PropertyType.Buildingland], {
        propertySubType,
        offerType,
        location,
        plotArea,
      }),
    ];
  }

  private getDetailParkingspaceTitle(
    propertySubType: string,
    offerType: string,
    location: string,
    price: string,
  ): string[] {
    const parts: string[] = [
      this.translate.instant('DETAIL.SEO.TITLE_START.' + PropertyType[PropertyType.Parkingspace], {
        propertySubType,
        offerType,
        location,
      }),
    ];

    if (price) {
      parts.push(
        this.translate.instant('DETAIL.SEO.FOR_PRICE', {
          price,
        }),
      );
    }

    return parts;
  }
}
