import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdmeiraEffects } from './admeira.effects';
import { admeiraFeatureKey, reducer } from './admeira.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(admeiraFeatureKey, reducer),
    EffectsModule.forFeature([AdmeiraEffects])
  ],
})
export class AdmeiraModule {}
