import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { ButtonToggleComponent } from './button/button-toggle.component';
import { ButtonToggleCheckmarkComponent } from './button/checkmark/button-toggle-checkmark.component';
import { ButtonToggleGroupComponent } from './group/button-toggle-group.component';

@NgModule({
  declarations: [ButtonToggleGroupComponent, ButtonToggleComponent, ButtonToggleCheckmarkComponent],
  imports: [CommonModule, ButtonModule],
  exports: [ButtonToggleGroupComponent, ButtonToggleComponent],
})
export class ButtonToggleModule {}
