import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import {
  listPrefixMap,
  listSuffixMap,
  locationTypeMap,
  offerTypeMap,
  propertySubTypeMap,
  propertyTypeMap,
  customPerimeterMap,
} from 'app/shared/seo/mappings';
import { availableLanguages } from 'app/shared/utilities';
import { staticRoutes } from './static-routes';

const validatePropertySubType = (segment: UrlSegment, language: string): boolean => {
  const propertySubType = propertySubTypeMap[language][segment.path];
  return propertySubType !== undefined;
};

const validateLocationIdentifier = (segment: UrlSegment, language: string): boolean => {
  const split = segment.path.split('-');
  if (split.length === 1) {
    return false;
  }

  const locationTypeIdentifier = split[0];
  const locationTypeLanguageMap = locationTypeMap[language];

  return Object.values(locationTypeLanguageMap).some(p => p === locationTypeIdentifier);
};

const validateCustomPerimeter = (segment: UrlSegment, language: string) => {
  const customPerimeterPath = segment.path;
  const customPerimeterLanguageMap = customPerimeterMap[language];
  return customPerimeterPath === customPerimeterLanguageMap;
};

interface ParseResult {
  valid: boolean;
  result?: any;
}

/**
 * Check `discover-routing.md` for further details
 */
const parseDiscoverWildcardSegments = (segments: UrlSegment[], language: string): ParseResult => {
  if (segments.length === 2) {
    const propertySubTypeIsValid = validatePropertySubType(segments[0], language);
    const locationIdentifierIsValid = validateLocationIdentifier(segments[1], language);
    const valid = propertySubTypeIsValid && locationIdentifierIsValid;

    return {
      valid,
      result: {
        propertySubType: segments[0],
        locationIdentifier: segments[1],
      },
    };
  }
  if (segments.length === 1) {
    const locationIdentifierIsValid = validateLocationIdentifier(segments[0], language);

    const customPerimeterIsValid = validateCustomPerimeter(segments[0], language);

    if (locationIdentifierIsValid) {
      return {
        valid: locationIdentifierIsValid,
        result: {
          locationIdentifier: segments[0],
        },
      };
    } else if (customPerimeterIsValid) {
      return {
        valid: customPerimeterIsValid,
        result: {
          customPerimeter: segments[0],
        },
      };
    }
  }

  if (segments.length === 0) {
    return {
      valid: true,
    };
  }

  return {
    valid: false,
  };
};

export const discoverMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
  const [languageSegment] = segments;
  if (!availableLanguages.some(p => p === languageSegment?.path)) {
    // invalid language
    return null;
  }

  if (segments.length < 2) {
    return null;
  }

  const [language, route] = segments;

  if (
    route?.path === staticRoutes.wizard ||
    staticRoutes.home ||
    isResultDiscoverRoute(segments) ||
    isSeoLandingRoute(segments)
  ) {
    return {
      consumed: [language],
      posParams: {
        lang: language,
      },
    };
  }
  return null;
};

const isResultDiscoverRoute = (segments: UrlSegment[]) => {
  const [language, offerTypeSegment, listPrefixSegment] = segments;

  const offerTypeRoutes = Object.values(offerTypeMap[language?.path]);
  const offerTypeMatches = offerTypeRoutes.some(p => p === offerTypeSegment?.path);
  const discoverRouteMatches = listPrefixMap[language?.path] === listPrefixSegment?.path;

  return offerTypeMatches && discoverRouteMatches;
};

const isSeoLandingRoute = (segments: UrlSegment[]) => {
  const [languageSegment, offerTypeSegment, propertyTypeSegment] = segments;

  if (availableLanguages.some(p => p === languageSegment?.path)) {
    const lang = languageSegment?.path;

    if (offerTypeMap[lang][offerTypeSegment?.path]) {
      if (propertyTypeMap[lang][propertyTypeSegment?.path]) {
        // route like /lang/offerType/propertyType
        return true;
      } else if (propertySubTypeMap[lang][propertyTypeSegment?.path]) {
        // route like /lang/offerType/propertySubType
        return true;
      }
    }
  }

  return false;
};

export const discoverSeoMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  const offerTypeIndex = 0;
  const discoverKeywordIndex = 1;
  const propertyTypeIndex = 2;
  const language = groups.segments[0]?.path;
  const suffixKeyword = listSuffixMap[language];
  const suffixIndex = segments.findIndex(p => p.path === suffixKeyword);
  if (suffixIndex === -1) {
    return null;
  }

  const discoverKeywordLocalized = segments[discoverKeywordIndex]?.path;
  const discoverPattern = /^(suchen|recherche|search|cerca)$/;
  if (!discoverKeywordLocalized?.match(discoverPattern)) {
    return null;
  }

  const propertyTypeIsValid = propertyTypeMap[language][segments[propertyTypeIndex]?.path] !== undefined;
  if (!propertyTypeIsValid) {
    return null;
  }
  const wildcards = segments.slice(propertyTypeIndex + 1, suffixIndex);

  const result = parseDiscoverWildcardSegments(wildcards, language);
  if (!result.valid) {
    return null;
  }

  const posParams = {
    ...result.result,
    offerType: segments[offerTypeIndex],
    propertyType: segments[propertyTypeIndex],
  };

  return {
    consumed: segments.slice(0, suffixIndex + 1),
    posParams,
  };
};
