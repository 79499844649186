export enum PageType {
  Landing = 1,
  Result = 2,
  Detail = 3,
  DetailMedia = 4,
  SearchSubscription = 5,
  Watchlist = 6,
  Messages = 7,
  MyNewhome = 8,
  CustomerAccount = 9,
  SearchSubscriptionResults = 10,
  Preview = 11,
  PreviewMedia = 12,
  FinancingData = 13,
  FinancingSustainabilityCheck = 14,
  DebtEnforcementExcerpt = 15,
  Wizard = 16,

  ProviderDirectory = 20,
  ProviderResult = 21,

  NotFound = 404,
  PropertyNotFound = 410,
  ProviderNotFound = 411,
  InternalServerError = 500,
  NotAvailable = 503,
}
