import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JsonServiceClient } from '@servicestack/client';
import * as fromRoot from 'app/reducers';
import * as RouterSelectors from 'app/router.selectors';
import { StatisticRequest } from 'newhome.dtos';
import { from } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import * as StatisticActions from './statistic.actions';

@Injectable()
export class StatisticEffects {
  sendContentBoxViewStatistic$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StatisticActions.sendStatisticData),
        withLatestFrom(this.store.select(RouterSelectors.selectLanguage)),
        switchMap(([action, languageIso]) => {
          const request = new StatisticRequest({
            statistic: action.statistic,
            immoCode: action.immocode,
            contentBoxIds: action.contentBoxIds,
            advertId: action.advertId,
            mandantCode: action.mandantCode,
            languageIso,
          });
          return from(this.client.post(request));
        }),
      );
    },
    { dispatch: false },
  );

  constructor(private actions$: Actions, private client: JsonServiceClient, private store: Store<fromRoot.State>) {}
}
