import { createReducer, on } from '@ngrx/store';
import * as AdmeiraActions from './admeira.actions';

export const admeiraFeatureKey = 'admeira';

export interface State {
  admeiraSupported: boolean;
}

const initialState: State = {
  admeiraSupported: false
}

const admeiraReducer = createReducer<State, AdmeiraActions.AdmeiraActionUnion>(
  initialState,
  on(AdmeiraActions.checkAdmeiraSupportSuccess, (state, { supported }) => ({
    ...state,
    admeiraSupported: supported
  }))
)

export function reducer(state: State, action: AdmeiraActions.AdmeiraActionUnion): State {
  return admeiraReducer(state, action);
}
