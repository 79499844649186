import { Action, createReducer, on } from '@ngrx/store';
import * as ObjectsMapActions from './objects-map.actions';

export const objectsMapFeatureKey = 'mapHelper';

export interface State {
  lastZoom: number;
}

export const initialState: State = {
  lastZoom: null,
};

const objectsMapReducer = createReducer(
  initialState,

  on(ObjectsMapActions.setLastZoom, (state, action) => {
    return {
      ...state,
      lastZoom: action.zoom,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return objectsMapReducer(state, action);
}
