import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as TravelDestinationActions from './travel-destination.actions';
import { TravelDestination } from 'newhome.dtos';

export const travelDestinationsFeatureKey = 'travelDestination';

export interface State extends EntityState<TravelDestination> {
  // additional entities state properties
}

export function selectTravelDestinationIdentifier(travelDestination: TravelDestination): number {
  return travelDestination.id;
}

export const adapter: EntityAdapter<TravelDestination> = createEntityAdapter<TravelDestination>({
  selectId: selectTravelDestinationIdentifier,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const travelDestinationReducer = createReducer(
  initialState,
  on(TravelDestinationActions.addTravelDestination, (state, action) => adapter.addOne(action.entry, state)),
  on(TravelDestinationActions.upsertTravelDestination, (state, action) => adapter.upsertOne(action.entry, state)),
  on(TravelDestinationActions.addTravelDestinations, (state, action) => adapter.addMany(action.entries, state)),
  on(TravelDestinationActions.upsertTravelDestinations, (state, action) => adapter.upsertMany(action.entries, state)),
  on(TravelDestinationActions.updateTravelDestination, (state, action) => adapter.updateOne(action.entry, state)),
  on(TravelDestinationActions.updateTravelDestinations, (state, action) => adapter.updateMany(action.entries, state)),
  on(TravelDestinationActions.deleteTravelDestination, (state, action) => adapter.removeOne(action.id, state)),
  on(TravelDestinationActions.deleteTravelDestinations, (state, action) => adapter.removeMany(action.ids, state)),
  on(TravelDestinationActions.loadTravelDestinations, (state, action) => adapter.setAll(action.entries, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return travelDestinationReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectTravelDestinationState = createFeatureSelector<State>(travelDestinationsFeatureKey);

export const selectTravelDestinations = createSelector(selectTravelDestinationState, selectAll);
