import { SearchPropertyType } from 'newhome.dtos';

import {
  locationRoute,
  propertyTypeRoute,
  subPropertyTypeRoute,
  roomsRoute,
  vehiclesRoute,
  livingAreaRoute,
  usableAreaRoute,
  plotAreaRoute,
  constructionYearRoute,
  priceRoute,
  priceM2Route,
  floorLoadRoute,
  grossReturnRoute,
  roomHeightRoute,
  equipmentRoute,
} from './../wizard-routes';

export enum WizardStep {
  Location = 0,
  PropertyType = 1,
  SubPropertyType = 2,
  Rooms = 3,
  Vehicles = 4,
  LivingArea = 5,
  UsableArea = 6,
  PlotArea = 7,
  ConstructionYear = 8,
  Price = 9,
  PriceM2 = 10,
  FloorLoad = 11,
  GrossReturn = 12,
  RoomHeight = 13,
  Equipment = 14,
}

export const stepOrderMap = {
  [SearchPropertyType.All]: [WizardStep.Location, WizardStep.PropertyType],
  [SearchPropertyType.HouseApartment]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.SubPropertyType,
    WizardStep.Price,
    WizardStep.Rooms,
    WizardStep.LivingArea,
    WizardStep.ConstructionYear,
    WizardStep.Equipment,
  ],
  [SearchPropertyType.House]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.SubPropertyType,
    WizardStep.Price,
    WizardStep.Rooms,
    WizardStep.LivingArea,
    WizardStep.PlotArea,
    WizardStep.ConstructionYear,
    WizardStep.Equipment,
  ],
  [SearchPropertyType.Apartment]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.SubPropertyType,
    WizardStep.Price,
    WizardStep.Rooms,
    WizardStep.LivingArea,
    WizardStep.ConstructionYear,
    WizardStep.Equipment,
  ],
  [SearchPropertyType.Buildingland]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.SubPropertyType,
    WizardStep.PriceM2,
    WizardStep.PlotArea,
  ],
  [SearchPropertyType.Business]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.SubPropertyType,
    WizardStep.Price,
    WizardStep.UsableArea,
    WizardStep.PlotArea,
    WizardStep.ConstructionYear,
    WizardStep.FloorLoad,
    WizardStep.RoomHeight,
    WizardStep.Equipment,
  ],
  [SearchPropertyType.ResidentialCommercialBuilding]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.Price,
    WizardStep.UsableArea,
    WizardStep.PlotArea,
    WizardStep.ConstructionYear,
    WizardStep.FloorLoad,
    WizardStep.RoomHeight,
    WizardStep.Equipment,
  ],
  [SearchPropertyType.Apartmenthouse]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.Price,
    WizardStep.PlotArea,
    WizardStep.ConstructionYear,
    WizardStep.GrossReturn,
    WizardStep.Equipment,
  ],
  [SearchPropertyType.Parkingspace]: [
    WizardStep.Location,
    WizardStep.PropertyType,
    WizardStep.SubPropertyType,
    WizardStep.Price,
    WizardStep.Vehicles,
    WizardStep.Equipment,
  ],
};

export const stepToRouteMap = {
  [WizardStep.Location]: locationRoute,
  [WizardStep.PropertyType]: propertyTypeRoute,
  [WizardStep.SubPropertyType]: subPropertyTypeRoute,
  [WizardStep.Price]: priceRoute,
  [WizardStep.PriceM2]: priceM2Route,
  [WizardStep.Rooms]: roomsRoute,
  [WizardStep.Vehicles]: vehiclesRoute,
  [WizardStep.LivingArea]: livingAreaRoute,
  [WizardStep.UsableArea]: usableAreaRoute,
  [WizardStep.PlotArea]: plotAreaRoute,
  [WizardStep.ConstructionYear]: constructionYearRoute,
  [WizardStep.FloorLoad]: floorLoadRoute,
  [WizardStep.GrossReturn]: grossReturnRoute,
  [WizardStep.RoomHeight]: roomHeightRoute,
  [WizardStep.Equipment]: equipmentRoute,
};

export const routeToStepMap = {
  [locationRoute]: WizardStep.Location,
  [propertyTypeRoute]: WizardStep.PropertyType,
  [subPropertyTypeRoute]: WizardStep.SubPropertyType,
  [priceRoute]: WizardStep.Price,
  [priceM2Route]: WizardStep.PriceM2,
  [roomsRoute]: WizardStep.Rooms,
  [vehiclesRoute]: WizardStep.Vehicles,
  [livingAreaRoute]: WizardStep.LivingArea,
  [usableAreaRoute]: WizardStep.UsableArea,
  [plotAreaRoute]: WizardStep.PlotArea,
  [constructionYearRoute]: WizardStep.ConstructionYear,
  [floorLoadRoute]: WizardStep.FloorLoad,
  [grossReturnRoute]: WizardStep.GrossReturn,
  [roomHeightRoute]: WizardStep.RoomHeight,
  [equipmentRoute]: WizardStep.Equipment,
};
