import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalAlertsComponent } from './global-alerts.component';
import * as fromGlobalAlerts from './global-alerts.reducer';
import { BannerModule } from '../banner/banner.module';

@NgModule({
  declarations: [GlobalAlertsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BannerModule,
    StoreModule.forFeature(fromGlobalAlerts.globalAlertsFeatureKey, fromGlobalAlerts.reducer),
  ],
  exports: [GlobalAlertsComponent],
})
export class GlobalAlertsModule {}
