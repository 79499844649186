import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { JsonServiceClient } from '@servicestack/client';
import { RouterStateUrl } from 'app/custom-route-serializer';
import * as fromRoot from 'app/reducers';
import * as RouterSelector from 'app/router.selectors';
import { buildResultsRouteUrl } from 'app/shared/directives/seo-routing/navigate-to-result/builder';
import { landingMap, locationTypeMap, offerTypeMap } from 'app/shared/seo/mappings';
import {
  LocationResolveRequest,
  LocationType,
  SearchListingRequest,
  SearchLocation,
  SearchPropertyType,
} from 'newhome.dtos';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

const teasersearchPropertyTypeMap = {
  ['H']: SearchPropertyType.House,
  ['HW']: SearchPropertyType.HouseApartment,
  ['W']: SearchPropertyType.Apartment,
  ['M']: SearchPropertyType.Apartmenthouse,
  ['G']: SearchPropertyType.Business,
  ['B']: SearchPropertyType.Buildingland,
  ['P']: SearchPropertyType.Parkingspace,
};

const teasersearchLocationTypeMap = {
  ['1']: LocationType.City,
  ['2']: LocationType.Region,
  ['3']: LocationType.Canton,
};

const getSearchFilter = (routerState: RouterStateUrl, location: SearchLocation) => {
  const offerType = offerTypeMap[routerState.lang][getIgnoreCase(routerState.params, 'offerType')];
  const propertyType =
    teasersearchPropertyTypeMap[getIgnoreCase(routerState.queryParams, 'Objektart')] ||
    SearchPropertyType.HouseApartment;

  let radius = getIgnoreCase(routerState.queryParams, 'Umkreis');
  radius = radius > 20 ? 20 : radius;

  const priceMin = getIgnoreCase(routerState.queryParams, 'PreisVon');
  const priceMax = getIgnoreCase(routerState.queryParams, 'PreisBis');
  const roomsMin = getIgnoreCase(routerState.queryParams, 'ZimmerVon');
  const roomsMax = getIgnoreCase(routerState.queryParams, 'ZimmerBis');
  const propertySubType = getIgnoreCase(routerState.queryParams, 'Unterobjektart');

  return new SearchListingRequest({
    offerType,
    propertyType,
    radius,
    location: [location.identifier],
    propertySubtypes: propertySubType ? [propertySubType] : [],
    priceMin,
    priceMax,
    roomsMin,
    roomsMax,
  });
};

function getIgnoreCase(list: { [key: string]: any }, keyName: string): any {
  for (const key in list) {
    if (list.hasOwnProperty(key) && key.toLowerCase() === keyName.toLowerCase()) {
      return list[key];
    }
  }
  return null;
}

@Injectable({
  providedIn: 'root',
})
export class TeasersearchGuard {
  canActivate(): Observable<UrlTree> {
    return this.store.select(RouterSelector.selectRouter).pipe(
      map(r => r.state),
      flatMap(r =>
        this.resolveLocation(r).pipe(
          map(location => {
            const searchFilter = getSearchFilter(r, location);
            const url = buildResultsRouteUrl(r.lang, searchFilter, [location]).split('/');
            return this.router.createUrlTree(['/', r.lang, ...url], {
              queryParams: {
                ...searchFilter,
                // redirect with utm params
                utm_source: r.queryParams.utm_source || null,
                utm_campaign: r.queryParams.utm_campaign || null,
                utm_medium: r.queryParams.utm_medium || null,
                utm_term: r.queryParams.utm_term || null,
                utm_content: r.queryParams.utm_content || null,
              },
            });
          }),
          catchError(() => {
            this.toastr.error(this.translate.instant('DISCOVER.RESULT.SEO_NOT_FOUND'));
            const lang = r.queryParams.lang || this.translate.currentLang;
            return of(this.router.createUrlTree(['/', lang, landingMap[lang]]));
          }),
        ),
      ),
    );
  }

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private client: JsonServiceClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  private resolveLocation(routerState: RouterStateUrl) {
    const locationType = teasersearchLocationTypeMap[routerState.queryParams.Lageart];
    const locationTypeString = locationTypeMap[routerState.lang][locationType];
    const locationString = routerState.queryParams.Lage;

    const request = new LocationResolveRequest({
      keyword: `${locationTypeString}-${locationString}`,
    });

    return from(this.client.get(request));
  }
}
