import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SearchLocation } from 'newhome.dtos';

export const loadSearchLocations = createAction(
  '[SearchLocation/API] Load SearchLocations',
  props<{ searchLocations: SearchLocation[] }>(),
);

export const addSearchLocation = createAction(
  '[SearchLocation/API] Add SearchLocation',
  props<{ searchLocation: SearchLocation }>(),
);

export const upsertSearchLocation = createAction(
  '[SearchLocation/API] Upsert SearchLocation',
  props<{ searchLocation: SearchLocation }>(),
);

export const addSearchLocations = createAction(
  '[SearchLocation/API] Add SearchLocations',
  props<{ searchLocations: SearchLocation[] }>(),
);

export const upsertSearchLocations = createAction(
  '[SearchLocation/API] Upsert SearchLocations',
  props<{ searchLocations: SearchLocation[] }>(),
);

export const updateSearchLocation = createAction(
  '[SearchLocation/API] Update SearchLocation',
  props<{ searchLocation: Update<SearchLocation> }>(),
);

export const updateSearchLocations = createAction(
  '[SearchLocation/API] Update SearchLocations',
  props<{ searchLocations: Update<SearchLocation>[] }>(),
);

export const deleteSearchLocation = createAction('[SearchLocation/API] Delete SearchLocation', props<{ id: string }>());

export const deleteSearchLocations = createAction(
  '[SearchLocation/API] Delete SearchLocations',
  props<{ ids: string[] }>(),
);

export const clearSearchLocations = createAction('[SearchLocation/API] Clear SearchLocations');
