import { AfterContentInit, ContentChildren, Directive, EventEmitter, Input, Output, QueryList, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioComponent } from './radio.component';
import { NH_RADIO_GROUP } from './tokens';

let uniqueId = 0;

@Directive({
  selector: 'nh-radio-group',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true
    },
    {
      provide: NH_RADIO_GROUP,
      useExisting: RadioGroupDirective
    }
  ]
})
export class RadioGroupDirective implements ControlValueAccessor, AfterContentInit {
  @Input() get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    this._value = newValue;

    this.updateSelectedRadioFromValue();
  }

  @Input() get name(): any {
    return this._name;
  }
  set name(newName: any) {
    this._name = newName;
    this.updateRadioNames();
  }

  @Input() get selected(): RadioComponent | null {
    return this._selected;
  }
  set selected(radio: RadioComponent) {
    this._selected = radio;
    this.value = radio ? radio.value : null;

    this.uncheckRadioButtons();
  }

  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }

  @ContentChildren(forwardRef(() => RadioComponent), { descendants: true })
  private radios: QueryList<RadioComponent>;

  @Output() readonly change = new EventEmitter<any>();

  _onChangeFn = (_: any) => {};
  _onTouchFn = () => {};

  private _value: any = null;
  private _name: string = `radio-group-${uniqueId++}`;
  private _disabled = false;
  private _selected: RadioComponent | null = null;
  private _isInitialized = false;

  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => unknown): void {
    this._onChangeFn = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this._onTouchFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.change.emit(this.value);
    }
  }

  private updateSelectedRadioFromValue(): void {
    const alreadySelected = this._selected && this._selected.value === this._value;

    if (this.radios && !alreadySelected) {
      this._selected = null;

      for (const radio of this.radios) {
        radio.checked = this.value === radio.value;

        if (radio.checked) {
          this.selected = radio;
        }
      }
    }
  }

  private uncheckRadioButtons(): void {
    if (!this.radios) {
      return;
    }

    for (const radio of this.radios.filter(r => r !== this.selected)) {
      radio.checked = false;
    }
  }

  private updateRadioNames(): void {
    if (!this.radios) {
      return;
    }

    for (const radio of this.radios) {
      radio.name = this.name;
    }
  }
}
