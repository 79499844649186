import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerType } from './banner-type.enum';

@Component({
  selector: 'app-custom-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Output() closed = new EventEmitter();

  @Input() title: string;
  @Input() size: 'small' | 'medium' | 'big' = 'medium';
  @Input() type: BannerType = BannerType.Cta;
  @Input() dismissible: boolean;

  public BannerType = BannerType;

  close() {
    this.closed.emit();
  }
}
