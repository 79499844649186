import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BannerPageContainerComponent } from './banner-page-container/banner-page-container.component';
import { BannerComponent } from './banner.component';
import { IconModule } from '@newhome/components/icon';
import { ButtonModule } from '@newhome/components/button';

@NgModule({
  declarations: [BannerComponent, BannerPageContainerComponent],
  imports: [CommonModule, TranslateModule, IconModule, ButtonModule],
  exports: [BannerComponent, BannerPageContainerComponent],
})
export class BannerModule {}
