import { ObjectCondition, OfferType, PropertySubType, SearchPropertyType } from 'newhome.dtos';

export type AdmeiraTargetingBoolean = 'ja' | 'nein';

export const propertyTypeAdmeiraTargetingMap = {
  [SearchPropertyType.All]: undefined,
  [SearchPropertyType.Apartment]: 'wohnung',
  [SearchPropertyType.Apartmenthouse]: 'mehrfamilienhaus',
  [SearchPropertyType.Buildingland]: 'bauland',
  [SearchPropertyType.Business]: 'gewerbe',
  [SearchPropertyType.House]: 'haus',
  [SearchPropertyType.HouseApartment]: 'hauswohnung',
  [SearchPropertyType.Parkingspace]: 'parkplatz',
};

export const propertySubTypeAdmeiraTargetingMap = {
  [PropertySubType.AgriculturalBuilding]: 'landwirtschaftsobjekt',
  [PropertySubType.AgriculturalLand]: 'agrarland',
  [PropertySubType.Apartment]: 'wohnung',
  [PropertySubType.AtticApartment]: 'attikawohnung',
  [PropertySubType.BuildingLand]: 'wohnbauland',
  [PropertySubType.BuildingLandMixedZone]: 'bauland-gemischte-bauzone',
  [PropertySubType.Castle]: 'schloss',
  [PropertySubType.Chalet]: 'chalet',
  [PropertySubType.CommercialBuildingLand]: 'gewerbebauland',
  [PropertySubType.CommercialPremisis]: 'gewerberaeume',
  [PropertySubType.CommericalBuilding]: 'geschaeftshaus',
  [PropertySubType.CornerHouse]: 'eckhaus',
  [PropertySubType.CoveredParkingSpace]: 'gedeckter-parkplatz',
  [PropertySubType.DetachedHouse]: 'einfamilienhaus',
  [PropertySubType.DoubleApartmentBuilding]: 'doppeleinfamilienhaus',
  [PropertySubType.Farmhouse]: 'bauernhaus',
  [PropertySubType.FurnishedApartment]: 'moeblierte-wohnung',
  [PropertySubType.Garage]: 'garage',
  [PropertySubType.HolidayApartment]: 'ferienwohnung',
  [PropertySubType.HolidyHome]: 'ferienhaus',
  [PropertySubType.Hotel]: 'hotel',
  [PropertySubType.IndustrialBuildingLand]: 'industriebauland',
  [PropertySubType.Loft]: 'loft',
  [PropertySubType.MaisonetteApartment]: 'maisonette',
  [PropertySubType.ManufacturingRoom]: 'fabrikationsraeume',
  [PropertySubType.MiddleHouse]: 'mittelhaus',
  [PropertySubType.MotorcycleParkingSpace]: 'motorradplatz',
  [PropertySubType.Office]: 'buero',
  [PropertySubType.PenthouseApartment]: 'dachwohnung',
  [PropertySubType.Pratice]: 'praxis',
  [PropertySubType.ResidentialCommercialBuilding]: 'wohnhaus-geschaeftshaus',
  [PropertySubType.Restaurant]: 'restaurant',
  [PropertySubType.RoomInHousingAssociation]: 'wg-zimmer',
  [PropertySubType.RoomInHousingAssociationHouse]: 'wg-zimmer',
  [PropertySubType.Rustic]: 'rustico',
  [PropertySubType.Salesroom]: 'verkaufsraeume',
  [PropertySubType.SteppedBuilding]: 'terrassenhaus',
  [PropertySubType.Studio]: 'atelier',
  [PropertySubType.StudioBedsit]: 'studio-zimmer',
  [PropertySubType.TerraceApartment]: 'terrassenwohnung',
  [PropertySubType.TerracedHouse]: 'reihenhaus',
  [PropertySubType.UncoveredParkingSpace]: 'ungedeckter-parkplatz',
  [PropertySubType.UndergroundGarage]: 'tiefgarage',
  [PropertySubType.Villa]: 'villa',
  [PropertySubType.Warehouse]: 'lager',
  [PropertySubType.WorkshopHobbyRoom]: 'werkstatt-hobby',
};

export const offerTypeAdmeiraTargetingMap = {
  [OfferType.Buy]: 'kaufen',
  [OfferType.Rent]: 'mieten',
};

export const conditionAdmeiraTargetingMap = {
  [ObjectCondition.NewlyRenovated]: 'renoviert',
  [ObjectCondition.FirstTimeOccupancy]: 'erstbezug',
  [ObjectCondition.Average]: 'mittel',
  [ObjectCondition.LikeNew]: 'neuwertig',
  [ObjectCondition.InNeedOfRenovation]: 'renovationsbeduerftig',
  [ObjectCondition.Good]: 'gut',
  [ObjectCondition.NewBuilding]: 'neubau',
  [ObjectCondition.OldBuilding]: 'altbau',
};

export interface SearchFilterAdmeiraTargeting {
  unterobjektart: string;
  angebotsart: string;
  preisVon: string;
  preisBis: string;
  zimmerVon: string;
  zimmerBis: string;
  wohnflaecheVon: string;
  wohnflaecheBis: string;
  grundstuecksflaecheVon: string;
  grundstuecksflaecheBis: string;
  minergieSuche: AdmeiraTargetingBoolean;
  kanton: string;
  ortschaft: string[];
  objektart: string;
  kantone: string[];
}

export interface DetailAdmeiraTargeting {
  objektart: string;
  unterobjektart: any;
  angebotsart: string;
  immocode: string;
  preisVon: string;
  preisBis: string;
  zimmerVon: string;
  zimmerBis: string;
  wohnflaecheVon: string;
  wohnflaecheBis: string;
  grundstuecksflaecheVon: string;
  grundstuecksflaecheBis: string;
  minergieSuche: AdmeiraTargetingBoolean;
  garage: AdmeiraTargetingBoolean;
  parkplatz: AdmeiraTargetingBoolean;
  baujahr: string;
  renoviert: string;
  objektzustand: string;
  ortschaft: string;
  kantone: string;
  postleitzahlen: string;
}
