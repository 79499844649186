import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormattedInputComponent } from './formatted-input.component';
import { FormattedInputControlDirective } from './directives/input-control.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FormattedInputTextDirective } from './directives/formatted-input-text.directive';

@NgModule({
  declarations: [FormattedInputControlDirective, FormattedInputTextDirective, FormattedInputComponent],
  imports: [CommonModule, TranslateModule],
  exports: [FormattedInputTextDirective, FormattedInputControlDirective, FormattedInputComponent],
})
export class FormattedInputModule {}
