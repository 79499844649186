import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabeledCheckboxComponent } from './labeled-checkbox.component';
import { CheckboxModule } from '../checkbox';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LabeledCheckboxComponent],
  imports: [CommonModule, CheckboxModule, ReactiveFormsModule],
  exports: [LabeledCheckboxComponent]
})
export class LabeledCheckboxModule { }
