import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './admeira.reducer';

const featureSelector = createFeatureSelector<fromReducer.State>(
  fromReducer.admeiraFeatureKey
);

export const selectIsAdmeiraSupported = createSelector(
  featureSelector,
  state => state.admeiraSupported
);
