import {
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { CheckboxControls } from '../common/checkbox/controls';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nh-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  host: { tabindex: '0' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ]
})
export class CheckboxComponent extends CheckboxControls {
  
  @Input() checkboxId: number;
 
  constructor(
  ) {
    super();
  }

}
