import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import * as fromRoot from 'app/reducers';
import * as UserData from 'app/reducers/user-data';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable()
export class LoadInfoGuard  {
  canActivate(): Observable<boolean> {
    this.store.dispatch(UserData.beginLoadUserInfo());

    return this.store.pipe(
      select(UserData.selectIsUserInfoLoading),
      skipWhile(flag => !flag),
      skipWhile(flag => flag),
      map(() => true),
    );
  }

  constructor(private readonly store: Store<fromRoot.State>) {}
}
