import { createAction, props } from '@ngrx/store';
import { SearchSubscriptionDetail } from 'newhome.dtos';
import { DeviceMismatchState, ExtendedSearchSubscriptionDetail } from './search-subscription.reducer';

export const beginLoadSearchSubscriptions = createAction(
  '[SearchSubscription] Begin Load SearchSubscriptions',
  props<{ guids: string[] }>(),
);

export const loadSearchSubscriptionsSuccess = createAction(
  '[SearchSubscription] Load SearchSubscriptions Success',
  props<{
    confirmed: ExtendedSearchSubscriptionDetail[];
    unconfirmed: SearchSubscriptionDetail[];
  }>(),
);

export const loadSearchSubscriptionsFailure = createAction(
  '[SearchSubscription] Load SearchSubscriptions Failure',
  props<{ error: any }>(),
);

export const beginDeleteSearchSubscription = createAction(
  '[SearchSubscription] Begin Delete SearchSubscription',
  props<{ name: string; guid: string }>(),
);

export const deleteSearchSubscriptionSuccess = createAction('[SearchSubscription] Delete SearchSubscription Success');

export const deleteSearchSubscriptionFailure = createAction(
  '[SearchSubscription] Delete SearchSubscription Failure',
  props<{ error: any }>(),
);

export const beginUpdateSearchSubscription = createAction(
  '[SearchSubscription] Begin Update SearchSubscription',
  props<{ data: any }>(),
);

export const updateSearchSubscriptionSuccess = createAction(
  '[SearchSubscription] Update SearchSubscription Success',
  props<{ response: any }>(),
);

export const updateSearchSubscriptionFailure = createAction(
  '[SearchSubscription] Update SearchSubscription Failure',
  props<{ error: any }>(),
);

export const toggleDeviceMismatchNotice = createAction(
  '[SearchSubscription] Toggle Device Mismatch Notice',
  props<{ state: DeviceMismatchState }>(),
);
