import { environment } from 'environments/environment';
import { AuthConfig } from 'app/shared/oauth2-oidc/lib/public_api';

export const authConfig: AuthConfig = {
  issuer: environment.identity.url,
  postLogoutRedirectUri: environment.identity.postLogoutUrl,
  redirectUri: environment.identity.callbackUrl,
  authenticatedStateUrl: environment.identity.url + '/Authenticated',
  requireHttps: 'remoteOnly',
  clientId: environment.identity.clientId,

  scope: 'openid profile api1 offline_access',

  responseType: 'code',
  showDebugInformation: environment.verbose,
  useSilentRefresh: true,
};
