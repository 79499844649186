<div class="nh-dropdown" [class.open]="dropdownOpen">
  <nh-form-field (mousedown)="openClose()" [theme]="theme">
    <nh-label *ngIf="theme == 'adaptive' && labelValue" for="id">{{ labelValue }}</nh-label>
    <input
      [id]="selectId"
      [value]="selectedOption?.content"
      nhInput
      readonly
      (blur)="close()"
      (keydown)="keyboardActions($event)"
    />
  </nh-form-field>
  <nh-icon class="arrow-icon" key="caret-down"></nh-icon>
  <div class="dropdown-list">
    <ng-content></ng-content>
  </div>
</div>
