import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as CountryActions from './country.actions';
import * as RouterSelector from 'app/router.selectors';
import * as fromRoot from 'app/reducers';
import { switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { JsonServiceClient } from '@servicestack/client';
import { Store } from '@ngrx/store';
import { GetCountriesRequest } from 'newhome.dtos';
import { from } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CountryEffects {
  beginLoadCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CountryActions.beingLoadCountries),
      withLatestFrom(this.store.select(RouterSelector.selectLanguage)),
      switchMap(([_, language]) => {
        const request$ = from(this.client.get(new GetCountriesRequest({ languageIso: language })));

        return request$.pipe(
          map(response => CountryActions.loadCountrys({ countrys: response.countries })),
          catchError(_ => [CountryActions.loadCountriesFailed()]),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly client: JsonServiceClient,
    private readonly store: Store<fromRoot.State>,
  ) {}
}
