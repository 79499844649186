import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon';
import { FormFieldModule } from '../form-field';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, ReactiveFormsModule, IconModule, FormFieldModule],
  exports: [SelectComponent]
})
export class SelectModule { }
