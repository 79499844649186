import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { separateQueryParams } from 'app/shared/filter/filter.separation';
import { IAdvertSearchFilter, OfferType, SearchLocation, SearchPropertyType, PropertySubType } from 'newhome.dtos';
import { LocationPipe } from 'app/shared/pipes/location-pipe/location.pipe';

export const normalizeCharsMap = {
  ['.']: '',
  [',']: '',
  ['%']: '',
  [':']: '',
  [';']: '',
  ['-']: '',
  ['+']: '',
  ['"']: '',
  ['_']: '',
  ['=']: '',
  ['!']: '',
  ['?']: '',
  ['(']: '',
  [')']: '',
  ['[']: '',
  [']']: '',
  ['{']: '',
  ['}']: '',
  ['/']: '',
  ['&']: '',
  ['*']: '',
  // tslint:disable-next-line: quotemark
  ["'"]: '',
  ['|']: '',
  ['¦']: '',
  ['>']: '',
  ['<']: '',
  ['\\']: '',
  ['§']: '',
  ['ä']: 'ae',
  ['Ä']: 'Ae',
  ['â']: 'a',
  ['à']: 'a',
  ['Â']: 'A',
  ['À']: 'A',
  ['é']: 'e',
  ['É']: 'E',
  ['è']: 'e',
  ['È']: 'E',
  ['ê']: 'e',
  ['Ê']: 'E',
  ['î']: 'i',
  ['Î']: 'I',
  ['ï']: 'i',
  ['Ï']: 'I',
  ['ö']: 'oe',
  ['Ö']: 'Oe',
  ['ô']: 'o',
  ['Ô']: 'O',
  ['Ü']: 'Ue',
  ['ü']: 'ue',
  ['Û']: 'u',
  ['û']: 'u',
  ['ç']: 'c',
};

export const replaceAll = (str: string, mapObj): string => {
  const pattern = new RegExp(
    Object.keys(mapObj)
      .map(x => `\\${x}`)
      .join('|'),
    'gi',
  );

  return str.replace(pattern, matched => {
    return mapObj[matched.toLowerCase()];
  });
};

export const hasFilterInQueryString = (queryParams: Params): boolean => {
  const filterQueryParams = separateQueryParams(queryParams);
  return (
    Object.keys({
      ...filterQueryParams.filter,
    }).length > 0
  );
};

export const firstCharToUppercase = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const parseClaims = (accessToken: string) => {
  const data = accessToken.split('.')[1];
  const decoded = atob(data);
  return JSON.parse(decoded);
};

export const availableLanguages = ['de', 'fr', 'it', 'en'];

export const getResultTitle = (
  searchFilter: IAdvertSearchFilter,
  searchLocations: SearchLocation[],
  titleTemplateTranslationKey: string,
  translate: TranslateService,
  allowMultipleLocations: boolean = false,
) => {
  const isCustomPerimeterSearch = !!searchFilter.radiusCenterCoordinate;
  if (
    searchFilter.offerType &&
    searchFilter.location &&
    (searchFilter.location.length === 1 || allowMultipleLocations || isCustomPerimeterSearch)
  ) {
    const propertyType = new Array();
    if (searchFilter.propertySubtypes && searchFilter.propertySubtypes.length > 0) {
      for (const subType of searchFilter.propertySubtypes) {
        propertyType.push(translate.instant('ENUM.PROPERTYSUBTYPE.' + PropertySubType[subType]));
      }
    } else {
      if (searchFilter.propertyType) {
        propertyType.push(translate.instant('ENUM.PROPERTYTYPE.' + SearchPropertyType[searchFilter.propertyType]));
      } else {
        propertyType.push(translate.instant('DISCOVER.RESULT.PROPERTIES'));
      }
    }

    const offerType = translate.instant('ENUM.OFFERTYPE.' + OfferType[searchFilter.offerType]).toLowerCase();

    searchLocations = searchLocations.filter(p => searchFilter.location?.some(l => p.identifier === l));
    if (!searchLocations?.length && !isCustomPerimeterSearch) {
      return undefined;
    }
    const locations = new Array();
    const pipe = new LocationPipe(translate);
    for (const location of searchLocations) {
      locations.push(pipe.transform(location));
    }
    const locationString = locations.join(', ');
    const title = translate.instant(titleTemplateTranslationKey, {
      propertyType: propertyType.join(', '),
      offerType,
      location: !isCustomPerimeterSearch
        ? translate.instant('DISCOVER.RESULT.SEO_LOCATION_TEMPLATE', {
            location: locationString,
          })
        : translate.instant('DISCOVER.RESULT.SEO_LOCATION_CUSTOM_PERIMETER_TEMPLATE'),
    });

    return firstCharToUppercase(title);
  }

  return undefined;
};

export const getObjectDifference = (a, b) =>
  Object.entries(b)
    .filter(([key, val]) => a[key] !== val && key in a)
    .reduce((x, [key, v]) => ({ ...x, [key]: v }), {});

export const inputTagNames = ['INPUT', 'TEXTAREA', 'SELECT'];

export const isInputFocused = () => {
  return inputTagNames.includes(document.activeElement.tagName);
};

export const focusableSelectors = [
  'a[href]:not([tabindex^="-"])',
  'area[href]:not([tabindex^="-"])',
  'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
  'input[type="radio"]:not([disabled]):not([tabindex^="-"])',
  'select:not([disabled]):not([tabindex^="-"])',
  'textarea:not([disabled]):not([tabindex^="-"])',
  'button:not([disabled]):not([tabindex^="-"])',
  'iframe:not([tabindex^="-"])',
  'audio[controls]:not([tabindex^="-"])',
  'video[controls]:not([tabindex^="-"])',
  '[contenteditable]:not([tabindex^="-"])',
  '[tabindex]:not([tabindex^="-"])',
];

export const getFocusableChildren = (node: HTMLElement) => {
  return Array.prototype.slice
    .call(node.querySelectorAll(focusableSelectors.join(',')))
    .filter((child: HTMLElement) => {
      return !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length);
    });
};

export const trapTabKey = (node: HTMLElement, event: KeyboardEvent) => {
  const focusableChildren = getFocusableChildren(node);
  const focusedItemIndex = focusableChildren.indexOf(document.activeElement);

  if (event.shiftKey && focusedItemIndex === 0) {
    // If the SHIFT key is being pressed while tabbing (moving backwards) and
    // the currently focused item is the first one, move the focus to the last focusable item from the node
    focusableChildren[focusableChildren.length - 1].focus();
    event.preventDefault();
  } else if (!event.shiftKey && focusedItemIndex === focusableChildren.length - 1) {
    // If the SHIFT key is not being pressed (moving forwards) and the currently
    // focused item is the last one, move the focus to the first focusable item from the node
    focusableChildren[0].focus();
    event.preventDefault();
  }
};

export const handleAutofocus = (node: HTMLElement) => {
  const autofocusEl: HTMLElement = node.querySelector('[autofocus]');

  if (autofocusEl) {
    if (inputTagNames.includes(autofocusEl.tagName)) {
      // Focus the first child element with an autofocus attribute
      autofocusEl.focus();
    } else {
      // Check for nested input elements because ng-select does not support
      // setting an autofocus attribute on the input element
      const nestedAutoFocusEl: HTMLElement = autofocusEl.querySelector(inputTagNames.join(','));

      if (nestedAutoFocusEl) {
        nestedAutoFocusEl.focus();
      }
    }
  } else {
    // Focus the node directly if no child with an autofocus attribute is found
    node.focus();
  }
};
