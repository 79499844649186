import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputStepperComponent } from './input-stepper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormattedInputModule } from '../formatted-input/formatted-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@newhome/components/icon';
import { ButtonModule } from '@newhome/components/button';
import { FormFieldModule } from '@newhome/components/form-field';

@NgModule({
  declarations: [InputStepperComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    FormattedInputModule,
    IconModule,
    ButtonModule,
    FormFieldModule,
  ],
  exports: [InputStepperComponent],
})
export class InputStepperModule {}
