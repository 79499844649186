import { Action, createReducer, on } from '@ngrx/store';
import { SearchListingEntry } from 'newhome.dtos';
import * as EmarsysRecommendationsActions from './emarsys-recommendations.actions';

export const emarsysRecommendationsFeatureKey = 'emarsys-recommendations';

export interface State {
  entries: SearchListingEntry[];
}

export const initialState: State = {
  entries: [],
};

const emarsysRecommendationsReducer = createReducer(
  initialState,

  on(EmarsysRecommendationsActions.beginLoadEmarsysRecommendationsAdverts, state => state),
  on(EmarsysRecommendationsActions.loadEmarsysRecommendationsAdvertsSucces, (state, action) => {
    return {
      ...state,
      entries: action.entires,
    };
  }),
  on(EmarsysRecommendationsActions.loadEmarsysRecommendationsAdvertsFailure, (state, action) => state),
);

export function reducer(state: State | undefined, action: Action) {
  return emarsysRecommendationsReducer(state, action);
}
