import { Action, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as WatchlistActions from './watchlist.actions';
import { SearchListingEntry } from 'newhome.dtos';

export const watchlistFeatureKey = 'watchlist';

export class WatchlistEntry {
  isExpired: boolean;
  entry: SearchListingEntry;

  public constructor(init?: Partial<WatchlistEntry>) {
    (Object as any).assign(this, init);
  }
}

export interface State extends EntityState<WatchlistEntry> {
  // additional entities state properties
}

export function selectWatchlistEntryIdentifier(watchlistEntry: WatchlistEntry): number {
  return watchlistEntry.entry.immocode;
}

export const adapter: EntityAdapter<WatchlistEntry> = createEntityAdapter<WatchlistEntry>({
  selectId: selectWatchlistEntryIdentifier,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const watchlistReducer = createReducer(
  initialState,
  on(WatchlistActions.addWatchlistEntry, (state, action) => adapter.addOne(action.entry, state)),
  on(WatchlistActions.upsertWatchlistEntry, (state, action) => adapter.upsertOne(action.entry, state)),
  on(WatchlistActions.addWatchlistEntries, (state, action) => adapter.addMany(action.entries, state)),
  on(WatchlistActions.upsertWatchlistEntries, (state, action) => adapter.upsertMany(action.entries, state)),
  on(WatchlistActions.updateWatchlistEntry, (state, action) => adapter.updateOne(action.entry, state)),
  on(WatchlistActions.updateWatchlistEntries, (state, action) => adapter.updateMany(action.entries, state)),
  on(WatchlistActions.deleteWatchlistEntry, (state, action) => adapter.removeOne(action.id, state)),
  on(WatchlistActions.deleteWatchlistEntries, (state, action) => adapter.removeMany(action.ids, state)),
  on(WatchlistActions.loadWatchlistEntries, (state, action) => adapter.setAll(action.entries, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return watchlistReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectWatchlistState = createFeatureSelector<State>(watchlistFeatureKey);

export const selectWatchlist = createSelector(selectWatchlistState, selectAll);

export const selectAdvertIsOnWatchlist = (immocode: number) =>
  createSelector(selectWatchlistState, x => x.entities[immocode] !== undefined);

export const selectContainsExpiredAdverts = createSelector(selectWatchlistState, x => {
  const values = Object.values(x.entities);
  return values.filter(entry => entry.isExpired).length > 0;
});
