import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as RouterSelectors from 'app/router.selectors';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { OAuthService } from './oauth2-oidc/lib/public_api';

@Injectable()
export class RequiresLoginGuard  {
  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(RouterSelectors.selectLanguage).pipe(
      switchMap(language => {
        return this.oauthService.isLoggedin().pipe(
          take(1),
          map(isLoggedin => {
            if (!isLoggedin) {
              if (isPlatformServer(this.platformId)) {
                return this.router.createUrlTree(['/startup']);
              }
              const state = window.location.pathname + window.location.search;
              this.oauthService.initCodeFlow(state, {
                ui_locales: language || 'de',
              });
              return false;
            }
            return true;
          }),
        );
      }),
    );
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly store: Store<any>,
    private readonly oauthService: OAuthService,
    private readonly router: Router,
  ) {}
}
