import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { isSameSiteNoneAvailable } from './shared/cookies/same-site-cookie';
import { OAuthStorage } from './shared/oauth2-oidc/lib/public_api';
import { refreshTokenExpiryDays } from './shared/oauth2-oidc/lib/refresh-token-expiry';

@Injectable()
export class BrowserTokenStoreService implements OAuthStorage {
  constructor(private cookieService: CookieService, @Inject(PLATFORM_ID) private platformId: any) {}

  getItem(key: string): string {
    return this.cookieService.get(key);
  }

  removeItem(key: string): void {
    this.cookieService.delete(key, '/');
  }

  setItem(key: string, data: string): void {
    if (key === 'refresh_token') {
      this.cookieService.set(
        key,
        data,
        refreshTokenExpiryDays, // expires in 15 days
        '/',
        null,
        environment.production,
        isPlatformBrowser(this.platformId) &&
          navigator &&
          navigator.userAgent &&
          isSameSiteNoneAvailable(navigator.userAgent)
          ? 'None'
          : 'Lax',
      );
    } else {
      this.cookieService.set(key, data, null, '/', null, environment.production);
    }
  }
}
