import { createAction, props } from '@ngrx/store';
import { SearchListingRequest, SearchListingResponse, SearchLocation } from 'newhome.dtos';

export const loadDiscovers = createAction('[Discover] Load Discovers');

export const loadDiscoversSuccess = createAction('[Discover] Load Discovers Success', props<{ data: any }>());

export const loadDiscoversFailure = createAction('[Discover] Load Discovers Failure', props<{ error: any }>());

export const beginLoadPropertiesCount = createAction('[Discover] Begin Load Properties Count');

export const loadPropertiesCountSuccessful = createAction(
  '[Discover] Load Properties Count Successful',
  props<{ count: number }>(),
);

export const loadPropertiesCountFailure = createAction('[Discover] Load Properties Count Failure', props<{ error }>());

export const beginLoadProperties = createAction(
  '[Discover] Begin Load Properties',
  props<{ request?: SearchListingRequest; jumpToImmoCode?: number }>(),
);

export const loadPropertiesSuccessful = createAction(
  '[Discover] Load Properties Successful',
  props<{ response: SearchListingResponse }>(),
);

export const loadPropertiesFailure = createAction('[Discover] Load Properties Failure', props<{ error: any }>());

export const beginResolveLocations = createAction(
  '[Discover] Begin Resolve Locations',
  props<{ locations: string[] }>(),
);

export const updateFilterInQueryParams = createAction(
  '[Router] Update Filter',
  props<{ filter: Partial<SearchListingRequest> }>(),
);
