import { Action, createReducer, on } from '@ngrx/store';
import { ListingDetailResponse } from 'newhome.dtos';
import * as DetailActions from './detail.actions';

export const detailFeatureKey = 'detail';

export interface State {
  detail: ListingDetailResponse;
  backUrl: string;
  loadingDetailState: { [id: string]: boolean };
  errorDetailState: { [id: string]: boolean };
}

export const initialState: State = {
  detail: undefined,
  backUrl: undefined,
  loadingDetailState: {},
  errorDetailState: {},
};

const detailReducer = createReducer(
  initialState,

  on(DetailActions.beginLoadDetail, (state, action) => {
    return {
      ...state,
      loadingDetailState: {
        ...state.loadingDetailState,
        [action.id]: true,
      },
      errorDetailState: {
        ...state.errorDetailState,
        [action.id]: false,
      },
    };
  }),
  on(DetailActions.loadDetailSuccess, (state, action) => {
    return {
      ...state,
      detail: action.response,
      loadingDetailState: {
        ...state.loadingDetailState,
        [action.immocode]: false,
      },
    };
  }),
  on(DetailActions.loadDetailFailure, (state, action) => {
    return {
      ...state,
      loadingDetailState: {
        ...state.loadingDetailState,
        [action.immocode]: false,
      },
      errorDetailState: {
        ...state.errorDetailState,
        [action.immocode]: true,
      },
    };
  }),
  on(DetailActions.setBackUrl, (state, action) => {
    return {
      ...state,
      backUrl: action.backUrl,
    };
  }),
  on(DetailActions.removeBackUrl, state => {
    return {
      ...state,
      backUrl: undefined,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return detailReducer(state, action);
}
