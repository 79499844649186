import { Action, createReducer, on } from '@ngrx/store';
import { SearchListingEntry, SearchListingRequest, SearchPropertyType } from 'newhome.dtos';
import { stepOrderMap, WizardStep } from './core/steps';
import * as WizardActions from './wizard.actions';
export const wizardFeatureKey = 'wizard';

export interface State {
  selectedProperty: SearchListingEntry;

  originatesFromLanding: boolean;
  hasVisitedLocation: boolean;
  hasVisitedPropertyType: boolean;
  hasVisitedSubPropertyType: boolean;
  hasVisitedRooms: boolean;
  hasVisitedVehicles: boolean;
  hasVisitedConstructionYear: boolean;
  hasVisitedLivingArea: boolean;
  hasVisitedUsableArea: boolean;
  hasVisitedPlotArea: boolean;
  hasVisitedPrice: boolean;
  hasVisitedGrossReturn: boolean;
  hasVisitedFloorLoad: boolean;
  hasVisitedRoomHeight: boolean;
  hasVisitedEquipment: boolean;
  hasVisitiedPriceM2: boolean;
}

export const initialState: State = {
  selectedProperty: undefined,

  originatesFromLanding: true,
  hasVisitedLocation: true,
  hasVisitiedPriceM2: false,
  hasVisitedPropertyType: false,
  hasVisitedSubPropertyType: false,
  hasVisitedRooms: false,
  hasVisitedVehicles: false,
  hasVisitedConstructionYear: false,
  hasVisitedLivingArea: false,
  hasVisitedUsableArea: false,
  hasVisitedPlotArea: false,
  hasVisitedPrice: false,
  hasVisitedGrossReturn: false,
  hasVisitedFloorLoad: false,
  hasVisitedRoomHeight: false,
  hasVisitedEquipment: false,
};

const checkIfStepIsVisibleDueToFilter = (step: WizardStep, filter: Partial<SearchListingRequest>): boolean => {
  switch (step) {
    case WizardStep.GrossReturn:
      return filter.grossReturnFrom !== undefined;
    case WizardStep.LivingArea:
      return filter.livingAreaMin !== undefined || filter.livingAreaMax !== undefined;
    case WizardStep.Location:
      return filter.location?.length > 0;
    case WizardStep.PlotArea:
      return filter.plotAreaMin !== undefined || filter.plotAreaMax !== undefined;
    case WizardStep.Price:
      return filter.priceMin !== undefined || filter.priceMax !== undefined;
    case WizardStep.PriceM2:
      return filter.priceM2Min !== undefined || filter.priceM2Max !== undefined;
    case WizardStep.PropertyType:
      return filter.propertyType !== undefined;
    case WizardStep.RoomHeight:
      return filter.roomHeightMin !== undefined || filter.roomHeightMax !== undefined;
    case WizardStep.Rooms:
      return filter.roomsMin !== undefined || filter.roomsMax !== undefined;

    case WizardStep.SubPropertyType:
      return filter.propertySubtypes?.length > 0;
    case WizardStep.UsableArea:
      return filter.usableAreaMin !== undefined || filter.usableAreaMax !== undefined;
    case WizardStep.Vehicles:
      return filter.numberOfVehiclesMin !== undefined || filter.numberOfVehiclesMax !== undefined;
  }
};

const wizardReducer = createReducer(
  initialState,

  // check visitUpTo.md for further explanation about this specific reducer(GER)
  on(WizardActions.visitUpTo, (state, action) => {
    const stepOrder = stepOrderMap[action.filter.propertyType || SearchPropertyType.All];

    const stepIndex = stepOrder.indexOf(action.step);
    const stepsToCheck = stepOrder.slice(stepIndex + 1).reverse();
    let counter = 0;
    for (const step of stepsToCheck) {
      if (checkIfStepIsVisibleDueToFilter(step, action.filter)) {
        break;
      }
      counter++;
    }

    const availableSteps = stepOrder.slice(0, stepOrder.length - counter);

    return {
      ...state,
      hasVisitedLocation: state.hasVisitedLocation || availableSteps.indexOf(WizardStep.Location) > -1,
      hasVisitiedPriceM2: state.hasVisitiedPriceM2 || availableSteps.indexOf(WizardStep.PriceM2) > -1,
      hasVisitedPropertyType: state.hasVisitedPropertyType || availableSteps.indexOf(WizardStep.PropertyType) > -1,
      hasVisitedSubPropertyType:
        state.hasVisitedSubPropertyType || availableSteps.indexOf(WizardStep.SubPropertyType) > -1,
      hasVisitedRooms: state.hasVisitedRooms || availableSteps.indexOf(WizardStep.Rooms) > -1,
      hasVisitedVehicles: state.hasVisitedVehicles || availableSteps.indexOf(WizardStep.Vehicles) > -1,
      hasVisitedConstructionYear:
        state.hasVisitedConstructionYear || availableSteps.indexOf(WizardStep.ConstructionYear) > -1,
      hasVisitedLivingArea: state.hasVisitedLivingArea || availableSteps.indexOf(WizardStep.LivingArea) > -1,
      hasVisitedUsableArea: state.hasVisitedUsableArea || availableSteps.indexOf(WizardStep.UsableArea) > -1,
      hasVisitedPlotArea: state.hasVisitedPlotArea || availableSteps.indexOf(WizardStep.PlotArea) > -1,
      hasVisitedPrice: state.hasVisitedPrice || availableSteps.indexOf(WizardStep.Price) > -1,
      hasVisitedGrossReturn: state.hasVisitedGrossReturn || availableSteps.indexOf(WizardStep.GrossReturn) > -1,
      hasVisitedFloorLoad: state.hasVisitedFloorLoad || availableSteps.indexOf(WizardStep.FloorLoad) > -1,
      hasVisitedRoomHeight: state.hasVisitedRoomHeight || availableSteps.indexOf(WizardStep.RoomHeight) > -1,
      hasVisitedEquipment: state.hasVisitedEquipment || availableSteps.indexOf(WizardStep.Equipment) > -1,
    };
  }),
  on(WizardActions.displayAll, (state, action) => {
    const stepOrder = stepOrderMap[action.propertyType || SearchPropertyType.All];

    return {
      ...state,
      hasVisitedEquipment: stepOrder.indexOf(WizardStep.Equipment) > -1,
      hasVisitedLocation: stepOrder.indexOf(WizardStep.Location) > -1,
      hasVisitedConstructionYear: stepOrder.indexOf(WizardStep.ConstructionYear) > -1,
      hasVisitedLivingArea: stepOrder.indexOf(WizardStep.LivingArea) > -1,
      hasVisitedUsableArea: stepOrder.indexOf(WizardStep.UsableArea) > -1,
      hasVisitedPlotArea: stepOrder.indexOf(WizardStep.PlotArea) > -1,
      hasVisitedPrice: stepOrder.indexOf(WizardStep.Price) > -1,
      hasVisitiedPriceM2: stepOrder.indexOf(WizardStep.PriceM2) > -1,
      hasVisitedGrossReturn: stepOrder.indexOf(WizardStep.GrossReturn) > -1,
      hasVisitedFloorLoad: stepOrder.indexOf(WizardStep.FloorLoad) > -1,
      hasVisitedPropertyType: stepOrder.indexOf(WizardStep.PropertyType) > -1,
      hasVisitedRooms: stepOrder.indexOf(WizardStep.Rooms) > -1,
      hasVisitedVehicles: stepOrder.indexOf(WizardStep.Vehicles) > -1,
      hasVisitedRoomHeight: stepOrder.indexOf(WizardStep.RoomHeight) > -1,
      hasVisitedSubPropertyType: stepOrder.indexOf(WizardStep.SubPropertyType) > -1,
    };
  }),
  on(WizardActions.resetAdditional, state => {
    return {
      ...state,
      hasVisitedEquipment: false,
      hasVisitedConstructionYear: false,
      hasVisitedLivingArea: false,
      hasVisitedUsableArea: false,
      hasVisitedPlotArea: false,
      hasVisitedPrice: false,
      hasVisitiedPriceM2: false,
      hasVisitedGrossReturn: false,
      hasVisitedFloorLoad: false,
      hasVisitedRooms: false,
      hasVisitedVehicles: false,
      hasVisitedRoomHeight: false,
      hasVisitedSubPropertyType: false,
    };
  }),
  on(WizardActions.resetAll, state => {
    return {
      ...state,
      hasVisitedEquipment: false,
      hasVisitedLocation: false,
      hasVisitedLivingArea: false,
      hasVisitedUsableArea: false,
      hasVisitedPlotArea: false,
      hasVisitedConstructionYear: false,
      hasVisitedPrice: false,
      hasVisitedGrossReturn: false,
      hasVisitedFloorLoad: false,
      hasVisitedPropertyType: false,
      hasVisitedRooms: false,
      hasVisitedVehicles: false,
      hasVisitedRoomHeight: false,
      hasVisitedSubPropertyType: false,
    };
  }),
  on(WizardActions.setOrigination, (state, action) => {
    return {
      ...state,
      originatesFromLanding: action.isFromLanding,
    };
  }),
  on(WizardActions.setSelectedProperty, (state, action) => {
    return {
      ...state,
      selectedProperty: action.selectedProperty,
    };
  }),
);

export const reducer = (state: State | undefined, action: Action) => wizardReducer(state, action);
