import { createAction, props } from '@ngrx/store';
import { ProviderAdvertsRequest, ProviderAdvertsResponse } from 'newhome.dtos';

export const loadProviderResultsSuccess = createAction(
  '[ProviderResult] Load ProviderResults Success',
  props<{ response: ProviderAdvertsResponse }>(),
);

export const loadProviderResultsFailure = createAction(
  '[ProviderResult] Load ProviderResults Failure',
  props<{ error: any }>(),
);

export const beginLoadProviderResults = createAction(
  '[ProviderResult] Begin Load ProviderResults',
  props<{ request: ProviderAdvertsRequest }>(),
);
