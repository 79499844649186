import { Component, HostBinding, HostListener, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SwitchTheme, defaultTheme } from './theme';
import { CheckboxControls } from '../common/checkbox/controls';

@Component({
  selector: 'nh-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent extends CheckboxControls {
  @Input()
  @HostBinding('attr.theme')
  theme: SwitchTheme = defaultTheme;

  @Input() switchId: string;

}
