import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { langPattern } from 'app/language';
import { notAvailableMap } from 'app/shared/seo/mappings';

export const notAvailableMatcher: UrlMatcher = (segments: UrlSegment[], groups: UrlSegmentGroup): UrlMatchResult => {
  const languageIndex = 0;
  const notAvailableSufflixIndex = 1;

  const language = segments[languageIndex]?.path;
  if (!language?.match(langPattern)) {
    return null;
  }

  const notFoundSuffix = segments[notAvailableSufflixIndex]?.path;
  if (notAvailableMap[language] !== notFoundSuffix) {
    return null;
  }

  return {
    consumed: segments,
    posParams: {
      lang: segments[languageIndex],
    },
  };
};
