import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StatisticEffects } from './statistic.effects';
import * as fromStatistic from './statistic.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([StatisticEffects]),
    StoreModule.forFeature(fromStatistic.statisticFeatureKey, fromStatistic.reducer),
  ],
})
export class StatisticModule {}
