import { Directive, Input } from '@angular/core';
import { PillTheme } from '../theme';
import { NH_DIVIDED_PILL_WRAPPER } from '../tokens';

@Directive({
  selector: 'nh-divided-pill',
  providers: [
    {
      provide: NH_DIVIDED_PILL_WRAPPER,
      useExisting: DividedPillDirective
    }
  ]
})
export class DividedPillDirective {
  @Input() theme: PillTheme;
}
