import { createAction, props } from '@ngrx/store';
import { CreateSearchSubscriptionRequest, CreateSearchSubscriptionResponse } from 'newhome.dtos';

export const beginCreateSearchSubscription = createAction(
  '[Result] Begin Create Search Subscription',
  props<{ data: CreateSearchSubscriptionRequest }>(),
);

export const removeSearchSubscriptionResponse = createAction('[Result] Remove Search Subscription Response');

export const createSearchSubscriptionSuccess = createAction(
  '[Result] Create Search Subscription Success',
  props<{ response: CreateSearchSubscriptionResponse }>(),
);

export const createSearchSubscriptionFailure = createAction(
  '[Result] Create Search Subscription Failure',
  props<{ error: any }>(),
);
